export const TABLE_COLUMNS_IND_LIST =
    [
        { prop: "name", name: "indicator_singular Name" },
        { prop: "description", name: "indicator_singular Description" },
        { prop: "classification", name: "Classification" },
        { prop: "type", name: "Type" },
        { prop: "lastRunDate", name: "Last Run Date" },
        { prop: "numOfSightings", name: "sighting_plural" },
        { prop: "coa", name: "Recommended coa_singular" },
        { prop: "active", name: "Activate Deactivate" },
        { prop: "execute", name: "Execute" },
    ];
