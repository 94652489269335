import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EnvService } from "../../env.service";;
import { Document } from "../models/document";
import { RivirHttp } from "./rivir-http.service";
import { catchError } from 'rxjs/operators';

export class TaskDocument {
    public CreatedBy: string;
    public CreatedDateTime: Date;
    public description: string;
    public id: string;
    public taskId: string;
    public taskName: string;
    public title: string;
    public url: string;
}

@Injectable()
export class DocumentService {
    private documentsUrl : string;
    private threatActorsUrl : string;
    private surveyDocsUrl : string;
    private surveyDocsUploadUrl: string;


    public downloadUrl: string;
    public allowedFileTypes: string[];
    public docMaxSize: number;

    constructor(private http: HttpClient, private env: EnvService) { 
        this.documentsUrl = this.env.rivirApiBaseUrl + "/documents";
        this.threatActorsUrl = this.env.rivirApiBaseUrl + "/threatActors";
        this.surveyDocsUrl = this.env.rivirApiBaseUrl + "/taskDocuments";
        this.surveyDocsUploadUrl = this.env.rivirApiBaseUrl + "/profilePicture";
        this.downloadUrl = `${this.env.rivirApiBaseUrl}/profilePicture/${this.env.uploadContainer}/download/`;
        this.allowedFileTypes = this.env.coaAttachmentAllowedFileTypes ||
            ["txt", "pdf", "png", "jpg", "jpeg", "gif", "html", "csv", "doc", "docx", "xls", "xlsx", "ppt", "pptx", "tif", "tiff"];
        this. docMaxSize = this.env.coaAttachmentAllowedMaxSize || 104857600;
    }

    public getDocuments(): Observable<Document[]> {
        const url: string = this.documentsUrl + "/getAll";
        return this.http.get<Document[]>(url, RivirHttp.getHttpOptions())
            .pipe(catchError(RivirHttp.handleError));
    }

    public getDocumentsForThreatActor(filterValue: string, isRoleView: boolean): Observable<Document[]> {
        // we'll need to query for threat actors here
        const filter = `?filter={"where" : {"threatActorId" : "${filterValue}"}}`;
        const url: string = this.threatActorsUrl + filter;

        return this.http.get<Document[]>(url, RivirHttp.getHttpOptions())
            .pipe(catchError(RivirHttp.handleError));
    }

    public searchDocumentsForSurvey(taskId: string, searchTerm: string = ""): Observable<TaskDocument[]> {
        const filter = `/getTaskAttachments?taskId=${taskId}&search=${searchTerm}`;

        const url: string = this.surveyDocsUrl + filter;
        return this.http.get<TaskDocument[]>(url, RivirHttp.getHttpOptions())
            .pipe(catchError(RivirHttp.handleError));
    }

    public getDocumentsForSurvey() {
        const url: string = this.surveyDocsUrl;
        return this.http.get<any>(url, RivirHttp.getHttpOptions())
            .pipe(catchError(RivirHttp.handleError));
    }

    public saveDocumentForSurvey(fileUpload: File, fileName: string) {
        const url: string = this.surveyDocsUploadUrl + `/${this.env.uploadContainer}/upload`;

        const formData: FormData = new FormData();
        formData.append("uploadFile", fileUpload, fileName);
        return this.http
            .post(url, formData, RivirHttp.getHttpOptions())
            .pipe(catchError(RivirHttp.handleError));
    }

    public saveDocumentDataForSurvey(data: any) {
        const url: string = this.surveyDocsUrl;

        return this.http
            .post(url, data, RivirHttp.getHttpOptions())
            .pipe(catchError(RivirHttp.handleError));
    }

    public deleteDocumentDataForSurvey(id: any) {
        const url: string = this.surveyDocsUrl + `/${id}`;

        return this.http
            .delete(url, RivirHttp.getHttpOptions())
            .pipe(catchError(RivirHttp.handleError));
    }

    public downloadDocumentDataForSurvey(urlStr: any, fileStr: any) {
        this.http.get(urlStr, { responseType: "blob" }).subscribe((res: any) => {
            const dataType = res.type;
            const binaryData = [];
            binaryData.push(res);
            const downloadLink = document.createElement("a");
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
            downloadLink.setAttribute("download", fileStr);
            document.body.appendChild(downloadLink);
            downloadLink.click();
        });
    }

}
