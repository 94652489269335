import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';

import { ToastrService } from "ngx-toastr";

import { CourseOfActionView, CaseService } from "../case-service";
import { StixConfigService } from "../../shared/services/stixConfig.service";
import { ConfirmDialogComponent } from "../../confirm-dialog/confirm-dialog.component";

import { fromEvent } from "rxjs";
import { tap } from 'rxjs/operators';
import { debounceTime } from 'rxjs/operators';
import { distinctUntilChanged } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';


@Component({
  selector: 'case-coas',
  templateUrl: './case-coas.component.html',
  styleUrls: ['./case-coas.component.css']
})
export class CaseCoasComponent implements OnInit {

  @Input() courseOfActions: CourseOfActionView[];
  @Input() caseId: string;
  @ViewChild(MatPaginator) public paginator: MatPaginator;
  @ViewChild(MatSort) public sort: MatSort;
  @ViewChild('input') input: ElementRef;

  public displayedColumns: string[] = [
    "id",
    "courseOfActionName",
    "coaDueDate",
    "status",
    "task",
    "UpdatedBy",
    "indicatorName",
    "sightingCreatedDateTime",
    "taId"
  ];
  public dataSource = new MatTableDataSource<CourseOfActionView>();

  constructor(
    public stixConfig: StixConfigService,
    public caseService: CaseService,
    public dialog: MatDialog,
    public toastr: ToastrService,
    private router: Router,
    private cookieService: CookieService
  ) { }

  ngOnInit() {

    this.dataSource.data = this.courseOfActions.map(c => {
      c.coaDueDate = c.coaDueDate ? new Date(c.coaDueDate).toLocaleDateString("en-us") : c.coaDueDate;
      return c;
    });
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'coaDueDate': return new Date(item.coaDueDate);
        default: return item[property];
      }
    };
  }

  public ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.dataSource.filter = this.input.nativeElement.value.trim().toLocaleLowerCase();
        })
      )
      .subscribe();
  }

  openUnlinkDialog(element: CourseOfActionView, event: MouseEvent) {

    if (this.courseOfActions.length !== 1) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: `Un-link ${this.stixConfig.coa_singular}`,
          message: `Are you sure you would like to unlink the ${this.stixConfig.coa_singular} [${element.instanceUIId}] from this Case [${this.caseId}]?`
        }
      });

      dialogRef.afterClosed().subscribe(confirmed => {

        if (confirmed) {
          this.unlinkCoa(element, event);
        }
      });
    }
    else {
      this.toastr.warning(`The case must have at least one ${this.stixConfig.coa_singular}.  The ${this.stixConfig.coa_singular} cannot be unlinked from the case.`)
    }
  }

  unlinkCoa(element: CourseOfActionView, event: MouseEvent) {
    this.caseService.modifyCaseCourseOfAction(this.caseId, element.instanceId, "REMOVE", this.cookieService.get("email")).subscribe(result => {

      // Remove the course of action from the list
      this.courseOfActions = this.courseOfActions.filter(coa => coa.instanceId !== element.instanceId);
      this.dataSource.data = this.courseOfActions.map(c => {
        c.coaDueDate = c.coaDueDate ? new Date(c.coaDueDate).toLocaleDateString("en-us") : c.coaDueDate;
        return c;
      });

      this.toastr.info(`${this.stixConfig.coa_singular} [${element.instanceUIId}] has been unlinked from Case [${this.caseId}]`);
    });
  }

  redirectToCaseEdit() {
    this.router.navigate(["/case/edit", this.caseId]);
  }

}
