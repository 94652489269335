import { Component, OnInit, Input } from '@angular/core';
import {
  CaseService,
  CaseStatusCount
} from "../case-service";


@Component({
  selector: 'app-case-counts',
  templateUrl: './case-counts.component.html',
  styleUrls: ['./case-counts.component.css']
})
export class CaseCountsComponent implements OnInit {

  public caseCounts: CaseStatusCount[];
  public matCardWidth: string = "0%";
  public statusColClass: string = "";
  @Input() public threatActorId: any;

  constructor(
    private caseService: CaseService
  ) { }

  async ngOnInit() {
    this.threatActorId = this.threatActorId || null;
    this.caseCounts = await this.caseService.getCaseStatusCounts(this.threatActorId);
    if (this.caseCounts.length == 0) {
      this.caseCounts = [{
          color: "#000000",
          count: 0,
          status: "Cases Found"
      }];
    }
  
    // Based on how many status counts there are determines the size 
    // for the material card and the width of each status column
    switch (this.caseCounts.length) {
      case 1:
        this.matCardWidth = "10%";
        this.statusColClass = "col-md-12";
        break;
      case 2:
        this.matCardWidth = "20%";
        this.statusColClass = "col-md-6";
        break;
      case 3:
        this.matCardWidth = "30%";
        this.statusColClass = "col-md-4";
        break;
      case 4:
        this.matCardWidth = "40%";
        this.statusColClass = "col-md-3";
        break;
      case 5:
        this.matCardWidth = "50%";
        this.statusColClass = "col-md-2";
        break;
      case 6:
        this.matCardWidth = "60%";
        this.statusColClass = "col-md-2";
        break;
      default:
        this.matCardWidth = "60%";
        this.statusColClass = "col-md-2";
        break;
    };

  }

}
