<table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort matSortActive="modified"
    matSortDirection="desc" style="width:100%">
    <!-- Course of Action ID Column -->
    <ng-container matColumnDef="instanceUIId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            ID
        </th>
        <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="!element.workflowComplete">
                <a routerLink="/coa/task/{{element.instanceId}}" class="cases-coas-link">
                    {{ element.instanceUIId }}</a>
            </ng-container>
            <ng-container *ngIf="element.workflowComplete">
                <span class="cases-coas-id">{{ element.instanceUIId }}</span>
            </ng-container>
        </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="courseOfActionName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Name
        </th>
        <td mat-cell *matCellDef="let element">
            {{ element.courseOfActionName }}
        </td>
    </ng-container>

    <!-- Number of Fields Column -->
    <ng-container matColumnDef="numberOfFields">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            # of Edits
        </th>
        <td mat-cell *matCellDef="let element">
            <span class="cases-coas-edits">{{ element.numberOfFields }}</span>
        </td>
    </ng-container>

    <!-- Fields Changed Column -->
    <ng-container matColumnDef="fieldsExpanded">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Field(s) Changed
        </th>
        <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.isFieldTextTruncated">
                {{ element.fieldsTruncated }} <button class="btn btn-link"
                    (click)="element.isFieldTextTruncated = false">...</button>
            </ng-container>

            <ng-container *ngIf="!element.isFieldTextTruncated">
                {{ element.fieldsExpanded }}
            </ng-container>
        </td>
    </ng-container>

    <!-- Modified By Column -->
    <ng-container matColumnDef="modifiedUserFullName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Modified By
        </th>
        <td mat-cell *matCellDef="let element">
            <div class="case-coa-user"> {{ element.modifiedUserFullName }}</div>
        </td>
    </ng-container>

    <!-- Task Column -->
    <ng-container matColumnDef="modified">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 40px;">
            Modified
        </th>
        <td mat-cell *matCellDef="let element">
            <div class="case-coa-modified"> {{ element.modified | date: 'MM/dd/yyyy, h:mm a'}}</div>
        </td>
    </ng-container>

    <!-- History Details Column -->
    <ng-container matColumnDef="instanceId">
        <th mat-header-cell *matHeaderCellDef>
            <span style="display: none; visibility: hidden;">View</span>
        </th>
        <td mat-cell *matCellDef="let element">
            <a routerLink="/coa/{{element.instanceId}}/activity/{{element.activityKey}}"
                class="cases-coas-link cases-coas-view-link">
                View</a>
        </td>
    </ng-container>

    <!-- RIViR Top Header -->
    <ng-container matColumnDef="rivir-top-header">
        <th mat-header-cell *matHeaderCellDef [attr.colspan]="7" class="rivir-mat-top-header">
            <div class="row">
                <div class="col-md-10" style="color:#fcfcfc; font-size:17px;">
                    List of {{stixConfig.coa_plural}}
                </div>
                <div class="col-md-2">
                    <a class="refresh-list" (click)="refreshList()">
                        <i class="fa fa-refresh"></i> Refresh List
                    </a>
                </div>
            </div>
        </th>
    </ng-container>

    <!-- RIViR Search Header -->
    <ng-container matColumnDef="rivir-search-header">
        <th mat-header-cell *matHeaderCellDef [attr.colspan]="10">
            <div class="row">
                <div class="col-md-9"></div>
                <div class="col-md-1 rivir-mat-search-label">
                    Filter:
                </div>
                <div class="col-md-2">
                    <input #input type="search" class="form-control" title="Filter">
                </div>
            </div>
        </th>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="['rivir-top-header']">
    </tr>
    <tr mat-header-row *matHeaderRowDef="['rivir-search-header']">
    </tr>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index"
        [ngClass]="i % 2 ? 'rivir-mat-table-row-even' : 'rivir-mat-table-row-odd'"></tr>
</table>

<mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 50]">
</mat-paginator>