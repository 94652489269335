import { Component, OnInit, Input } from '@angular/core';
import { FilterPipe } from '../../shared/pipes/filter.pipe';
import { StixConfigService } from '../../shared/services/stixConfig.service';

@Component({
  selector: 'app-groups-assignment',
  templateUrl: './groups-assignment.component.html',
  styleUrls: ['./groups-assignment.component.css'],
  providers: [FilterPipe]
})
export class GroupsAssignmentComponent implements OnInit {

  @Input() public groups;
  @Input() public submitted;
  public groupsToAssign = []
  public selectedGroups = []
  public selectedGroupsToAssign = []
  public term = '';
  public original_groups = [];
  public percentageType = "EQUAL";
  public usersToAssign = [];
  public totalPercentage = 0;
  public label_coa: string;

  constructor(private filterPipe: FilterPipe, public stixConfig: StixConfigService) { }

  ngOnInit() {

    this.label_coa = this.stixConfig.coa_singular;

    const groups = []
    this.groups.forEach(group => {
      let groupUsers = group.users ? group.users.filter(user => (user.active)) : [];
      groups.push({ name: group.name, users: groupUsers });
      this.original_groups.push({ name: group.name, users: groupUsers });
    });
    this.groups = groups;
  }


  public addSelected() {
    if (this.selectedGroups.length > 0) {
      this.selectedGroups.forEach((group) => {
        if (group.users.length > 0) {
          this.groups.splice(this.groups.indexOf(group), 1);
          let i = this.original_groups.findIndex(g => g.name == group.name)
          this.original_groups.splice(i, 1);
          this.groupsToAssign.push(group);
        }
      });
      this.refreshView()
      this.collectUsersFromSelectedGroups()
    }
  }

  public removeSelected() {
    if (this.selectedGroupsToAssign.length > 0) {

      this.selectedGroupsToAssign.forEach((group) => {
        this.groupsToAssign.splice(this.groupsToAssign.indexOf(group), 1)
        this.groups.push(group);
        this.original_groups.push(group);
      });
      this.refreshView()

      for (let i = this.usersToAssign.length - 1; i >= 0; --i) {
        let user = this.usersToAssign[i];
        let index = this.groupsToAssign.findIndex(g => g.name == user.groupName);
        if (index == -1) {
          this.usersToAssign.splice(i, 1)
        }
      }
      this.collectUsersFromSelectedGroups()
    }
  }

  public filterRows() {
    let _groups = [];
    this.original_groups.forEach(group => {
      _groups.push({ name: group.name, users: group.users });
    });
    this.groups = this.filterPipe.transform(_groups, this.term, [{ prop: "name" }]);
  }

  public collectUsersFromSelectedGroups() {
    this.groupsToAssign.forEach(group => {
      for (var i = 0; i < group.users.length; i++) {
        let user = group.users[i];

        let index = this.usersToAssign.findIndex(u => user.email == u.email);
        if (index == -1) {
          this.usersToAssign.push({ name: user.name, email: user.email, percent: 0, groupName: group.name });
        }
      }
    });
    if (this.percentageType == 'CUSTOM') {
      this.percentChange(null)
    } else {
      this.dividePercentageEqually()
    }
  }

  public percentChange(item) {
    this.totalPercentage = 0;
    if (item && !parseFloat(item.percent)) {
      item.percent = 0;
    } else if (item && !isNaN(Math.trunc(item.percent))) {
      item.percent = Math.trunc(item.percent);
    }

    for (var i = 0; i < this.usersToAssign.length; i++) {
      this.usersToAssign[i].percent = parseFloat(this.usersToAssign[i].percent)
      this.totalPercentage = this.totalPercentage + this.usersToAssign[i].percent;
    }
  }

  private dividePercentageEqually() {
    let percent = 100 / this.usersToAssign.length;
    for (var i = 0; i < this.usersToAssign.length; i++) {
      this.usersToAssign[i].percent = percent;
    }
    this.totalPercentage = 100;
  }

  percentageTypeChange() {
    if (this.percentageType == 'CUSTOM') {
      for (var i = 0; i < this.usersToAssign.length; i++) {
        this.usersToAssign[i].percent = 0;
      }
      this.totalPercentage = 0;
    } else {
      this.dividePercentageEqually();
    }
  }

  private refreshView() {
    let groups = [];
    this.groups.forEach(group => {
      groups.push({ name: group.name, users: group.users });
    });
    this.groups = groups;
    this.selectedGroups = [];
    this.selectedGroupsToAssign = [];

    if (this.term) {
      this.filterRows();
    }

    let usersToAssign = [];
    this.usersToAssign.forEach(u => {
      usersToAssign.push(u);
    });
    this.usersToAssign = usersToAssign;

    if (this.groupsToAssign.length > 0) {
      this.groupsToAssign.sort((a, b) => {
        if (a.name > b.name) return 1
        if (a.name < b.name) return -1
        if (a.name = b.name) return -0
      })
    }

  }

}
