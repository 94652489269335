import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { catchError } from 'rxjs/operators';

import { EnvService } from "../env.service";
import { RivirHttp } from "../shared/services/rivir-http.service";
import { StixConfigService } from "../shared/services/stixConfig.service";
import { PermissionService } from "../core/permission.service";

@Injectable()
export class ThreatActorService {
  /* ThreatActor List variables */
  public critiria = {
    filterProperties: ["taId", "name", "referenceDataID", "type"],
    pageNumber: 1,
    pageLimit: 10,
    sortBy: "name",
    asc: true,
    filterBy: "",
  };

  /* Sighting's ThreatActors List variables */
  public dateFormatFields: string[] = [];
  public sighting_critiria = {
    sightingName: "",
    page: 1,
    count: 10,
    sort: null,
    filter: null,
  };
  public selected_ThreatActors: string[] = [];

  private _threatActorUrl: string;
  private _sightingsUrl: string;
  private _externalDataSourceUrl: string;

  constructor(
    private _http: HttpClient,
    private permissions: PermissionService,
    public stixConfig: StixConfigService,
    private env: EnvService
  ) { 
    this._threatActorUrl = this.env.rivirApiBaseUrl + "/threatactors";
    this._sightingsUrl = this.env.rivirApiBaseUrl + "/sightings";
    this._externalDataSourceUrl = this.env.rivirApiBaseUrl + "/externalDataSource";
  }

  public getThreatActorList(): Observable<any> {
    return this._http
      .get<any>(this._threatActorUrl, RivirHttp.getHttpOptions())
      .pipe(catchError(RivirHttp.handleError));
  }

  public getFormattedThreatActors(): Observable<any> {
    const params = "?critiria=" + JSON.stringify(this.critiria);
    return this._http
      .get<any>(
        this._threatActorUrl + "/getFormattedThreatActors" + params,
        RivirHttp.getHttpOptions(),
      )
      .pipe(catchError(RivirHttp.handleError));
  }

  public getAllThreatActorsCount(filter): Observable<any> {
    let params: string = null;

    if (filter) {
      const invalid = /[°"§%()\[\]{}=\\?´`'#<>|,;.:+_-]+/;
      const filterRegExp = filter.replace(invalid, "");
      params = filter
        ? `?where={"or": [` +
        `{"name" : {"regexp": "/${filterRegExp}/i" }},` +
        `{"type" : {"regexp": "/${filterRegExp}/i" }},` +
        `{"referenceDataID" : {"regexp": "/${filterRegExp}/i" }},` +
        `{"taId" : {"regexp": "/${filterRegExp}/i" }}]}`
        : "";
    } else {
      params = "";
    }
    return this._http
      .get<any>(
        this._threatActorUrl + "/count" + params,
        RivirHttp.getHttpOptions(),
      )
      .pipe(catchError(RivirHttp.handleError));
  }

  public getThreatActorDetails(id): Observable<any> {
    return this._http
      .get<any>(
        this._threatActorUrl + "/details/" + id,
        RivirHttp.getHttpOptions(),
      )
      .pipe(catchError(RivirHttp.handleError));
  }

  public getThreatActor(id): Observable<any> {
    return this._http
      .get<any>(this._threatActorUrl + "/" + id, RivirHttp.getHttpOptions())
      .pipe(catchError(RivirHttp.handleError));
  }

  public getThreatActorsBySighting(name): Observable<any> {
    return this._http
      .get<any>(
        this._threatActorUrl + "/getThreatActorsBySighting/" + name,
        RivirHttp.getHttpOptions(),
      )
      .pipe(catchError(RivirHttp.handleError));
  }

  public getFilteredThreatActorsBySighting(params): Observable<any> {
    return this._http
      .post<any>(
        this._sightingsUrl + "/getFilteredThreatActors",
        params,
        RivirHttp.getHttpOptions(),
      )
      .pipe(catchError(RivirHttp.handleError));
  }

  public getExternalSupportingDataCSV(name, table, sourceQuery, orderBy): Observable<any> {
    // httpReq object passed to allow timeout to be set
    let httpReq = new XMLHttpRequest();
    const params = `?req=${httpReq}&name=${name}&table=${table}&identifier=${JSON.stringify(sourceQuery)}&orderBy=${JSON.stringify(orderBy)}`;
    return this._http
      .get<any>(
        this._externalDataSourceUrl + "/csv" + params,
        RivirHttp.getHttpOptions(),
      )
      // removing timeout due to nginix conflict
      //.timeout(2000000)
      .pipe(catchError(RivirHttp.handleError));

  }

  public replaceThreatActor(instance: any): Observable<any> {
    const url = this._threatActorUrl + "/" + instance.id + "/" + "replace";
    return this._http
      .post(url, instance, RivirHttp.getHttpOptions())
      .pipe(catchError(RivirHttp.handleError));
  }

  public ignoreThreatActor(id, ignoreValue): Observable<any> {
    const url = this._threatActorUrl + "/ignore";
    const ignorePayload = { id, ignoreValue };
    return this._http
      .post(url, ignorePayload, RivirHttp.getHttpOptions())
      .pipe(catchError(RivirHttp.handleError));
  }

  public getTableColumns(tableName): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      let columns = tableName;
      this.permissions.getPermission("Ignore Threat Actor").then((p1) => {
        columns = !p1 ? columns.filter((col) => col.prop !== "ignore") : columns;
        this.stixConfig.stixLabeling(columns);
        resolve(columns);
      });
    });
  }

  public modifyFilter(prop, string) {
    if (!this.sighting_critiria.filter) {
      this.sighting_critiria.filter = {};
    }
    if (string) {
      this.sighting_critiria.filter[prop] = string.trim();
      if (
        this.dateFormatFields.indexOf(prop) != -1 &&
        new Date(string).toString() != "Invalid Date"
      ) {
        this.sighting_critiria.filter[
          prop
        ] = this.formatISODateWithoutLocalTime(string);
      }
    }
    else {
      delete this.sighting_critiria.filter[prop];
    }

    if (Object.keys(this.sighting_critiria.filter).length < 1) {
      this.sighting_critiria.filter = null;
    }
  }

  public getGeoCordinates(address): Observable<any> {
    address = address.replace(/\s+/g, "+");
    return this._http
      .get<any>(
        "https://maps.googleapis.com/maps/api/geocode/json?address=" +
        address.replace() +
        "&key=AIzaSyD6iBcgnWH_hhu7Cr2I2Npsa5LODIoVUDI",
      )
      .pipe(catchError(RivirHttp.handleError));
  }

  public formatISODateWithoutLocalTime(string) {
    let dateISO = new Date(string).toISOString();
    const i = dateISO.indexOf("T");
    dateISO = dateISO.substring(0, i);
    dateISO = dateISO + "T00:00:00.000Z";
    return dateISO;
  }
}
