export const TABLE_COLUMNS_IND_DETAIL = [
  { prop: "CreatedDateTime", name: "Run Date" },
  { prop: "threatActorCount", name: "threatActor_plural" },
  { prop: "status", name: "Status" },
  { prop: "viewMoreDetails", name: "More Details" },
  { prop: "bulkStartResults", name: "Bulk Runs" },
  { prop: "reportStatus", name: "Supporting Data" }
];

export const LABELS_IND_DETAIL = [
  { prop: "types", name: "Type" },
  { prop: "classifications", name: "Classification" },
  { prop: "CreatedBy", name: "Created By" },
  { prop: "CreatedDateTime", name: "Created Date" },
  { prop: "coursesOfAction", name: "Recommended coa_singular" },
];
