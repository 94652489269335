// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    appKey: "rivir",
    envLogo: "envlogo.png",
    searchBar: true,
    reportBar: true,
    bulkAssignment: true,
    visualizationBaseUrl: "http://localhost:8000/",
    visualizationApiBaseUrl: "http://localhost:8200/api/",
    visualizationAuthApiBaseUrl: "http://localhost:3358/",
    rivirApiBaseUrl: "http://localhost:3000/api",
    workflowBaseUrl: "http://localhost:5050",
    keycloak: { url: "http://localhost:8080/auth", realm: "RIViR", clientId: "rivir" },
    uploadContainer: "elasticbeanstalk-us-east-1-441052774114",
    courseOfActionStatus: "Status",
    workflowClosedStatus: "Review Closed",
    redirectDashboard: { enable: false, to: "search" },
    showReportExport: true,
    reportSuffixes: [""],
    sessionIdleTimeout: 30,
    hasSearch: true,
    hasDataEntry: true,
    hasCaseManagement: true,
    hasReport: true,
    caseStatusCustomOrder: null,
    coaAttachmentAllowedFileTypes: ["txt", "pdf", "png", "jpg", "jpeg", "gif", "html", "csv", "doc", "docx", "xls", "xlsx", "ppt", "pptx", "tif", "tiff"],
    coaAttachmentAllowedMaxSize: 136314880,
    associatedDocumentsDescriptionRequired: true,
    tableau: true,
    useMayan: false
};
