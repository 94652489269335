import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';

import * as Survey from "survey-angular";
import * as SurveyEditor from "surveyjs-editor";

import { ToastrService } from "ngx-toastr";

import { ProcessDefinition, WorkflowService } from '../shared/services/workflow.service';
import { StixConfigService } from "../shared/services/stixConfig.service";
import { SurveyService } from "../shared/services/survey.service";
import { DataEntry, DataEntryListService } from '../data-entry-list/data-entry-list.service';

import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-data-entry-form',
  templateUrl: './data-entry-form.component.html',
  styleUrls: ['./data-entry-form.component.css']
})
export class DataEntryFormComponent implements OnInit {

  processDefintionKey: string;
  intakeForms: ProcessDefinition[];
  selectedForm: ProcessDefinition;
  editor: SurveyEditor.SurveyEditor;
  model: any;
  surveyIsLoading: boolean;
  instanceId: string;
  taskId: any;
  currentUser: string;
  public pages: any;
  dataText: string;
  dataEntryId : string;
  dataEntry : DataEntry;
  disableSaveSubmit: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private workflowService: WorkflowService,
    private cookieService: CookieService,
    private surveyService: SurveyService,
    public stixConfig: StixConfigService,
    public toastr: ToastrService,
    public stixConfigService: StixConfigService,
    public dataEntryService : DataEntryListService,
    public dialog: MatDialog
  ) { }

  async ngOnInit() {
    this.surveyIsLoading = true;
    const params =  await this.getParams();
    this.processDefintionKey = params.processDefintionKey;
    this.dataEntryId = params.dataEntryId;


    // Get the form from Camunda
    this.intakeForms = await this.workflowService.getIntakeForms();
    this.selectedForm = this.intakeForms.find( form => form.name === this.processDefintionKey );

    if ( this.dataEntryId ){
      const dataEntryResult= await this.dataEntryService.searchDataEntries( { dataEntryId : this.dataEntryId }, {}, 0, 10, {} ).toPromise();
      if (dataEntryResult && dataEntryResult.results && dataEntryResult.results.length){
        this.dataEntry = dataEntryResult.results.pop();

        // If the form has beed submited, disable Save & Submit
        this.disableSaveSubmit = this.dataEntry.status.trim().toLowerCase() === "submitted";
      }
    }



    // If we don't have a saved Data Entry and the form name is valid 
    // start a new workflow
    if (!this.dataEntry && this.selectedForm ){
      const currentUser = this.cookieService.get("email");
      const payload = {
        InitialUser: { value: currentUser, type: "String" },
        dataEntryFormName: { value: this.selectedForm.name, type: "String" }
      };

      const startedProcess : any = await this.workflowService
                                        .startProcessWithTenantId(
                                          this.selectedForm.key,
                                          this.selectedForm.tenantId,
                                          { payload } 
                                        );
      this.instanceId = startedProcess.id;

      await this.getDataEntrySurvey(this.instanceId);
    }
    // If we have a data entry, get the existing one from the database
    else if ( this.dataEntry && this.selectedForm ){
      await this.getDataEntrySurvey(this.dataEntry.instanceId, this.dataEntry);
    }
    else {
      this.router.navigate(["/data-entry"]);
    }
  }

  async getParams(): Promise<Params> {
    return new Promise( resolve => {
      this.route.params.subscribe((params) => {
        resolve(params);
      });
    });
  }

  public async getDataEntrySurvey(instanceId: string, dataEntry? : DataEntry): Promise<void> {
    
    const { task , taskVariables } = await this.surveyService.getIntakeForm(instanceId);

    if (taskVariables?.surveyJson){

      const surveyJson = JSON.parse(taskVariables?.surveyJson.value);

      this.taskId = task.id;
      this.model = new Survey.ReactSurveyModel(surveyJson);
      this.model.onAfterRenderPage.add(() => {
        this.surveyIsLoading = false;
      });
  
      if ( dataEntry ){
        this.model.data = dataEntry.form;
      }

      this.model.showCompletedPage = false;
      this.model.showNavigationButtons = false;
      this.model.showPageTitles = false;
      this.model.checkErrorsMode = "onComplete";
      this.model.clearInvisibleValues = "onHidden";
      this.pages = this.model.pages;
      Survey.SurveyNG.render("surveyContainer", { model: this.model });
  
      return;
    }
    else {
      this.toastr.error(`Failed to load ${this.selectedForm.name}`);
    }

  }

  public nextPage() {
    this.model.nextPage();
  }

  public prevPage() {
    this.model.prevPage();
  }

  public getPage($event, page) {
    $event.stopPropagation();
    $event.preventDefault();
    this.model.currentPageNo = page;
  }

  public async save(){
    this.surveyIsLoading = true;

    await this.saveDataEntryForm();

    this.router.navigate(["/data-entry-list"]);
  }

  public async saveDataEntryForm() : Promise<DataEntry>{

    let modelData = this.surveyService.deDuplicateValues(this.model.data);
    modelData = this.surveyService.replaceNonUnicodeCharacters( modelData );
    
    // Move this to initialization
    if ( !this.dataEntry ){
      this.dataEntry = {
        name: this.selectedForm.name,
        deploymentId: this.selectedForm.deploymentId,
        status : "In Progress", // Retain old status
        instanceId: this.instanceId,
        form: modelData,
      }
    }
    else {
      this.dataEntry.form = modelData;
    }


    return await this.dataEntryService.saveDataEntry( this.dataEntry );
  }

  public back(){
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: `Close ${this.stixConfig.dataEntry_singular}`,
        message: `Are you sure you want to close this ${this.stixConfig.dataEntry_singular} form?`
      }
    });

    dialogRef.afterClosed().subscribe(confirmed => {

      if (confirmed) {
        this.router.navigate(["/data-entry-list"]);
      }
    });
  }

  public async complete(){

    try {
      const isPageValid = !this.model.hasErrors(true, true);
      
      if (isPageValid) {
          this.surveyIsLoading = true;

          // Save it to the backend first 
          const dataEntry = await this.saveDataEntryForm();

          // Always use the current logged user as the assignee 
          const currentDateTime = `${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`;

          const result = await this.dataEntryService.completeDataEntry( dataEntry ); 

          this.toastr.info(`<b> Successful Entry</b><br/>${currentDateTime}<br/>${this.selectedForm.name}`, '', { closeButton: true, enableHtml: true }); 

          setTimeout(() => {
            // Redirect to newly created threat actor
            this.router.navigate([`/threatActor/${result.threatActorId}`]);
          }, 2500);
      }
    }
    catch(saveIntakeFormErr){
      console.error(saveIntakeFormErr);
      this.toastr.error(`Failed to save Data Entry Form ${this.selectedForm.name}`);
    }
  }
}
