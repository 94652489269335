  <mat-horizontal-stepper #stepper (selectionChange)="selectionChange($event)">
    <mat-step label="Script" [completed]="false" state="indicator">
        <indicator-edit></indicator-edit>
    </mat-step>
    <mat-step label="Details" [completed]="false" state="details">
        <indicator-wiz-details></indicator-wiz-details>
    </mat-step>
    <mat-step label="{{stixConfig.visualization_singular}}" [completed]="false" state="visual" >
        <indicator-wiz-visuals></indicator-wiz-visuals>
    </mat-step>
    <mat-step label="Recommended {{stixConfig.coa_singular}}" [completed]="false" state="coa">
        <indicator-wiz-rec-coa></indicator-wiz-rec-coa>
    </mat-step>
    <mat-step label="Autostart {{stixConfig.coa_singular}}" [completed]="false" state="auto">
        <indicator-wiz-autostart></indicator-wiz-autostart>
    </mat-step>
    <mat-step label="Review" [completed]="false" state="review">
        <indicator-wiz-review></indicator-wiz-review>
    </mat-step>
  </mat-horizontal-stepper>
  
  