import {
  HttpClient,
  HttpErrorResponse
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CookieService } from 'ngx-cookie-service';

import { Observable, forkJoin } from "rxjs";
import { catchError } from 'rxjs/operators';

import { EnvService } from "../../env.service";
import { LoggingService, LogLevel, LogType } from "../../core/logging.service";
import { UserProfileService } from "../../core/userProfile.service";
import { Sighting } from "../../indicators/indicators-list/sighting";
import { coa } from "../models/coa";
import { CourseOfActionDetails } from "../models/CourseOfActionDetails";
import { RolePermission } from "../models/rolePermission";
import { RivirHttp } from "./rivir-http.service";

@Injectable()
export class SightingService {
  private _sightingsUrl: string;
  private _rolePermissionUrl : string;
  private _coursesOfActionsUrl : string;
  private _documentsUrl: string;

  constructor(
    private _http: HttpClient,
    private userService: UserProfileService,
    private log: LoggingService,
    private cookieService: CookieService,
    private env: EnvService
  ) {
    this._sightingsUrl = this.env.rivirApiBaseUrl + "/sightings";
    this._rolePermissionUrl = this.env.rivirApiBaseUrl + "/rolepermissions";
    this._coursesOfActionsUrl =`${this.env.rivirApiBaseUrl}/courseofactions`;
    this._documentsUrl = this.env.rivirApiBaseUrl + "/taskDocuments";
   }

  public getSightings(): Observable<Sighting[]> {
    const url: string = this._sightingsUrl + "/getAll";
    return this._http
      .get<Sighting[]>(url, RivirHttp.getHttpOptions())
      .pipe(catchError(RivirHttp.handleError));
  }

  public getSightingsByName(name): Observable<Sighting[]> {
    const url = `${this._sightingsUrl
      }?filter={ "where" : { "name" : "${name}" }, "include" : "algorithm"}`;
    return this._http
      .get<Sighting[]>(url, RivirHttp.getHttpOptions())
      .pipe(catchError(RivirHttp.handleError));
  }

  public getFormattedSightings(): Observable<Sighting[]> {
    const url: string = this._sightingsUrl + "/getFormattedSightings";
    return this._http
      .get<Sighting[]>(url, RivirHttp.getHttpOptions())
      .pipe(catchError(RivirHttp.handleError));
  }

  public getCoursesOfAction(): Observable<coa[]> {
    return this._http
      .get<coa[]>(this._coursesOfActionsUrl, RivirHttp.getHttpOptions())
      .pipe(catchError(RivirHttp.handleError));
  }

  public getCourseOfActionDetails(
    courseOfActionId,
  ): Observable<CourseOfActionDetails> {
    return this._http
      .get<CourseOfActionDetails>(
        `${this._coursesOfActionsUrl}/details/${courseOfActionId}`,
        RivirHttp.getHttpOptions(),
      )
      .pipe(catchError(RivirHttp.handleError));
  }

  public getTaskDocuments(taskId): Observable<any> {
    const documentfilterUrl = `${this._documentsUrl
      }?filter={"where" : {"taskId" : "${taskId}"}`;
    return this._http
      .get<any>(documentfilterUrl, RivirHttp.getHttpOptions())
      .pipe(catchError(RivirHttp.handleError));
  }

  public getPermissions(
    filterValue: string,
    isRoleView: boolean,
  ): Observable<RolePermission[]> {
    const filter = isRoleView
      ? `?filter={"where" : {"role" : "${filterValue}"}}`
      : `?filter={"where" : {"page" : "${filterValue}"}}`;
    // let filter = '';
    const url: string = this._rolePermissionUrl + filter;

    return this._http
      .get<RolePermission[]>(url, RivirHttp.getHttpOptions())
      .pipe(catchError(RivirHttp.handleError));
  }

  public savePermissions(rolePermissions: RolePermission[]) {
    let responses = [];
    for (const perm of rolePermissions) {
      // Update the user and the date
      perm.UpdatedDateTime = new Date();
      perm.UpdatedBy = this.cookieService.get("email");

      const query = '{"id" : "' + perm.id + '"}';
      const executeUrl: string =
        this._rolePermissionUrl + "/upsertWithWhere?where=" + query;
      responses.push(this._http
        .post(executeUrl, perm, RivirHttp.getHttpOptions()))
    }
    return forkJoin(responses)
  }

  public updateVizualizationUserRoles(role, config, create, consume) {
    const url = this.env.rivirApiBaseUrl + 
      `/tableau/updateuserroles/${role}/${config}/${create}/${consume}`;

    const data = { 
      role: role,
      config: config,
      create: create,
      consume: consume
    };

    return this._http
      .post(
        url,
        data,
        RivirHttp.getHttpOptions(),
      );
  }

  public logClientEvent(message, data) {
    this.log.logClientEvent(
      message,
      LogLevel.Info,
      LogType.App,
      this.userService.userEmail,
      JSON.stringify(data),
    );
  }

  public checkForImagesContainer() {
    const url =
      this.env.rivirApiBaseUrl +
      `/profilePicture/${this.env.uploadContainer}`;

    return this._http
      .get(url, RivirHttp.getHttpOptions())
      .pipe(catchError(this.createImagesContainer));
  }

  public createImagesContainer(err: HttpErrorResponse) {
    console.log("Images container not found, creating images container...");
    const data = { name: this.env.uploadContainer };
    this._http
      .post(
        this.env.rivirApiBaseUrl + "/profilePicture",
        data,
        RivirHttp.getHttpOptions(),
      )
      .subscribe((data) => console.log("Images container created"));

    return Observable.throw(null);
  }
}
