
    export const TABLE_COLUMNS_TA_IND_LIST =
    [
        { prop: "name", name: "indicator_singular Name" },
        { prop: "description", name: "indicator_singular Description" },
        { prop: "classification", name: "Classification" },
        { prop: "type", name: "Type" },
        { prop: "lastRunDate", name: "Last Run Date" },
        { prop: "numOfThreatActors", name: "threatActor_plural" },
        { prop: "numOfOccurrence", name: "Occurrences" },
        { prop: "coursesOfAction", name: "Recommended coa_singular" },
    ];

    export const TABLE_COLUMNS_TA_COA_LIST =
    [
        { prop: "instance_Id", name: "coa_singular ID" },
        { prop: "courseOfActionName", name: "coa_singular Name" },
        { prop: "UpdatedDateTime", name: "Last Updated" },
        { prop: "UpdatedBy", name: "Last Assigned" },
        { prop: "status", name: "Status" },
        { prop: "coaDueDate", name: "Due Date" },
        { prop: "viewHistory", name: "coa_singular History" },
    ];

    export const TABLE_COLUMNS_TA_SIGHTING_LIST =
    [
        { prop: "type", name: "sighting_singular Type" },
        { prop: "indicatorId", name: "indicator_singular Id" },
        { prop: "indicatorName", name: "indicator_singular Name" },
        { prop: "classificationsDesc", name: "Classifications" },
        { prop: "runDate", name: "Run Date" },
        { prop: "threatActorCount", name: "Num of threatActor_plural" },
    ];
