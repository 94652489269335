import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { EnvService } from "../../env.service";
import { RivirHttp } from './rivir-http.service';
import { catchError } from 'rxjs/operators';

import * as moment from "moment";

@Injectable()
export class SurveyService {

    constructor(
        private http: HttpClient, private env: EnvService
    ) { }

    public async getTask(instanceId: string): Promise<any> {
        const getSurveyTaskUrl = `${this.env.rivirApiBaseUrl}/courseofactions/survey/${instanceId}`;
        return this.http.get<any>(getSurveyTaskUrl, RivirHttp.getHttpOptions())
            .pipe(catchError(RivirHttp.handleError)).toPromise();

    }

    public async saveTask(surveyJson: any, taskId, instanceId, assignee): Promise<any> {

        surveyJson = this.replaceNonUnicodeCharacters( surveyJson );

        const payload = { surveyJson, taskId, instanceId, assignee };
        const saveTaskUrl = `${this.env.rivirApiBaseUrl}/courseofactions/survey/save`;
        return this.http.post<any>(saveTaskUrl, payload, RivirHttp.getHttpOptions())
            .pipe(catchError(RivirHttp.handleError)).toPromise();

    }

    public completeTask(surveyJson: any, taskId, instanceId, assignee): Promise<any> {
        const payload = { surveyJson, taskId, instanceId, assignee };
        const saveTaskUrl = `${this.env.rivirApiBaseUrl}/courseofactions/survey/complete`;
        return this.http.post<any>(saveTaskUrl, payload, RivirHttp.getHttpOptions())
            .pipe(catchError(RivirHttp.handleError)).toPromise();

    }

    public setDefaultValuesForTask(task, surveyJson) {

        if (surveyJson) {
          for (const page of surveyJson.pages) {
    
            if (page.elements) {
              for (const element of page.elements) {
    
                if (element.defaultValue) {
                  task[element.name] = task[element.name] ? task[element.name] : element.defaultValue;
                }
    
                if (task[element.name]  && isNaN(task[element.name])) {
                  // Check to see if the value is a Date if so change it to yyyy-mm-dd format 
                  // ((currentValue instanceof Date) || moment(currentValue, serviceConfig.validDateFormats, true).isValid())
                  const validDateFormats = ["MM/DD/YY",
                    "MM/DD/YYYY", moment.ISO_8601];
                  if ((task[element.name] instanceof Date) || moment(task[element.name], validDateFormats, true).isValid()) {
                      if ( task[element.name] instanceof Date ){
                        task[element.name] = task[element.name].toISOString();
                      }
                      const dateString = task[element.name].replace('Z', '');
                      task[element.name] = moment(dateString).format("YYYY-MM-DD");
                    
                  }
                }

                // If an element is a number input and the value is string cast the string into a number 
                if ( element.inputType === "number" ){
                  if ( task[element.name] && typeof( task[element.name]) === 'string' ){
                    task[element.name] = Number( task[element.name].replace(/[^0-9.-]+/g,""));
                  }
                }

                // If an element is a boolean and the value is a string convert the string to boolean
                if ( element.type === "boolean"){
                  if ( task[element.name] && typeof( task[element.name]) === 'string' ){
                    task[element.name] =  task[element.name].toLowerCase() === 'true';
                  }
                }
              }
            }
          }
        }
    
        return task;
      }

      public timeout(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
      }

        /**
   * This is a work-around for a Camunda bug where if you set the default values for a checkbox 
   * list it duplicates the values 
   * @param modelData Survey JS model
   */
  public deDuplicateValues(modelData) {

    for (const [key, value] of Object.entries(modelData)) {

      if (Array.isArray(value) && this.IsStringArray(value)) {
        modelData[key] = value.filter((v, i, a) => a.indexOf(v) === i);
      }
    }

    return modelData;
  }

  public IsStringArray(arr) {
    return arr.every(i => (typeof i === "string"));
  }

  public async getIntakeForm(instanceId: string): Promise<any> {

    const getIntakeForm = `${this.env.rivirApiBaseUrl}/courseofactions/survey/intakeForm/${instanceId}`;
    return this.http.get<any>(getIntakeForm, RivirHttp.getHttpOptions())
    .pipe(catchError(RivirHttp.handleError)).toPromise();
  }

  public updateChoicesFromPreviousQuestions( task: any, surveyJson: any ){

    if ( surveyJson ){
      for (const page of surveyJson.pages ){
        for( const element of page.elements){

          if ( ( element.type === "dropdown" || element.type === "radiogroup" || element.type === "checkbox") && (element.otherText)) {

              // Find an the array in the 
              if ( task[element.otherText] ){

                // If the choices are a string, split by , to convert to an array
                if (typeof task[element.otherText] === 'string' || task[element.otherText] instanceof String){
                  task[element.otherText] = task[element.otherText].split(",");
                }

                element.choices = task[element.otherText];
              }
          }
          else if ( element.type === "matrixdynamic" || element.type === "matrixdropdown"  || element.type === "matrix" ){
            if ( task[ element.name ] ){
              element.defaultValue = task[ element.name ];
            }
          }
        }
      }
    }

    return surveyJson;
  }

  replaceNonUnicodeCharacters( surveyJson) {

      // Replace any curly apostrophes or double quotes from any values in the surveyJson
      for (const key of Object.keys(surveyJson)) {
        if (typeof surveyJson[ key ] === 'string' || surveyJson[ key ] instanceof String){
          surveyJson[ key ] = surveyJson[ key ].replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
        }
      }

      return surveyJson;
  }
}