<div class="row ">
  <div class="col-md-2 label-text text-center">
    <label for="groupSearch" class=" groups-header">Search Users:</label>
  </div>
  <div class="col-md-3">
    <input [(ngModel)]="term" class="form-control input-sm" type="search" (ngModelChange)="filterRows()" id="groupSearch" />
  </div>
  <div class="col-md-2"></div>
  <div class="col-md-5 label-text groups-header">Groups to Assign for {{label_coa}}</div>
</div>
<div class="row ">
  <div class="col-md-5">
    <div>
      <select name="filteredGroups" [(ngModel)]="selectedGroups" (ngModelChange)="selectedGroupsToAssign = []" multiple="multiple"
        size="15" class="form-control">
        <option *ngFor="let group of groups | orderBy: 'name':true" [ngValue]="group">
          {{group.name}} ({{group.users.length}})
        </option>
      </select>
    </div>
  </div>
  <div class="col-md-2 text-center">
    <div class="add-btn">
      <button type="button" class="btn btn-default group-btn" (click)="addSelected()">&#x3E;&#x3E;</button>
    </div>
    <div class="remove-btn">
      <button type="button" class="btn btn-default group-btn" (click)="removeSelected()">&#x3C;&#x3C;</button>
    </div>
  </div>
  <div class="col-md-5">
    <div>
      <select name="groupUsers" [(ngModel)]="selectedGroupsToAssign" (ngModelChange)="selectedGroups = []" multiple="multiple"
        size="15" class="form-control">
        <option *ngFor="let groupToAssign of groupsToAssign" [ngValue]="groupToAssign">
          {{groupToAssign.name}} ({{groupToAssign.users.length}})
        </option>
      </select>
    </div>
  </div>
</div>

<div class="row  m-t-xl">
  <div class="col-sm-12">
    <div class="row col-sm-12">
      <label for="rdoEQUAL" class="i-checks">
        <input type="radio" [(ngModel)]="percentageType" id="rdoEQUAL" name="radioValue" value="EQUAL" (ngModelChange)="percentageTypeChange()"
        />
        <i></i>Assign
        <b>Equal</b> percentages to group participants
      </label>
    </div>
    <div class="row col-sm-12">
      <label for="rdoCustom" class="i-checks">
        <input type="radio" [(ngModel)]="percentageType" id="rdoCustom" name="radioValue" value="CUSTOM" (ngModelChange)="percentageTypeChange()"
        />
        <i></i>Assign
        <b>Custom</b> percentages to group participants
      </label>
    </div>
  </div>
</div>

<div *ngIf="percentageType == 'EQUAL' && submitted && usersToAssign.length == 0" class="row m-t-lg">
  <div class="col-sm-8">
    <div class="alert alert-danger panel-body">No Groups Selected</div>
  </div>
</div>

<div *ngIf="percentageType == 'CUSTOM'" class="row m-t-lg">
  <div class="col-lg-8 col-sm-12">
    <div class="panel panel-default" [ngClass]="{'red-border': submitted && (totalPercentage < 100 || totalPercentage > 100)}">
      <div class="panel-heading bg-white">
      </div>
      <div *ngIf="usersToAssign.length == 0" class="no-selected panel-body" [ngClass]="{'alert alert-danger': submitted}">No Groups Selected</div>
      <div *ngIf="usersToAssign.length > 0" class="form-inline">
        <div class="row">
          <div class="col-sm-12">
            <table class="table table-striped b-t b-b" role="grid">
              <thead>
                <tr role="row">
                  <th style="opacity: 0.85;">Name</th>
                  <th style="opacity: 0.85;">Percentage</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of usersToAssign | orderBy: 'name': true" role="row">
                  <td>
                    <span title="" class="row-user">
                      {{item.name}} - {{item.email}}
                    </span>
                  </td>
                  <td>
                    <span title="" class="">
                      <input [(ngModel)]="item.percent" type="text" class="form-control percentage" (change)="percentChange(item)" > %
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="total-percentage">
              Total&nbsp;&nbsp; {{totalPercentage}}%
            </div>
            <div *ngIf="(totalPercentage > 100) || (submitted && totalPercentage < 100)" class="total-percentage alert-danger" style="font-size:14px">
              <i class="glyphicon glyphicon-warning-sign"></i> Total percentage must be equal to 100
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>