<table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort matSortActive="modified"
    matSortDirection="desc" style="width:100%">
    <!-- Description Column -->
    <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Action
        </th>
        <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.description !== 'coas'">
                {{ element.description }}
            </ng-container>
            <ng-container *ngIf="element.description === 'coas'">
                <div *ngIf="element.action ==='ADD'">Added Courses of Action:</div>
                <div *ngIf="element.action !=='ADD'">Removed Courses of Action:</div>
                <div *ngFor="let coa of element.updated" style="margin-left:20px;">
                    <a routerLink="/coa/task/{{coa.instanceId}}" class="cases-coas-link" *ngIf="!coa.workflowComplete">
                        {{coa.instanceUIId}} {{coa.courseOfActionName}}
                    </a>
                    <span *ngIf="coa.workflowComplete">
                        {{coa.instanceUIId}} {{coa.courseOfActionName}}
                    </span>
                </div>
            </ng-container>
        </td>
    </ng-container>

    <!-- Modified User Full Name Column -->
    <ng-container matColumnDef="modifiedUserFullName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Modified By
        </th>
        <td mat-cell *matCellDef="let element">
            {{ element.modifiedUserFullName }}
        </td>
    </ng-container>

    <!-- Modified Column -->
    <ng-container matColumnDef="modified">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 40px">
            Modified
        </th>
        <td mat-cell *matCellDef="let element">
            {{ element.modified | date: 'MM/dd/yyyy, h:mm a'}}
        </td>
    </ng-container>

    <!-- RIViR Top Header -->
    <ng-container matColumnDef="rivir-top-header">
        <th mat-header-cell *matHeaderCellDef [attr.colspan]="3" class="rivir-mat-top-header">
            <div class="row">
                <div class="col-md-10" style="color:#fcfcfc; font-size:17px;">
                    Case History
                </div>
                <div class="col-md-2">
                    <a class="refresh-list" (click)="refreshList()">
                        <i class="fa fa-refresh"></i> Refresh List
                    </a>
                </div>
            </div>
        </th>
    </ng-container>

    <!-- RIViR Search Header -->
    <ng-container matColumnDef="rivir-search-header">
        <th mat-header-cell *matHeaderCellDef [attr.colspan]="10">
            <div class="row">
                <div class="col-md-9"></div>
                <div class="col-md-1 rivir-mat-search-label">
                    Filter:
                </div>
                <div class="col-md-2">
                    <input #input type="search" class="form-control" title="Filter">
                </div>
            </div>
        </th>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="['rivir-top-header']">
    </tr>
    <tr mat-header-row *matHeaderRowDef="['rivir-search-header']">
    </tr>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index"
        [ngClass]="i % 2 ? 'rivir-mat-table-row-even' : 'rivir-mat-table-row-odd'"></tr>
</table>

<mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 50]">
</mat-paginator>