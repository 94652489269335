<div class="container-fluid" id="bulk-assignment">
  <div class="row">
    <mat-card class="bulk-assign-card">
      <mat-card-header>
        <mat-card-title class="col-sm-12">
          <span *ngIf="!coa_view">{{ label_indicator }}: {{ indicator }}</span>
          <span *ngIf="coa_view">{{ coa_title }}</span>
        </mat-card-title>
        <mat-card-subtitle class="col-sm-12" *ngIf="!coa_view">{{ label_sighting }}: {{ sighting }} </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div class="container-fluid">
          <div class="row" *ngIf="!coa_view">
            <div class="col-lg-2 col-md-4 col-sm-12" style="padding-right: 0;">
              {{ model.courseOfActionLabel }}
            </div>
            <div class="col-lg-4 col-md-7 col-sm-12">
              <ng-select [items]="model.coaOptions" bindLabel="text" bindValue="id"
                placeholder="{{coaSelectPlaceHolder}}" [multiple]="true" [closeOnSelect]="false"
                [(ngModel)]="selectedCOAs">
              </ng-select>
              <div *ngIf="submitted && selectedCOAs.length == 0" class="alert alert-danger">
                <div>At least one {{ label_coa }} is required</div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="!coa_view">
            <div class="col-lg-2 col-md-4 col-sm-12">
              Due Date:*
            </div>
            <div class="col-lg-4 col-md-7 col-sm-12">
              <input class="form-control date-input" type="date" [(ngModel)]="dueDate" #dueDateIn="ngModel"
                name="dueDate" min="{{todayDate}}" id="setDueDate" />
              <div *ngIf="submitted && !dueDate" class="alert alert-danger">
                <div>Due Date is required</div>
              </div>
            </div>
          </div>
          <div class="row" style="margin:20px auto;">
            <div class="col-lg-2 col-md-4 col-sm-12">
              <span *ngIf="!coa_view">{{ model.threatActorTableHeader }}</span>
              <span *ngIf="coa_view">{{ model.coaTableHeader }}</span>
            </div>
            <div [hidden]="showGrid" class="col-lg-4 col-md-7 col-sm-12">
              <button type="button" class="btn btn-link" (click)="toggleGrid(true)">
                + Expand to see {{ label_current_plural }}
              </button>
            </div>
            <div [hidden]="!showGrid" class="col-lg-7 col-md-7 col-sm-12">
              <div>
                <button type="button" class="btn btn-link" (click)="toggleGrid(false)">
                  - Minimize {{ label_current_plural }}
                </button>
              </div>

              <div id="bulk-grid" class="bulk-grid">
                <div class="spinner-container" *ngIf="(dataSourceTa && dataSourceTa.loading$) | async">
                  <mat-spinner></mat-spinner>
                </div>
                <table mat-table [dataSource]="dataSourceTa" class="mat-elevation-z8" *ngIf="!coa_view" matSort
                  matSortActive="taId" matSortDirection="asc" matSortDisableClear>
                  <!-- Position Column -->
                  <ng-container matColumnDef="taId">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      {{ label_threatActor }} ID
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.taId }}
                    </td>
                  </ng-container>

                  <!-- Name Column -->
                  <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      {{ label_threatActor }} Name
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.name }}
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>

                <div class="spinner-container" *ngIf="(dataSourceCoa && dataSourceCoa.loading$) | async">
                  <mat-spinner></mat-spinner>
                </div>
                <table mat-table [dataSource]="dataSourceCoa" class="mat-elevation-z8" matSort matSortActive="taId"
                  matSortDirection="asc" matSortDisableClear *ngIf="coa_view">
                  <!-- Position Column -->
                  <ng-container matColumnDef="coaId">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      {{ label_coa }} ID
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.instanceId.split("-")[0].toUpperCase() }}
                    </td>
                  </ng-container>

                  <!-- Name Column -->
                  <ng-container matColumnDef="coaName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      {{ label_coa }} Name
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding-left:5px">
                      {{ element.courseOfActionName }}
                    </td>
                  </ng-container>

                  <!-- Threat Actor Id Column -->
                  <ng-container matColumnDef="taId">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      {{ label_threatActor }} ID
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding-left:5px">
                      {{ element.taId }}
                    </td>
                  </ng-container>

                  <!-- Threat Actor Name Column -->
                  <ng-container matColumnDef="taName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      {{ label_threatActor }} Name
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding-left:5px">
                      {{ element.threatActorName }}
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumnsCoas"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsCoas"></tr>
                </table>

                <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 100, 1000]" [length]="paginationLength">
                </mat-paginator>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 m-t-sm">
              <mat-divider></mat-divider>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-2 col-md-4 col-sm-12">
              Assign to User:*
            </div>
            <div class="{{assignType == 'INDIVIDUAL'? 'col-lg-6':''}} col-md-8 col-sm-12">

              <div class="row">
                <label for="typeIndividual" class="i-checks m-r-xl">
                  <input type="radio" [(ngModel)]="assignType" id="typeIndividual" (ngModelChange)="assignTypeChange()"
                    name="typeIndividual" value="INDIVIDUAL" /><i></i>
                  Assign to individual user
                </label>
                <label for="typeGroup" class="i-checks">
                  <input type="radio" [(ngModel)]="assignType" id="typeGroup" (ngModelChange)="assignTypeChange()"
                    name="typeGroup" value="GROUP" /><i></i>
                  Assign to group users
                </label>

                <div *ngIf="assignType === 'INDIVIDUAL'" class="row col-lg-8 col-md-11" style="padding-left: 0">
                  <ng-select [items]="model.userOptions" [(ngModel)]="selectedUser.id" bindLabel="text" bindValue="id"
                    placeholder="Select User">
                  </ng-select>
                  <div *ngIf="submitted && !selectedUser.id" class="alert alert-danger">
                    <div>Individual User is required</div>
                  </div>
                </div>

                <div *ngIf="assignType === 'GROUP'" class=" m-t-md col-sm-12" style="padding-left: 0">
                  <app-groups-assignment #groupAssignment [groups]="model.groups" [submitted]="submitted">
                  </app-groups-assignment>
                </div>
                <div *ngIf="!assignType && submitted" class="row">
                  <div class="col-sm-6">
                    <div class="alert alert-danger">Assign to users is required</div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-lg-10 m-t-xl">
              <button (click)="save()" class="btn btn-primary col-sm-1 pull-right m-r-md">Start</button>
              <button (click)="goBack()" n class="btn btn-default col-sm-1 pull-right m-r-lg">Cancel</button>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>