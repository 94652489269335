import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { CookieService } from 'ngx-cookie-service';
import { EnvService } from "../env.service";
import { AppSettingService } from "../shared/services/appsetting.service";
import { RivirHttp } from "../shared/services/rivir-http.service";
import { StixConfigService } from "../shared/services/stixConfig.service";
import { KeycloakService } from "./keycloak/keycloak.service";
import { UserProfile } from "../shared/models/userProfile";

@Injectable()
export class StartupService {
  private _initialized = false;

  constructor(
    private http: HttpClient,
    private appSettings: AppSettingService,
    public stixConfig: StixConfigService,
    private kc: KeycloakService,
    private cookieService: CookieService,
    private env : EnvService
  ) { }

  public async load(): Promise<any> {

    try {
      this._initialized = null;

      // return new Promise((resolve) => {
      // Get the current user's keycloak token
      //const keycloakToken = await this.kc.getToken();
      const keycloakToken = await this.kc.getToken();

      // Load the User Profile from the RIViR API.  This also resets
      // the user's camunda password, Logs that they have logged in
      const userProfile = await this.http
        .post<UserProfile>(`${this.env.rivirApiBaseUrl}/keycloak/login`, { keycloakToken }).toPromise();

      // If the user is not authenticated with RIViR immediately log them out
      if (userProfile.status == "Unauthorized") {
        this.kc.logout( "Startup User Unauthorized" );
      } else {
        // Set the cookies used throughout the application
        this.setCookies(userProfile);

        let settings : any = await this.appSettings.getAppSetting(this.env.appKey).toPromise();
        settings = settings.results ? settings.results : settings;
        this.stixConfig.setStixConfig(settings.stixConfig || {});
        this._initialized = true;
        return this._initialized;
      }
    }
    catch( error ){
      window.location.href = "/error.htm";
      return true;
    }
  }

  get initialized(): any {
    return this._initialized;
  }

  /**
   * Sets all the userprofile cookie used by RIViR
   * @param userProfile Userprofile
   */
  public setCookies(userProfile: any) {
    if (userProfile.rivirUserProfile) {
      // TODO Refactor this to save the full object
      this.cookieService.set("email", userProfile.rivirUserProfile.email, 1);
      this.cookieService.set("firstName", userProfile.rivirUserProfile.firstName, 1);
      this.cookieService.set("lastName", userProfile.rivirUserProfile.lastName, 1);
      this.cookieService.set("role", userProfile.rivirUserProfile.accountType, 1);
      this.cookieService.set("userProfileId", userProfile.rivirUserProfile.id, 1);
      this.cookieService.set("profilePic", userProfile.rivirUserProfile.profilePicUrl, 1);
      this.cookieService.set(
        "userProfile",
        JSON.stringify(userProfile.rivirUserProfile),
        1,
      );
    }

    RivirHttp.rivirApiToken = userProfile.accessToken;
    RivirHttp.camundaToken = userProfile.camundaToken;
  }
}
