import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TableauReport, TableauService } from "../../core/tableau.service";
import tableau from "tableau-api-js";
import { UserProfileService } from '../../core/userProfile.service';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: 'app-reportpreview',
  templateUrl: './reportpreview.component.html',
  styleUrls: ['./reportpreview.component.css']
})
export class ReportpreviewComponent implements OnInit {

  public workBookId = "";
  viz: any;
  public tableauServer: string;
  public dashboard: TableauReport;
  public contentUrl = "";
  public disableShowBtn = false;
  public tableauView: string;
  public selectedWorkBook: any;
  public userDisplayMessage = `Would you like to display the visual on the Dashboard?`;
  public workBookTitle = "";
  public workBookError = false;

  constructor(
    private router: Router,
    private activatedroute: ActivatedRoute,
    private tableauService: TableauService,
    private userProfileService: UserProfileService,
    public dialog: MatDialog
  )
  {}

  ngOnInit(): void {
    this.workBookId = this.activatedroute.snapshot.paramMap.get('id');
    this.initTableau();
  }

  async initTableau() {
    this.dashboard = await this.tableauService.getUserDashboard(this.userProfileService.userEmail);
    if (this.dashboard && (this.dashboard.workBookId == this.workBookId)){
      this.disableShowBtn = true;
    }
    else{
      this.disableShowBtn = false;
    }
    this.loadWorkBook();
  }   

  async loadWorkBook(){
    this.selectedWorkBook = await this.tableauService.getWorkBookById(this.workBookId);
    if (!this.selectedWorkBook.tsResponse.error)
    {
      if (this.selectedWorkBook.tsResponse.workbook.views.view.contentUrl){
        this.contentUrl = this.selectedWorkBook.tsResponse.workbook.views.view.contentUrl.replace('/sheets','');
      }
      else{
        this.contentUrl = this.selectedWorkBook.tsResponse.workbook.views.view[0].contentUrl.replace('/sheets',''); 
      }
      this.workBookTitle = this.selectedWorkBook.tsResponse.workbook.name;

        const workBookUrl =  await this.tableauService.getWorkbookUrl( this.contentUrl );
        const containerDiv = document.getElementById("vizContainer");
        const options = { hideTabs: false, hideToolbar: false };
        this.viz = new tableau.Viz( containerDiv, workBookUrl, options );
      
    }
    else{
      this.workBookError = true;
    }

  }

  setDashBoard(){
    if(this.dashboard){
      this.userDisplayMessage = `Would you like to replace the dashboard visual?`;
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: `Report: ${this.selectedWorkBook.tsResponse.workbook.name} `,
        message: this.userDisplayMessage
      }
    });

    dialogRef.afterClosed().subscribe( async (confirmed) => {
      if (confirmed) {
        if (this.selectedWorkBook.tsResponse.workbook.views.view.contentUrl){
          this.contentUrl = this.selectedWorkBook.tsResponse.workbook.views.view.contentUrl.replace('/sheets','');
          this.tableauView = this.selectedWorkBook.tsResponse.workbook.views.view.name;
          }
        else{
          this.contentUrl = this.selectedWorkBook.tsResponse.workbook.views.view[0].contentUrl.replace('/sheets',''); 
          this.tableauView = this.selectedWorkBook.tsResponse.workbook.views.view[0].name
        }
          await this.tableauService.setDashboard(this.userProfileService.userEmail, "", this.tableauView, 
          this.selectedWorkBook.tsResponse.workbook.name, this.selectedWorkBook.tsResponse.workbook.id, this.selectedWorkBook.tsResponse.workbook.project.id, 
          this.userProfileService.userEmail, this.contentUrl);
          const navigationDetails: string[] = ['/'];
          this.router.navigate(navigationDetails);
      }     
    });
  }

  goBack(){
    const navigationDetails: string[] = [`/report`];
    this.router.navigate(navigationDetails);
  }

}
