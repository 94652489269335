<div class="col-sm-5 nav-tabs-alt m-b-lg">
  <ul class="nav nav-tabs nav-">
    <li *ngFor="let tab of tabs" class="{{ tab == activeTab ? 'active' : '' }}">
      <a
        (click)="selectTab(tab)"
        role="tab"
        data-toggle="tab"
        aria-expanded="false"
        >{{ tab.name }}</a
      >
    </li>
  </ul>
</div>

<div class="col-sm-12" *ngIf="activeTab.prop == 'allCOAs'">
  <app-all-coa-list></app-all-coa-list>
</div>

<div class="col-sm-12 mystaff-wrapper m-t-md" *ngIf="activeTab.prop == 'myStaff'">
  <app-manager-staff></app-manager-staff>
</div>
