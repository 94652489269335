
<div class="container-fluid">
    <div class="row data-entry-add-new-row">
        <div class="col-md-4">
            <button class="btn btn-primary" (click)="redirectToAddNewDataEntry()"><mat-icon style="font-size:14px" > add </mat-icon><span>Add New {{stixConfig.dataEntry_singular}}</span></button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8" matSort matSortActive="formattedUpdatedDateTime"
            matSortDirection="desc" matSortDisableClear>
            <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
                <th mat-header-cell *matHeaderCellDef mat-sort-header attr.aria-label="{{getDataEntryListColumnName(column)}}">
                        <span class="data-entry-header">{{getDataEntryListColumnName(column)}}</span>
                </th>
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="getDataEntryColumnType(column) === 'text'">{{element[column]}}</span>
                    <span *ngIf="getDataEntryColumnType(column) === 'link'">
                        <a routerLink="{{getGridLink(column, element)}}" class="cases-grid-link">{{element[column]}}</a>
                    </span>
                </td>
            </ng-container>
        
                <!-- RIViR Top Row -->
                <ng-container matColumnDef="rivir-top-header">
                    <th mat-header-cell *matHeaderCellDef [attr.colspan]="10" class="rivir-mat-top-header">
                        <div class="row">
                            <div class="col-md-10" style="color:#fcfcfc; font-size:17px;">
                                List of {{stixConfig.dataEntry_plural}}
                            </div>
                            <div class="col-md-2">
                                <a class="refresh-list" (click)="refreshList()">
                                    <i class="fa fa-refresh"></i> Refresh List
                                </a>
                            </div>
                        </div>
                    </th>
                </ng-container>
        
                    <!-- RIViR Search Row -->
                    <ng-container matColumnDef="rivir-search-header">
                        <th mat-header-cell *matHeaderCellDef [attr.colspan]="10">
                            <div class="row">
                                <div class="col-md-9"></div>
                                <div class="col-md-1 rivir-mat-search-label">
                                    Filter:
                                </div>
                                <div class="col-md-2">
                                    <input #input type="search" class="form-control" title="Filter">
                                </div>
                            </div>
                        </th>
                    </ng-container>
        
        
            <tr mat-header-row *matHeaderRowDef="['rivir-top-header']">
            </tr>
            <tr mat-header-row *matHeaderRowDef="['rivir-search-header']">
            </tr>
            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr mat-row *matRowDef="let rivirCase; columns: columnsToDisplay;" class="example-element-row"
            [ngClass]="rivirCase.index % 2 ? 'rivir-mat-table-row-even' : 'rivir-mat-table-row-odd'"
            >
            </tr>
        </table>
        <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 50]" [length]="paginationLength">
        </mat-paginator>
        </div>
    </div>
</div>
