<div *ngIf="error">
  <div class="col-sm-12">
    <div class="form-horizontal">
      <h2 class="col-sm-12">
        <i class="fa fa-exclamation-triangle m-r-sm"></i>An Unexpected Error Occurred
      </h2>
      <h4 class="col-sm-6">We apologize for the inconvenience.
        <br> This error has been logged and will be fixed as soon as possible.</h4>

      <div class="col-sm-12 m-t-md m-b-md">
        <button class="btn btn-default" (click)="goToPrevUrl()">
          <i class="fa fa-arrow-circle-left m-r-xs"></i>Back to Previous Page</button>
      </div>

      <h4 class="col-sm-12 m-t-lg m-l-sm">
        <a class="red" (click)="expandStack = !expandStack">
          <i class="fa fa-caret-{{expandStack? 'down':'right'}} m-r-xs"></i>{{expandStack? 'Hide ':'View '}}Error</a>
      </h4>
      <div class=" m-l-md" *ngIf="expandStack">
        <h5 class="col-sm-12 red" style="font-size: 16px">{{error.name}}: {{error.message}}</h5>
        <p class="col-sm-8">{{error.stack}}
          <a (click)="expandStack = !expandStack">Hide Error</a>
        </p>
      </div>
    </div>

  </div>
</div>