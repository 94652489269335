import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { SightingService } from "../../shared/services/sighting.service";
import { Indicator } from "../indicators-list/indicator";
import { IndicatorService } from "../indicators.service";
import { StixConfigService } from "../../shared/services/stixConfig.service";

@Component({
  selector: "app-indicator-threat-actors",
  templateUrl: "./indicator-threat-actors.component.html",
  styleUrls: ["./indicator-threat-actors.component.css"],
})
export class IndicatorThreatActorsComponent implements OnInit {

  public indicator: Indicator = new Indicator();
  public runDate: Date;
  public sightingId_params: String;
  public sightingLabel: string;

  constructor(private indicatorService: IndicatorService, private route: ActivatedRoute,
    private sightingService: SightingService, private stixConfig: StixConfigService,) { }

  public ngOnInit() {

    const self = this;
    this.sightingLabel = this.stixConfig.sighting_plural + ":";

    this.route.params.subscribe((params) => {
      const indicatorId_params = params.id;
      this.sightingId_params = params.name;
      if (indicatorId_params) {
        this.indicatorService.getIndicatorDetail(indicatorId_params)
          .subscribe((indicator) => {
            this.indicator = indicator.pop();
            this.runDate = this.indicator.lastRunDate;
          });
      } else {
        this.sightingService.getSightingsByName(this.sightingId_params)
          .subscribe(function (sightings: any[]) {

            if (sightings && sightings.length > 0) {
              self.runDate = sightings[0].CreatedDateTime;
              self.indicator = sightings[0].algorithm;
            }
          });
      }
    });
  }

}