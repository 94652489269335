import { Component, Input, OnChanges } from "@angular/core";

import { Subscription } from "rxjs";
import { map } from "rxjs/operators";

import { Message } from "../../shared/models/message";
import { MessageService } from "../../shared/services/message.service";
import { StixConfigService } from "../../shared/services/stixConfig.service";

@Component({
  selector: "[app-notifications]",
  templateUrl: "./notifications.component.html",
  styleUrls: ["./notifications.component.css"],
})
export class NotificationsComponent implements OnChanges {

  @Input() public userEmail;
  public userMessages: Message[] = [];
  public subscription: Subscription;
  public newMessagesCount: number;
  public viewCount: number;
  public dropdown_isOpen = false;

  constructor(private messageService: MessageService, public stixConfig: StixConfigService) { }

  public ngOnChanges() {
    if (this.userEmail) {
      this.messageService.getMessagesForUserEmail(this.userEmail);
      this.subscription = this.messageService.getUserMessages()
        .pipe(map((messages: any[]) => {
          this.newMessagesCount = this.messageService.getNewMessagesCount(messages);
          this.messageService.getTimePassedForMessage(messages);
          messages.sort(function (a, b) {
            return new Date(b.createDtime).getTime() - new Date(a.createDtime).getTime();
          });
          return messages;
        }))
        .subscribe((messages) => {
          const viewCount = this.newMessagesCount > this.viewCount ? this.newMessagesCount : this.viewCount;
          this.userMessages = this.stixConfig.stixLabeling(messages.slice(0, viewCount));
        });
    }
  }

  public onIconClick() {
    this.userMessages = this.messageService.getTimePassedForMessage(this.userMessages);
    this.dropdown_isOpen = !this.dropdown_isOpen ? true : false;
    if (!this.dropdown_isOpen && this.newMessagesCount > 0) {
      this.messageService.markAllAsSeen();
    }
  }

  public clickOut() {
    if (this.dropdown_isOpen) {
      if (this.newMessagesCount > 0) {
        this.messageService.markAllAsSeen();
      }
      this.dropdown_isOpen = false;
    }
  }
}
