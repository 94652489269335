import { Component, OnInit, ViewChild, ElementRef, Input } from "@angular/core";
import { animate, state, style, transition, trigger } from '@angular/animations';

import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";

import { merge, fromEvent } from "rxjs";
import { tap } from 'rxjs/operators';
import { debounceTime } from 'rxjs/operators';
import { distinctUntilChanged } from 'rxjs/operators';

import {
  CaseService,
  RIViRCase,
  RIViRCaseCoa,
  RIViRCaseDataSource,
} from "../case-service";
import { StixConfigService } from "../../shared/services/stixConfig.service";


@Component({
  selector: 'app-cases-grid',
  templateUrl: './cases-grid.component.html',
  styleUrls: ['./cases-grid.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class CasesGridComponent implements OnInit {

  public paginationLength: number = 10;
  public dataSource: RIViRCaseDataSource;
  public rivirCases: RIViRCase[];
  public columnsToDisplay: string[];
  @ViewChild(MatPaginator) public paginator: MatPaginator;
  @ViewChild(MatSort) public sort: MatSort;
  @ViewChild('input') input: ElementRef;
  @Input() public threatActorId: any;
  public coaSortOrderDesc: boolean;
  public coaSortColumn: string;

  public expandedCase: RIViRCase;

  constructor(
    private caseService: CaseService,
    public stixConfig: StixConfigService
  ) { }

  /**
   * Loads the case grid with the default search
   */
  public ngOnInit(): void {
    this.threatActorId = this.threatActorId || null;
    this.columnsToDisplay = (this.threatActorId) ? ['caseId', 'types', "priority", "created", "dueDate", "status", "assignee", "X"] : ['caseId', 'taId', 'threatActorName', 'types', "priority", "created", "dueDate", "status", "assignee", "X"];
    this.dataSource = new RIViRCaseDataSource(this.caseService);
    this.dataSource.loadingCases(this.threatActorId, null, "caseId DESC", null, null);
    this.dataSource.count$.subscribe(count => {
      this.paginationLength = count;
    });
  }

  /**
   * Creates event listeners to run a new search based on user input
   */
  public ngAfterViewInit(): void {

    // When the user updates the search input after debounce load cases
    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadCases();
        })
      )
      .subscribe();

    // Whenver there is a sort set the back to 0
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    // When the user changes the sort or the page load cases
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => {

          this.loadCases();
        })
      )
      .subscribe();

  }

  public refreshList() {
    this.input.nativeElement.value = null;
    this.loadCases();
  }

  /**
   * Run the search for cases based on the selected criteria
   */
  public loadCases(): void {
    this.dataSource.loadingCases(
      this.threatActorId,
      this.input.nativeElement.value,
      this.sort.active + " " + this.sort.direction.toUpperCase(),
      this.paginator.pageIndex,
      this.paginator.pageSize);
  }

  /**
   * Expands or Collapses the Case row when selected
   * @param event MouseEvent when case row is selected
   * @param selectedCase Selected Case
   */
  public caseRowSelected(event: MouseEvent, selectedCase: RIViRCase) {

    const currentTarget = event.currentTarget as HTMLElement;
    if (currentTarget.classList.contains("example-expanded-row")) {
      this.expandedCase = null;
    }
    else {
      this.expandedCase = selectedCase;
      this.sortCoas(selectedCase, "dueDate", true);
    }
  }

  /**
   * Determines whether to display text link or carrot
   * @param column 
   */
  public getCaseColumnType(column: string): string {

    switch (column) {
      case "caseId":
      case "taId":
        return "link";
      case "X":
        return "expand";
      default:
        return "text";
    }
  }

  /**
   * Returns the Router link for link in the cases grid
   * @param column Column Name
   * @param row Selected Case
   */
  public getGridLink(column: string, row: any) {

    switch (column) {
      case "caseId":
        return `/case/${row.caseId}`;
      case "taId":
        return `/threatActor/${row.threatActorId}`
    }
  }

  /**
   * Returns the English description of the columnn ames 
   * @param column Column Name
   */
  public getCaseColumnName(column: string) {

    switch (column) {
      case "caseId": return "Case ID";
      case "taId": return `${this.stixConfig.threatActor_singular} ID`;
      case "threatActorName": return `${this.stixConfig.threatActor_singular} Name`;
      case "types": return "Case Types";
      case "priority": return "Priority";
      case "created": return "Date Opened";
      case "dueDate": return "Due Date";
      case "status": return "Status";
      case "assignee": return "Assignee";
      case "X": return "Expand";
    }
  }

  /**
   * Sorts the rows in the Course of Action Grid
   * @param rivirCase Selected Case
   * @param sortColumn Column to be sorted 
   * @param sortOrderDesc Is Sort Order descending
   */
  public sortCoas(rivirCase: any, sortColumn: string, sortOrderDesc?: boolean) {

    // Set the sortOrder which can be manually set
    // if not and the column is the same then toggle
    // if the column is different default to desc
    if (sortOrderDesc) {
      this.coaSortOrderDesc = sortOrderDesc;
    }
    else if (this.coaSortColumn === sortColumn) {
      this.coaSortOrderDesc = !this.coaSortOrderDesc;
    }
    else {
      this.coaSortOrderDesc = true;
    }

    // Set the sort column for ordering in the future
    this.coaSortColumn = sortColumn;

    // Convert Date columns to ISO dates for storing
    if (["dueDate", "sigthing", "sigthingShort"].includes(sortColumn)) {
      rivirCase.courseOfActions.sort((a: RIViRCaseCoa, b: RIViRCaseCoa) => {
        let date1 = this.coaSortOrderDesc ? new Date(b[sortColumn]).toISOString() : new Date(a[sortColumn]).toISOString();
        let date2 = this.coaSortOrderDesc ? new Date(a[sortColumn]).toISOString() : new Date(b[sortColumn]).toISOString();

        return date1.localeCompare(date2);

      });
    }
    else {
      // All other columns do a string compare for storing
      rivirCase.courseOfActions.sort((a: RIViRCaseCoa, b: RIViRCaseCoa) => {
        return this.coaSortOrderDesc ?
          b[sortColumn].localeCompare(a[sortColumn]) : a[sortColumn].localeCompare(b[sortColumn]);
      });
    }

  }

  /** 
   * Displays the appropriate sort icon next the title
   * @param sortColumn Column being sorted
   */
  public displaySortIcon(sortColumn: string) {

    let iconName = "";
    if (this.coaSortColumn === sortColumn) {
      iconName = this.coaSortOrderDesc ? "arrow_upward" : "arrow_downward";
    }

    return iconName;
  }

}