<mat-card class="bulk-status-card">
    <mat-card-header>
        <mat-card-title>
            Questions and Inquires
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-1 contact-title" style="font-weight:bold;">Phone:</div>
            </div>
            <div class="row">
                <div class="col-md-3 contact-desc">410.822.0697</div>
            </div>
            <div class="row">
                <div class="col-md-1 contact-title">Toll Free:</div>
            </div>
            <div class="row">
                <div class="col-md-3 contact-desc">800.999.3362</div>
            </div>
            <div class="row">
                <div class="col-md-1 contact-title">Fax:</div>
            </div>
            <div class="row">
                <div class="col-md-3 contact-desc">410.822.7971</div>
            </div>
            <div class="row">
                <div class="col-md-1 contact-title">Email: </div>
            </div>
            <div class="row">
                <div class="col-md-3 contact-desc contact-mailto">
                    <a href="mailto:RIViR_Admin@qlarant.com">RIViR_Admin@qlarant.com</a>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>