<div class="modal-header">
  <h2 class="modal-title pull-left">{{modelName}}</h2>
  <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row col-sm-12">
    <span class="col-sm-1">
      <i class="glyphicon glyphicon-warning-sign"></i>
    </span>
    <p class="col-sm-11">Your session is about to expire due to inactivity.
      <br> You will be logged out automatically in <strong>{{countdown}}</strong> minutes.</p>
  </div>
</div>
<div class="modal-footer">
  <div class="form-group">
    <div class="col-sm-5 col-sm-offset-7">
      <button class="btn btn-default m-r-xs" (click)="logout( 'HTML IDLE Timeout Button Pressed')"> Log Out</button>
      <button class="btn btn-primary" (click)="modalRef.hide()"> Continue Session</button>
    </div>
  </div>
</div>