import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { catchError } from 'rxjs/operators';

import { RivirHttp } from "../../.././app/shared/services/rivir-http.service";
import { EnvService } from "../../env.service";
import { ExecutionResults } from "./execution-results";
import { IAlgorithm } from "./nerdy-algorithm-list";

@Injectable()
export class AlgorithmService {
    private _algorithmUrl: string;
    private executionResultsUrl: string;

    constructor(private _http: HttpClient, private env: EnvService) {
        this._algorithmUrl = this.env.rivirApiBaseUrl + "/algorithms";
        this.executionResultsUrl = this.env.rivirApiBaseUrl + "/executionresults";
    }

    public getAlgorithm(algorithmId: string): Observable<IAlgorithm> {
        const url: string = this._algorithmUrl + "/" + algorithmId;
        return this._http.get<IAlgorithm>(url, RivirHttp.getHttpOptions())
            .pipe(catchError(RivirHttp.handleError));
    }

    public getAlgorithms(): Observable<IAlgorithm[]> {
        return this._http.get<IAlgorithm[]>(this._algorithmUrl, RivirHttp.getHttpOptions())
            .pipe(catchError(RivirHttp.handleError));
    }

    public executeAlgorithm(algorithm: IAlgorithm): void {
        const executeUrl = this._algorithmUrl + "/execute";
        const body = { name: algorithm.name };

        this._http
            .post(executeUrl, body, RivirHttp.getHttpOptions())
            .subscribe((data) => console.log(data));

    }

    public postAlgorithm(algorithm: IAlgorithm): void {
        const query = '{"id" : ' + algorithm.id + "}";
        const executeUrl: string = this._algorithmUrl + "/upsertWithWhere?where=" + query;
        this._http.post(executeUrl, algorithm, RivirHttp.getHttpOptions())
            .subscribe((data) => console.log(data));
    }

    public getExecutionResults(algorithm: IAlgorithm): Observable<ExecutionResults[]> {

        const query = '{"where" : {"algorithmId" : ' + algorithm.id + "}}";

        return this._http.get<ExecutionResults[]>(this.executionResultsUrl + "?filter=" + query, RivirHttp.getHttpOptions())
            .pipe(catchError(RivirHttp.handleError));
    }
}
