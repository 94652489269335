export class coa {
    public instanceId: string;
    public instance_Id: string;
    public passedQA: boolean;
    public workflowComplete: boolean;
    public CreateDateTime: Date;
    public UpdatedDateTime: Date;
    public runDate: string;
    public CreatedBy: string;
    public UpdatedBy: string;
    public tasks: any[];
    public courseOfActionName: string;
    public numberOfIndicators: number;
    public threatActorId: string;
    public threatActorName: string;
    public sightingName: string;
    public lastCompletedTask: string;
    public status: string;
    public coaDueDate: string;
    public dueDateHistory: any[];
    public rivirStatus: string;
    public selected: boolean;
    public notes: any[];
}
