import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";

import { merge } from "rxjs";
import { tap } from 'rxjs/operators';

import { IDropdownSettings } from 'ng-multiselect-dropdown';

import {
  BulkAssignmentModel,
  BulkAssignmentRequest,
  BulkAssignmentService,
  BulkAssignmentCOADataSource,
  BulkAssignmentTADataSource
} from "../shared/services/bulk.assignment.service";
import { StixConfigService } from "../shared/services/stixConfig.service";
import { CoAService } from "../coa/coa.service";
import { PermissionService } from "../core/permission.service";
import { ThreatActorService } from "../threat-actor/threat-actor.service";
import { GroupsAssignmentComponent } from "./groups-assignment/groups-assignment.component";


@Component({
  selector: "app-bulk-assignment",
  templateUrl: "./bulk-assignment.component.html",
  styleUrls: ["./bulk-assignment.component.css"],
})
export class BulkAssignmentComponent implements OnInit {
  public model: BulkAssignmentModel = new BulkAssignmentModel();
  public displayedColumns: string[] = ["taId", "name"];
  public displayedColumnsCoas: string[] = [
    "coaId",
    "coaName",
    "taId",
    "taName",
  ];
  public dataSourceTa: BulkAssignmentTADataSource;
  public dataSourceCoa: BulkAssignmentCOADataSource;
  @ViewChild(MatPaginator, { static: true }) public paginator: MatPaginator;
  @ViewChild(MatSort) public sort: MatSort;
  @ViewChild(GroupsAssignmentComponent)
  public groupAssignment;
  public showGrid = false;
  public assignType: string;
  public dueDate: string;
  public indicator: string;
  public sighting: string;
  public sightingFullName: string;
  public submitted: boolean = false;
  public selectedCOAs = [];
  public selectedUser = { id: null, percent: 100 };
  public paginationLength: number = 10;

  public label_threatActors: string;
  public label_threatActor: string;
  public label_coas: string;
  public label_coa: string;
  public label_indicator: string;
  public label_sighting: string;
  public label_current_singular: string;
  public label_current_plural: string;
  public coa_title: string = "";
  public todayDate: String;
  public coa_view: boolean = false;
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    // idField: 'item_id',
    // textField: 'item_text',
    //selectAllText: 'Select All',
    //unSelectAllText: 'Unselect All',
    //itemsShowLimit: 3,
    allowSearchFilter: true
  };
  coaSelectPlaceHolder: string;

  constructor(
    private bulkAssignService: BulkAssignmentService,
    private threatActorService: ThreatActorService,
    public stixConfig: StixConfigService,
    private permissions: PermissionService,
    private coaService: CoAService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  public ngOnInit() {
    const self = this;

    this.todayDate = new Date().toISOString().substr(0, 10);

    this.label_threatActors = this.stixConfig.threatActor_plural;
    this.label_threatActor = this.stixConfig.threatActor_singular;
    this.label_coas = this.stixConfig.coa_plural;
    this.label_coa = this.stixConfig.coa_singular;
    this.label_indicator = this.stixConfig.indicator_singular;
    this.label_sighting = this.stixConfig.sighting_singular;
    this.label_sighting = this.stixConfig.sighting_singular;

    this.coaSelectPlaceHolder = `Select ${this.label_coa}`;

    if (this.bulkAssignService.coaView) {

      // Get all the coas to be sent to bulk start/assign
      // TODO Refactor this to send the query to the bulk start so that 
      // this doesn't have to be passed betwee the api and client browser 
      this.bulkAssignService.getDataForCOAs(null, null, null).subscribe((coas) => {
        self.model.coadata = coas;
      });

      // Check whether the user has access to this view
      this.permissions
        .getPermission("Bulk Re-assign Course of Action")
        .then((permission) => {
          if (!permission) {
            this.goBack();
          }
        });

      // Get the initial data for the course of action table
      this.paginationLength = this.bulkAssignService.selectedCOAs.length;
      this.dataSourceCoa = new BulkAssignmentCOADataSource(this.bulkAssignService);
      this.dataSourceCoa.loadingCourseOfActions("taId ASC", 0, 10);


      this.coa_view = true;
      this.label_current_plural = this.label_coas;
      this.label_current_singular = this.label_coa;
      this.coa_title = this.label_coa + " Bulk Re-assignment";
    } else {
      // Set the Sighting and Indicator name
      this.route.params.subscribe((params) => {
        this.indicator = params.id;
        if (params.name) {
          this.sightingFullName = params.name;
          this.sighting = params.name.substring(
            params.name.indexOf("--") + 2,
            params.name.length,
          );
        }

        // Check whether the user has access to this view
        this.permissions
          .getPermission("Bulk Assign Course of Action")
          .then((permission) => {
            if (!permission) {
              this.goBack();
            }
          });

        // Get the initial data for the course of action table
        this.paginationLength = this.bulkAssignService.selectedThreatActors.length;
        this.dataSourceTa = new BulkAssignmentTADataSource(this.bulkAssignService);
        this.dataSourceTa.loadingThreatActors(this.sightingFullName, "taId ASC", 0, 10);

        this.coa_view = false;
        this.label_current_plural = this.label_threatActors;
        this.label_current_singular = this.label_threatActor;
      });
    }

    // If there isn't any selected threat actors redirect back to
    // the sightings page
    if (
      !this.coa_view &&
      (!this.bulkAssignService.selectedThreatActors ||
        this.bulkAssignService.selectedThreatActors.length == 0)
    ) {
      this.goBack();
    } else if (this.coa_view) {
      // Get the inital model from the service
      this.bulkAssignService
        .getInitialData()
        .then((initialModel: BulkAssignmentModel) => {
          self.model = initialModel;

        });
    } else {


      // Get the inital model from the service
      this.bulkAssignService
        .getInitialData()
        .then((initialModel: BulkAssignmentModel) => {
          self.model = initialModel;

        });
    }
  }

  public ngAfterViewInit(): void {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);


    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => {
          if (this.coa_view) {
            this.loadCoursesOfAction();
          }
          else {
            this.loadThreatActors();
          }

        })
      )
      .subscribe();
  }

  public loadCoursesOfAction() {

    const coaColumnMapping = {
      coaId: "id",
      coaName: "courseOfActionName",
      taId: "taId",
      taName: "threatActorName"
    }
    this.dataSourceCoa.loadingCourseOfActions(
      coaColumnMapping[this.sort.active] + " " + this.sort.direction.toUpperCase(),
      this.paginator.pageIndex,
      this.paginator.pageSize);
  }

  public loadThreatActors() {

    this.dataSourceTa.loadingThreatActors(
      this.sightingFullName,
      this.sort.active + " " + this.sort.direction.toUpperCase(),
      this.paginator.pageIndex,
      this.paginator.pageSize);
  }

  public toggleGrid(showGrid) {
    this.showGrid = showGrid;
  }

  public save() {
    /// Check for all inputs validation.
    if (this.coa_view &&
      ((this.assignType == "INDIVIDUAL" && this.selectedUser.id) ||
        (this.assignType == "GROUP" &&
          this.groupAssignment.totalPercentage == 100 &&
          this.groupAssignment.usersToAssign.length))) {

      const coas = this.bulkAssignService.selectedCOAs.map(instanceId => { return { instanceId }; });
      const bulkAssignRequest = {
        coas,
        users:
          this.assignType == "GROUP"
            ? this.groupAssignment.usersToAssign
            : [
              {
                email: this.selectedUser.id,
                percent: this.selectedUser.percent,
              },
            ],
      };

      this.bulkAssignService
        .bulkAssignCoa(bulkAssignRequest)
        .subscribe((response) => {
          this.coaService.selected_COAs = [];
          this.router.navigate(["bulkstatus", response.instanceId]);
        });
    } else if (
      this.selectedCOAs.length > 0 &&
      this.dueDate &&
      ((this.assignType == "INDIVIDUAL" && this.selectedUser.id) ||
        (this.assignType == "GROUP" &&
          this.groupAssignment.totalPercentage == 100 &&
          this.groupAssignment.usersToAssign.length))
    ) {

      const threatActors = this.bulkAssignService.selectedThreatActors.map(taId => { return { taId }; });
      const bulkAssignmentRequest: BulkAssignmentRequest = {
        threatActors,
        coas: this.selectedCOAs,
        dueDate: this.dueDate,
        users:
          this.assignType == "GROUP"
            ? this.groupAssignment.usersToAssign
            : [
              {
                email: this.selectedUser.id,
                percent: this.selectedUser.percent,
              },
            ],
        sighting: this.sightingFullName,
      };

      //// CALL backend POST....
      this.bulkAssignService
        .bulkStartThreactActors(bulkAssignmentRequest)
        .subscribe((response) => {
          this.threatActorService.selected_ThreatActors = [];
          this.router.navigate(["bulkstatus", response.instanceId]);
        });
    }

    this.submitted = true;
  }

  public assignTypeChange() {
    this.selectedUser.id = null;
    this.submitted = false;
  }

  public goBack() {
    this.router.navigate(["../"], { relativeTo: this.route });
  }
}
