<div class="content add-margin">
    <div class="row form-margin form-margin-bottom">
      <div class="col-2 col-sm-2 col-md-2 col-lg-2">
        <h3 class="wb-count">{{workBookTitle}}</h3>
      </div>
      <div class="col-7 col-sm-7 col-md-7 col-lg-7">
      </div>
      <div class="col-3 col-sm-3 col-md-3 col-lg-3 btn-align">
        <button type="button" (click)="goBack()" class="btn btn-default">Back</button>
        <button type="button" (click)="setDashBoard()" class="btn btn-success continue-btn-padding" [disabled]="disableShowBtn">Set to Dashboard</button>
      </div>
    </div>
  </div>
  <div class="content">
    <div id="vizContainer" style="display: flex; justify-content: center"></div>
  </div>
  <div class="content">
    <div *ngIf="workBookError" style="display: flex; justify-content: center">
        <h2>Workbook Not Found.</h2>
    </div>
  </div>





