export const TABLE_COLUMNS_TA_LIST =
    [
        { prop: "taId", name: "threatActor_singular ID" },
        { prop: "name", name: "threatActor_singular Name" },
        { prop: "referenceDataID", name: "threatActor_referenceDataID"},
        { prop: "type", name: "threatActor_singular Type"},
        { prop: "indicatorsCount", name: "indicator_plural" },
        { prop: "sightingsCount", name: "sighting_plural" },
        { prop: "coursesOfActionCount", name: "coa_plural" },
        { prop: "ignore", name: "Ignore" },
    ];

export const TABLE_COLUMNS_INDICATOR_TA_LIST =
    [
        { prop: "taId", name: "threatActor_singular ID" },
        { prop: "name", name: "threatActor_singular Name" },
        { prop: "coursesOfActionCount", name: "coa_plural" },
        { prop: "ignore", name: "Ignore" },
    ];

export const TABLE_COLUMNS_TA_LIST_SEARCHABLE =
    [
        { prop: "taId", name: "threatActor_singular ID" },
        { prop: "name", name: "threatActor_singular Name" },
        { prop: "assigneeFullName", name: "Reviewer"},
        { prop: "processInstanceId", name: "coa_singular ID"},
        { prop: "UpdatedDateTime", name: "Last Updated"},
        { prop: "Status", name: "Status"},
        { prop: "patientId", name: "threatActor_referenceDataID"}
    ];

    export const TABLE_COLUMNS_LIST_SEARCHABLE =
    [
        { prop: "taId", name: "threatActor_singular ID" },
        { prop: "threatActorName", name: "threatActor_singular Name" },
        { prop: "assigneeFullName", name: "Reviewer"},
        { prop: "processInstanceId", name: "coa_singular ID"},
        { prop: "UpdatedDateTime", name: "Last Updated"},
        { prop: "Status", name: "Status"},
    ];