import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { EnvService } from "../../app/env.service";
import { RivirHttp } from "../shared/services/rivir-http.service";

import { CollectionViewer } from "@angular/cdk/collections";
import { DataSource } from "@angular/cdk/table";

import { Observable, BehaviorSubject, forkJoin, of } from "rxjs";
import { map } from "rxjs/operators";
import { catchError, finalize } from "rxjs/operators";
import { UserProfileService } from "../core/userProfile.service";
import { AppSettingService } from "../shared/services/appsetting.service";

export class RIViRCaseSearchResult {
    public cases: RIViRCase[];
    public count: number;
}

export class RIViRCase {
    public caseId: string;
    public status: string;
    public dueDate: string;
    public priority: string;
    public types: string;
    public created: string;
    public courseOfActions: RIViRCaseCoa[];
    public index: number;
}

export class RIViRCaseCoa {
    public indicatorName: string;
    public type: string;
    public classification: string;
    public sighting: string;
    public instanceId: string;
    public status: string;
    public dueDate: Date;
    public assignee: string;
}

export class CaseSearchCriteria {

}

export class CaseStatusCount {
    public status: string;
    public count: number;
    public color: string;
}

export enum CaseEditPageType {
    CASE_START = "CASE_START",
    CASE_EDIT = "CASE_EDIT",
    COA_START = "COA_START",
    CONFIRMATION = "CONFIRMATION",
}

export enum CaseEditNextAction {
    START_CASE = "start_case",
    ASSIGN_CASE = "assign_case",
    NO_ACTION = "no_action",
}

export enum CaseHistoryType {
    CASE = "CASE",
    COURSE_OF_ACTION = "COURSE_OF_ACTION",
}

export class CaseHistory {
    public type: CaseHistoryType;
    public instance: string;
    public fields: string[];
    public original: string[];
    public updated: any[];
    public taskName: string;
    public action: string;
    public modified: Date;
    public modifiedUserFullName: string;
    public modifiedUserEmail: string;
    public courseOfActionName: string;

    public description?: string;
    public searchString: string;

    public instanceUIId: string;
    public fieldsTruncated: string;
    public fieldsExpanded: string;
    public isFieldTextTruncated: boolean;
    public numberOfFields?: number;
    public currentAssignee: any;
}

@Injectable()
export class CaseService {

    public priorities: string[] = ["High", "Medium", "Low"];
    public caseStatuses: string[] = [];
    public customOrder: CaseStatusCount[] = [];

    constructor(
        private http: HttpClient,
        private userProfileService: UserProfileService,
        private env: EnvService,
        private appSettingService: AppSettingService
    ) {

        this.customOrder = [
            { status: "Open", color: "#FF0000", count: null },
            { status: "In Progress", color: "#1c6fc6", count: null },
            { status: "Pending", color: "#800080", count: null },
            { status: "Review", color: "#808000", count: null },
            { status: "Closed", color: "#008000", count: null },
        ];

        // Allow the user to override this object in the web app configuration
        this.customOrder = this.env.caseStatusCustomOrder ? this.env.caseStatusCustomOrder : this.customOrder;
        this.caseStatuses = this.customOrder.map((co) => co.status);
    }

    /**
     * Get the Payload which is configurable.   By default, we get the reference data from when the sighting has started
     * but when the useLatestReferenceData flag is set it will always get the latest reference data (i.e. reference data set by
     * in progess or completed courses of action regardless of sighting)
     * @param threatActor Threat Actor Object
     * @param coaName Course of Action Name
     * @param sightingName Current Sighting Name
     */
    async getPayload( threatActor: any, coaName: string, sightingName : string){

        const appSettings : any = await this.appSettingService.getAppSetting( this.env.appKey ).toPromise();
        let payload = null;
        if (!appSettings.results.useLatestReferenceData) {
            payload = await this.createPayloadUsingObservableDataFromSighting(threatActor, sightingName);
        }
        else {
            payload = this.createPayloadUsingLatestReferenceData( threatActor, coaName, sightingName );
        }

        return payload;
    }

   createPayloadUsingLatestReferenceData( threatActor : any, coaName : string, sightingName: string ){
    const payload = {
        courseOfActionName: { value: coaName, type: "String" },
        threatActorId: { value: threatActor.id, type: "String" },
        threatActorName: { value: threatActor.name, type: "String" },
        threatActorAddress: { value: threatActor.address, type: "String" },
        taId: { value: threatActor.taId, type: "String" },
        StartedBy: { value: this.userProfileService.userEmail, type: "String" },
        sightingName: { value: sightingName, type: "String" },
      };
  
      // Add the Observable Data
      threatActor.observableData.forEach((ob) => {
        if (typeof ob.value == "object") {
          payload[ob.name] = { value: JSON.stringify(ob.value), type: "json" };
        }
        else {
          payload[ob.name] = { value: ob.value, type: "String" };
        }
      });

      return payload;
   }


  /***
   * Creates the payload based on the reference data from the selected sighting
   */
  async createPayloadUsingObservableDataFromSighting(threatActor: any, sightingName: string) {

    let newPayload = null;

    // Get the Observable Data for the Sighting asynchronously to no affect the loading of the page 
    const historicalObs = await this.getObservableDatata(sightingName, threatActor.id).toPromise();

    if (historicalObs && historicalObs.length) {
      const historicalOb = historicalObs.pop();

      newPayload = {
        taId: { value: threatActor.taId, type: "String" },
        threatActorId: { value: threatActor.id, type: "String" },
        threatActorName: { value: threatActor.name, type: "String" },
        threatActorAddress: { value: threatActor.address, type: "String" },
        StartedBy: { value: this.userProfileService.userEmail, type: "String" },
        sightingName: { value: sightingName, type: "String" },
      };

      // Add the Observable Data for the current sighting
      for (const ob of historicalOb.observableData) {
        const fieldsToIgnore = ["workflowcompleted", "status"];
        if (!fieldsToIgnore.includes(ob.name.toLowerCase())) {
          if (typeof ob.value == "object") {
            newPayload[ob.name] = { value: JSON.stringify(ob.value), type: "json" };
          }
          else {
            newPayload[ob.name] = { value: ob.value, type: "String" };
          }
        }
      }
    }

    return newPayload;
  }

    /**
     * Gets the Case Counts from the database for all cases or by Threat Actor
     * @param threatActorId Threat Actor ID  - Optional
     */
    public async getCaseStatusCounts(threatActorId: string): Promise<CaseStatusCount[]> {
        return new Promise<CaseStatusCount[]>((resolve, reject) => {
            const params = threatActorId ? `?threatActorId=${threatActorId}` : "";
            const getCaseCountUrl = `${this.env.rivirApiBaseUrl}/cases/counts${params}`;
            this.http
                .get<CaseStatusCount[]>(getCaseCountUrl, RivirHttp.getHttpOptions())
                .pipe(catchError(RivirHttp.handleError))
                .subscribe((dbCaseCounts) => {

                    const caseStatusCounts: CaseStatusCount[] = [];

                    // Using for loop with a standard order and colors to make sure that
                    // the list comes out in the same order on the UI
                    for (const standardCaseCount of this.customOrder) {
                        const dbCaseCount = dbCaseCounts.find((dCC) => dCC.status === standardCaseCount.status);

                        if (dbCaseCount) {
                            standardCaseCount.count = dbCaseCount.count;
                            caseStatusCounts.push(standardCaseCount);
                        }
                    }

                    resolve(caseStatusCounts);
                });
        });
    }

    /**
     * Calls the api to search cases by specific criteria
     * @param criteria Case Search Criteria
     */
    public searchCases(criteria: CaseSearchCriteria): Observable<RIViRCaseSearchResult> {
        const searchCasesUrl = `${this.env.rivirApiBaseUrl}/cases/search`;
        return this.http
            .post<RIViRCaseSearchResult>(searchCasesUrl, { criteria }, RivirHttp.getHttpOptions())
            .pipe(catchError(RivirHttp.handleError));
    }

    /**
     * Gets the Threat Actor Id and Name from the API using the taId
     * @param taId Threat Actor taId
     */
    public getTrimmedThreatActor(taId: string): Observable<any> {
        const filter = `?filter={"where" : {"taId" : "${taId}"}}`;
        const trimmedTaUrl = `${this.env.rivirApiBaseUrl}/threatactors` + filter;
        return this.http
            .get<RIViRCaseSearchResult>(trimmedTaUrl, RivirHttp.getHttpOptions())
            .pipe(catchError(RivirHttp.handleError));
    }

    public getTrimmedCoas(taId: string): Observable<any> {
        const trimmedCoaUrl = `${this.env.rivirApiBaseUrl}/cases/courseofactions/available/${taId}`;
        return this.http
            .get<RIViRCaseSearchResult>(trimmedCoaUrl, RivirHttp.getHttpOptions())
            .pipe(catchError(RivirHttp.handleError));;
    }

    public getCaseTypes(): Observable<string[]> {
        const caseTypesUrl = `${this.env.rivirApiBaseUrl}/cases/types`;
        return this.http
            .get<string[]>(caseTypesUrl, RivirHttp.getHttpOptions())
            .pipe(catchError(RivirHttp.handleError));
    }

    public getObservableDatata(sightingName, threatActorId): Observable<any> {
        const obUrl = `${this.env.rivirApiBaseUrl}/sightings/observableData/${sightingName}/threatActor/${threatActorId}`;
        return this.http
            .get<any>(obUrl, RivirHttp.getHttpOptions())
            .pipe(catchError(RivirHttp.handleError));

    }

    public getCaseStartInfo(taId: string): Observable<any> {

        const caseStartSource = forkJoin({
            threatActor: this.getTrimmedThreatActor(taId),
            coas: this.getTrimmedCoas(taId),
            activeUsers: this.userProfileService.getActiveUsers(),
            types: this.getCaseTypes(),
        });

        return caseStartSource;
    }


    public saveCase(rivirCase: any): Observable<any> {
        const startCaseUrl = `${this.env.rivirApiBaseUrl}/cases/save`;
        return this.http
            .post(startCaseUrl, { rivirCase }, RivirHttp.getHttpOptions())
            .pipe(catchError(RivirHttp.handleError));
    }

    public getCase(caseId: string): Observable<any> {
        const getCaseUrl = `${this.env.rivirApiBaseUrl}/cases/details/${caseId}`;
        return this.http
            .get<RIViRCaseSearchResult>(getCaseUrl, RivirHttp.getHttpOptions())
            .pipe(catchError(RivirHttp.handleError));

    }

    public getCaseDetailInfo(caseId: string): Observable<any> {

        const caseDetailSource = forkJoin({
            rivirCase: this.getCase(caseId),
            activeUsers: this.userProfileService.getActiveUsers(),
            caseTypes: this.getCaseTypes(),
        });

        return caseDetailSource;
    }

    /**
     * Add, Removes or Moves a courses of action to/from a case
     * @param caseId Case Id
     * @param courseOfAction Instance Id
     * @param action "ADD" or "REMOVE"
     */
    public modifyCaseCourseOfAction(caseId: string, courseOfAction: string, action: string, userId: string): Observable<any> {

        const modifyCaseCourseOfActionUrl = `${this.env.rivirApiBaseUrl}/cases/courseofaction`;

        return this.http
            .post<RIViRCaseSearchResult>(modifyCaseCourseOfActionUrl,
                { caseId, courseOfAction, action, userId },
                RivirHttp.getHttpOptions())
            .pipe(catchError(RivirHttp.handleError));
    }

    public getHistory(caseId: string, type: CaseHistoryType): Observable<CaseHistory[]> {

        const caseHistoryType = type === CaseHistoryType.CASE ? "case" : "courseofaction";
        const getCaseHistoryUrl = `${this.env.rivirApiBaseUrl}/cases/history/${caseHistoryType}/${caseId}`;

        return this.http
            .get<CaseHistory[]>(getCaseHistoryUrl, RivirHttp.getHttpOptions())
            .pipe(map((caseHistories: CaseHistory[]) => {

                if (type === CaseHistoryType.CASE) {

                    caseHistories = caseHistories.map((caseHistory: CaseHistory) => {
                        return this.formatCaseHistoryForCase(caseHistory);
                    });
                } else {
                    caseHistories = caseHistories.map((caseHistory: CaseHistory) => {
                        return this.formatCaseHistoryForCourseOfAction(caseHistory);
                    });
                }

                return caseHistories;

            }))
            .pipe(catchError(RivirHttp.handleError));
    }

    public capitalizeFirstLetter = (text) => {
        return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    }

    public spaceOutCommas = (text) => {
        return text ? text.replaceAll(",", ", ") : text;
    }

    public formatCaseHistoryForCase(caseHistory: CaseHistory): CaseHistory {

        const field = caseHistory.fields &&
            caseHistory.fields.length &&
            caseHistory.fields[0] ?
            this.capitalizeFirstLetter(caseHistory.fields[0]) : "[Empty]";

        caseHistory.modified = caseHistory.modified ?
            new Date(caseHistory.modified) : null;

        if (field.toLowerCase() !== "courseofactions") {

            const original = caseHistory.original &&
                caseHistory.original.length &&
                caseHistory.original[0] ?
                this.spaceOutCommas(caseHistory.original[0]) : "[Empty]";

            const updated = caseHistory.updated &&
                caseHistory.updated.length &&
                caseHistory.updated[0] ?
                this.spaceOutCommas(caseHistory.updated[0]) : "[Empty]";

            caseHistory.searchString = `${field} ${original} ${updated} \
                                        ${caseHistory.modified.toLocaleString("en-us")} ${caseHistory.modifiedUserFullName} \
                                        ${caseHistory.modifiedUserEmail}`.toLowerCase();

            caseHistory.description = `${field} updated from ${original} to ${updated}`;
        } else {

            caseHistory.description = `coas`;
            for (const updatedCoa of caseHistory.updated) {
                const updatedCoaDesc = `${updatedCoa.instanceUIId} ${updatedCoa.courseOfActionName} `;
                caseHistory.searchString += updatedCoaDesc +
                    ` ${caseHistory.modified.toLocaleString("en-us")} ${caseHistory.modifiedUserFullName} \
                        ${caseHistory.modifiedUserEmail}`.toLowerCase();;
            }
        }

        return caseHistory;

    }

    public formatCaseHistoryForCourseOfAction(caseHistory: CaseHistory): CaseHistory {

        const FIELD_TRUNCATION_LENGTH = 75;
        caseHistory.fieldsExpanded = caseHistory.fields.join();
        caseHistory.fieldsExpanded = caseHistory.fieldsExpanded ?
            this.spaceOutCommas(caseHistory.fieldsExpanded) :
            caseHistory.fieldsExpanded;

        caseHistory.fieldsTruncated = caseHistory.fieldsExpanded.length > FIELD_TRUNCATION_LENGTH ?
            caseHistory.fieldsExpanded.substring(0, FIELD_TRUNCATION_LENGTH) :
            caseHistory.fieldsExpanded;

        caseHistory.numberOfFields = caseHistory.fieldsExpanded !== "assignee" ? caseHistory.fields.length : null;

        caseHistory.isFieldTextTruncated = caseHistory.fieldsExpanded !== caseHistory.fieldsTruncated;

        // Special Case for Assignee
        if (caseHistory.fieldsExpanded === "assignee") {
            const reassignedUser = caseHistory.currentAssignee ?
                caseHistory.currentAssignee.name : caseHistory.updated;
            caseHistory.fieldsExpanded = `Reassigned to ${reassignedUser} `;
        }

        caseHistory.modified = caseHistory.modified ?
            new Date(caseHistory.modified) : null;

        caseHistory.searchString = `${caseHistory.instanceUIId} ${caseHistory.courseOfActionName} ${caseHistory.fieldsExpanded} \
                ${caseHistory.modified.toLocaleString("en-us")} ${caseHistory.modifiedUserFullName} \
                ${caseHistory.modifiedUserEmail} ${caseHistory.original.join()} \
                ${caseHistory.updated.join()} `.toLowerCase();

        return caseHistory;
    }
    public getRelatedCases(taId: string): Observable<RIViRCase> {
        const filter = `?filter={"where":{ "taId": "${taId}"}}`;
        const getRelatedCasesUrl = `${this.env.rivirApiBaseUrl}/cases` + filter;

        return this.http.get<RIViRCase>(getRelatedCasesUrl, RivirHttp.getHttpOptions())
            .pipe(catchError(RivirHttp.handleError));
    }

}

export class RIViRCaseDataSource implements DataSource<RIViRCase> {

    private caseSubject = new BehaviorSubject<RIViRCase[]>([]);

    private loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingSubject.asObservable();

    public countSubject = new BehaviorSubject<number>(0);

    public count$ = this.countSubject.asObservable();

    constructor(private caseService: CaseService) {

    }

    /**
     * Runs the search based on the search criteria parameters and updated Observables for cases and count to update
     * the Material Table
     * @param threatActorId Threat Actor ID
     * @param search Seach Keywords
     * @param sort Sort (Column  ASC/DESC)
     * @param page Page Index
     * @param pageSize Page Size
     */
    public loadingCases(
        threatActorId: string,
        search: string = "",
        sort: string = "name ASC",
        page: number = 0,
        pageSize: number = 3,
    ) {

        this.loadingSubject.next(true);
        const criteria = {
            threatActorId,
            search,
            sort,
            page,
            pageSize,
        };

        this.caseService
            .searchCases(criteria)
            .pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false)),
            )
            .subscribe((caseSearchResult: RIViRCaseSearchResult) => {
                let { cases, count } = caseSearchResult;

                // Adding an index for alternating colors
                cases = cases.map((rivirCase: RIViRCase, index: number) => {
                    rivirCase.index = index;
                    return rivirCase;
                });

                this.caseSubject.next(cases);
                this.countSubject.next(count);
            });
    }

    public connect(collectionViewer: CollectionViewer): Observable<RIViRCase[]> {
        return this.caseSubject.asObservable();
    }

    public disconnect(collectionViewer: CollectionViewer): void {
        this.caseSubject.complete();
        this.loadingSubject.complete();
        this.countSubject.complete();
    }
}

export class CourseOfActionView {
    public UpdatedBy: string;
    public UpdatedDateTime: string;
    public caseId: string;
    public coaDueDate: string;
    public courseOfActionName: string;
    public groups: string[];
    public indicatorName: string;
    public instanceId: string;
    public instanceUIId: string;
    public sightingClassifications: string;
    public sightingCreatedDateTime: string;
    public sightingName: string;
    public sightingTypes: string;
    public status: string;
    public taId: string;
    public task: string;
    public threatActorId: string;
    public threatActorName: string;
    public workflowComplete: boolean;

    public showRemovePrompt?: boolean;
}
