import { Component, OnInit } from '@angular/core';

import { StixConfigService } from "../../shared/services/stixConfig.service";
import { IAlgorithm } from  "../../indicators/nerdy-algorithm-list/nerdy-algorithm-list";
import { IndicatorWizardModel, IndicatorWizardService } from "../indicator-wizard.service";
import { ProcessDefinition } from '../../shared/services/workflow.service';

@Component({
  selector: 'indicator-wiz-rec-coa',
  templateUrl: './indicator-wiz-rec-coa.component.html',
  styleUrls: ['./indicator-wiz-rec-coa.component.css']
})
export class IndicatorWizRecCoaComponent implements OnInit {

  public allProcessDefinitions: ProcessDefinition[];
  public model: IndicatorWizardModel;
  public algorithm: IAlgorithm = new IAlgorithm();
  public recommendedCoas : ProcessDefinition[];
  public saveBoundCallback: () => void;
  public deploymentId: string;
  public label_indicator: string;


  constructor(
    private indicatorWizardService: IndicatorWizardService, 
    public stixConfig: StixConfigService,
    ) {
    this.label_indicator = stixConfig.indicator_singular;
    }

  async ngOnInit() {

    this.saveBoundCallback = this.saveLocalDataToModel.bind(this);
    this.allProcessDefinitions = await this.indicatorWizardService.getAllProcessDefintions();
    this.indicatorWizardService.currentData.subscribe( indicatorWizardModel => {

      // Keep a local copy of the model
      this.model = indicatorWizardModel ? indicatorWizardModel : new IndicatorWizardModel();

      this.algorithm = indicatorWizardModel && indicatorWizardModel.indicator ? 
                       indicatorWizardModel.indicator :
                       new IAlgorithm();

      // Make sure course of action is an array 
      this.algorithm.coursesOfAction = this.algorithm.coursesOfAction ? [].concat(this.algorithm.coursesOfAction) : [];
      this.recommendedCoas = this.allProcessDefinitions.filter( c => this.algorithm.coursesOfAction.includes( c.name ));
      this.loadBPMNViewer( this.recommendedCoas );

    });

    this.indicatorWizardService.navigation.subscribe( event => {
      if ( event )  {
        this.saveLocalDataToModel();
      }
    });
  }

  processDefinitionChanged( event ){
    this.loadBPMNViewer( event );
  }

  async loadBPMNViewer( selectedCoas ) {

    try {
      let activeTab = null;
      // Check which ones is active
      if (this.recommendedCoas.length === selectedCoas.length && this.recommendedCoas.length !== 0 ) {
        // This is an initial page load, set the first tab to be active
        activeTab = this.recommendedCoas[0].name;
      }
      else if ( this.recommendedCoas.length < selectedCoas.length  ){

        // this means a user has selected a coa, set that one to be active 
        const addedCoas = selectedCoas.filter(  x => !this.recommendedCoas.includes( x ));
        activeTab = addedCoas && addedCoas.length ? addedCoas.pop().name : 'Not Found';
        
      }
      else {
        // this means at least one has been removed

        // if check to see if empty, if so set to 'Not Found'
        if ( selectedCoas.length === 0 ){
          this.deploymentId = null;
        }
        else {

          
          // if the active one was removed, set the first one to be active 
          const currentActiveTab = this.recommendedCoas.find( a => a.active ).name;
          const removedCoas = this.recommendedCoas.filter( y => !selectedCoas.includes( y ));
          const removedActiveCoa = removedCoas.find(  z => z.name === currentActiveTab );
          if ( removedActiveCoa) {
            activeTab = selectedCoas[0].name;
          }
          else {
            // else leave the active tab to be the current active tab
            activeTab = currentActiveTab;
          }
        }
      }

      if ( selectedCoas.length ){
        this.deploymentId = selectedCoas[selectedCoas.length - 1].deploymentId;  
      }


      // Set the recommended coas 
      this.recommendedCoas = selectedCoas;

      for( const rc of this.recommendedCoas){
        if ( rc.name === activeTab ){
          rc.active = true;
          activeTab = "Setting to be Junk, so other tabs with the same name don't get set again";
        }
        else {
          rc.active = false;
        }
      }
    }
    catch( bpmnViewerError ){
      console.error( bpmnViewerError );
    }
  }

  saveLocalDataToModel = () => {
    this.model.indicator.coursesOfAction = this.recommendedCoas && this.recommendedCoas.length ? this.recommendedCoas.map ( d => d.name ) : [];
    this.model.indicator.coasDesc = this.model.indicator.coursesOfAction && this.model.indicator.coursesOfAction.length ? this.model.indicator.coursesOfAction.join(", ") : null;
  }

  updateDiagram( preview ){
    this.deploymentId = preview.deploymentId;
  }

  showDiagramControls() {
    return (this.deploymentId) ? "visible" : "hidden";
  }
}
