<h3>MayanEDMS File Upload</h3>
<div style="padding: 10px;">
    <div class="row">
        <div class="col-md-6" style="background-color: #fff;padding:5px;">
            <input type="file" />
        </div>
        <div class="col-md-6">
            <button (click)="uploadDoc()" class="btn btn-primary">Upload</button>
        </div>
    </div>
</div>
<br/>
<form ngNoForm enctype="multipart/form-data" action="{{apiUrl}}/documents/sendFile?access_token={{rivirApiAccessToken}}" method="POST">
    <div style="padding: 10px;">
        <div class="row">
            <div class="col-md-6" style="background-color: #fff;padding:5px;">
                <input type="file" name="file_new" />
                <input type="hidden" name="action" value="1" />
            </div>
            <div class="col-md-6">
                <input type="submit" value="Send via RIViR API" />
            </div>
        </div>
    </div>    
</form>
