import { Component } from "@angular/core";

@Component({
  selector: 'app-manager-staff',
  templateUrl: './manager-staff.component.html',
  styleUrls: ['../all-coa-list/all-coa-list.component.css', './manager-staff.component.css']
})
export class ManagerStaffComponent {

  public isAggregateView: boolean = true;

  constructor() { }

  changeToListView() {
    this.isAggregateView = !this.isAggregateView;
  }

}
