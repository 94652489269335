<div class="modal-header">
    <!-- <span class="glyphicon glyphicon-road pull-left" style="margin-top:1em;margin-right:1em;"></span> -->
    <h2 class="modal-title pull-left">{{modelName}}</h2>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="form-horizontal" *ngIf="(payload) && !permission_execute_CoA">
        <p>Not Authorized to execute a {{label_coa}}</p>
    </div>
    <div class="form-horizontal" *ngIf="(currentTask) && !permission_reassignCoA">
        <p>Not Authorized to Re-assign a {{label_coa}}</p>
    </div>
    <div class="form-horizontal" *ngIf="(courseOfAction) && !permission_update_dueDate">
        <p>Not Authorized to update {{label_coa}} Due Date</p>
    </div>
    <form name="form" (ngSubmit)="fm.form.valid" class="darker-form" #fm="ngForm" novalidate>
        <div class="form-horizontal" *ngIf="!execute_success">
            <div class="form-group" *ngIf="payload && permission_execute_CoA">
                <div *ngIf="recommendedCoa.length <= 0">
                    <div class="col-sm-3">
                        <label for="selectWorflow">Select {{label_coa}}:</label>
                    </div>
                    <div class=" col-offset-sm-1 col-sm-8">
                        <ng-select [items]="processesItems" bindLabel="text" bindValue="id" [(ngModel)]="processId"
                            [ngClass]="{ 'is-invalid': fm.submitted && !workflow_valid }"
                            (change)="recommendedCoASelected($event)" placeholder="-- Select {{label_coa}} --"
                            name="coaExecuteProcessDefiniton" id="coaExecuteProcessDefinfition">
                        </ng-select>
                        <div *ngIf="fm.submitted && !workflow_valid" class="alert alert-danger">
                            <div>{{label_coa}} is required</div>
                        </div>
                    </div>
                </div>
                <div *ngIf="recommendedCoa.length > 0">
                    <div class="col-sm-3">
                        <label for="recommendedCOA">Select {{label_coa}}:</label>
                    </div>
                    <div class="col-offset-sm-1 col-sm-8">
                        <!-- <ng-select [allowClear]="true" [ngClass]="{ 'is-invalid': fm.submitted && !workflow_valid }"
                            [items]="recommendedCoAItems" [active]="coaSelected"
                            (removed)="recommendedCoARemoved($event)" (selected)="recommendedCoASelected($event)"
                            placeholder="-- Select a Recommended {{label_coa}} --">
                        </ng-select> -->
                        <ng-select [items]="recommendedCoAItems" bindLabel="text" bindValue="id" [(ngModel)]="processId"
                            [ngClass]="{ 'is-invalid': fm.submitted && !workflow_valid }"
                            (change)="recommendedCoASelected($event)"
                            placeholder="-- Select a Recommended {{label_coa}} --" name="coaExecuteProcessDefiniton2"
                            id="coaExecuteProcessDefinfition2">
                        </ng-select>
                        <div *ngIf="fm.submitted && !workflow_valid" class="alert alert-danger">
                            <div>{{label_coa}} is required</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group" *ngIf="currentTask && permission_reassignCoA">
                <div class="col-sm-3">
                    Current Task:
                </div>
                <div class="col-offset-sm-1 col-sm-4">
                    <div class="m-b-xs">{{ currentTask.name }}</div>
                </div>
            </div>
            <div class="form-group"
                *ngIf="(currentTask && permission_reassignCoA) || (payload && permission_execute_CoA)">
                <div class="col-sm-3">
                    <label for="assignWorflow">Assign Workflow To:</label>
                </div>
                <div class=" col-offset-sm-1 col-sm-8">
                    <ng-select [items]="investigatorItems" bindLabel="text" bindValue="id" [(ngModel)]="investigatorId"
                        [ngClass]="{ 'is-invalid': fm.submitted && !investigator_valid }"
                        (change)="investigatorSelected($event)" placeholder="-- Select Assignee --"
                        name="coaExecuteAssignee" id="coaExecuteAssignee">
                    </ng-select>
                    <div *ngIf="fm.submitted && !investigator_valid" class="alert alert-danger">
                        <div>Assignee is required</div>
                    </div>
                </div>
            </div>
            <div *ngIf="permission_update_dueDate || (payload && permission_execute_CoA)">
                <div class="form-group m-t-md" *ngIf="courseOfAction || (payload && permission_execute_CoA)">
                    <div class="col-sm-3">
                        <label for="setDueDate">Set Due Date:</label>
                    </div>
                    <div class="input-group col-sm-4">
                        <input class="form-control date-input" type="date" [(ngModel)]="dueDate" #dueDateIn="ngModel"
                            name="dueDate" min="" id="setDueDate"
                            [ngClass]="{ 'is-invalid': fm.submitted &&  dueDateIn.invalid }" required>
                        <span class="input-group-btn datepicker-btn">
                            <button type="button" class="btn btn-default">
                                <i class="glyphicon glyphicon-calendar"></i><span class="hidden">Calender</span>
                            </button>
                        </span>
                        <div *ngIf="fm.submitted && dueDateIn.invalid" class="alert alert-danger"
                            style="padding-top: 38px;padding-bottom: 7px;">
                            <div *ngIf="dueDateIn.errors.required">Due Date is required</div>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <div class="form-group" style="margin-top: 25px;">
                <div class="col-sm-4">
                    <button *ngIf="payload && permission_execute_CoA" type="submit" class="btn btn-primary"
                        (click)="executeCoa()">
                        <span class="glyphicon glyphicon-play"></span> Execute {{label_coa}}
                    </button>
                    <button *ngIf="currentTask && permission_reassignCoA" type="submit" class="btn btn-primary"
                        (click)="assignCoa()">
                        Assign {{label_coa}}
                    </button>
                    <button *ngIf="courseOfAction && permission_update_dueDate" type="submit"
                        class="btn btn-primary update-btn" (click)="update()">Update
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="execute_success" class="form-horizontal">
            <div *ngIf="statusMessage" class="pull-left message-status alert alert-success">
                <i class="fa fa-check-circle"></i>{{ statusMessage }}
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <div *ngIf="statusMessage && !execute_success" class="pull-left message-status danger">
        <i class="fa fa-exclamation-triangle"></i>{{ statusMessage }}
    </div>
    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">{{closeBtnName}}</button>
</div>