import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ElementRef } from '@angular/core';
import { EnvService } from '../../env.service';
import { MatDialog } from "@angular/material/dialog";
import { RivirHttp } from '../../shared/services/rivir-http.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';
import { IndicatorWizardModel, IndicatorWizardService } from "../indicator-wizard.service";
import { StixConfigService } from '../../shared/services/stixConfig.service';
import { TableauService } from "../../core/tableau.service";
import { IndicatorWizVisualsUnsetConfirmDialogComponent } from '../indicator-wiz-visuals-unset-confirm-dialog/indicator-wiz-visuals-unset-confirm-dialog.component';
import { IndicatorWizVisualsViewSelectDialogComponent } from '../indicator-wiz-visuals-view-select-dialog/indicator-wiz-visuals-view-select-dialog.component';
import tableau from "tableau-api-js";


@Component({
  selector: 'indicator-wiz-visuals',
  templateUrl: './indicator-wiz-visuals.component.html',
  styleUrls: ['./indicator-wiz-visuals.component.css']
})
export class IndicatorWizVisualsComponent implements OnInit {

  public viz: any = null;
  private tableauUrl: string;
  public searchItem: string;
  public image: any;
  public workbookName: string;
  public allWorkBooks: any;
  public modifiedAllWorkBooks = [];
  public isSelected: string;
  public selectedWorkBookId: string;
  public selectedWorkBook: any;
  public pendingWorkBook: any;
  public model: IndicatorWizardModel;
  public workbookViewList: Array<any>;
  public confirmNoVisual = false;
  public saveBoundCallback: () => void;
  public changeBoundCallback: () => void;

  noVisualSelected = true;
  public loaded = false;
  public isWorkBookSelected = false;
  public showSelected = false;
  public searchInitiated = false;
  public workBookTitle: string;
  public tableauServer: string;
  public contentUrl: string;
  public selectLabel: string;
  public label_indicator: string;
  public label_visualization: string;
  public foundSelectedWorkbook: true;
  public missingVizError: string;


  constructor(
    private indicatorWizardService: IndicatorWizardService,
    private http: HttpClient,
    private env: EnvService,
    private tableauService: TableauService,
    private router: Router,
    public elm: ElementRef,
    private sanitizer: DomSanitizer,
    public stixConfig: StixConfigService,
    public viewSelectDialog: MatDialog,
    public unsetConfirmDialog: MatDialog
  ) { 
    this.tableauUrl = this.env.rivirApiBaseUrl + "/tableau";
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };
    this.label_indicator = stixConfig.indicator_singular;
    this.label_visualization = stixConfig.visualization_singular;

  }

  ngOnInit(): void {
    this.changeBoundCallback = this.changeWorkbookButtonClick.bind(this);
    this.saveBoundCallback = this.saveLocalDataToModel.bind(this);
    this.initTableau();
    this.selectLabel = this.stixConfig.visualization_singular;
  }

  async initTableau() {
    this.indicatorWizardService.currentData.subscribe( indicatorWizardModel => {

      // Keep a local copy of the model
      this.model = indicatorWizardModel ? indicatorWizardModel : new IndicatorWizardModel();
      this.loadWorkBooks(`${this.tableauUrl}/getWorkBook?filter=`);
    });

    this.indicatorWizardService.navigation.subscribe( event => {
      if ( event )  {
        this.saveLocalDataToModel();
      }
    });
    this.tableauServer = await this.tableauService.getServerURI();  
  } 
  
  arrayBufferToBase64(buffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  } 

  getWorkbooks() {
    this.searchInitiated = true;
    this.isWorkBookSelected = false;
    this.loadWorkBooks(`${this.tableauUrl}/getWorkBook?filter=` + this.searchItem);
  }

  loadWorkBooks(url){
    this.allWorkBooks = [];
    this.loaded = false;
    this.http.get(url, RivirHttp.getHttpOptions()).subscribe(async (workbooks) => {
      this.allWorkBooks = workbooks;
      this.allWorkBooks.forEach((obj)=>{
        obj.lastUpdatedDate = moment(obj.lastUpdatedDate).format("MM-DD-YYYY");
        if (obj.previewImage.data.length == 0){
          obj.previewImage = "./images/Tableau+Logo.png";
        }
        else {
          const base64String = this.arrayBufferToBase64(obj.previewImage.data);
          obj.previewImage = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + base64String);
        }

      });
      this.allWorkBooks.sort(function(a, b) {
        return +new Date(b.lastUpdatedDate) - +new Date(a.lastUpdatedDate);
      });

      this.foundSelectedWorkbook = this.allWorkBooks.find( x => x.workbookName === this.model.indicator.visualizations);

      if ( !this.foundSelectedWorkbook && this.model.indicator.visualizations ){
        this.noVisualSelected = false;
      }

      if ( this.model.indicator.visualizations  && this.foundSelectedWorkbook) {
        // Try to find the workbook in all workbook, have to check if workbook still exists because users
        // can delete diagrams that are still referenced by indicators
        this.noVisualSelected = false;
        this.selectedWorkBook = this.foundSelectedWorkbook ? this.foundSelectedWorkbook : this.selectedWorkBook;
        this.showSelected = true;
        if ( this.selectedWorkBook && this.model.indicator.defaultView ){
          this.selectedWorkBook.defaultView = this.model.indicator.defaultView;
          // this.contentUrl = this.selectedWorkBook.defaultView.replace('/sheets',''); 
        }
      } else {
        this.selectedWorkBook = null;
      }
      this.loaded = true;
    });
  }

  async select(workbook){
    this.searchInitiated = false;
    const viewIndex = 0;
    if(workbook) {
      const workBookId = workbook.workBookId
      this.allWorkBooks.filter(obj => {
        if (obj.workBookId === workBookId){
          this.pendingWorkBook = obj;
        }
      });
      this.workBookTitle = this.pendingWorkBook.workbookName;
      if (this.pendingWorkBook.workbookViews.view){
        if (this.pendingWorkBook.workbookViews.view[0] && this.pendingWorkBook.workbookViews.view[viewIndex].view.contentUrl){
          this.contentUrl = this.pendingWorkBook.workbookViews.view[0].view.contentUrl.replace('/sheets','');
        }
        else if(this.pendingWorkBook.workbookViews.view[0].view[viewIndex]){
          this.contentUrl = this.pendingWorkBook.workbookViews.view[0].view[viewIndex].contentUrl.replace('/sheets',''); 
        }
        this.isWorkBookSelected = true;
        this.showSelected = false;
        this.loadViz(this.contentUrl);
      }
    } else {
      if (!this.noVisualSelected) this.openUnsetConfirmDialog();
    }
  }

  isThisWorkbookSelected(workbookName) {
    return this.selectedWorkBook ? (workbookName == this.selectedWorkBook.workbookName) : false;
  }

  async loadViz(contentUrl) {
    if (this.viz) { this.viz.dispose(); this.viz = null; }
      const workBookUrl = await this.tableauService.getWorkbookUrl( contentUrl );
      const containerDiv = document.getElementById("vizContainer");
      const options = { hideTabs: false, hideToolbar: false,
        onFirstInteractive: function () {
          // triggered when visualization is loaded
        } 
      };
      this.viz = new tableau.Viz( containerDiv, workBookUrl, options );
  }
  saveLocalDataToModel() {
    if ( this.selectedWorkBook ){
      this.model.indicator.visualizations = this.selectedWorkBook.workbookName,
      this.model.indicator.defaultView = this.selectedWorkBook.defaultView ? this.selectedWorkBook.defaultView : null;
    } else if ( this.noVisualSelected ){
      this.model.indicator.visualizations = null;
    }
  }

  changeWorkbookButtonClick() {
    this.showSelected = false;
  }

  selectClick(){
    this.workbookViewList = this.pendingWorkBook.workbookViews.view[0].view.map(({id, name, contentUrl}, index) => { return {id, name, contentUrl, index}});
    this.openViewSelectDialog();
  }

  clearSelectedClick() {
    this.isWorkBookSelected = false;
    this.showSelected = !!(this.selectedWorkBook && this.selectedWorkBook.workbookName);
  }

  openUnsetConfirmDialog() {
    const dialogRef = this.unsetConfirmDialog.open(IndicatorWizVisualsUnsetConfirmDialogComponent, {
      panelClass: 'indicatorWizardVisualPopup',
      data: {
        title: this.selectedWorkBook ? "Currently set workbook" : `Set no ${this.stixConfig.visualization_singular}`,
        workbookName: this.selectedWorkBook?.workbookName,
        message: this.selectedWorkBook ? "Are you sure you want to unset this workbook?" : `Are you sure want to  set no ${this.stixConfig.visualization_singular} for this ${this.stixConfig.indicator_singular}?`
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.selectedWorkBook = null;
        this.noVisualSelected = true;    
        this.showSelected = false;
        this.confirmNoVisual = false;
      }
    });
  }  

  openViewSelectDialog() {
    const dialogRef = this.viewSelectDialog.open(IndicatorWizVisualsViewSelectDialogComponent, {
      panelClass: 'indicatorWizardVisualPopup',
      data: {
        title: "Are you sure you want to select this workbook?",
        workbookViewList: this.workbookViewList,
        workbookName: this.pendingWorkBook.workbookName,
        item: this.workbookViewList && this.workbookViewList.length ? this.workbookViewList[ 0 ].name  : null
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.pendingWorkBook.defaultView = data;
        this.noVisualSelected = false;
        this.selectedWorkBook = this.pendingWorkBook;
        this.isWorkBookSelected = false;
        this.showSelected = true;
        this.foundSelectedWorkbook = true;
      }
    });
  }

}
