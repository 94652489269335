import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ElementRef } from '@angular/core';
import { UserProfileService } from '../core/userProfile.service';
import { EnvService } from '../env.service';
import { RivirHttp } from '../shared/services/rivir-http.service';
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { TableauService } from "../core/tableau.service";
import { Router } from '@angular/router';
import * as moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';
import { StixConfigService } from '../shared/services/stixConfig.service';


@Component({
  selector: 'app-tableau',
  templateUrl: './tableau.component.html',
  styleUrls: ['./tableau.component.css']
})
export class TableauComponent implements OnInit {

  viz: any;
  private _tableauUrl: string;
  public searchItem: string;
  public image: any;
  public workbookName: string;
  public allWorkBooks: any;
  public modifiedAllWorkBooks = [];
  public isSelected: string;
  public selectedWorkBookId: string;
  public selectedWorkBook: any;
  selected=0;
  public loaded = false;

  constructor(
    private _http: HttpClient,
    private env: EnvService,
    private userProfileService: UserProfileService,
    public dialog: MatDialog,
    private tableauService: TableauService,
    private router: Router,
    public elm: ElementRef,
    private sanitizer: DomSanitizer,
    public stixConfig: StixConfigService
  ) { 

    this._tableauUrl = this.env.rivirApiBaseUrl + "/tableau";
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };
  }

  ngOnInit(): void {
    this.initTableau();
  }
  


  initTableau() {
    this.loadWorkBooks(`${this._tableauUrl}/getWorkBook?filter=`);
  }   

  arrayBufferToBase64(buffer) {
    let binary = '';
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  } 


  getWorkbooks() {
    this.loadWorkBooks(`${this._tableauUrl}/getWorkBook?filter=` + this.searchItem);
  }

  loadWorkBooks(url){
    this.allWorkBooks = [];
    this.loaded = false;
    this._http.get(url, RivirHttp.getHttpOptions()).subscribe((workbooks) => {
      this.allWorkBooks = workbooks;
      this.allWorkBooks.forEach((obj)=>{
        obj.lastUpdatedDate = moment(obj.lastUpdatedDate).format("MM-DD-YYYY");
        if (obj.previewImage.data.length == 0){
          obj.previewImage = "https://images.squarespace-cdn.com/content/v1/587670ef03596ec731de6e3d/1486276069386-ENNKG7EAXQBUZHPMEY37/Tableau+Logo.png";
        }
        else{
          let base64String = this.arrayBufferToBase64(obj.previewImage.data);
          obj.previewImage = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + base64String);
        }
      });
      this.allWorkBooks.sort(function(a, b) {
        return +new Date(b.lastUpdatedDate) - +new Date(a.lastUpdatedDate);
      });
      this.loaded = true;
    });
  }


  clickedCancel(){
    const navigationDetails: string[] = ['/'];
    this.router.navigate(navigationDetails);
  }

  select(workBookId){
    this.selectedWorkBookId = workBookId;
    this.allWorkBooks.filter(obj => {
      if (obj.workBookId === workBookId){
        this.selectedWorkBook = obj;
      }
    });
  }

  clickedWorkBook(){
    if (this.selectedWorkBookId !== "") {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: `Create Dashboard: ${this.selectedWorkBook.workbookName} `,
          message: `Are you sure you would like to select this workbook?`
        }
      });

      dialogRef.afterClosed().subscribe( async (confirmed) => {
        if (confirmed) {
            let contentUrl = '';
            let viewName = '';
            if (this.selectedWorkBook.workbookViews.view[0].view && this.selectedWorkBook.workbookViews.view[0].view.contentUrl){
              contentUrl = this.selectedWorkBook.workbookViews.view[0].view.contentUrl.replace('/sheets','');
              viewName = this.selectedWorkBook.workbookViews.view[0].view.name;

            }
            else{
              contentUrl = this.selectedWorkBook.workbookViews.view[0].view[0].contentUrl.replace('/sheets',''); 
              viewName = this.selectedWorkBook.workbookViews.view[0].view[0].name
            }
            await this.tableauService.setDashboard(this.userProfileService.userEmail, "", viewName, 
            this.selectedWorkBook.workbookName, this.selectedWorkBook.workBookId, this.selectedWorkBook.parentProject.name, this.userProfileService.userEmail, contentUrl);
            const navigationDetails: string[] = ['/'];
            this.router.navigate(navigationDetails);
        }     
      });
   }
  }


}