import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { catchError, map } from 'rxjs/operators';

import { EnvServiceFactory } from "../env.service.provider";
import { RivirHttp } from "../shared/services/rivir-http.service";

export class RivirObject {
    protected m_object = null;
    
    constructor(public json: string) {
        if (json == null) {
            this.m_object = {};
        } else {
            this.m_object = JSON.parse(json);
        }
    }

    public getActive() {
        let result = false;

        if ("active" in this.m_object) {
            result = this.m_object.active;
        }

        return result;
    }

    public setActive(val) {
        this.m_object.active = val;
    }

    public getCreateDtime() {
        let date = null;

        if ("create_dtime" in this.m_object) {
            const dateObject = this.m_object.create_dtime;
            const ts = dateObject.$date;
            date = new Date(ts);
        }

        return date;
    }

    public setCreateDtime(date: Date) {
        const object = { $date: date.getMilliseconds };

        this.m_object.init_dtime = object;
    }

    public getData() {
        let data = null;

        if ("data" in this.m_object) {
            data = this.m_object.data;
        }

        return data;
    }

    public setData(data: Object) {
        this.m_object.data = data;
    }

    public getJSON() {
        return JSON.stringify(this.m_object);
    }

    public getJSONObject() {
        return this.m_object;
    }

    public getObjectId() {
        let oid = null;

        if ("_id" in this.m_object) {
            const objectId = this.m_object._id;
            oid = objectId.$oid;
        }

        return oid;
    }

    public getField(fieldname: string) {
        let fval = null;

        if (fieldname in this.m_object) {
            fval = this.m_object[fieldname];
        }

        return fval;
    }

    public setField(fieldname: string, fval) {
        this.m_object[fieldname] = fval;
    }

    public getUpdateDtime() {
        let date = null;

        if ("update_dtime" in this.m_object) {
            const dateObject = this.m_object.update_dtime;
            const ts = dateObject.$date;
            date = new Date(ts);
        }

        return date;
    }

    public setUpdateDtime(date: Date) {
        const object = { $date: date.getMilliseconds };

        this.m_object.update_dtime = object;
    }

    public serializeJSON(jobj: Object) {
        this.m_object = jobj;
    }
}

export class RivirAPIResponse extends RivirObject {
    constructor(public json: string) {
        super(json);
    }

    public getStatusCode() {
        let code = 0;

        if ("status_code" in this.m_object) {
            code = this.m_object.status_code;
        }

        return code;
    }

    public setStatusCode(code: number) {
        this.m_object.status_code = code;
    }

    public getMessage() {
        let message = null;

        if ("message" in this.m_object) {
            message = this.m_object.message;
        }

        return message;
    }

    public setMessage(message: string) {
        this.m_object.message = message;
    }

    public getObjectId() {
        let objectId = null;

        if ("object_id" in this.m_object) {
            objectId = this.m_object.object_id;
        }

        return objectId;
    }

    public setObjectId(objectId: string) {
        this.m_object.object_id = objectId;
    }
}

export class RivirAPIFilter extends RivirObject {
    private m_expressions = [];
    private m_filterType: string = null;
    private m_operator = null;

    constructor(public json: string) {
        super(json);
    }

    public addExpression(expr: object) {
        this.m_expressions.push(expr);
    }

    public getFilterString(): string {
        let filterString = "";

        if (this.m_filterType != null && this.m_expressions.length > 0) {
            const filter: object = {};

            if (this.m_expressions.length == 1) {
                filter[this.m_filterType] = this.m_expressions[0];
                filterString = JSON.stringify(filter);
            } else {
                if (this.m_operator != null) {
                    const expressionList = [];
                    let idx = 0;

                    for (idx = 0; idx < this.m_expressions.length; idx++) {
                        expressionList.push(this.m_expressions[idx]);
                    }

                    const clause = {};
                    clause[this.m_operator] = expressionList;
                    filter[this.m_filterType] = clause;
                    filterString = JSON.stringify(filter);
                }
            }
        }

        return filterString;
    }

    public getFilterType() {
        return this.m_filterType;
    }

    public setFilterType(filterType: string) {
        this.m_filterType = filterType;
    }

    public getOperator() {
        return this.m_operator;
    }

    public setOperator(operator: string) {
        this.m_operator = operator;
    }
}

@Injectable()
export class RivirAPI {
    private env = EnvServiceFactory();
    private m_serviceUrl: string = this.env.rivirApiBaseUrl;

    constructor(private http: HttpClient) {

    }

    public getRequestWithPath(path: string, filter: RivirAPIFilter): Observable<RivirAPIResponse> {
        let ep = this.m_serviceUrl + "/" + path;

        if (filter != null) {
            const filterJson = filter.getFilterString();
            const queryString = "?filter=" + encodeURIComponent(filterJson);
            ep += queryString;
        }

        return this.http.get(ep, RivirHttp.getHttpOptions())
            .pipe(map((response: any) => {

                const items = new Array<RivirObject>();
                const returned: any = response;
                let idx = 0;

                if (returned != null && returned.length > 0) {
                    for (idx = 0; idx < returned.length; idx++) {
                        const item: RivirObject = new RivirObject(null);
                        item.serializeJSON(returned[idx]);
                        items.push(item);
                    }
                }

                const gresponse = new RivirAPIResponse(null);
                gresponse.setStatusCode(200);
                gresponse.setMessage("Successfully returned " + items.length + " items.");
                gresponse.setObjectId("N/A");
                gresponse.setData(items);

                return gresponse;
            }),
                catchError(RivirHttp.handleError));
    }

    public postRequestWithPath(path: string, parameters: RivirObject): Observable<RivirAPIResponse> {
        const ep = this.m_serviceUrl + "/" + path + "/replaceOrCreate";
        const postParams = parameters.getData(); // parameters.getPostParametersAsObject();

        const headers = new HttpHeaders()
            .set("Content-Type", "application/json");
        const options = { headers };

        return this.http.post(ep, postParams, options)
            .pipe(map(this.handleData),
                catchError(RivirHttp.handleError));
    }

    public updateRequestWithPath(path: string, parameters: RivirObject): Observable<RivirAPIResponse> {
        const ep = this.m_serviceUrl + "/" + path + "/replaceOrCreate";
        const postParams = parameters.getData(); // parameters.getPostParametersAsObject();

        const headers = new HttpHeaders()
            .set("Content-Type", "application/json");
        const options = { headers };

        return this.http.post(ep, postParams, options)
            .pipe(map(this.handleData),
                catchError(RivirHttp.handleError));
    }

    private handleData(res: any) {
        // retrieve the json coming back
        const body = res.json();
        // console.log("Handle Data Response: " + JSON.stringify(body));
        // serialize the response as a gobj
        const gresponse: RivirAPIResponse = new RivirAPIResponse(null);
        gresponse.setData(body);
        gresponse.setMessage("Successfully POSTed data.");
        gresponse.setStatusCode(res.status);

        const idvalue = body.id;

        if (idvalue != null) {
            gresponse.setObjectId(idvalue);
        }

        return gresponse;
    }
}
