import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";

import { StixConfigService } from "../../shared/services/stixConfig.service";
import { CaseHistory, CaseHistoryType, CaseService } from "../case-service";

import { ToastrService } from "ngx-toastr";

import { fromEvent, Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { distinctUntilChanged } from "rxjs/operators";
import { tap } from "rxjs/operators";

@Component({
  selector: "case-history",
  styleUrls: ["./case-history.component.css"],
  templateUrl: "./case-history.component.html",
})
export class CaseHistoryComponent implements OnInit {

  @Input() public caseId: string;
  @ViewChild(MatPaginator) public paginator: MatPaginator;
  @ViewChild(MatSort) public sort: MatSort;
  @ViewChild("input") public input: ElementRef;

  @Input() public caseSubject: Subject<any>;

  public displayedColumns: string[] = [
    "description",
    "modifiedUserFullName",
    "modified",
  ];
  public dataSource = new MatTableDataSource<CaseHistory>();

  constructor(
    public stixConfig: StixConfigService,
    public caseService: CaseService,
    public toastr: ToastrService
  ) { }

  public async ngOnInit() {
    await this.loadCaseHistory();
    this.dataSource.filterPredicate = this.createFilter();

    this.caseSubject.subscribe((event) => {
      this.refreshList();
    });

  }

  public ngAfterViewInit(): void {

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    fromEvent(this.input.nativeElement, "keyup")
      .pipe(
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.dataSource.filter = this.input.nativeElement.value.trim().toLocaleLowerCase();
        }),
      )
      .subscribe();
  }

  public createFilter(): (data: CaseHistory, filter: string) => boolean {

    const filterFunction = (data: CaseHistory, filter: string): boolean => {

      return data.searchString.includes(filter.toLowerCase());
    };
    return filterFunction;

  }

  public async loadCaseHistory() {
    const caseHistory = await this.caseService.getHistory(this.caseId, CaseHistoryType.CASE).toPromise();
    this.dataSource.data = caseHistory;
  }

  public async refreshList() {
    this.input.nativeElement.value = null;
    await this.loadCaseHistory();
    this.dataSource.filter = null;
  }

  public ngOnDestroy() {
    this.caseSubject.unsubscribe();
  }

}
