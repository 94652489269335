<mat-card class="bulk-status-card">
    <mat-card-header>
        <mat-card-title>
            Helpful Links
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-6">
                    <a class="helpLink" href="https://www.qlarant.com">Qlarant</a>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <a class="helpLink" href="https://mmcp.health.maryland.gov/healthchoice/pages/Home.aspx">Maryland
                        Department of Health</a>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>