import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EnvService } from "../../env.service";;
import { RivirHttp } from "./rivir-http.service";

export class MayanDocument{
    public id: number;
    public version: number;
    public fileId: number;
    public name: string;
    public size: any;
    public downloadUrl: string;
    public mimetype: string;
    public dateCreated: Date;
    public createdBy: string;
    public description: string;
    public previewUrl: string; 
    public createdByName: string;
    public createdByEmail: string;
    public isInQueue: boolean;
}

export class MayanFolder{
    public id: number;
    public name: string; 
    public path: string;
}



@Injectable()
export class MayanDocumentService {
    private documentsUrl : string;
    public downloadUrl: string;
    public allowedFileTypes: string[];
    public docMaxSize: number;
    private coaFolder: MayanFolder;


    constructor(private http: HttpClient, private env: EnvService) { 
        this.documentsUrl = this.env.rivirApiBaseUrl + "/mayanDocuments";
        this.allowedFileTypes = this.env.coaAttachmentAllowedFileTypes ||
            ["txt", "pdf", "png", "jpg", "jpeg", "gif", "html", "csv", "doc", "docx", "xls", "xlsx", "ppt", "pptx", "tif", "tiff"];
        this. docMaxSize = this.env.coaAttachmentAllowedMaxSize || 104857600;
    }


    get folder():MayanFolder{
        return this.coaFolder;
    }

    set folder(value: MayanFolder){
        this.coaFolder = value;
    }

    public async saveDocument( fileName: string, folderId: number, fileUpload: File, description: string, email: string, userFullName: string, taskName: string, documentId: string, instanceId : string ): Promise<any>  {

        if ( this.env.useMayan && !folderId ){
            return { error : "Failed to retrieve folder needed for upload.  Please try again or contact your System Administrator " };
        }
        else {
            try {
                const url = `${this.documentsUrl}/upload`;
                const formData: FormData = new FormData();
                formData.append("file_new", fileUpload, fileName);
                formData.append("name", fileName);
                formData.append("folderId", folderId ? folderId.toString() : "");
                formData.append("description", description);
                formData.append("email",  email);
                formData.append("userFullName", userFullName);
                formData.append("taskName", taskName);
                formData.append("documentId", documentId);
                formData.append("instanceId", instanceId);
                return await this.http
                    .post(url, formData, RivirHttp.getHttpOptions())
                    .toPromise();
            }
            catch ( err ) {
                console.error( err );
                return { error: "Failed to upload, Please try again or contact your System Administrator"};
            }
        }
    }

    public async getDocuments( folderPath ) : Promise<MayanDocument[]> {
        const getMayanDocumentsUrl = `${this.documentsUrl}/folder?folderPath=${encodeURI(folderPath)}`;
        let folderContents = await this.http.get<MayanDocument[]>(getMayanDocumentsUrl, RivirHttp.getHttpOptions()).toPromise();

        // Setting this here because "this" is causing problems in certain environments;
        const rivirApiBaseUrl = this.env.rivirApiBaseUrl;

        folderContents = folderContents && folderContents.length ? folderContents.map( file => {
            file.size = file.size ? this.formatBytes(file.size) : file.size;
            const baseUrl = file.downloadUrl.includes("staged-uploads") || file.downloadUrl.includes("coa-attachments") ? rivirApiBaseUrl.slice(0,-4) : rivirApiBaseUrl;
            file.downloadUrl = `${baseUrl}${file.downloadUrl}?access_token=${RivirHttp.rivirApiToken}`;
            return file;
            
        }) : [];
        return folderContents as MayanDocument[];
    }

    public formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';
    
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    
        const i = Math.floor(Math.log(bytes) / Math.log(k));
    
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    public async createFolder( instanceId ) : Promise<MayanFolder>{
        const createFolderUrl = `${this.documentsUrl}/folder`;
        const createFolderResponse = await this.http.post<MayanFolder>(createFolderUrl, { parentFolder: 'coa-attachments', folderName: instanceId}, RivirHttp.getHttpOptions()).toPromise();
        return createFolderResponse;
    }

    public async getVersions( documentId: string ) : Promise<MayanDocument[]>{

        const getVersionsUrl = `${this.documentsUrl}/document/${documentId}/versions`;
        let versions = await this.http.get<MayanDocument[]>(getVersionsUrl, RivirHttp.getHttpOptions()).toPromise();
        versions = versions.map( (version) => {
            version.size = version.size ? this.formatBytes(version.size) : version.size;
            version.downloadUrl = `${this.env.rivirApiBaseUrl}${version.downloadUrl}?access_token=${RivirHttp.rivirApiToken}`;
            return version;
        })
        return versions;
    }

    public async getFolderId(instanceId: string) : Promise<any>{
        const getFolderIdUrl= `${this.documentsUrl}/getFolderId/${instanceId}`;
        let folderContents = await this.http.get<string>(getFolderIdUrl, RivirHttp.getHttpOptions()).toPromise();
        return(folderContents);
    }

}
