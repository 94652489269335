import { Component, OnInit, EventEmitter, Output, NgZone } from '@angular/core';
import { UserProfileService } from '../../../core/userProfile.service';
import { TABLE_COLUMNS_COAS_AGGREGATE_STAFF } from '../../coa-list-columns';
import { CoAService } from '../../coa.service';
import { StixConfigService } from '../../../shared/services/stixConfig.service';
import { BehaviorSubject, Subscription, interval } from "rxjs";

@Component({
  selector: 'app-aggregate-view',
  templateUrl: './aggregate-view.component.html',
  styleUrls: ['./aggregate-view.component.css']
})
export class AggregateViewComponent implements OnInit {

  public pageTitle: "Courses of Action";
  public rows = [];
  public columns = [];
  public tableTitle: string;
  public p: number = 1;
  public limitRowsOptions = [10, 25, 50, 100];
  public limitRows: number = this.limitRowsOptions[0];
  public tableInfo: string = "";
  public sortKey = "firstName";
  public asc = true;
  public loaded = false;
  public term: string = "";
  public autoRefresh: Subscription;
  public myData: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  @Output() isAggregateView = new EventEmitter<boolean>();

  // TODO This is legacy object for code that does not exist anymore. 
  // All associated code should be refactored and deleted
  public sideTable: any = null;

  constructor(private userService: UserProfileService, public stixConfig: StixConfigService, private coaService: CoAService, private ngZone: NgZone) {

  }

  ngOnInit() {
    this.tableTitle = "List of My Staff";
    this.coaService.resetCritiria();
    this.getAggregateView().then((r) => {
      this.updateTabelInfo();
    });
  }

  ngOnDestroy() {
    this.autoRefresh.unsubscribe();
  }



  public getAggregateView() {
    return new Promise((resolve, reject) => {
      const observable = interval(1000);
      this.autoRefresh = observable.subscribe((val) => {
        this.userService.getManagerStaffUsers().subscribe((users) => {
          const staffList = users ? users.map(u => u.email) : [];

          this.columns = TABLE_COLUMNS_COAS_AGGREGATE_STAFF;
          let rowsArr = [];

          this.coaService.getUserStaffAggregateCOAs(staffList).subscribe((staff) => {
            users.forEach(user => {
              let aggregate = staff.find(u => u.user === user.email) || {};
              user.closed = aggregate.closed || 0;
              user.open = aggregate.open || 0;
              user.overDuedate = aggregate.overDuedate || 0;
              rowsArr.push(user);
            });
            this.ngZone.run(() => {
              this.rows.length = 0;
              this.rows = rowsArr;
            });
            this.loaded = true;
            resolve(null);
          })
        });
      });
    });
  }

  public itemClick(prop, item) {

    if (prop === "open") {
      this.coaService.modifyFilter("workflowComplete", false);
      this.coaService.modifyFilter("UpdatedBy", `${item.firstName} ${item.lastName}`);
      this.isAggregateView.emit(false);
    } else if (prop === "closed") {
      this.coaService.modifyFilter("workflowComplete", true);
      this.coaService.modifyFilter("UpdatedBy", `${item.firstName} ${item.lastName}`);
      this.isAggregateView.emit(false);
    } else if (prop === "overDuedate") {
      this.coaService.modifyFilter("workflowComplete", false);
      this.coaService.modifyFilter("overDueDate", new Date().toISOString());
      this.coaService.modifyFilter("UpdatedBy", `${item.firstName} ${item.lastName}`);
      this.isAggregateView.emit(false);
    }
  }


  public updateTabelInfo() {
    const startRange = this.rows.length ? this.limitRows * (this.p - 1) + 1 : 0;
    let endRange = this.limitRows * this.p;
    endRange = endRange > this.rows.length ? this.rows.length : endRange;
    this.tableInfo = "Showing " + startRange + " to " + endRange + " of " + this.rows.length + " entries";
  }

  public columnClick(columnName) {
    if (this.sortKey === columnName) {
      this.asc = !this.asc;
    }
    this.sortKey = columnName;
    this.p = 1;
    this.updateTabelInfo();
  }

  public getHoverTitle(prop, item) {
    if (prop == "open") {
      return "Click to view list of user's Open " + this.stixConfig.coa_plural;
    } else if (prop == "closed") {
      return "Click to view list of user's Closed " + this.stixConfig.coa_plural;
    } else if (prop == "overDuedate") {
      return "Click to view list of user's over Duedate " + this.stixConfig.coa_plural;
    }
  }
}
