<div [hidden]="!isLoading">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-5"></div>
      <div class="col-md-4">
        <mat-spinner></mat-spinner>
      </div>
    </div>
  </div>

</div>

<div [hidden]="isLoading">
  <div style="padding-bottom: 25px;">

  <div *ngIf="dataSource.data.length == 0">
    <div style="font-size: 24px; margin: 20px 0 0 20px;">
      No Attachments
    </div>
  </div>

  <div [ngClass]="dataSource.data && dataSource.data.length ? 'task-doc-show-table' : 'task-doc-hide-table'">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort matSortActive="dateCreated"
      matSortDirection="desc" style="width:100%">
      <!-- Title -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Name
        </th>
        <td mat-cell *matCellDef="let element">
          <a href="{{element.downloadUrl}}" target="_blank" class="task-doc-link">
            {{ element.name }}</a>
        </td>
      </ng-container>

      <!-- Created Date Time -->
      <ng-container matColumnDef="dateCreated">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Date/Time Attached
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="task-doc-uploaded">
            {{ element.dateCreated | date: 'MM/dd/yyyy, h:mm a'}}
          </div>
        </td>
      </ng-container>

      <!-- Created By Column -->
      <ng-container matColumnDef="size">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          File Size
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="task-doc-createdby">
            {{ element.size }}
          </div>
        </td>
      </ng-container>

      <!-- Created By Column -->
      <ng-container matColumnDef="createdByName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Attached By
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="task-doc-createdByName">
            {{ element.createdByName }}
          </div>
        </td>
      </ng-container>

      <!-- Task Name Column -->
      <ng-container matColumnDef="taskName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Task
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="task-doc-createdby">
            {{ element.taskName }}
          </div>
        </td>
      </ng-container>

      <!-- Task Column -->
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Description
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.description }}
        </td>
      </ng-container>

      <!-- Delete Column -->
      <ng-container matColumnDef="url" *ngIf="!readOnly">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <span style="display: none; visibility: hidden;">Delete</span>
        </th>
        <td mat-cell *matCellDef="let element">
          <button mat-button [matMenuTriggerFor]="menu"><mat-icon matSuffix>more_vert</mat-icon></button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="redirectToHistory(element)">View History</button>
            <button mat-menu-item (click)="openUpdateFileDialog(element)">Update File</button>
          </mat-menu>
        </td>
      </ng-container>


      <!-- RIViR Top Header -->
      <ng-container matColumnDef="rivir-top-header">
        <th mat-header-cell *matHeaderCellDef [attr.colspan]="7" class="rivir-mat-top-header">
          <div class="row">
            <div class="col-md-10" style="color:#fcfcfc; font-size:17px;">
              Attachments
            </div>
            <div class="col-md-2">
              <a class="refresh-list" (click)="refreshList()">
                <i class="fa fa-refresh"></i> Refresh
              </a>
            </div>
          </div>
        </th>
      </ng-container>

      <!-- RIViR Search Header -->
      <ng-container matColumnDef="rivir-search-header">
        <th mat-header-cell *matHeaderCellDef [attr.colspan]="10">
          <div class="row">
            <div class="col-md-9"></div>
            <div class="col-md-1 rivir-mat-search-label">
              Filter:
            </div>
            <div class="col-md-2">
              <input #input type="search" class="form-control" title="Filter">
            </div>
          </div>
        </th>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="['rivir-top-header']">
      </tr>
      <tr mat-header-row *matHeaderRowDef="['rivir-search-header']">
      </tr>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index"
        [ngClass]="i % 2 ? 'rivir-mat-table-row-even' : 'rivir-mat-table-row-odd'"></tr>
    </table>

    <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 50]">
    </mat-paginator>
  </div>
</div>