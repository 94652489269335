import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-indicator-wiz-visuals-view-select-dialog',
  templateUrl: './indicator-wiz-visuals-view-select-dialog.component.html',
  styleUrls: ['./indicator-wiz-visuals-view-select-dialog.component.css']
})
export class IndicatorWizVisualsViewSelectDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: IndicatorWizardDialogData) { }
}

export interface IndicatorWizardDialogData{
  title: string;
  workbookViewList: Array<any>;
  workbookName: string;
  item: string;
}
