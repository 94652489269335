<div class="container">
    <div *ngFor="let ss of serviceStatus" class="row service-check-row">
        <mat-card>
            <div style="margin-bottom: 20px;">
                <div class="col-md-4">
                    <span class="{{ss.status}}"><mat-icon>{{ss.icon}}</mat-icon></span>
                    <span class="service-check-text">{{ss.service}}</span>
                </div>
                <div class="col-md-4">
                    <span class="service-check-text">Last Checkin: {{ss.dateUpdatedDesc}}</span> 
                 </div>
                <div class="col-md-4">
                    <span class="service-check-text"> Age: {{ss.ageDesc}}</span>
                 </div>
            </div>
        </mat-card>
    </div>
</div>