<div class="row nav-button-row form-group" *ngIf="stepNumber !== 5">
    <div class="col-sm-5 col-12">
        <span class="indicator-title">{{ model.indicator.name }}</span>
        <!-- <span class="" *ngIf="model.validationMessage">
            <input type="text" 
            class="form-control" 
            aria-describedby="Name of Indicator"
            [(ngModel)]="model.indicator.name"
            readonly
            >
        </span> -->
        <span class="indicator-title-stepof-label">Step {{ stepNumber + 1 }} of {{ allSteps.length }}: </span>
        <span class="indicator-title-label">{{ currentStep.header }}</span>
    </div>
    <div class="col-sm-7 col-12 action-btn-col">
        <button type="button" class="btn btn-outline-secondary btn-indicator-nav" (click)="back()" *ngIf="stepNumber !== 0">Back</button>
        <button type="button" class="btn btn-outline-success btn-indicator-nav change-button" (click)="change()" *ngIf="showChangeButton">Change</button>
        <button type="button" class="btn btn-outline-secondary btn-indicator-nav" *ngIf="stepNumber === 0" (click)="uploadIndicator.click()">Upload</button>
        <input #uploadIndicator hidden="true" style="display:none" type="file" onclick="this.value=null" aria-label=" " aria-hidden="true"
        (change)="fileSelected($event)"/>
        <button type="button" class="btn btn-outline-success btn-indicator-nav" (click)="save()" [disabled]="!model.indicator.name || !model.indicator.script || !model.indicator.description || !model.indicator.classifications || !model.indicator.classifications.length  || model.validationMessage">Save</button>
        <button type="button" class="btn btn-success btn-indicator-nav" (click)="next()">Next</button>
    </div>
</div>
<div class="row nav-button-row review-nav-btn-row" *ngIf="stepNumber === 5">
    <div class="col-sm-5 col-12">
        <span class="indicator-title">{{ model.indicator.name }}</span>
        <span class="indicator-title-stepof-label">Step {{ stepNumber + 1 }} of {{ allSteps.length }}: </span>
        <span class="indicator-title-label">{{ currentStep.header }}</span>
    </div>
    <div class="col-sm-7 col-12 action-btn-col"> 
        <button type="button" class="btn btn-outline-secondary btn-indicator-nav" (click)="leaveWizard()">Cancel</button>
        <button type="button" class="btn btn-outline-success btn-indicator-nav" (click)="save()" [disabled]="!model.indicator.name || !model.indicator.script || !model.indicator.description || !model.indicator.classifications || !model.indicator.classifications.length || model.validationMessage">Save</button>
        <button type="button" class="btn btn-success btn-indicator-nav" (click)="execute()" [disabled]="!isExecutable()">Execute</button>
    </div>
</div> 
<div class="row" *ngIf="model.validationMessage">
    <div class="col-12">
        <div class="alert alert-danger">
            {{ model.validationMessage }}
        </div>
    </div>
</div>