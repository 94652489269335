import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "filter",
})
export class FilterPipe implements PipeTransform {
  public transform(items: any[], filterText: string, columns: any[]): any[] {
    if (!items) { return []; }
    if (!filterText) { return items; }

    filterText = filterText.toLowerCase();

    if (columns) {
      return items.filter((it) => {
        for (const c in columns) {
          const prop = columns[c].hasOwnProperty("prop") ? columns[c].prop : columns[c];
          if (it[prop]) {
            if (typeof it[prop].getTime === "function") {
              it[prop] = it[prop].toDateString();
            }
            if (typeof it[prop] === "number") {
              it[prop] = it[prop] + "";
            }
            if (Array.isArray(it[prop])) {
              it[prop] =  it[prop].join(" ");
            }
            if (it[prop] && it[prop] !== true && it.hasOwnProperty(prop) && it[prop].toLowerCase().indexOf(filterText) !== -1) {
              return true;
            }
          }
        }
        return false;
      });

    } else {
      return items.filter((it) => {
        for (const k in it) {
          if (it[k] && it[k] !== true && it.hasOwnProperty(k) && it[k].toLowerCase().indexOf(filterText) !== -1) {
            return true;
          }
        }
        return false;
      });
    }
  }

}
