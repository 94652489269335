import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { Router } from "@angular/router";

import { CookieService } from 'ngx-cookie-service';
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";

import { forkJoin } from 'rxjs';
import {  map } from 'rxjs/operators';

import { iMessage } from "../../shared/models/alert-message";
import { UserProfile } from "../../shared/models/userProfile";
import { StixConfigService } from "../../shared/services/stixConfig.service";
import { WorkflowService } from "../../shared/services/workflow.service";
import { LoggingService, LogLevel, LogType } from "../../core/logging.service";
import { UserProfileService } from "../../core/userProfile.service";
import { IndicatorService } from "../indicators.service";
import {
  CourseOfActionAutoStart,
  IAlgorithm,
} from "../nerdy-algorithm-list/nerdy-algorithm-list";
import { Indicator } from "../indicators-list/indicator";
import { PermissionService } from "../../core/permission.service";



declare var $: any;

@Component({
  selector: "app-algoview",
  templateUrl: "./algoview.component.html",
  styleUrls: ["./algoview.component.css"],
})
export class AlgoviewComponent implements OnInit {
  public classificationList = [];
  public typeList = [];
  public pathToCOAList = [];
  public visualizationsList = [];
  public indicator: any = {};
  public uploadedFile: any = {};
  public wrapModal = false;
  public indicatorForm = true;
  public updateRecord = false;
  public message: iMessage = null;
  public newClass: string;
  public newType: string;
  public label_indicator: string;
  public label_indicators: string;
  public label_coa: string;
  public label_coas: string;
  public investigatorItems: any[] = [];
  public investigators: UserProfile[] = [];
  public processDefinitions: any[] = [];

  @Input() public algorithm: IAlgorithm;
  @Input() public modelName: string;
  @Input() public editMode = false;
  @ViewChild("selectCoA")
  public selected_CoAs: any;
  @ViewChild("selectType")
  public selected_Types: any;
  @ViewChild("selectClass")
  public selected_Classifications: any;
  @ViewChild("selectVisualization")
  public selected_Visualization: any;
  public executeAfterSave = false;
  public classification_valid = false;

  // New variables 
  allClassifications: string[];
  allTypes: string[];
  allUsers: any[];
  allProcessDefinitions: any[];
  allVisuals: string[];
  canExecute: boolean;

  classifications?: string[];

  constructor(
    public modalRef: BsModalRef,
    private _workflowService: WorkflowService,
    private _indicatorService: IndicatorService,
    private router: Router,
    private userService: UserProfileService,
    public toastr: ToastrService,
    vcr: ViewContainerRef,
    public stixConfig: StixConfigService,
    private log: LoggingService,
    private userProfileService: UserProfileService,
    private cookieService: CookieService,
    private permissions: PermissionService
  ) {
    this.label_indicator = this.stixConfig.indicator_singular;
    this.label_indicators = this.stixConfig.indicator_plural;
    this.label_coa = this.stixConfig.coa_singular;
    this.label_coas = this.stixConfig.coa_plural;
  }


  public async ngOnInit() {

    const {
      types,
      classifications,
      processDefinitions,
      activeUsers
    } = await this.getIndicatorInitialData();

    this.allTypes = types;
    this.allClassifications = classifications;
    this.allProcessDefinitions = processDefinitions;
    this.allUsers = activeUsers;

    this.allVisuals = [];
    this.canExecute = await this.permissions.getPermission("Execute Indicator");
  }

  public addNewClassification() {
    this.allClassifications = [...this.allClassifications, this.newClass];
    this.newClass = '';
  }

  public addNewType() {
    this.allTypes = [...this.allTypes, this.newType];
    this.newType = '';
  }

  public async saveIndicator(algorithm): Promise<any> {

    try {
      // Check if auto starts are valid
      let autoStartsValid = true;
      if (algorithm.autoStartCoursesOfAction) {
        algorithm.autoStartCoursesOfAction.forEach(function (
          autoStart: CourseOfActionAutoStart,
        ) {
          autoStart.coaIsInvalid = !(
            autoStart.courseOfActions && autoStart.courseOfActions.length != 0
          );
          autoStart.userIsInvalid = autoStart.user ? false : true;
          autoStart.dayOutIsInvalid = !(
            autoStart.daysOut && autoStart.daysOut >= 0
          );

          // If any of these are not valid set the invalid flag, only does this check if no previous
          // autostart is invalid
          if (autoStartsValid) {
            autoStartsValid =
              !autoStart.coaIsInvalid &&
              !autoStart.userIsInvalid &&
              !autoStart.dayOutIsInvalid;
          }
        });
      }

      // Form Validations:
      this.classification_valid = this.algorithm.classifications.length > 0;

      if (
        algorithm.name &&
        algorithm.description &&
        this.classification_valid &&
        autoStartsValid &&
        (this.uploadedFile.value || this.algorithm.script)
      ) {


        const currentDateTime = new Date();
        let indicatorToSave: Indicator = {
          name: this.algorithm.name.trim(),
          description: this.algorithm.description.trim(),
          visualizations: this.algorithm.visualizations,
          coursesOfAction: this.algorithm.coursesOfAction,
          types: this.algorithm.types,
          classifications: this.algorithm.classifications,
          script: this.uploadedFile.value || this.algorithm.script,
          CreatedBy: this.userService.fullName,
          UpdatedBy: this.userService.fullName,
          CreatedDateTime: currentDateTime,
          UpdatedDateTime: currentDateTime,
          active: true,
          autoStartCoursesOfAction: this.algorithm.autoStartCoursesOfAction,
          updateRecord: this.algorithm.id ? true : false // This flag which determines whether an algorithm is being created or updated
          // should be refactored in the UI to just look for an the id
        };

        // Save the Indicator
        let data: Indicator = await this._indicatorService.saveIndicator(indicatorToSave).toPromise();

        // Hide the modal if in edit mode, in create mode show the configuration view 
        this.editMode ? this.modalRef.hide() : this.indicatorForm = false;


        this.indicator.id = data.id || "";
        this.indicator.name  = this.algorithm.name;
        const runningMessage = this.executeAfterSave ? `${this.stixConfig.indicator_singular} is runnning now...` : "";
        this.message = new iMessage(
          "Success",
          `${this.stixConfig.indicator_singular} "${this.algorithm.name}" has been saved successfully`,
          runningMessage,
        );

        // Log indicator change entry
        this.log.logClientEvent(
          "Add/Edit Indicator",
          LogLevel.Info,
          LogType.App,
          this.cookieService.get("email"),
          JSON.stringify(this.indicator),
        );

        if (this.executeAfterSave) {
          await this._indicatorService.executeAlgorithm(this.indicator, this.userService.fullName).toPromise();

          this.message = new iMessage(
            "Success",
            `${this.stixConfig.indicator_singular} "${this.algorithm.name}" has been saved successfully`,
            `${this.stixConfig.indicator_singular} is runnning now...`,
          );
          this.executeAfterSave = false;
        }
      }
    } catch (indicatorSaveError) {
      console.error(indicatorSaveError);
      // Give the user a generic message that the indicator failed 
      this.message = new iMessage("Error", "The Indicator failed to Save/Execute please contact your system Administrator", "")
    }
  }

  public onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsBinaryString(file);
      reader.onload = () => {
        this.uploadedFile = {
          filename: file.name,
          filetype: file.type,
          value: reader.result,
        };
      };
    }
  }

  public viewUploadedFile() {
    const file = this.uploadedFile.value || this.algorithm.script;
    alert(file.replace(/\r/g, "\n"));
  }

  public goToIndicatorDetail() {
    // this._indicatorService.currentIndicator = this.indicator;
    this.router.navigate(["/indicators", this.indicator.name]);
  }

  public classificationChange(e) {
    this.classification_valid = true;
  }

  public filterbyText(array) {
    const newArray = [];
    for (let i = 0; i < array.length; i++) {
      newArray.push(array[i].text);
    }
    return newArray;
  }

  public addNewAutoStartCoa(event) {
    if (!this.algorithm.autoStartCoursesOfAction || !this.algorithm.autoStartCoursesOfAction.length) {
      this.algorithm.autoStartCoursesOfAction = [];
    }
    this.algorithm.autoStartCoursesOfAction = [...this.algorithm.autoStartCoursesOfAction, new CourseOfActionAutoStart()];
    event.preventDefault();
  }

  public showRemovePrompt(aCoa) {
    aCoa.showRemovePrompt = true;
    event.preventDefault();
  }

  public cancelRemovePrompt(aCoa) {
    aCoa.showRemovePrompt = false;
    event.preventDefault();
  }

  public removeAutoStartCoa(aCoa) {
    this.algorithm.autoStartCoursesOfAction = this.algorithm.autoStartCoursesOfAction.filter(
      (x) => x !== aCoa,
    );
  }

  public async getIndicatorInitialData(): Promise<any> {

    const processDefinitions = await this._workflowService.getProcessDefinitions();

    const algoSource = forkJoin({
      types: this._indicatorService.getTtypesList(),
      classifications: this._indicatorService.getTClassificationsList(),
      activeUsers: this.userProfileService.getActiveUsers(),
    }).pipe(
      map(({ types, classifications, activeUsers }) => {

        // Sort the active users by name 
        activeUsers = activeUsers.map(au => {
          au.nameAndEmail = `${au.name} (${au.email})`;
          return au;
        })
        activeUsers = activeUsers.sort((a, b) => a.name.localeCompare(b.name));

        return {
          types,
          classifications,
          processDefinitions,
          activeUsers
        };
      })
    );

    return algoSource.toPromise();
  }

  public mockReportInitialData(): Promise<any> {
    return new Promise((resolve, reject) => {

      const visualizations = [
        {
          visualiztionGroup: "Reports",
          visualizationName: "Fake Report 1",
          visualizationValue: "R-Fake Report 1"
        },
        {
          visualiztionGroup: "Reports",
          visualizationName: "Fake Report 2",
          visualizationValue: "R-Fake Report 2"
        },
        {
          visualiztionGroup: "Reports",
          visualizationName: "Fake Report 3",
          visualizationValue: "R-Fake Report 3"
        },
        {
          visualiztionGroup: "Dashboards",
          visualizationName: "Dashboard 1",
          visualizationValue: "D-Dashboard 1"
        },
        {
          visualiztionGroup: "Dashboards",
          visualizationName: "Dashboard 2",
          visualizationValue: "D-Dashboard 2"
        },
        {
          visualiztionGroup: "Dashboards",
          visualizationName: "Dashboard 3",
          visualizationValue: "D-Dashboard 3"
        },
        {
          visualiztionGroup: "Dashboards",
          visualizationName: "Dashboard 4",
          visualizationValue: "D-Dashboard 4"
        },
        {
          visualiztionGroup: "Dashboards",
          visualizationName: "Dashboard 5",
          visualizationValue: "D-Dashboard 5"
        },
      ];

      resolve(visualizations);
    })
  }

  public getReportInitialData(): Promise<any> {
   throw new Error("Not Implemented");
  }

  /****************************************************************************************************************************/

  public downloadScript(algorithm: IAlgorithm): void {
    const text = algorithm.script;
    const fileName = algorithm.name + ".py";

    this.download(fileName, text);
  }

  public download(filename, text): void {
    const element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(text),
    );
    element.setAttribute("download", filename);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  public showResults(algorithm: IAlgorithm, vizname: string): void {
    let url: string = null;

    if (vizname != null && vizname.length > 0) {
      url = "/embedviewer?viz_name=" + vizname;
    } else {
      url = "/embedviewer?viz_name=rivir-data-mongobi-test/Events";
    }

    this.router.navigateByUrl(url);
  }
}
