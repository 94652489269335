  export const TABLE_COLUMNS_COAS = [
  { prop: "instanceUIId", name: "coa_singular ID" },
  { prop: "taId", name: "threatActor_singular ID" },
  { prop: "threatActorName", name: "threatActor_singular Name" },
  { prop: "courseOfActionName", name: "coa_singular Name" },
  { prop: "UpdatedDateTime", name: "Last Updated" },
  { prop: "UpdatedBy", name: "Last Assigned" },
  { prop: "status", name: "Status" },
  { prop: "coaDueDate", name: "Due Date" },
  { prop: "viewHistory", name: "coa_singular History" },
];

export const TABLE_COLUMNS_COAS_AGGREGATE_STAFF = [
  { prop: "firstName", name: "First Name" },
  { prop: "lastName", name: "Last Name" },
  { prop: "email", name: "Email Address" },
  { prop: "open", name: "Open" },
  { prop: "closed", name: "Closed" },
  { prop: "overDuedate", name: "Overdue" },
];
