import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { catchError } from 'rxjs/operators';

import { EnvService } from '../../app/env.service';
import { RivirHttp } from '../shared/services/rivir-http.service';

export class HelpLink {
    name: string;
    url: string;
    permission: string;
    id: string;
    show: boolean;
    newTab: boolean;
}

@Injectable()
export class HelpService {

    constructor(
        private http: HttpClient,
        private env: EnvService
    ) { }

    public getHelpLinks(): Promise<HelpLink[]> {
        return new Promise<HelpLink[]>((resolve, reject) => {
            const helpLinkUrl = `${this.env.rivirApiBaseUrl}/helpLinks`;
            this.http
                .get<HelpLink[]>(
                    helpLinkUrl,
                    RivirHttp.getHttpOptions(),
                )
                .pipe(catchError(RivirHttp.handleError))
                .subscribe(
                    (helpLinks: HelpLink[]) => {
                        for (const helpLink of helpLinks) {
                            // Perform string replacement the rivirApiBaseUrl and accessToken
                            helpLink.url = helpLink.url.replace("{rivirApiBaseUrl}", this.env.rivirApiBaseUrl);
                            helpLink.url = helpLink.url.replace("{accessToken}", RivirHttp.rivirApiToken);
                        }

                        resolve(helpLinks);
                    }
                );
        });
    }
};
