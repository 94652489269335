<div *ngIf="!loaded" class="loader">
  Loading
  <span class="loader__dot">.</span>
  <span class="loader__dot">.</span>
  <span class="loader__dot">.</span>
</div>
<div *ngIf="loaded">
  <div class="container-fluid background-white" *ngIf="task && courseOfAction">
    <h2 class="hidden">Task</h2>
    <h3 class="row">
      <span class="id-label">
        <div class="col-sm-2">Current Task:</div>
        <div class="col-sm-8">{{ task.name }}</div>
      </span>
    </h3>
    <div class="main-details">
      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <div class="col-sm-4 coa-details-label">{{ label_coa }} ID:</div>
            <div class="col-sm-8">
              {{ courseOfAction.instanceUID }}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 coa-details-label">{{ label_coa }} Name:</div>
            <div class="col-sm-8">
              {{ courseOfAction.name }}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 coa-details-label">
              {{ label_threatActor }} ID:
            </div>
            <div class="col-sm-8 i-link">
              <a (click)="goToThreatactorPage()" *ngIf="courseOfAction.taId"
                title="Click to open {{label_threatActor}}'s Page">{{ courseOfAction.taId }}</a>
              <span *ngIf="!courseOfAction.taId">--</span>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 coa-details-label">
              {{ label_threatActor }} Name:
            </div>
            <div class="col-sm-8">
              {{ courseOfAction.threatActorName || "--" }}
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row" *ngIf="indicatorName" >
            <div class="col-sm-4 coa-details-label">
              {{ label_indicator }} Name:
            </div>
            <div class="col-sm-8 i-link">
              <a (click)="goToIndicatorPage(indicatorName)" *ngIf="indicatorName"
                title="Click to open {{label_indicator}}'s Page">{{ indicatorName }}</a>
            </div>
          </div>
          <div class="row" *ngIf="indicatorDescription">
            <div class="col-sm-4 coa-details-label">
              {{ label_indicator }} Description:
            </div>
            <div class="col-sm-8">
              {{ indicatorDescription }}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 coa-details-label">
              {{ label_sighting }} Date/Time:
            </div>
            <div class="col-sm-8">
              {{ runDate }}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 coa-details-label">
              Assigned To:
            </div>
            <div class="col-sm-8">
              {{ task.assigneeFullName || task.assignee || "--" }}
            </div>
            <div class="col-sm-4 m-t-md">
              <button *ngIf="showReassign" (click)="reAssign()" class="btn btn-primary reassignAny">
                Reassign
              </button>
              <button *ngIf="showReassign && !hideAssignToMeButton" (click)="assignToMe()" class="btn btn-primary assign">
                Assign to me
              </button>
            </div>
            <div class="col-sm-4 m-t-md">
              <button class="btn btn-primary assign" (click)="uploadFile()" [disabled]="!enableTabContent">
                Attach Files
              </button>
            </div>
            <div class="col-sm-4 m-t-md">
              <button *ngIf="showPending" class="btn btn-default" style="background-color:#F1BB3B;"
                (click)="togglePending()">
                {{pendingButtonText}}</button>
            </div>
          </div>
        </div>
      </div>
      <div class="row m-t-xl">
        <div class="col-sm-12 ">
          <div class="nav-tabs-alt m-b-lg">
            <ul class="nav nav-tabs">
              <li class="active"><a data-toggle="tab" href="#tasksurvey">Form</a></li>
              <li><a data-toggle="tab" href="#taskdiagram">Task Diagram</a></li>
              <li><a data-toggle="tab" href="#taskdocuments">Attachments</a></li>
            </ul>
          </div>

          <div [ngClass]="enableTabContent ? 'enabledform' : 'disableform'">
            <div class="tab-content">
              <div id="tasksurvey" class="tab-pane fade in active" style="margin-top: 20px;">
                <surveyjs-component [statusSubject]="statusSubject">
                </surveyjs-component>
                <div id="tasknotes">
                  <h3>Notes</h3>
                  <app-task-note>
                  </app-task-note>
                </div>
              </div>
              <div id="taskdiagram" class="tab-pane fade">
                <h3>Task Diagram</h3>
                <app-task-diagram-view>
                </app-task-diagram-view>
              </div>
              <div id="taskdocuments" class="tab-pane fade" style="margin-top:20px;">
                <task-documents [instanceId]="instanceId" [readOnly]="false" [task]="task">
                </task-documents>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>