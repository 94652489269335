export class UserProfile {
    public active: boolean;
    public verified: boolean;
    public email: string;
    public firstName: string;
    public lastName: string;
    public username: string;
    public name: string;
    public profilePicUrl: string;
    public ssoId: string;
    public password: string;
    public title: string;
    public organization: string;
    public accountType: string;
    public manager: string;
    public managerEmail: string;
    public officeLocation: string;
    public cellPhoneNumber: string;
    public homePhoneNumber: string;
    public lastIpAddress: string;
    public lastLoginDateTime: string;
    public lastLogoutDateTime: string;
    public CreatedBy: string;
    public UpdatedBy: string;
    public CreatedDateTime: Date;
    public UpdatedDateTime: Date;
    public id: string;
    public customManagerEmailAddress: string;
    public status: string;
    public passwordExpirationDate: Date;
    public dayUntilExpiration: string;
    public passwordExpired: boolean;
    public token: string;
    public groups: string[];
    public nameAndEmail?: string;

    constructor() {
        this.profilePicUrl = "./images/user.png";
    }
}

