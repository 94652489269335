import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";

import { ToastrService } from "ngx-toastr";

import { StixConfigService } from "../../shared/services/stixConfig.service";
import { IAlgorithm } from  "../../indicators/nerdy-algorithm-list/nerdy-algorithm-list";
import { IndicatorWizardModel, IndicatorWizardService } from "../indicator-wizard.service";

import { UserProfileService } from '../../core/userProfile.service';
import { IndicatorService } from '../../indicators/indicators.service';
import { ProcessDefinition } from '../../shared/services/workflow.service';

@Component({
  selector: 'indicator-wiz-review',
  templateUrl: './indicator-wiz-review.component.html',
  styleUrls: ['./indicator-wiz-review.component.css']
})
export class IndicatorWizReviewComponent implements OnInit {

  public model: IndicatorWizardModel;
  public algorithm: IAlgorithm = new IAlgorithm();
  public classDesc: string;
  public typesDesc: string;
  public coasDesc: string;
  public allProcessDefinitions: ProcessDefinition[];
  public label_coa: string;
  public label_coas: string;
  public indicatorHistory = [];

  constructor(
    private indicatorWizardService: IndicatorWizardService, 
    public stixConfig: StixConfigService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserProfileService,
    private indicatorService: IndicatorService,
    private toastr : ToastrService
  ) { 
    this.label_coa = this.stixConfig.coa_singular;
    this.label_coas = this.stixConfig.coa_plural;
  }

  async ngOnInit() {


    this.allProcessDefinitions = await this.indicatorWizardService.getAllProcessDefintions();

    this.indicatorWizardService.currentData.subscribe( indicatorWizardModel => {

      // Keep a local copy of the model
      this.model = indicatorWizardModel ? indicatorWizardModel : new IndicatorWizardModel();

      this.algorithm = indicatorWizardModel && indicatorWizardModel.indicator ? 
                       indicatorWizardModel.indicator :
                       new IAlgorithm();

      this.algorithm.classDesc = this.algorithm.classifications ? this.algorithm.classifications.join( ", " ) : null;
      this.algorithm.typesDesc = this.algorithm.types ? this.algorithm.types.join( ", " ) : null;
      this.algorithm.coasDesc = this.algorithm.coursesOfAction ? this.algorithm.coursesOfAction.join(", ") : null;

      if ( this.model.indicator.name ){
        this.indicatorService.getVersions( this.model.indicator.name ).subscribe( indicatorHistory => {

          indicatorHistory = indicatorHistory.sort( (a,b) => new Date(b.UpdatedDateTime).getTime()  - new Date(a.UpdatedDateTime).getTime()  );

          // any of the versions are null, re-order them 
          const emptyVersion = indicatorHistory.find( ih => !ih.version );
          if ( emptyVersion ){
            let index = 1;
            for( let i = indicatorHistory.length -1; i >= 0; i--){
              indicatorHistory[i].version = index;
              index++;
            }
          }


          this.indicatorHistory = indicatorHistory;
        });
      }

      
      if ( this.algorithm.autoStartCoursesOfAction ){

        this.algorithm.autoStartCoursesOfAction = this.algorithm.autoStartCoursesOfAction.map( a => {

          if ( a.courseOfActions && a.courseOfActions.length ){

            const coaNameArray = [];
            for (const autoStartCoaId of a.courseOfActions){
              const coa = this.allProcessDefinitions.find( x => x.key === autoStartCoaId )
              if ( coa ){
                coaNameArray.push( coa.name );
              }
            }

            a.coasDesc = coaNameArray.join(  ", " );
          }
          else {
            a.coasDesc = "No Course of Action Selected";
          }

          return a;
        });
      }

    });
  }

  saveLocalDataToModel() {
    console.log("Saving from Indicator from review page");
  }

  cancel() { 
    if ( this.algorithm && this.algorithm.id  ) {
      this.router.navigate( ["../../"],  { relativeTo: this.route });
    }
    else {
      this.router.navigate( ["../"],  { relativeTo: this.route });
    }
  }

  public async validateAndSave(){

    // TODO Run Validation first

    const savedIndicator = await this.save(); 

    const updatedDateTime = new Date(savedIndicator.UpdatedDateTime).toLocaleDateString() + 
                            " " + new Date(savedIndicator.UpdatedDateTime).toLocaleTimeString();
    this.toastr.info( `Successfully saved ${savedIndicator.name} at ${updatedDateTime}`);

    this.cancel();
  }

  public async save() {

    // Save the Indicator
    const response = await this.indicatorService.saveWizardIndicator( this.model.indicator, this.userService.fullName );
    if ( !response ){
      this.toastr.error( `Failed to save [${this.model.indicator.name}] `);
    }
    return response;
  }

  public async execute(){
    const savedIndicator = await this.save();

    if ( savedIndicator ) { 
      await this.indicatorService.executeAlgorithm(savedIndicator, this.userService.fullName).toPromise();

      const updatedDateTime = new Date(savedIndicator.UpdatedDateTime).toLocaleDateString() + 
                              " " + new Date(savedIndicator.UpdatedDateTime).toLocaleTimeString();
      this.toastr.success( `Successfully saved and executing ${savedIndicator.name} at ${updatedDateTime}. <br/> ${savedIndicator.name} is running now`, '', { closeButton: true, enableHtml: true });

      this.router.navigate( ["../../"],  { relativeTo: this.route });
    }
  }

  public edit( step: number){

    this.model.currentStep = step;
    this.indicatorWizardService.changeData( this.model );
  }

  public download( indicator ): void {

    const filename = `${indicator.name}_${indicator.version}.py`;
    const element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(indicator.script),
    );
    element.setAttribute("download", filename);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

}
