import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EnvService } from "../../app/env.service";
import { Indicator } from "./indicators-list/indicator";

import { Observable, Subscription, timer, interval } from "rxjs";
import { catchError, takeUntil } from "rxjs/operators";

import { RivirHttp } from "../shared/services/rivir-http.service";
import { StixConfigService } from "../shared/services/stixConfig.service";
import { LoggingService, LogLevel, LogType } from "../core/logging.service";
import { PermissionService } from "../core/permission.service";
import { IAlgorithm } from "./nerdy-algorithm-list/nerdy-algorithm-list";

@Injectable()
export class IndicatorService {
  public currentIndicator: Indicator;
  public currentRunDateObj: any;
  public autoRefresh: Subscription;

  private _algorithmUrl: string;
  private _sightingUrl: string;
  private saveUrl: string;
  private latestUrl: string;
  private typesUrl: string;
  private coaUrl: string;
  private classificationsUrl: string;
  private executeUrl: string;
  private abortExecuteUrl: string;
  private threatActorUrl: string;

  constructor(
    private _http: HttpClient,
    private permissions: PermissionService,
    public stixConfig: StixConfigService,
    private log: LoggingService,
    private env: EnvService
  ) { 
    this._algorithmUrl = this.env.rivirApiBaseUrl + "/algorithms";
    this._sightingUrl = this.env.rivirApiBaseUrl + "/sightings";
    this.saveUrl = this._algorithmUrl + "/save";
    this.latestUrl = this._algorithmUrl + "/latest";
    this.typesUrl = this._algorithmUrl + "/types";
    this.coaUrl = this._algorithmUrl + "/courseofactions";
    this.classificationsUrl = this._algorithmUrl + "/classifications";
    this.executeUrl = this._algorithmUrl + "/execute";
    this.abortExecuteUrl = this._algorithmUrl + "/abortExecution";
    this.threatActorUrl = this.env.rivirApiBaseUrl + "/threatactors";
  }

  public getVersions( name: string ): Observable<Indicator[]>{
    const query = `${this._algorithmUrl }?filter={ "where" : { "name" : "${name}" }, "includeHistory" : true}`;
    return this._http.get<Indicator[]>(query, RivirHttp.getHttpOptions())
    .pipe(catchError(RivirHttp.handleError));
  }

  public getIndicatorList(): Observable<Indicator[]> {
    return this._http
      .get<Indicator[]>(this.latestUrl, RivirHttp.getHttpOptions())
      .pipe(catchError(RivirHttp.handleError));
  }

  public saveIndicator(indicator: Indicator): Observable<any> {
    return this._http.post(this.saveUrl, indicator, RivirHttp.getHttpOptions());
  }

  public async saveWizardIndicator(algorithm: IAlgorithm, userFullName: string): Promise<any> {

    let saveResponse = null;
    // First validate indicator 
    const validationMessage = await this.validateIndicator( algorithm.name, algorithm.script);

    if( !validationMessage ) {

      const currentDateTime = new Date();

      const indicator: Indicator = {
        name: algorithm.name ? algorithm.name.trim() : `${userFullName} indicator ${currentDateTime.toISOString()}`,
        description: algorithm.description ? algorithm.description.trim() : " ",
        visualizations: algorithm.visualizations,
        coursesOfAction: algorithm.coursesOfAction,
        types: algorithm.types,
        classifications: algorithm.classifications,
        script: algorithm.script ? algorithm.script : " ",
        CreatedBy: algorithm.CreatedBy ? algorithm.CreatedBy : userFullName,
        UpdatedBy: userFullName,
        CreatedDateTime: algorithm.CreatedDateTime ? algorithm.CreatedDateTime : currentDateTime,
        UpdatedDateTime: currentDateTime,
        active: true,
        autoStartCoursesOfAction: algorithm.autoStartCoursesOfAction,
        updateRecord: algorithm.id ? true : false,
        defaultView: algorithm.defaultView
      };
      saveResponse = await this._http.post(this.saveUrl, indicator, RivirHttp.getHttpOptions()).toPromise();
    }
    else {
      console.error(`Indicator ${algorithm.name} is invalid with error: ` + validationMessage );
    }

    return saveResponse;
  }

  public getIndicatorDetail(name): Observable<any> {
    const url = `${this.latestUrl}?filter={"where" : {"name" : "${name}"}}`;
    return this._http
      .get<any>(url, RivirHttp.getHttpOptions())
      .pipe(catchError(RivirHttp.handleError));
  }

  public getIndicatorDetailById(id): Observable<any> {
    const url = `${this.latestUrl}?filter={"where" : {"id" : "${id}"}}`;
    return this._http
      .get<any>(url, RivirHttp.getHttpOptions())
      .pipe(catchError(RivirHttp.handleError));
  }

  public getIndicatorListBySighting(id): Observable<any> {
    return this._http
      .get<any>(
        this._sightingUrl + "/" + id + "/algorithm",
        RivirHttp.getHttpOptions(),
      )
      .pipe(catchError(RivirHttp.handleError));
  }

  public getIndicatorsByThreatActorId(id): Observable<any> {
    return this._http
      .get<any>(
        this.threatActorUrl + "/indicators/" + id,
        RivirHttp.getHttpOptions(),
      )
      .pipe(catchError(RivirHttp.handleError));
  }

  public getSightingsByThreatActorId(id): Observable<any> {
    return this._http
      .get<any>(
        this.threatActorUrl + "/sightings/" + id,
        RivirHttp.getHttpOptions(),
      )
      .pipe(catchError(RivirHttp.handleError));
  }

  public getSightingsByAlgorithmName(id): Observable<any> {
    const url = `${this._sightingUrl}/algorithm/${id}`;
    return this._http
      .get<any>(url, RivirHttp.getHttpOptions())
      .pipe(catchError(RivirHttp.handleError));
  }

  public getLastRunDate(lastSighting): Observable<any> {
    const url = `${this._sightingUrl
      }?filter={"where" : {"name" : "${lastSighting}"}}`;
    return this._http.get<any>(url).pipe(catchError(RivirHttp.handleError));
  }

  public executeAlgorithm(indicator: Indicator, user: string): Observable<any> {
    // Log indicator execute entry
    this.log.logClientEvent(
      "Indicator Executed",
      LogLevel.Info,
      LogType.App,
      user,
      indicator.name,
    ); // JSON.stringify(indicator));

    const body = { name: indicator.name, currentUser: user };
    return this._http
      .post(this.executeUrl, body, RivirHttp.getHttpOptions())
      .pipe(catchError(RivirHttp.handleError));
  }

  public abortExecution(indicator: Indicator): Observable<any> {
    const body = { AlgorithmName: indicator.name };
    return this._http
      .post(this.abortExecuteUrl, body, RivirHttp.getHttpOptions())
      .pipe(catchError(RivirHttp.handleError));
  }

  public getTtypesList(): Observable<string[]> {
    return this._http
      .get<string[]>(this.typesUrl, RivirHttp.getHttpOptions())
      .pipe(catchError(RivirHttp.handleError));
  }

  public getTClassificationsList(): Observable<string[]> {
    return this._http
      .get<string[]>(this.classificationsUrl, RivirHttp.getHttpOptions())
      .pipe(catchError(RivirHttp.handleError));
  }

  public getTCOAsList(): Observable<string[]> {
    return this._http
      .get<string[]>(this.coaUrl, RivirHttp.getHttpOptions())
      .pipe(catchError(RivirHttp.handleError));
  }

  public getTableColumns(tableName): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      let columns = tableName;
      this.permissions
        .getPermission("Activate/Deactivate Indicator")
        .then((p1) => {
          columns = !p1
            ? columns.filter((col) => col.prop !== "active")
            : columns;
          this.permissions.getPermission("Execute Indicator").then((p2) => {
            columns = !p2
              ? columns.filter((col) => col.prop !== "execute")
              : columns;
            this.stixConfig.stixLabeling(columns);
            resolve(columns);
          });
        });
    });
  }

  public autoRefreshCalls(item, intervalTime, timeOut) {
    return new Promise((resolve, reject) => {
      const observable = interval(intervalTime).pipe(takeUntil(timer(timeOut)));

      this.autoRefresh = observable.subscribe((val) => {
        this.getIndicatorDetail(item.name).subscribe((indicator) => {
          if (indicator[0].status !== "in-progress") {
            item.status = indicator[0].status;
            item.lastRunDate = indicator[0].lastRunDate;
            item.numOfSightings = indicator[0].sighting ? indicator[0].sighting.length : "";
            this.autoRefresh.unsubscribe();
            resolve(null);
          }
        });
      });
    });
  }

  public async getIndicatorNames() {
    const url = `${this._algorithmUrl}?filter={ "fields": { "name": true } }`;
    const results =  await this._http
      .get<any>(url, RivirHttp.getHttpOptions())
      .pipe(catchError(RivirHttp.handleError)).toPromise();

    const indicatorNames = results.map( result => {
      return result.name;
    })
    
    return indicatorNames;
  }

  public updateNameifExisting( ){
    // Check to see if this is an existing indicator, if so replace the name with the id 
    let currentUrl = window.location.href; 
    if ( currentUrl.endsWith( "/" ) ){
      currentUrl.slice( 0, -1);
    }
    const urlArray = currentUrl.split( "indicator-wizard" );
    return urlArray[1] === '';
  }

  public async validateIndicator( name, script ) : Promise< string > {

    const isNew =  this.updateNameifExisting();
    name = isNew ? name :  new Date().toISOString();

    if ( !name || !script ){
      return new Promise ( ( resolve => resolve( "Name and Script are required")) );
    }
    else {
    
    return new Promise( ( resolve ) => {

      const indicatorValidationUrl = `${this._algorithmUrl}/validate`;
      this._http
      .post( indicatorValidationUrl, { name, script },  RivirHttp.getHttpOptions())
      .subscribe(
        () => resolve( null ),
        ( error : HttpErrorResponse)  => {
          resolve( error?.error?.error?.message );
        }
      );

    });
   }

  }
}
