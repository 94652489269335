import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";

import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";

import { merge, fromEvent } from "rxjs";
import { tap } from 'rxjs/operators';
import { debounceTime } from 'rxjs/operators';
import { distinctUntilChanged } from 'rxjs/operators';

import { 
  DataEntryListService,
  DataEntryListDataSource,
  DataEntry
 } from './data-entry-list.service';
 import { StixConfigService } from "../shared/services/stixConfig.service";
import { Router } from "@angular/router";

@Component({
  selector: 'app-data-entry-list',
  templateUrl: './data-entry-list.component.html',
  styleUrls: ['./data-entry-list.component.css']
})
export class DataEntryListComponent implements OnInit {

  private filter: any;
  private columnSort: any;
  private resultsPerPage: number;
  private pageNumber: number;
  private fields = { dataentryform : 0 }

  public paginationLength: number;
  public dataSource: DataEntryListDataSource;
  public dataEntries : DataEntry[];

  public columnsToDisplay: string[];
  @ViewChild(MatPaginator) public paginator: MatPaginator;
  @ViewChild(MatSort) public sort: MatSort;
  @ViewChild('input') input: ElementRef;
  public dataEntryOrderDesc: boolean;
  public dataEntrySortColumn: string;



  constructor( private dataEntryListService: DataEntryListService, 
                public stixConfig: StixConfigService,
                private router: Router,
    ) { }

  async ngOnInit(): Promise<void> {
    const urlParams = new URLSearchParams(window.location.search);
    const  urlFilter = urlParams.get( 'filter');
    const urlSort = urlParams.get('sort');
    const urlResultsPerPage = urlParams.get('resultsPerPage')
    const urlPageNumber = urlParams.get('pageNumber');


    this.filter = urlFilter && this.IsJsonString( urlFilter ) ? JSON.parse( urlFilter ) : {};
    this.sort = urlSort && this.IsJsonString( urlSort ) ? JSON.parse( urlSort ) : {};
    this.resultsPerPage =  urlResultsPerPage && !Number.isNaN( urlResultsPerPage ) ? parseInt( urlResultsPerPage ) : 10;
    this.pageNumber = urlPageNumber && !Number.isNaN( urlPageNumber ) ? parseInt( urlPageNumber ) : 0;

    // TODO Need to update this list to not have Silver Stay specific fields and add those fields dynamically
    this.columnsToDisplay = [ "dataEntryId", "taId", "threatActorName", "priorityscore", "primaryReferralSourceFacilityName", "status", "formattedUpdatedDateTime", "updatedByFullName" ];
    this.dataSource = new DataEntryListDataSource( this.dataEntryListService );
    this.dataSource.loadingDataEntryList( this.filter, this.columnSort, this.pageNumber, this.resultsPerPage, this.fields );
    this.dataSource.count$.subscribe( count => this.paginationLength = count );
  }

  public ngAfterViewInit(): void {

    // When the user updates the search input after debounce load cases
    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadDataEntryList();
        })
      )
      .subscribe();

    // Whenver there is a sort set the back to 0
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    // When the user changes the sort or the page load cases
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => {

          this.loadDataEntryList();
        })
      )
      .subscribe();

  }

  public refreshList() {
    this.input.nativeElement.value = null;
    this.loadDataEntryList();
  }

  /**
   * Run the search for cases based on the selected criteria
   */
  public loadDataEntryList(): void {
    const columnSort = {};
    columnSort [ this.sort.active ] = this.sort.direction === 'asc' ? 1 : -1;
    this.columnSort = columnSort;

    this.dataSource.loadingDataEntryList( { searchString : this.input.nativeElement.value ? this.input.nativeElement.value.toLowerCase() : this.input.nativeElement.value  }, 
                                            this.columnSort, 
                                            this.paginator.pageIndex, 
                                            this.paginator.pageSize, 
                                            this.fields );
  }

  public getDataEntryListColumnName(column: string) {
   
    switch (column) {
    case "dataEntryId": return `${this.stixConfig.dataEntry_singular} ID`;
      case "name": return `${this.stixConfig.dataEntry_singular} Name`;
      case "taId": return `${this.stixConfig.threatActor_singular} ID`;
      case "threatActorName": return `${this.stixConfig.threatActor_singular} Name`;
      case "status": return "Status";
      case "formattedUpdatedDateTime": return "Last Modified";
      case "updatedByFullName": return "Entered By";
      // TODO These fields are Silver Stay specific, need to be updated to be dynamic
      case "priorityscore": return "Priority";
      case "userBin" : return "Bin";
      case "primaryReferralSourceFacilityName": return "Referral Facility";
    }
  }

  public getDataEntryColumnType(column: string): string {
    switch (column) {
      case "dataEntryId":
      case "taId":
        return "link";
      default:
        return "text";
    }
  }

  public getGridLink(column: string, row: DataEntry) {
    switch( column ){
      case "taId": 
        return `/threatActor/${row.threatActorId}`
      case "dataEntryId":
        return `/data-entry/${row.name}/${row.dataEntryId}`;
    }
  }


  IsJsonString(str){
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
  }

  redirectToAddNewDataEntry(){
    this.router.navigate(["/data-entry" ]);
  }

}
