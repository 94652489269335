import { Component } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-errors-handler",
  templateUrl: "./errors-handler.component.html",
  styleUrls: ["./errors-handler.component.css"],
})

export class ErrorsHandler {
  public error;
  public expandStack = false;
  constructor(private router: Router) {

    const error = JSON.parse(sessionStorage.getItem("angularError")) || {};
    if (error.name || error.url) {
      this.error = error;
      const stack = error.stack || "";
      console.info(stack);
    } 
  }

  public goToPrevUrl() {

    const url = this.error.url || "";
    const index = url.lastIndexOf("/");
    const prev_url = index ? url.slice(0, index) : "/";
    this.router.navigate([prev_url]);
  }
}
