import { Component, Input, OnInit } from "@angular/core";
import { Idle } from "@ng-idle/core";
import * as moment from "moment";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { EnvService } from "../../env.service";
import { KeycloakService } from "../keycloak/keycloak.service";

@Component({
  selector: "app-idle-timeout",
  templateUrl: "./idle-timeout.component.html",
  styleUrls: ["./idle-timeout.component.css"],
})
export class IdleTimeoutComponent implements OnInit {

  public timeout: number;
  public timer: number;
  public interval: any;
  public countdown: string;
  public userIdleConfig : number;

  @Input() public modelName: string;

  constructor(private kc: KeycloakService, 
              private idle: Idle, 
              private modalService: BsModalService,
              public modalRef: BsModalRef,
              private env: EnvService
              ) {
      this.userIdleConfig = this.env.sessionIdleTimeout || 30;
               }

  public ngOnInit() {

    this.timeout = Math.round(this.userIdleConfig / 3) * 60;

    this.timer = this.timeout;
    this.countdown = moment(this.timer * 1000).format("mm:ss");

    this.interval = setInterval(() => {
      this.timer = this.timer - 1;
      this.countdown = moment(this.timer * 1000).format("mm:ss");
      if (this.timer < 0) {
        this.logout( "Idle Timeout Interval");
      }
    }, 1000);

    this.modalService.onHide.subscribe(() => {
      this.resetTimer();
    });
  }

  public resetTimer() {
    this.kc.getToken()
      .then((x) => {
        this.idle.watch();
        this.modalRef.hide();
        this.countdown = "";
        this.timer = this.timeout;
        clearInterval(this.interval);
      })
      .catch((err) => {
        console.error(err);
        this.kc.logout( "resetTimer" );
      });
  }

  public logout( message: string) {
    this.kc.logout( message );
  }

}
