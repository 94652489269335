import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";

import { CookieService } from 'ngx-cookie-service';
import { Subject } from "rxjs";
import { MessageService } from "../../shared/services/message.service";
import { StixConfigService } from "../../shared/services/stixConfig.service";
import {
  CaseService,
} from "../case-service";

@Component({
  selector: "app-case-detail",
  styleUrls: ["./case-detail.component.css"],
  templateUrl: "./case-detail.component.html",
})
export class CaseDetailComponent implements OnInit {

  public caseId: string;
  public rivirCase: any;
  public coaSelectControl = new FormControl();
  public typesSelectControl = new FormControl();
  public newTypeControl = new FormControl();
  public assigneeControl = new FormControl();
  public priorities: string[];
  public caseStatuses: string[];
  public caseTypes: string[];
  public selectedType: string;
  public selectedPriority: string;
  public activeUsers: any;
  public dateOpened: string;
  public caseNotFound: boolean;
  public hasCoas: boolean;
  public hasLongThreatActorName: boolean;
  public currentUserIsNotAssignee: boolean;
  public previousStatus: string;
  public dueDate: string;
  public typesList: any[];
  public selectedTypes: any[];

  public caseSubject: Subject<any> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private caseService: CaseService,
    public stixConfig: StixConfigService,
    private toastr: ToastrService,
    private messageService: MessageService,
    private cookieService: CookieService
  ) { }

  public ngOnInit() {
    this.route.params.subscribe((params) => {
      this.caseId = params.caseId;

      this.caseService.getCaseDetailInfo(this.caseId).subscribe((subscription) => {

        if (subscription.rivirCase.statusCode === 404) {
          this.caseNotFound = true;
        } else {
          this.rivirCase = subscription.rivirCase;
          this.activeUsers = subscription.activeUsers;
          this.caseTypes = subscription.caseTypes.filter((t) => t);
          this.priorities = this.caseService.priorities;
          this.caseStatuses = this.caseService.caseStatuses;

          this.typesList = subscription.caseTypes.filter((t) => t);
          this.selectedTypes = this.rivirCase.types;

          this.dateOpened = new Date(this.rivirCase.CreatedDateTime).toLocaleDateString("en-US");
          this.dueDate = this.rivirCase.dueDate;

          this.hasCoas = this.rivirCase.courseOfActions && this.rivirCase.courseOfActions.length;

          this.typesSelectControl.setValue(this.rivirCase.types);

          this.hasLongThreatActorName = this.rivirCase.threatActorName.length > 20;

          this.toggleEnableOfControls();

          this.previousStatus = this.rivirCase.status;
        }

      });

    });
  }

  public getTypesDescription() {

    const first3Types = this.typesSelectControl.value.slice(0, 3);
    const typeDescription = first3Types.join();

    return typeDescription;
  }

  public isCurrentUserAssigned() {
    const currentAssignee = this.activeUsers.find((au) => au.id === this.rivirCase.assignee);
    return currentAssignee.email === this.cookieService.get("email");
  }

  public isCaseValid(event) {
    let isFormValid = true;

    // Prevent the user from status to be closed if any of the Courses of Action are not Closed
    if (event &&
      event.currentTarget &&
      event.currentTarget.classList.contains("case-detail-status") &&
      this.rivirCase.status === "Closed") {

      // Check to see if any coas are not closed
      const openCoas = this.rivirCase.courseOfActions ?
        this.rivirCase.courseOfActions.filter((coa) => coa.status !== "Closed") : [];

      if (openCoas && openCoas.length > 0) {
        this.toastr.warning(
          `Unable to close this case because there are one or more open ${this.stixConfig.coa_plural}`);
        event.source.writeValue(this.previousStatus);
        isFormValid = false;
      }
    }

    return isFormValid;

  }

  public async saveCase(event: any) {

    if (this.isCaseValid(event)) {

      // Get the current values of the case in case it has been changed 
      const dbCase = await this.caseService.getCase(this.rivirCase.caseId).toPromise();
      this.rivirCase.courseOfActions = dbCase.courseOfActions;

      // Set the previous status in case of rollback
      this.previousStatus = this.rivirCase.status;

      // Enable/Disable controls
      this.toggleEnableOfControls();

      // Get the types from selected types
      const types = this.selectedTypes && this.selectedTypes.length ?
        this.selectedTypes : [];

      const updatedCase = {
        CreatedBy: this.rivirCase.CreatedBy,
        CreatedDateTime: this.rivirCase.CreatedDateTime,
        UpdatedBy: this.cookieService.get("email"),
        UpdatedDateTime: new Date().toISOString(),
        assignee: this.rivirCase.assignee,
        caseId: this.rivirCase.caseId,
        created: this.rivirCase.created,
        updated: new Date().toLocaleDateString("en-US"),
        status: this.rivirCase.status,
        dueDate: this.dueDate ? new Date(this.dueDate) : null,
        id: this.rivirCase.id,
        priority: this.rivirCase.priority,
        strDueDate: this.dueDate,
        strTypes: types ? types.join : null,
        taId: this.rivirCase.taId,
        threatActorId: this.rivirCase.threatActorId,
        types,
        courseOfActions: this.rivirCase.courseOfActions.map((coa) => coa.instanceId),
      };

      this.caseService.saveCase(updatedCase).subscribe((responseCase: any) => {
        if (responseCase.status && responseCase.status.toUpperCase() === "ERROR") {
          this.toastr.error("Unable to save Case, Please contact your System Administrator");
        }

        if (event && event.source && event.source.id === "caseDetailAssignee") {
          const currentUser = this.activeUsers.find((au) => au.email === this.cookieService.get("email"));
          const currentAssignee = this.activeUsers.find((au) => au.id === this.rivirCase.assignee);
          this.messageService.postMessage(
            "Case assigned",
            `Case [${this.rivirCase.caseId}] has been re-assigned to you by ${currentUser.name}`,
            currentAssignee.email,
            true,
          );
        }

        this.caseSubject.next(responseCase);
      });
    }
  }

  public toggleEnableOfControls() {
    const currentAssignee = this.activeUsers.find((au) => au.id === this.rivirCase.assignee);
    this.currentUserIsNotAssignee = currentAssignee ? currentAssignee.email !== this.cookieService.get("email") : true;
  }

  public typeChanged() {
    debugger;
    this.saveCase(null);
  }

  public caseHistoryTabClicked(event: Event): void {
    this.caseSubject.next(this.rivirCase);
  }
}
