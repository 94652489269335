import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import _orderBy from "lodash-es/orderBy";
import _chain from "lodash-es/chain";
import * as moment from "moment";
import _ from "lodash";
import {
  BulkAssignmentService,
  BulkQueueItem,
  BulkStatusModel,
} from "../shared/services/bulk.assignment.service";
import { StixConfigService } from "../shared/services/stixConfig.service";
import { WorkflowService } from "../shared/services/workflow.service";
import { CoAService } from "../coa/coa.service";

@Component({
  selector: "app-bulk-status",
  templateUrl: "./bulk-status.component.html",
  styleUrls: ["./bulk-status.component.css"],
})
export class BulkStatusComponent implements OnInit {
  public selectedInstanceId: string;
  public selectedSighting: string;
  public selectedRunDate: string;
  public instanceIds: any[];
  public instanceGroups: any[];
  public selectedBulkRun: string;
  public queueItems: BulkQueueItem[];
  public displayedColumns: string[] = [
    "taId",
    "user",
    "courseOfAction",
    "status",
  ];
  public dataSource = new MatTableDataSource<BulkQueueItem>();
  public bulkStatusType: string;
  public statusTotals: any[];
  public coursesOfAction: any[];
  @ViewChild(MatPaginator) public paginator: MatPaginator;
  @ViewChild(MatSort) public sort: MatSort;
  public stix: any;

  constructor(
    private bulkAssignService: BulkAssignmentService,
    public stixConfig: StixConfigService,
    private route: ActivatedRoute,
    private workflowService: WorkflowService,
    private coaService: CoAService,
    private router: Router,
  ) {
    this.stix = {
      sightingLabel: this.stixConfig.sighting_singular,
      threatActorLabel: this.stixConfig.threatActor_singular,
      coursesOfActionsLabel: this.stixConfig.coa_plural,
    };
  }

  public async ngOnInit() {

    this.coursesOfAction = await this.workflowService.getProcessDefinitions();

    this.bulkAssignService.getQueueInstanceIds().subscribe((instanceIds) => {
      this.instanceIds = instanceIds.map((instanceId) => {
        const { sighting, bulkRunDate } = this.splitBulkInstanceId(instanceId);
        return {
          instanceId,
          sighting,
          bulkRunDate,
        };
      });

      // Filter out the bulk re assignment
      this.instanceIds = this.instanceIds.filter(
        (i) => !i.sighting.startsWith("bulkAssignment"),
      );

      this.instanceIds = _orderBy(this.instanceIds, "bulkRunDate", "desc");

      this.instanceGroups = _.chain(this.instanceIds)
        .groupBy("sighting")
        .map((instances, sighting) => ({ instances, sighting }))
        .value();


      // Convert the date in the sighting into a date so that can be used for sorting
      this.instanceGroups = this.instanceGroups.map((ig) => {
        ig.DateCreated = this.getDateTimeFromSightingName(ig.sighting);
        return ig;
      });

      this.instanceGroups = this.instanceGroups.sort(
        (a, b) => b.DateCreated - a.DateCreated,
      );
    });

    this.route.params.subscribe((params) => {
      if (params.instanceId) {
        this.selectedBulkRun = params.instanceId;
        this.loadQueueItems(params.instanceId);
      }
    });
  }

  public getDateTimeFromSightingName(sighting): Date {
    // Split the sighting to just get the date
    const sightingDate = sighting.split("--")[1];

    // Split into an array we have have each pieces
    const dateArray = sightingDate.split(" ");

    // Remove the ordinal from the day
    const day = dateArray[1].replace(/(\d+)(st|nd|rd|th)/g, "$1");

    // Convert DateTime stamp
    const time = moment(`${dateArray[3]} ${dateArray[4]}`, "HH:mm:ss a").format(
      "HH:mm:ss",
    );

    // Create the string back in a valid Javascript date format
    const newDateString = `${day} ${dateArray[0]} ${dateArray[2]} ${time}`;

    return new Date(newDateString);
  }

  public ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public onBulkRunChanged($event) {
    this.loadQueueItems(this.selectedBulkRun);
  }

  public loadQueueItems(bulkRunInstanceId: string): any {
    this.bulkAssignService
      .loadQueueData(bulkRunInstanceId)
      .then((bulkStatusModel: BulkStatusModel) => {
        // Set the CourseOfAction Name based on if the COAs have been
        // loaded from Camunda
        bulkStatusModel.queueItems = bulkStatusModel.queueItems.map((q) => {
          q.courseOfActionName = q.courseOfAction;
          if (
            this.coursesOfAction &&
            this.coursesOfAction.length > 0 &&
            q.courseOfAction
          ) {
            const coa = this.coursesOfAction.find(
              (c) => c.key == q.courseOfAction,
            );
            if (coa) {
              q.courseOfActionName = coa.name;
            }
          }

          return q;
        });
        this.bulkStatusType = bulkStatusModel.bulkStatusType;
        this.dataSource.data = bulkStatusModel.queueItems as BulkQueueItem[];
        this.paginator._changePageSize(this.paginator.pageSize);
        this.statusTotals = bulkStatusModel.statusTotals;

        // Set the selected sighting and bulk run
        const { sighting, bulkRunDate } = this.splitBulkInstanceId(
          bulkRunInstanceId,
        );
        this.selectedSighting = sighting;
        this.selectedBulkRun = moment(bulkRunDate).format("LLLL");

        // Refresh the Queue Items if any have been set to Retry or Error
        if (bulkStatusModel.retry) {
          setTimeout(() => {
            this.loadQueueItems(bulkRunInstanceId);
          }, 3000);
        }
      });
  }

  public splitBulkInstanceId(instanceId: string): any {
    const instanceData = instanceId.split("_bulkStart_");

    const sighting = instanceData[0];
    const bulkRunDate = moment(instanceData[1]).format("MM/DD/YYYY, h:mm:ss a");

    return { sighting, bulkRunDate };
  }

  // NOTE: This doesn't working work.   Leaving this here until the
  // logic can updated correctly.  Need to update to use similar functionality
  // as coa-list-threat-actor
  public redirectToCourseOfAction(queueItem) {
    this.coaService
      .getCoursesOfActionById(queueItem.courseOfActionId)
      .subscribe((courseOfAction) => {
        if (courseOfAction) {
          if (courseOfAction.workflowComplete) {
            alert(`This ${this.stix.coursesOfActionsLabel} has been completed`);
          }
          // Get the last task
          else if (courseOfAction.tasks && courseOfAction.tasks.length > 0) {
            // Get the last task
            const lastTask =
              courseOfAction.tasks[courseOfAction.tasks.length - 1];

            // Redirect to the task page for the course of action
            this.router.navigate([
              `/threatActor/${queueItem.threatActorId}/task/${lastTask.taskId}`,
            ]);
          }
        }
      });
  }
}
