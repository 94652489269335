<h1 mat-dialog-title>{{data.modelItemKey}}</h1>
<div mat-dialog-content>
    <div class="row">
      <div class="col-md-4">
        <span class="diff-heading" *ngIf="!data.showOneTable">Original:</span>
      </div>
      <div class="col-md-8" *ngIf="data.modalItemOriginal && data.modalItemOriginal.length">
        <a (click)="exportData( data.modalItemOriginal )" aria-label="Export CSV" class="json-diff-export-csv">
          <i class="fa fa-file-o"></i>
          <span class="json-diff-export-csv-label">Export CSV</span>
        </a>
      </div>
    </div>
    <div class="row original-section">
      <div class="col-md-12" *ngIf="data.modalItemOriginal">
        <div *ngIf="!data.modalOriginalIsArray" class="textValue">
          {{data.modalItemOriginal}}
        </div>
        <table *ngIf="data.modalOriginalIsArray" class="table table-striped b-t b-b changesTable no-footer"
          role="grid">
          <thead>
            <tr role="row">
              <th *ngFor="let column of data.modalOriginalColumns">
                {{column}}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let originalItem of data.modalItemOriginal" role="row">
              <td *ngFor="let originalColumn of data.modalOriginalColumns">
                <span [innerHtml]="originalItem[originalColumn]"></span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-md-12 empty" *ngIf="!data.modalItemOriginal">Empty</div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <span class="diff-heading" *ngIf="!data.showOneTable" >Updated:</span>
      </div>
      <div class="col-md-8" *ngIf="data.modalItemUpdated && data.modalItemUpdated.length">
        <a (click)="exportData( data.modalItemUpdated )" aria-label="Export CSV" class="json-diff-export-csv">
          <i class="fa fa-file-o"></i>
          <span class="json-diff-export-csv-label">Export CSV</span>
        </a>
      </div>
    </div>
    <div class="row" *ngIf="!data.showOneTable">
      <div class="col-md-12" *ngIf="data.modalItemUpdated">
        <div *ngIf="!data.modalUpdatedIsArray" class="textValue">
          {{data.modalItemUpdated}}
        </div>
        <table *ngIf="data.modalUpdatedIsArray" class="table table-striped b-t b-b changesTable no-footer" role="grid">
          <thead>
            <tr role="row">
              <th *ngFor="let column of data.modalUpdatedColumns">
                {{column}}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let updatedItem of data.modalItemUpdated" role="row">
              <td *ngFor="let updatedColumn of data.modalUpdatedColumns">
                <span [innerHtml]="updatedItem[updatedColumn]"></span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-md-12 empty" *ngIf="!data.modalItemUpdated">Empty</div>
    </div>
  </div>