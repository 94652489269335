import { Component, ElementRef, Injectable, Input, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";

import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";


import { ToastrService } from "ngx-toastr";

import { MayanDocumentService, MayanFolder } from "../../shared/services/mayan.document.service";
import { RivirHttp } from "../../shared/services/rivir-http.service";
import { TasksService } from "../tasks.service";
import { CoaFileUploadDialogComponent } from  "../../coa-file-upload-dialog/coa-file-upload-dialog.component";

import { fromEvent } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { distinctUntilChanged } from "rxjs/operators";
import { tap } from "rxjs/operators";
import { EnvService } from "../../env.service";




@Component({
  selector: "task-documents",
  styleUrls: ["./task-documents.component.css"],
  templateUrl: "./task-documents.component.html",
})
@Injectable()
export class TaskDocumentComponent implements OnInit {
  public taskId: string;
  public fileToUpload: File = null;
  public accessToken = RivirHttp.rivirApiToken;
  public uploadControl = new FormControl();
  public uploadFileName = "";
  public uploadDescription = "";
  public folder: MayanFolder;

  @Input() public readOnly: boolean;
  @Input() public instanceId: string;
  @Input() public task: any;
  public displayedColumns: string[] = [
    "name",
    "dateCreated",
    "size",
    "createdByName",
    "taskName",
    "description",
    "url",
  ];
  @ViewChild(MatPaginator) public paginator: MatPaginator;
  @ViewChild(MatSort) public sort: MatSort;
  @ViewChild("input") public input: ElementRef;
  public dataSource = new MatTableDataSource<any>();
  public isLoading = false;

  constructor(
    public toastr: ToastrService,
    public dialog: MatDialog,
    private mayanDocumentService: MayanDocumentService,
    private taskService: TasksService,
    private router: Router,
    private route: ActivatedRoute, 
    private envService : EnvService
  ) {

  }

  public async ngOnInit() {

    // if Mayan is not enabled, disable the hamburger menu which has the history
    if ( !this.envService.useMayan){
      this.readOnly = true;
    }
    else {
      // Get folderid first to reduce calls to mayan search API
      this.folder = await this.mayanDocumentService.createFolder(`${this.instanceId}`);
      this.mayanDocumentService.folder = this.folder;
    }

    this.displayedColumns = this.readOnly  ? [
      "name",
      "dateCreated",
      "size",
      "createdByName",
      "taskName",
      "description",
    ] : this.displayedColumns;
    

    // Get the documents
    await this.loadTaskDocuments();

    this.taskService.attachmentsUpdated$.subscribe( async () => {
      await this.refreshList();
     });
  }

  public ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.input){
      fromEvent(this.input.nativeElement, "keyup")
        .pipe(
          debounceTime(150),
          distinctUntilChanged(),
          tap(() => {
            this.paginator.pageIndex = 0;
            this.dataSource.filter = this.input.nativeElement.value.trim().toLocaleLowerCase();
          }),
        )
        .subscribe();
    }
  }

  public async loadTaskDocuments() {
    this.isLoading = true;


    if ( this.envService.useMayan ){
      this.dataSource.data = await this.mayanDocumentService.getDocuments(`${this.folder.id}`);
    }
    else {
      this.dataSource.data = await this.mayanDocumentService.getDocuments( `coa-attachments / ${this.instanceId}` );
    }

    this.isLoading = false;
  }

  public async refreshList() {
    if ( this.input ){ 
      this.input.nativeElement.value = null;
    }
    await this.loadTaskDocuments();
    this.dataSource.filter = null;
  }

  public redirectToHistory(doc){
    this.router.navigate(["file-history", doc.id], { relativeTo: this.route })
  }

  public openUpdateFileDialog(doc){

    this.dialog.open(CoaFileUploadDialogComponent, {
      data: {
        task: this.task,
        doc
      },
    });

  }
}
