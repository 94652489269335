import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";

import { Subject, Subscription, interval } from 'rxjs';

import { StixConfigService } from "../../../shared/services/stixConfig.service";
import { ThreatActorService } from "../../../threat-actor/threat-actor.service";
import { Indicator } from "../../indicators-list/indicator";
import { IndicatorService } from "../../indicators.service";
import { TABLE_COLUMNS_IND_DETAIL } from "../indicator-detail-columns";
import { RivirHttp } from "../../../shared/services/rivir-http.service";
import { EnvService } from "../../../env.service";
import { DocumentService } from "../../../shared/services/document.service";


@Component({
  selector: "app-indicator-run-dates",
  templateUrl: "./indicator-run-dates.component.html",
  styleUrls: ["./indicator-run-dates.component.css"],
})
export class IndicatorRunDatesComponent implements OnInit {
  @Input() public indicator: Indicator;
  @Output() public refresh = new EventEmitter<void>();

  public columns = [];
  public rows = [];
  public tableTitle: string;
  public p: number = 1;
  public limitRowsOptions = [10, 25, 50, 100];
  public limitRows: number = 7;
  public tableInfo: string = "";
  public sortKey = "CreatedDateTime";
  public asc = false;
  public loaded = false;
  public accessToken = "?access_token=" + RivirHttp.rivirApiToken;
  public autoRefresh: Subscription;
  public searcherSubject: Subject<any> = new Subject<any>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private indicatorService: IndicatorService,
    private threatActorService: ThreatActorService,
    private stixConfig: StixConfigService,
    public dialog: MatDialog,
    private documentService: DocumentService,
    private env: EnvService
  ) { }

  public ngOnInit() {
    this.tableTitle = "List of " + this.stixConfig.sighting_plural;
    this.columns = this.stixConfig.stixLabeling(TABLE_COLUMNS_IND_DETAIL);
    this.getRunDateList().then((r) => {
      this.updateTabelInfo();
    });
  }

  ngOnDestroy() {
    this.autoRefresh.unsubscribe();
  }

  public getRunDateList() {
    return new Promise((resolve, reject) => {
      const observable = interval(1500);
      this.autoRefresh = observable.subscribe((val) => {
        this.indicatorService
          .getSightingsByAlgorithmName(this.indicator.name)
          .subscribe((sightings) => {

            sightings = sightings.filter( s => s.status );
            // clear the rows where the status is error or aborted
            const errorStatus = ["error", "aborted"];
            this.rows = sightings.map((sighting) => {
              if (errorStatus.includes(sighting.status.toLowerCase())) {
                sighting = {
                  CreatedDateTime: sighting.CreatedDateTime,
                  status: sighting.status,
                  name: sighting.name
                };
              } else {
                sighting.moreDetailsText = "View";
                sighting.url = "";
                if (this.indicator.visualizations) {
                  sighting.moreDetailsTitle =
                    "View a visualization for this sighting.";
                } else {
                  sighting.moreDetailsTitle =
                    "No more details are available for this sighting.";
                }
              }

              // Use the status to determine  the class
              sighting.zipDownloadClass = "reportStatus" + sighting.zipDownloadStatus;

              // Using the lowercase and three dots to match the running... status 
              sighting.zipDownloadStatus = sighting.zipDownloadStatus === "Pending" ? "pending..." : sighting.zipDownloadStatus;

              return sighting;
            });
            this.updateTabelInfo();
            this.loaded = true;
            resolve();
          });
      });
    });
  }

  public itemClick(columnName, item) {
    if (columnName === "threatActorCount" && item.status !== "in-progress") {
      this.indicatorService.currentRunDateObj = item;
      this.threatActorService.sighting_critiria.sightingName = "";
      this.threatActorService.selected_ThreatActors = [];
      this.router.navigate(["sighting", item.name], { relativeTo: this.route });
    } else if (columnName == "status") {
      this.router.navigate(["log", item.name ], { relativeTo: this.route });
    } else if (columnName == "viewMoreDetails") {
      if (item && this.indicator.visualizations) {
        this.router.navigate(["sightingReport", this.indicator.name ? this.indicator.name : "", 
          this.indicator.visualizations ? this.indicator.visualizations : ""], 
          { relativeTo: this.route });
      }
    }

  }

  public downloadReport(columnName, item) {

    if (item.zipDownloadStatus == "Download" && item.zipDownloadFile) {
      let fileName = item.zipDownloadFile.split('.');
      const urlStr = `${this.env.rivirApiBaseUrl}/documents/sighting-supporting-data/download/${item.zipDownloadFile}/${this.accessToken}`;
      this.documentService.downloadDocumentDataForSurvey(urlStr, fileName[0]);
    }
  }

  public updateTabelInfo() {
    const startRange = this.rows.length ? this.limitRows * (this.p - 1) + 1 : 0;
    let endRange = this.limitRows * this.p;
    endRange = endRange > this.rows.length ? this.rows.length : endRange;
    this.tableInfo =
      "Showing " +
      startRange +
      " to " +
      endRange +
      " of " +
      this.rows.length +
      " entries";
  }

  public columnClick(columnName) {
    if (this.sortKey === columnName) {
      this.asc = !this.asc;
    }
    this.sortKey = columnName;
    this.p = 1;
    this.updateTabelInfo();
  }
  public refreshPage() {
    this.loaded = false;
    this.refresh.emit();
  }

  public bulkRunClick(bulkRun) {
    this.router.navigate(["bulkstatus", bulkRun.instanceId]);
  }
}
