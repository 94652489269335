import { AccountRequestViewComponent } from "../admin-settings/accountrequestview/accountrequestview.component";
import { BulkAssignmentComponent } from "../bulk-assignment/bulk-assignment.component";
import { TaskDetailsComponent } from "../tasks/task-details/task-details.component";
import { TaskDocHistoryComponent } from "../tasks/task-doc-history/task-doc-history.component";
import { AllCoaListComponent } from "./all-coa-list/all-coa-list.component";
import { CoaDetailsComponent } from "./coa-details/coa-details.component";
import { CoaListThreatActorComponent } from "./coa-list-threat-actor/coa-list-threat-actor.component";
import { CoaComponent } from "./coa.component";
import { AggregateViewComponent } from "./manager-staff/aggregate-view/aggregate-view.component";
import { ManagerStaffComponent } from "./manager-staff/manager-staff.component";

const CoARoutes = [
  {
    path: "",
    component: CoaComponent,
  },
  {
    path: "bulkassignment",
    component: BulkAssignmentComponent,
    data: {
      text: "Bulk Re-assign",
      breadcrumbs: true,
      icon: "transfer",
    },
  },
  {
    path: "task/:instanceId",
    data: { text: "Task", breadcrumbs: true },
    children: [
      { path: "",  component: TaskDetailsComponent},
      { path: "file-history/:documentId", component: TaskDocHistoryComponent, data: { text: "File History", breadcrumbs: true}}
    ]
  },
  {
    path: ":id",
    data: {
      text: "coa_singular Details",
      breadcrumbs: true,
    },
    children: [
      {
        path: "",
        component: CoaDetailsComponent,
      },
      { path: "activity/:activityId", component: CoaDetailsComponent },
      {
        path: "userProfile/:id",
        component: AccountRequestViewComponent,
        data: {
          text: "View User Profile",
          breadcrumbs: true,
        },
      },
    ],
  }

];
export const CoARoutingComponents = [
  CoaComponent,
  AllCoaListComponent,
  CoaListThreatActorComponent,
  CoaDetailsComponent,
  ManagerStaffComponent,
  BulkAssignmentComponent,
  AggregateViewComponent,
];

export { CoARoutes };
