import { Component, Input, OnChanges } from "@angular/core";
import _has from "lodash-es/has";
import { ThreatActorService } from '../threat-actor.service';
import { ToastrService } from 'ngx-toastr';
import { EnvService } from "../../../app/env.service";
import { RivirHttp } from "../../shared/services/rivir-http.service";

@Component({
  selector: "app-threat-actor-supporting-data",
  templateUrl: "./threat-actor-supporting-data.component.html",
  styleUrls: ["./threat-actor-supporting-data.component.css"],
})
export class ThreatActorSupportingDataComponent implements OnChanges {
  public rows = [];
  public columns = [];
  public tableTitle: string = "Supporting Data";
  public p: number = 1;
  public limitRowsOptions = [5, 10, 25, 50, 100];
  public limitRows: number = this.limitRowsOptions[0];
  public tableInfo: string = "";
  public sortKey = "";
  public asc = false;
  public loaded = false;
  public sideTable = true;
  public term;
  public showSupportingData;
  public isExternalDataSource = false;
  public downloadUrl : string;

  @Input() public sighting;
  @Input() public threatActorName;

  constructor(private threatActorService: ThreatActorService,
    public toastr: ToastrService, private env: EnvService) {
      this.downloadUrl = this.env.rivirApiBaseUrl + `/documents/sighting-supporting-data/download/`;

     }

  public ngOnChanges() {
    this.loaded = false;
    this.columns = [];
    this.rows = [];

    // Check first is the supporting data is availble
    this.showSupportingData =
      _has(this.sighting, "observableData.supportingData") &&
      this.sighting.observableData.supportingData.length > 0;
    if (this.showSupportingData) {
      // Set the rows to the supporting data
      this.rows = this.sighting.observableData.supportingData;

      if (this.rows.length > 0) {
        // Convert all the data into strings because false and 0 are automatically showing up as blank
        this.rows.map((row) => {
          Object.keys(row).forEach(function (key, index) {
            row[key] =
              row[key] != null && row[key] != undefined
                ? row[key].toString()
                : row[key];
          });

          return row;
        });

        const keys = Object.keys(this.rows[0]);
        keys.forEach((k) => {
          this.columns.push({ prop: k, name: k });
        });
      }
    }

    // Check If Supporting data is from External Data Source.
    const eds = this.sighting.observableData ?
      this.sighting.observableData.ExternalDataSource : null;
    this.isExternalDataSource = eds && eds.externalDataSource &&
      eds.table ? true : false;

    this.tableTitle =
      this.columns.length > 0 && !this.isExternalDataSource
        ? "Supporting Data (" + this.rows.length + ")"
        : "Supporting Data";
    this.updateTabelInfo();
    this.loaded = true;
  }

  /**
   * Exports the Supporting Data OR External Data Source
   */
  public exportSupportingData() {

    const sightingData = this.sighting.observableData;
    const idData = sightingData.idData;
    const supportingData = sightingData.supportingData;
    const observableData = sightingData.observableData;

    if (this.isExternalDataSource) {
      const sourceName = sightingData.ExternalDataSource.externalDataSource;
      const sourceTable = sightingData.ExternalDataSource.table;
      const sourceQuery = {
        property: sightingData.ExternalDataSource.threatActorId,
        value: null
      };
      const orderBy = sightingData.ExternalDataSource.orderby || {};
      const errMsg = `Cannot retrieve external data List with name: ${sourceName} Table: ${sourceTable}`;

      if (sourceQuery.property) {
        let data = (idData ? { value: (idData[sourceQuery.property] || idData).toString() } : null) ||
          (supportingData.length > 0 ? { value: supportingData[0][sourceQuery.property] } : null) ||
          (observableData.length > 0 ? observableData.find(obs => obs.name === sourceQuery.property) : null) || {};
        sourceQuery.value = data.value.trim();
      }
      // get External Data Source List from api
      this.toastr.info("Your file is being generated, please wait...", 'Downloading File', {
        disableTimeOut: true,
        progressBar: true
      });
      this.threatActorService.getExternalSupportingDataCSV(sourceName, sourceTable, sourceQuery, orderBy)
        .subscribe(response => {
          if (response &&
            response.rows &&
            response.rows.length > 0) {
            let fileStr = response.rows[0].toString().split("/");
            if (fileStr[0] == ".") {
              let urlStr = response.rows.toString().split("/");
              this.downloadUrl += urlStr[3] + "?access_token=" + RivirHttp.rivirApiToken;
              window.open(this.downloadUrl);
              this.toastr.clear();
              this.toastr.success("File generated successfully", 'Success', {
                disableTimeOut: true,
                progressBar: true
              });
              this.downloadUrl = this.env.rivirApiBaseUrl + `/documents/sighting-supporting-data/download/`;
            }
            else
              this.exportCSV(response.rows);
            this.toastr.clear();
          }
          else if (response &&
            response.rows &&
            response.rows.length === 0) {
            this.toastr.warning("No rows were found to export");
          }
          else {
            this.toastr.error("Failed to export Supporting Data");
          }
        }, err => console.log(errMsg))

    } else if (this.showSupportingData) {
      this.exportCSV(supportingData)
    }
  }

  /**
   * Exports Data into a CSV format
   */
  public exportCSV(supportingDataList) {
    // Get the columns
    let supportingDataCSV = "data:text/csv;charset=utf-8,";

    let dataColumns = Object.keys(supportingDataList[0]);

    supportingDataCSV += this.adjustCommasAndQuotes(dataColumns) + "\r\n";

    // Get the rows, adding apostrophes to values with leading zeros and not having decimals
    const dataRows = supportingDataList.map((sdata) =>
      Object.values(sdata).map((item) => {
        if (isNaN(item as any)) {
          return item;
        } else {
          if (item && item.toString()[0] === "0") {
            // No apostrophe if a single 0
            if (item.toString().length == 1) {
              return item;
            }

            let row = item.toString();

            let decimalFound = false;
            for (var i = 1; i < row.length; i++) {
              if (row[i] === ".") {
                decimalFound = true;
              }
            }

            if (decimalFound) {
              return item;
            } else {
              return "'" + item;
            }
          } else {
            return item;
          }
        }
      }),
    );

    const Adjusted_dataRows = [];
    for (var r = 0; r < dataRows.length; r++) {
      Adjusted_dataRows.push(this.adjustCommasAndQuotes(dataRows[r]));
    }

    // Join to create rows
    supportingDataCSV += Adjusted_dataRows.join("\r\n");

    // Open the CSV in a new window
    const encodedUri = encodeURI(supportingDataCSV);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute(
      "download",
      `${this.threatActorName}-${this.sighting.observableData.sightingId}.csv`,
    );
    document.body.appendChild(link); // Required for FF
    link.click();
  }

  public adjustCommasAndQuotes(data) {
    // Adjust any rowValue that have commas to cover it with quotes.
    let Adjusted_data = [];
    for (var i = 0; i < data.length; i++) {
      let rowValue = (data[i] !== undefined && data[i] !== null) ? data[i].toString() : '';
      if (rowValue && rowValue.indexOf('"') != -1) {
        rowValue = rowValue.replace(/"/gi, '""');
        rowValue = '"' + rowValue + '"';
      } else if (rowValue && rowValue.indexOf(',') != -1) {
        rowValue = '"' + rowValue + '"';
      }
      if (rowValue && rowValue.indexOf('#') != -1) {
        rowValue = rowValue.replace(/#/gi, '');
      }
      Adjusted_data.push(rowValue);
    }
    return Adjusted_data;
  }

  public itemClick(columnName, item) { }

  public updateTabelInfo() {
    const startRange = this.rows.length ? this.limitRows * (this.p - 1) + 1 : 0;
    let endRange = this.limitRows * this.p;
    endRange = endRange > this.rows.length ? this.rows.length : endRange;
    this.tableInfo =
      "Showing " +
      startRange +
      " to " +
      endRange +
      " of " +
      this.rows.length +
      " entries";
  }

  public columnClick(columnName) {
    if (this.sortKey === columnName) {
      this.asc = !this.asc;
    }
    this.sortKey = columnName;
    this.p = 1;
    this.updateTabelInfo();
  }
}
