export class Message {
    public createdBy: object;
    public from: object;
    public to: object[];
    public body: string;
    public type: string;
    public status: string;
    public recvDtime: Date;
    public createDtime: Date;
    public sendDtime: Date;
    public timePassed: string;
    public isNew :boolean
}
