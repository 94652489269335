import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { CookieService } from 'ngx-cookie-service';
import { ModalDirective } from "ngx-bootstrap/modal";
import { LoggingService, LogLevel, LogType } from "../../core/logging.service";
import { IAlgorithm } from "../nerdy-algorithm-list/nerdy-algorithm-list";
import { AlgorithmService } from "../nerdy-algorithm-list/nerdy-algorithm-list.service";

@Component({
  selector: "app-algoedit",
  templateUrl: "./algoedit.component.html",
  styleUrls: ["./algoedit.component.css"],
})
export class AlgoEditComponent implements OnInit {
  public m_classType = "Select Class Type";
  public m_coaPath: string = null;
  public m_inputDataPaths: string = null;
  public m_name: string = null;
  public m_outputDataPaths: string = null;
  public m_script: string = null;
  public m_submitButtonLabelText = "Submit";
  public m_vizPath: string = null;

  public m_algorithm: IAlgorithm = null;
  public m_errorMessage: string = null;

  public m_alertType = "info"; // or: success, danger, warning
  public m_saveState: string = null;
  public m_showAlert = "none";
  public m_statusMessage: string = null;

  @ViewChild("insufficientValuesModal")
  public insufficientValuesModal: ModalDirective;

  constructor(
    private route: ActivatedRoute,
    private algorithmService: AlgorithmService,
    private log: LoggingService,
    private cookieService: CookieService
  ) { }

  public ngOnInit() {
    // get the route parameters off of the url
    this.route.params.subscribe((params) => {
      console.log(params);
      const algorithmId: string = params.id;
      if (algorithmId != null && algorithmId.length > 0) {
        this.algorithmService.getAlgorithm(algorithmId).subscribe(
          (algorithm) => {
            console.log("Get Algorithm Returned");
            if (algorithm != null) {
              console.log("Returned " + algorithm.name + " algorithm.");
              this.m_algorithm = algorithm;
              this.populateAlgorithmData(this.m_algorithm);
            }
          },
          (error) => (this.m_errorMessage = error as any),
        );
        console.log("AlgorithmId: " + algorithmId); // log the value of id
      }
    });
  }

  public hideAlert() {
    this.m_showAlert = "none";
  }

  public onHandleTextKeyUp(event: any) {
    console.log("Not Implemented");
    // console.log("Key Up: " + event.target.value);
    // get the last key struck
    // if (event.target.value.length > 0) {
    //   const idx = event.target.value.length - 1;
    // }
  }

  public onPersistentClassTypeSelected() {
    this.m_classType = "persistent";
  }

  public onSubmitButtonClicked(event: any) {
    console.log("Submit clicked.");

    if (this.validateForm()) {
      if (this.m_algorithm != null) {
        // this is an update
        let newVersion = this.m_algorithm.version;
        newVersion += 1;
        this.m_algorithm.version = newVersion;
        this.m_algorithm.name = this.m_name;
        this.m_algorithm.script = encodeURIComponent(this.m_script);
        this.m_algorithm.UpdatedDateTime = new Date();

        // update the algorithm
        console.log(
          "Updating algorithm: " +
          this.m_algorithm.name +
          " to version: " +
          this.m_algorithm.version,
        );
        this.algorithmService.postAlgorithm(this.m_algorithm);

        // Log indicator change entry
        this.log.logClientEvent(
          "Add/Edit Indicator",
          LogLevel.Info,
          LogType.App,
          this.cookieService.get("email"),
          JSON.stringify(this.m_algorithm),
        );

        this.showAlert(
          "success",
          "Success!",
          "Successfully saved " +
          this.m_algorithm.name +
          " to version: " +
          this.m_algorithm.version,
        );
      } else {
        const algo: any = {
          name: this.m_name,
          version: 1,
          requirements: "none",
          script: encodeURIComponent(this.m_script),
          CreatedBy: "mappw",
          UpdatedBy: "mappw",
          CreatedDateTime: new Date(),
          UpdatedDateTime: new Date(),
        };
        console.log("Posting algorithm to server: " + JSON.stringify(algo));
        // this.algorithmService.postAlgorithm(algo);

        // this.router.navigate(['/']);
        // display the alert dialog
        this.showAlert(
          "success",
          "Success!",
          "Successfully created version: " +
          algo.version +
          " of algorithm " +
          this.m_name,
        );
      }
    }
  }

  public onTransientClassTypeSelected() {
    this.m_classType = "transient";
  }

  public populateAlgorithmData(algorithm: IAlgorithm): void {
    // set the input data values
    console.log("Populating: " + algorithm.name);
    this.m_name = algorithm.name;
    this.m_coaPath = algorithm.coa;
    this.m_inputDataPaths = algorithm.inputDataPath;
    this.m_outputDataPaths = algorithm.outputDataPath;
    this.m_script = decodeURIComponent(algorithm.script);
    this.m_vizPath = algorithm.visualizations;
  }

  public showAlert(alertType: string, status: string, message: string) {
    this.m_alertType = alertType;
    this.m_saveState = status;
    this.m_showAlert = "block";
    this.m_statusMessage = message;
  }

  public updateAlgorithm(algorithm: IAlgorithm): void {
    console.log("Updating: " + algorithm.name);
  }

  public validateForm(): boolean {
    let formValid = false;

    if (
      this.m_name != null &&
      this.m_name.length > 0 &&
      this.m_vizPath != null &&
      this.m_vizPath.length > 0 &&
      this.m_script != null &&
      this.m_script.length > 0
    ) {
      // valid data
      formValid = true;
    } else {
      this.insufficientValuesModal.show();
    }

    return formValid;
  }
}
