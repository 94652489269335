import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CookieService } from 'ngx-cookie-service';
import { EnvService } from "../../app/env.service";
import { RivirHttp } from "../shared/services/rivir-http.service";

export enum LogLevel {
    Info = 1,
    Debug = 2,
    Warn = 3,
    Error = 4,
}

export enum LogType {
    Server = 1,
    Admin = 2,
    Session = 3,
    App = 4,
}

@Injectable()
export class LoggingService {

    private loggingUrl: string; 

    constructor(private _http: HttpClient,
                private cookieService: CookieService,
                private env: EnvService) {
                    this.loggingUrl = `${this.env.rivirApiBaseUrl}/logging`;
    }

    public logClientEvent(message: string, level: LogLevel = LogLevel.Info, type: LogType = LogType.App,
        userName: string = "", metaData: string = "") {
        if (!userName && this.cookieService.get("email")) {
            userName = this.cookieService.get("email");
        }

        const logLevel: string = LogLevel[level];
        const logType: string = LogType[type];

        const params = {
            message,
            level: logLevel,
            logType,
            userName,
            metaData,
        };

        this._http.post(this.loggingUrl + "/logClientEvent", params, RivirHttp.getHttpOptions());
    }

}
