export const RIViRSurveyUtil = () => {

    const getAccessToken = () => {
        return sessionStorage.getItem("rivir_access_token");
    }

    const getCurrentTask = () => {
        let task = JSON.parse(sessionStorage.getItem("rivir_current_task"));
        task.instanceId = task.processInstanceId;
        return task;
    }

    const runAlgorithm = async (name, currentUser, parameters, accessToken) => {
        const runAlgorithmUrl = window.__env.rivirApiBaseUrl + '/algorithms/execute?access_token=' + accessToken;
        const executeResponse = await fetch(runAlgorithmUrl, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ name, currentUser, parameters: JSON.stringify(parameters) })
        });
        const executionResult = await executeResponse.json();

        console.log(executionResult);
        return executionResult;
    }

    const getAlgorithmStatus = async (name, task, accessToken) => {

        const query = '%7B%20%22where%22%20%3A%20%7B%20%22parameters.instanceId%22%20%3A%20%22' + task.instanceId + '%22%20%7D%7D';
        const statusUrl = window.__env.rivirApiBaseUrl + '/sightings?access_token=' + accessToken + '&filter=' + query;
        const statusResponse = await fetch(statusUrl);
        let statusArray = await statusResponse.json();
        statusArray = statusArray.sort( (a,b) => new Date(b.UpdatedDateTime).getTime()  - new Date(a.UpdatedDateTime).getTime()  );

        const status = statusArray.length ? statusArray.pop() : {};
        console.log(status);
        return status;
    }

    const sleep = (time) => {
        return new Promise(resolve => setTimeout(resolve, time));
    }

    const getEmailTemplate = async (emailTemplateName, accessToken ) => {
        const getEmailTemplateQuery = encodeURIComponent('{ "where" : { "name" : "' + emailTemplateName + '"  } }');
        const getEmailTemplateUrl = window.__env.rivirApiBaseUrl + '/emailTemplates?access_token=' + accessToken + '&filter=' + getEmailTemplateQuery;
        const emailTemplateResponse = await fetch(getEmailTemplateUrl);
        const emailTemplateArray = await emailTemplateResponse.json();

        const emailTemplate = emailTemplateArray.length ? emailTemplateArray.pop() : {};
        console.log( emailTemplate );
        return emailTemplate;
    }

    const replaceEmailKeywordsWithTaskValues = ( keywordTemplate, task ) => {
        
        // Decode the template to change it plain text 
        keywordTemplate = decodeURIComponent( keywordTemplate );

        var handleBarsTemplate = window.__handlebars.compile( keywordTemplate );
        const handlebarsResult = handleBarsTemplate( task );

        return handlebarsResult; 
    }
  
    return { getAccessToken,
             getCurrentTask,
             runAlgorithm,
             getAlgorithmStatus,
             sleep,
             getEmailTemplate,
             replaceEmailKeywordsWithTaskValues };
}