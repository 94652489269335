<div class="nav-tabs-alt m-b-lg">
  <ul class="nav nav-tabs nav-">
    <li *ngFor="let tab of tabs" class="{{tab == activeTab? 'active':''}}">
      <a (click)="selectTab(tab)" role="tab" data-toggle="tab" aria-expanded="false">{{tab.name}}</a>
    </li>
  </ul>
</div>

<div class="col-sm-12" *ngIf="activeTab.prop == 'myTasks'">
  <app-my-tasks-list></app-my-tasks-list>
</div>
<div class="col-sm-12" *ngIf="activeTab.prop == 'allTasks'">
  <app-all-tasks-list></app-all-tasks-list>
</div>