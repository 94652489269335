import { EnvService } from './env.service';
import { environment } from "../environments/environment";
import { RIViRSurveyUtil } from "../js/RIViRSurveyUtil";
import Handlebars from "handlebars/dist/handlebars.js";

export const EnvServiceFactory = () => {  
  // Create env
  let env = new EnvService();

  // Read environment variables from browser window
  const browserWindow = window || {};
  const browserWindowEnv = browserWindow['__env'] || {};


  // Convert the JSON string to valid JSON
  for (const envKey of Object.keys(browserWindowEnv)){
     browserWindowEnv[envKey] = IsJsonString(browserWindowEnv[envKey]) ? JSON.parse(browserWindowEnv[envKey]) : browserWindowEnv[envKey];
  }

  env = { ...environment, ...browserWindowEnv};

  // Set the windows variable to be used by any custom scripts
  browserWindow['__env'] = env;

  browserWindow['__rivirSurveyUtil'] = RIViRSurveyUtil();

  browserWindow['__handlebars'] = Handlebars;

  return env;
};

const IsJsonString =  (str)  =>{
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}

export const EnvServiceProvider = {  
  provide: EnvService,
  useFactory: EnvServiceFactory,
  deps: [],
};