import { from, of, Observable } from 'rxjs';

export interface IBreadcrumb {
  text: string;
  path: string;
}

declare var require: any;

export function r(module) {
  return require(`${name}`);
}

export function stringFormat(template: string, binding: any): string {
  // const compiled = _.template(template);
  // return compiled(binding);
  // NOTE: Removed the template binding because it's not being used for this project
  // if needed add using ES6 modules 
  return template;
}

export function isPromise(value: any): boolean {
  return value && typeof value.then === 'function';
}

export function wrapIntoObservable<T>(
  value: T | Promise<T> | Observable<T>
): Observable<T> {
  if (value instanceof Observable) {
    return value;
  }

  if (isPromise(value)) {
    return from(Promise.resolve(value));
  }

  return of(value as T);
}
