import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable, BehaviorSubject } from "rxjs";
import { catchError } from 'rxjs/operators';

import * as moment from "moment";

import { EnvService } from "../../env.service";
import { UserProfileService } from "../../core/userProfile.service";
import { Message } from "../models/message";
import { RivirHttp } from "./rivir-http.service";

@Injectable()
export class MessageService {
  private userMessages = new BehaviorSubject<Message[]>([]);
  private _messagesUrl: string;

  constructor(private _http: HttpClient,
              private userProfileService: UserProfileService,
              private env: EnvService) {
              this._messagesUrl = this.env.rivirApiBaseUrl + "/messages";
               }

  public getUserMessages(): Observable<any> {
    return this.userMessages.asObservable();
  }

  public getMessages(): Observable<Message[]> {
    const url: string = this._messagesUrl + "/getAll";
    return this._http.get<Message[]>(url, RivirHttp.getHttpOptions())
      .pipe(catchError(RivirHttp.handleError));
  }

  public getMessagesForUserEmail(email: string) {
    const filter = `?filter={"where" : {"to.email" : "${email}"}}`;
    const url: string = this._messagesUrl + filter;
    return this._http.get<Message[]>(url, RivirHttp.getHttpOptions())
      .subscribe((messages) => {
        this.userMessages.next(messages);
      });
  }

  public async getUsersLatestMessage( email: string ) : Promise<any> {
    const filter = `{ "where" : {"to.email" : "${email}" },  "order" : "createDtime DESC",  "limit": 5, "fields" : { "type": true, "body": true, "status": true, "createDtime": true }}`; 
    const url = `${this._messagesUrl}?filter=${filter}`;
    return await this._http.get(url, RivirHttp.getHttpOptions()).toPromise();

  }

  public async getCountOfNewMessages( email : string ): Promise<number> {
    const filter = `{ "status" : "New", "to.email" : "${email}"  }`;
    const url = `${this._messagesUrl}/count/?where=${filter}`;
    const countResponse : any = await this._http.get(url, RivirHttp.getHttpOptions()).toPromise();
    return countResponse ? countResponse.count : 0;
  }

  public postMessage(messageType: string,
    messageBody: string,
    to: string = this.userProfileService.userEmail,
    onlyAlert: boolean = false) {
    const message: Message = new Message;
    message.body = messageBody;
    message.type = messageType;
    message.createDtime = new Date();
    message.status = "New";
    message.createdBy = { id: this.userProfileService.userEmail };
    message.to = [{ email: to }];
    message.from = { email: "messages@rivirbed.xyz" };

    if (!onlyAlert) {
      this.sendEmail(message);
    }
    return this._http.post<Message>(this._messagesUrl, message)
      .subscribe((res) => this.getMessagesForUserEmail(this.userProfileService.userEmail));
  }

  public sendEmail(msg: Message) {
    this._http.post<Message>(this._messagesUrl + "/sendEmail", msg, RivirHttp.getHttpOptions())
      .subscribe((res) => console.log(res), (err) => RivirHttp.handleError(err));
  }

  public sendEmailUsingTemplate(templateData: any) {

    return this._http.post<Message>(this._messagesUrl + "/template", templateData, RivirHttp.getHttpOptions())
      .subscribe((res) => console.log(res), (err) => RivirHttp.handleError(err));
  }

  public markAllAsSeen() {
    return this._http.post<Message>(this._messagesUrl +
      `/update?where={"status":"New", "to.email" : "${this.userProfileService.userEmail}"}`, { status: "Seen" }, RivirHttp.getHttpOptions());
  }

  public getNewMessagesCount(messages) {
    let count = 0;
    messages.forEach((message) => { if (message.status === "New") { count++; } });
    return count;
  }

  public getTimePassedForMessage(messages) {
    messages.forEach((message) => {
      message.timePassed = moment(message.createDtime).fromNow();
    });
    return messages;
  }
}
