import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { catchError } from 'rxjs/operators';

import { EnvService } from '../env.service';
import { RivirHttp } from '../shared/services/rivir-http.service';

export class TableauReport {
    workbook: string;
    project: string;
    url: string;
    username: string;
    workBookId: string;
}

export class TableauTrustedTicket {
    ticket: string;
    site: string; 
    tableauServer: string;
}



@Injectable()
export class TableauService {

    constructor(
        private http: HttpClient,
        private env: EnvService
    ) { }

    public async getUserDashboard( username : string ) : Promise<TableauReport> {

        const getUserDashboardUrl = `${this.env.rivirApiBaseUrl}/tableau/dashboard?username=${username}`;

        const userDashboard = await this.http
                  .get<TableauReport>(getUserDashboardUrl, RivirHttp.getHttpOptions())
                  .pipe(catchError(RivirHttp.handleError)).toPromise();

        return userDashboard;

    }

    public async removeDashboard( username: string ) : Promise<null> {

        const deleteDashboardUrl = `${this.env.rivirApiBaseUrl}/tableau/dashboard`;

        const options = RivirHttp.getHttpOptions( { username });
        await this.http
        .request( 'DELETE', deleteDashboardUrl, options)
        .pipe(catchError(RivirHttp.handleError)).toPromise();

        return;
    }


    public async setDashboard( username: string, indicator: string, view: string, workbook: string, workBookId: string, project: string, updatedBy: string, contentUrl: string) : Promise<null> {

        const setDashboardUrl = `${this.env.rivirApiBaseUrl}/tableau/dashboard?username=
        ${username.trim()}&view=${view}&workbook=${workbook}&workBookId=${workBookId}&UpdatedBy=${updatedBy}&contentUrl=${contentUrl}`;

        const options = RivirHttp.getHttpOptions();
        await this.http
        .request( 'POST', setDashboardUrl, options)
        .pipe(catchError(RivirHttp.handleError)).toPromise();

        return;
    }


    public async getTrustedTicket() : Promise<TableauTrustedTicket> {

        const trustedTicketUrl = `${this.env.rivirApiBaseUrl}/tableau/getTrustedTicket`;
        const trustedTicket = await this.http
        .get<TableauTrustedTicket>(trustedTicketUrl, RivirHttp.getHttpOptions())
        .pipe(catchError(RivirHttp.handleError)).toPromise();

        return trustedTicket;
    }

    public async getServerURI() : Promise<string> {

        const trustedTicketUrl = `${this.env.rivirApiBaseUrl}/tableau/getTableauUri`;
        const trustedTicket = await this.http
        .get<string>(trustedTicketUrl, RivirHttp.getHttpOptions())
        .pipe(catchError(RivirHttp.handleError)).toPromise();

        return trustedTicket;
    }

    public async getAllWorkBooks(filter: string) : Promise<Array<any>> {

        const trustedTicketUrl = `${this.env.rivirApiBaseUrl}/tableau/getWorkBook?filter=${filter}`;
        const trustedTicket = await this.http
        .get<Array<any>>(trustedTicketUrl, RivirHttp.getHttpOptions())
        .pipe(catchError(RivirHttp.handleError)).toPromise();

        return trustedTicket;
    }

    public async getWorkBookById(workBookId: string) : Promise<string> {

        const trustedTicketUrl = `${this.env.rivirApiBaseUrl}/tableau/getWorkBookById/${workBookId}`;
        const trustedTicket = await this.http
        .get<string>(trustedTicketUrl, RivirHttp.getHttpOptions())
        .pipe(catchError(RivirHttp.handleError)).toPromise();

        return trustedTicket;
    }

    public async getWorkbookUrl( contentUrl ) : Promise<string> {

        const trusted = await this.getTrustedTicket();
        
        let workBookUrl;
        if (trusted && trusted.ticket){

        workBookUrl = trusted.site ?  
            `${trusted.tableauServer}/trusted/${trusted.ticket}/t/${trusted.site}/views/${contentUrl}` :
            `${trusted.tableauServer}/trusted/${trusted.ticket}/views/${contentUrl}`;
        }
        else {
            throw new Error('Unable to retrieve Tableau Trusted Ticket');
        }

        return workBookUrl;

    }

}
