import { Component, OnInit } from "@angular/core";
import { CookieService } from "ngx-cookie-service";

import { map } from "rxjs/operators";

import { MessageService } from "../../shared/services/message.service";
import { StixConfigService } from "../../shared/services/stixConfig.service";

@Component({
  selector: "app-notifications-page",
  templateUrl: "./notifications-page.component.html",
  styleUrls: ["./notifications-page.component.css"],
})
export class NotificationsPageComponent implements OnInit {

  public allMessages;

  constructor(private messageService: MessageService, 
              public stixConfig: StixConfigService,
              public cookieService: CookieService) { }

  public async ngOnInit() {
    this.messageService.getMessagesForUserEmail( this.cookieService.get("email"));
    this.messageService.getUserMessages()
      .pipe(map((messages: any[]) => {
        this.messageService.getTimePassedForMessage(messages);
        messages.sort(function (a, b) {
          return new Date(b.createDtime).getTime() - new Date(a.createDtime).getTime();
        });
        return messages;
      }))
      .subscribe((messages) => {
        this.allMessages = this.stixConfig.stixLabeling(messages);
      });
  }

}
