<div *ngIf="!loaded" class="loader">Loading
    <span class="loader__dot">.</span>
    <span class="loader__dot">.</span>
    <span class="loader__dot">.</span>
</div>
<div *ngIf="loaded" class="">
    <div *ngIf="permission_accessUserAccount">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-6" style="padding-right:30px">
                    <div class="container-fluid m-b-xs">
                        <div class="row profile-input-row">
                            <div class="col-md-4">
                                <label for="firstName">First Name: </label>
                            </div>
                            <div class="col-md-8">
                                <input id="firstName" type="text" class="form-control"
                                    [(ngModel)]="userProfile.firstName" [disabled]="!editable" />
                            </div>
                        </div>
                        <div class="row profile-input-row">
                            <div class="col-md-4">
                                <label for="lastName">Last Name: </label>
                            </div>
                            <div class="col-md-8">
                                <input id="lastName" type="text" class="form-control" [(ngModel)]="userProfile.lastName"
                                    [disabled]="!editable" />
                            </div>
                        </div>
                        <div class="row profile-input-row m-b-xs">
                            <div class="col-md-4">
                                <div class="_label">Email: </div>
                            </div>
                            <div class="col-md-8">
                                <div class="form-control readOnly" id="userEmail">{{userProfile.email}}</div>
                            </div>
                        </div>
                        <div class="row profile-input-row">
                            <div class="col-md-4"></div>
                            <div class="col-md-8">
                                <button type="button" class="btn btn-primary" (click)="resetPassword()">Reset
                                    Password</button>
                            </div>
                        </div>
                        <div class="row profile-input-row m-b-md">
                            <div class="col-md-4"></div>
                            <div class="col-md-8">
                                <span [ngClass]="{'profile-expired': userProfile.passwordExpired}">
                                    {{ userProfile.dayUntilExpiration }} </span>
                            </div>
                        </div>
                        <div class="row profile-input-row">
                            <div class="col-md-4">
                                <div class="_label" *ngIf="!permission_changeAccountType">Role: </div>
                                <label for="userRole" *ngIf="permission_changeAccountType">Role: </label>
                            </div>
                            <div class="col-md-8" *ngIf="!permission_changeAccountType">
                                <div id="userRole" class="form-control readOnly">{{ userProfile.accountType }}</div>
                            </div>
                            <div class="col-md-8" *ngIf="permission_changeAccountType">
                                <select id="userRole" class="form-control" [(ngModel)]="userProfile.accountType">
                                    <option *ngFor="let r of accountTypeOptions" [ngValue]="r.name">{{r.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row profile-input-row">
                            <div class="col-md-4">
                                <div class="_label" *ngIf="!permission_changeAccountType">Manager in RIViR: </div>
                                <label for="rivirManager" *ngIf="permission_changeAccountType">Manager in RIViR:
                                </label>
                            </div>
                            <div class="col-md-8" *ngIf="!permission_changeAccountType">
                                <div id="rivirManager" *ngIf="manager" class="form-control readOnly">{{ manager.email }}
                                    - {{ manager.name }}</div>
                            </div>
                            <div class="col-md-8" *ngIf="permission_changeAccountType">
                                <select id="rivirManager" class="form-control" [(ngModel)]="userProfile.manager"
                                    (ngModelChange)="setCustomManagerEmail($event)">
                                    <option *ngFor="let i of managedUserOptions" [ngValue]="i.id">{{ i.email }} -
                                        {{i.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row profile-input-row">
                            <div class="col-md-4">
                                <label for="organization">Organization: </label>
                            </div>
                            <div class="col-md-8">
                                <input id="organization" type="text" class="form-control"
                                    [(ngModel)]="userProfile.organization" [disabled]="!editable" />
                            </div>
                        </div>
                        <div class="row profile-input-row">
                            <div class="col-md-4">
                                <label for="businessPhone">Business Phone: </label>
                            </div>
                            <div class="col-md-8">
                                <input id="businessPhone" type="text" class="form-control"
                                    [(ngModel)]="userProfile.homePhoneNumber" [disabled]="!editable" />
                            </div>
                        </div>
                        <div class="row profile-input-row">
                            <div class="col-md-4">
                                <label for="cellPhone">Cell Phone: </label>
                            </div>
                            <div class="col-md-8">
                                <input id="cellPhone" type="text" class="form-control"
                                    [(ngModel)]="userProfile.cellPhoneNumber" [disabled]="!editable" />
                            </div>
                        </div>
                        <div class="row profile-input-row">
                            <div class="col-md-4">
                                <label for="officeLocation">Office Location: </label>
                            </div>
                            <div class="col-md-8">
                                <input id="officeLocation" type="text" class="form-control"
                                    [(ngModel)]="userProfile.officeLocation" [disabled]="!editable" />
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-md-6 profile-pic-section m-b-md">
                    <div><img src="{{userProfile.profilePicUrl}}?access_token={{accessToken}}" class="profile-pic"
                            (error)="updateUrl($event)" alt="Profile Picture" /></div>
                    <h1 class="h3">{{userProfile.firstName}} {{userProfile.lastName}}</h1>
                    <div *ngIf="editable" class="m-b-sm">
                        <image-upload buttonCaption="Select Image" dropBoxMessage="Drop Image Here" [max]="1"
                            (uploadFinished)="onUploadFinished($event)" (removed)="onRemoved($event)"
                            [style]="customStyle">
                        </image-upload>
                    </div>
                    <div style="text-align:left;" *ngIf="permission_changeAccountType">
                        <fieldset class="m-l-xs">
                            <legend class="userStatus m-t-md m-b-xs">Status</legend>
                            <div *ngFor="let userStatus of statusOptions" style="margin: 0 0 5px 10px;">
                                <div class="radio">
                                    <label for="{{userStatus.id}}" class="i-checks">
                                        <input id="{{userStatus.id}}" name="userStatusGroup" [value]="userStatus.name"
                                            [(ngModel)]="currentUserStatus" type="radio">
                                        <i [class]="'status-' + userStatus.name"></i>
                                        {{userStatus.name}}
                                    </label>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div class="row col-md-12">
                    <div class="col-md-4 col-md-offset-2">
                        <button *ngIf="editable" class="btn btn-primary profile-save-button"
                            (click)="updateProfile()">Save</button>
                        <button *ngIf="editable" class="btn btn-secondary profile-cancel-button"
                            (click)="cancel()">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <page-denied-message *ngIf="!permission_accessUserAccount"></page-denied-message>

</div>