<div class="container-fluid" style="height:700px;" *ngIf="model">
    <indicator-wiz-navigation [stepNumber]="3"  [parentLocalSaveFunction]="saveBoundCallback"></indicator-wiz-navigation>
    <div class="row form-group">
        <div class="col-sm-11 text-center">
            <label class="header-label" for="description">Optional step.  Choose one or many {{stixConfig.coa_plural}} when this {{label_indicator}} is executed.</label>
        </div>
        <label class="col-sm-2 control-label" for="recommendedCoA_input">Recommended
            {{stixConfig.coa_singular}}:</label>
        <div class="col-sm-10">
            <ng-select [items]="allProcessDefinitions" bindLabel="name" bindValue="name"
                placeholder="Select one or multiple {{stixConfig.coa_plural}}.." [multiple]="true"
                [closeOnSelect]="false" [(ngModel)]="algorithm.coursesOfAction" id="coursesOfAction"
                name="coursesOfAction" (change)="processDefinitionChanged($event)">
            </ng-select>
        </div>
    </div>   
    <div class="row" style="height: 100%;">
        <div class="nav-tabs-alt m-b-lg">
            <ul class="nav nav-tabs">
              <ng-container *ngFor="let preview of recommendedCoas">
                <li  [ngClass]="{'active': preview.active}">
                    <a data-toggle="tab" (click)="updateDiagram( preview)">{{preview.name}}</a>
                </li>
              </ng-container>
            </ul>
          </div>
        <div class="tab-content" style="height: 100%">
            <indicator-wiz-diagram [deploymentId]="deploymentId" [style.visibility]="showDiagramControls()"></indicator-wiz-diagram>
        </div>

    </div>
</div>
