import { CourseOfActionAutoStart } from "./../nerdy-algorithm-list/nerdy-algorithm-list";
import { Sighting } from "./sighting";

export class Indicator {
  public id?: string;
  public name: string;
  public description: string;
  public version?: number;
  public status?: string;
  public classification?: string;
  public classifications: any[];
  public visualizations: string;
  public type?: string;
  public types: any[];
  public script: string;
  public lastRunDate?: Date;
  public numOfSightings?: number;
  public numOfOccurrence?: number;
  public coa?: string;
  public coursesOfAction: any[];
  public active?: boolean;
  public execute?: boolean;
  public CreatedBy: string;
  public UpdatedBy: string;
  public CreatedDateTime: Date;
  public UpdatedDateTime: Date;
  public sightings?: Sighting[];
  public sighting?: any[];
  public collapse?: boolean;
  public autoStartCoursesOfAction?: CourseOfActionAutoStart[];
  public updateRecord?: boolean;
  public defaultView?: string;
  constructor() { }
}
