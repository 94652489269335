import { Injectable } from "@angular/core";
import { isDevMode } from "@angular/core";
import { CookieService } from 'ngx-cookie-service';
import { EnvServiceFactory } from "../../env.service.provider";
import { EnvService } from "../..//env.service";

import { RivirHttp } from "../../shared/services/rivir-http.service";

declare var Keycloak: any;

@Injectable()
export class KeycloakService {
  public static auth: any = { loggedIn: false };
  public static env = EnvServiceFactory();

  public static init(): Promise<any> {
    KeycloakService.auth.loggedIn = false;

    const keycloakAuth: any = Keycloak(this.env.keycloak);

    return new Promise((resolve, reject) => {
      keycloakAuth.init({ onLoad: "login-required" })
        .success(() => {
          if (isDevMode()) { console.log("[KeycloakService] - Setting auth.loggedIn = true."); }
          KeycloakService.auth.loggedIn = true;
          KeycloakService.auth.authz = keycloakAuth;
          KeycloakService.auth.logoutUrl = `${keycloakAuth.authServerUrl}/realms/${this.env.keycloak.realm}/protocol/openid-connect/logout?post_logout_redirect_uri =${document.baseURI}&id_token_hint=${keycloakAuth.idToken}`;
          if (isDevMode()) { console.log("Keycloak Init: login-required."); }
          if (isDevMode()) { console.log("Keycloak Init: BaseURI: " + document.baseURI); }
          resolve(null);
        })
        .error(() => {
          reject();
        });
    });
  }

  public static initWithParameter(param: string): Promise<any> {
    KeycloakService.auth.loggedIn = false;

    const keycloakAuth: any = Keycloak(this.env.keycloak);

    return new Promise((resolve, reject) => {
      keycloakAuth.init({ onLoad: param })
        .success(() => {
          if (isDevMode()) { console.log("[KeycloakService] - Setting auth.loggedIn = true."); }
          KeycloakService.auth.loggedIn = true;
          KeycloakService.auth.authz = keycloakAuth;
          KeycloakService.auth.logoutUrl = `${keycloakAuth.authServerUrl}/realms/${this.env.keycloak.realm}/protocol/openid-connect/logout?post_logout_redirect_uri =${document.baseURI}&id_token_hint=${keycloakAuth.idToken}`;
          if (isDevMode()) { console.log("Keycloak Init: " + param + "."); }
          if (isDevMode()) { console.log("Keycloak Init: BaseURI: " + document.baseURI); }
          resolve(null);
        })
        .error(() => {
          reject();
        });
    });
  }

  constructor(private cookieService: CookieService, private env: EnvService) {

  }

  public getLoginUrl() {
    const kc = Keycloak(this.env.keycloak);
    return kc.createLoginUrl();
  }

  public async logout( source : string ) {

      const username = this.cookieService.get("email");
      console.log(`Logging out user [ ${username} ] from source: ${source}`);

      const auditMessage = `${this.env.rivirApiBaseUrl}/securityAudit/log?username=${username}&status=LOGOUT&source=${source}`;
      await fetch(auditMessage);

      // Delete all the API Tokens
      const userProfileId = this.cookieService.get("userProfileId");
      const deleteTokenUrl = `${this.env.rivirApiBaseUrl}/userprofiles/token/${userProfileId}?access_token=${RivirHttp.rivirApiToken}`;
      const response = await fetch(deleteTokenUrl, { method: 'DELETE' });

      // Delete all cookies 
      this.cookieService.deleteAll();

      // Clear Keycloak information
      KeycloakService.auth.loggedIn = false;
      KeycloakService.auth.authz = null;

      // Redirect to the login page
      window.location.href = KeycloakService.auth.logoutUrl;
      return response;
  }

  public async getToken(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      if (KeycloakService.auth.authz.token) {
        // if (isDevMode()) console.log("[KeycloakService] - Stored token: " + KeycloakService.auth.authz.token);
        KeycloakService.auth.authz
          .updateToken(5)
          .success(() => {
            // if (isDevMode()) console.log("[KeycloakService] - Refreshed Token: " + KeycloakService.auth.authz.token);
            resolve(KeycloakService.auth.authz.token as string);
          })
          .error(() => {
            reject("Failed to refresh token");
          });
      } else {
        console.log("[KeycloakService] - Not Logged In.");
        reject("Not logged in");
      }
    });
  }

  public isLoggedIn() {
    console.log("KeycloakService Init");
    KeycloakService.auth.loggedIn = false;

    const keycloakAuth: any = Keycloak(this.env.keycloak);

    return new Promise((resolve, reject) => {
      keycloakAuth.isLoggedIn()
        .success(() => {
          resolve(true);
        })
        .error(() => {
          reject(false);
        });
    });
  }

  public loadUserInfo(): Promise<Object> {
    return new Promise<Object>((resolve, reject) => {
      if (KeycloakService.auth.authz.token) {
        KeycloakService.auth.authz
          .loadUserInfo()
          .success(() => {
            resolve(KeycloakService.auth.authz.userInfo as Object);
          })
          .error(() => {
            reject("Failed to retrieve user info.");
          });
      }
    });
  }

  public async loadUserProfile(): Promise<Object> {
    return new Promise<Object>((resolve, reject) => {
      if (KeycloakService.auth.authz.token) {
        KeycloakService.auth.authz
          .loadUserProfile()
          .success(() => {
            resolve(KeycloakService.auth.authz.profile as Object);
          })
          .error(() => {
            reject("Failed to retrieve user profile.");
          });
      }
    });
  }
}
