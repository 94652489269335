import { Component} from "@angular/core";

@Component({
    selector: "page-denied-message",
    template: `
    <div  class="alert alert-danger text-center m-t-xl" style="opacity: 0.9;">
       <h3>Access is denied</h3>
       <h4> You may not have the appropriate permissions to access this page</h4>
    </div>
    `,
  })
  export class PageDeniedMessageComponent {}
