<div *ngIf="!loaded" class="loader">Loading
  <span class="loader__dot">.</span>
  <span class="loader__dot">.</span>
  <span class="loader__dot">.</span>
</div>
<div *ngIf="loaded">
  <div class="row ta-detail-wrapper m-t-sm">
    <h2 class="row col-sm-{{threatActor.address? '6':'11'}} m-b-md details" *ngIf="threatActor">
      <span class="id-label ">
        <!-- <i class="glyphicon glyphicon-user"></i> -->
        <span>{{threatActor.name}} - {{threatActor.taId}}</span>
      </span>
    </h2>
    <div *ngIf="threatActor.address" class="col-sm-5 map-container">
      <app-map-view [name]="threatActor.name" [address]="threatActor.address"></app-map-view>
    </div>
    <div class="row col-sm-{{threatActor.address? '6':'12'}} details" *ngIf="threatActor">
      <br>
      <div class="row col-sm-{{threatActor.address? '': obDisplayData.length > 5? '6':'7'}}"
        *ngFor="let obDisplay of obDisplayData">
        <h5 *ngIf="obDisplay.section">{{obDisplay.section}}</h5>
        <div *ngIf="!obDisplay.section">
          <div class="i-label col-sm-5 capitalize" data-toggle="tooltip" title="{{obDisplay.helpText}}">
            <b>{{obDisplay.name}}:</b></div>
          <div class="col-sm-7" *ngIf="obDisplay.isString">{{obDisplay.value}}</div>
          <div class="col-sm-7" *ngIf="obDisplay.isArray"><a (click)="openArrayModal(obDisplay)" class="ta-array-modal-link">View {{obDisplay.name}}</a></div>
          <div class="col-sm-7" *ngIf="obDisplay.isObject">
            <div *ngFor="let item of obDisplay.value | keyvalue">
              <span class="ta_obj_key">{{item.key}} :</span> <span>{{item.value}}</span>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12">

      <div *ngIf="hasCaseManagement" class="case-mgmt-tabs">
        <ul class="nav nav-tabs">
          <li class="active"><a data-toggle="tab" href="#indicatorsTab">Indicators</a></li>
          <li><a data-toggle="tab" href="#casesTab">Cases</a></li>
        </ul>
      </div>
      <div class="tab-content">
        <div id="indicatorsTab" class="tab-pane fade in active">
          <app-indicators-details-threat-actor [threatActor]="threatActor" [sightingFromRoute]="sightingFromRoute">
          </app-indicators-details-threat-actor>
        </div>
        <div id="casesTab" class="cases-tab tab-pane fade">
          <div class="row" style="margin-right:0;">
            <div class="col-md-10">
              <app-case-counts threatActorId={{threatActor.id}}></app-case-counts>
            </div>
            <div class="col-md-2 start-case-btn" *ngIf="hasCaseManagement" style="margin-right: 0; padding-right: 0;">
              <button class="btn btn-primary" (click)="startCase()">Start Case</button>
            </div>
          </div>
          <div class="cases-grid">
            <app-cases-grid threatActorId={{threatActor.id}}></app-cases-grid>
            <div class="case-listing-bottom-buffer">
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>

</div>