<div *ngFor="let message of allMessages">         
  <div class="m-l-lg panel b-a">
    <div class="panel-heading pos-rlt b-b b-light">
      <span class="arrow left"></span>
      <span>{{message.type}}</span>
      <!-- <label class="label bg-light m-l-xs">{{message.status}}</label>  -->
      <span class="text-muted m-l-sm pull-right">
        {{message.timePassed}}
      </span>
    </div>
    <div class="panel-body">
      <blockquote>
        <p>{{message.body}}</p>
        <small>{{message.createDtime | date: 'MMM d, y, h:mm a'}}</small>
      </blockquote>
      <!-- <div>Lorem ipsum dolor sit amet, consecteter adipiscing elit...</div> -->
    </div>
  </div>
</div>