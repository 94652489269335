import { HttpClient } from "@angular/common/http";
import { isDevMode } from "@angular/core";
import { Injectable } from "@angular/core";

import { CookieService } from 'ngx-cookie-service';

import {  timer, Subject, Observable } from "rxjs";
import { catchError } from 'rxjs/operators';

import { EnvService } from "../env.service";
import { UserProfile } from "../shared/models/userProfile";
import { RivirHttp } from "../shared/services/rivir-http.service";
import { WorkflowService } from "../shared/services/workflow.service";
import {
  RivirAPI,
  RivirAPIFilter,
  RivirObject,
} from "../rivirapi/rivirapi.sdk";
import { KeycloakService } from "./keycloak/keycloak.service";

@Injectable()
export class UserProfileService {

  public subscription;
  public profile: any = null;
  public fullName = "";
  public accountType = "";
  public userEmail = "";
  public profilePic = "../images/user.png";
  private userProfile = new Subject<any>();
  private usersListing: string[] = [];
  private usersNameMap: NameMapObject[] = [];
  private timerStarted = false;

  private _userProfileUrl: string;
  private _rivirKeycloakUrl: string; 

  constructor(
    private _http: HttpClient,
    private kc: KeycloakService,
    private workflowService: WorkflowService,
    private rivirApi: RivirAPI,
    private cookieService: CookieService,
    private env: EnvService
  ) {
    this._userProfileUrl = this.env.rivirApiBaseUrl + "/userprofiles";
    this. _rivirKeycloakUrl = this.env.rivirApiBaseUrl + "/keycloak";
    this.fullName = `${this.cookieService.get("firstName")} ${this.cookieService.get("lastName")}`;
    this.accountType = this.cookieService.get("role");
    this.userEmail = this.cookieService.get("email");
    this.profilePic = this.cookieService.get("profilePic")
      ? this.cookieService.get("profilePic")
      : "images/user.png";

    // TODO remove this and data from the cookie
    const profileHeaders = {
      fullName: this.fullName,
      accountType: this.accountType,
      profilePic: this.profilePic,
      email: this.userEmail,
    };
    this.setUserProfile(profileHeaders);

    // TODO This should be moved the the startup service and the 5 minute time interval should
    // be in configuration
    // TODO Part 2 - This should really be handled by the cookie an not constantly pinging the server
    this.addIntervalTimertoCheckUserAccess(5);
  }

  public handleException(error: Response) {
    console.error(error);
    return Observable.throw(error.statusText || "Server Error");
  }

  public resetPassword(keycloakId: string): Observable<any> {
    const url = `${this._rivirKeycloakUrl}/resetPassword/${keycloakId}/${this.userEmail
      }`;
    return this._http.post(url, null, RivirHttp.getHttpOptions());
  }

  public saveUserProfile(
    userProfile: UserProfile,
    oldGroup = "",
    reloadUser = true,
  ) {

    const query = '{"email" : "' + userProfile.email + '"}';
    const executeUrl: string =
    this._userProfileUrl + "/update?where=" + query;

    // Update the user and the date
    userProfile.UpdatedDateTime = new Date();
    userProfile.UpdatedBy = this.cookieService.get("email");
    userProfile.CreatedBy = userProfile.CreatedBy
      ? userProfile.CreatedBy
      : userProfile.UpdatedBy;
    userProfile.CreatedDateTime = userProfile.CreatedDateTime
      ? userProfile.CreatedDateTime
      : new Date();

    // Delete properties which should not be saved to the database
    delete userProfile.token;
    delete userProfile.dayUntilExpiration;
    delete userProfile.id;



    this._http
      .post(executeUrl, userProfile, RivirHttp.getHttpOptions())
      .subscribe(() => {
        if (reloadUser) {
          this.loadUserProfile();
        }

        // Save user profile changes to workflow user as well
        this.workflowService
          .updateUser(userProfile, oldGroup)
          .subscribe(() => {
            const keyCloakUpdateUserUrl = `${this._rivirKeycloakUrl}/user/${userProfile.ssoId
              }`;
            this._http
              .post(keyCloakUpdateUserUrl, userProfile)
              .subscribe(() => {});
          });

      });
  }

  public loadUserProfile() {
    new Promise<void>((resolve) => {

      this.kc
        .loadUserProfile()
        .then()
        .then((profile) => {
          this.profile = profile;

          if (this.profile != null) {
            // we should check to see if the user profile exists from the sso agent
            // if it doesn't we should register this account
            // check the api to see if the email exists
            const filter: RivirAPIFilter = new RivirAPIFilter(null);
            filter.setFilterType("where");
            filter.addExpression({ email: this.profile.username });
            // if now continue and let angular routing work
            this.rivirApi
              .getRequestWithPath("userprofiles", filter)
              .pipe(catchError(RivirHttp.handleError))
              .subscribe((rivirResponse) => {
                const userProfiles = rivirResponse.getData();

                if (userProfiles != null && userProfiles.length == 1) {
                  // set attach the rivir profile to the keycloak profile
                  this.profile.rivirUserProfile = userProfiles[0].getJSONObject();

                  this.fullName = `${this.profile.rivirUserProfile.firstName} ${this.profile.rivirUserProfile.lastName
                    }`;
                  this.accountType = this.profile.rivirUserProfile.accountType;
                  this.userEmail = this.profile.rivirUserProfile.email;
                  this.profilePic = this.profile.rivirUserProfile.profilePicUrl
                    ? this.profile.rivirUserProfile.profilePicUrl
                    : "images/user.png";

                  // set the Cookie for the name and email address and role
                  // TODO the whole userprofile and keycloak object should be saved to the cookie instead of some properties
                  this.cookieService.set("email", this.profile.rivirUserProfile.email);
                  this.cookieService.set("name", this.profile.rivirUserProfile.name);
                  this.cookieService.set("firstName", this.profile.firstName);
                  this.cookieService.set("lastName", this.profile.lastName);
                  this.cookieService.set("role", this.profile.rivirUserProfile.accountType);
                  this.cookieService.set("userProfileId", this.profile.rivirUserProfile.id);


                  const getToken = this.kc.getToken();

                  getToken.then((token) => {
                    this.profile.password = token; // Not used

                    this.cookieService.set("userProfile", this.profile);

                    this.workflowService.loginUser(
                      this.profile.username,
                      this.profile.firstName,
                      this.profile.lastName,
                      this.profile.email,
                      token,
                      this.profile.accountType,
                    );
                  });
                } else {
                  const getToken = this.kc.getToken();

                  getToken.then((token) => {
                    this.profile.password = token;

                    this.cookieService.set("userProfile", this.profile);

                    // create a new user profile, defaulted with values
                    this.registerUser(
                      this.profile.id,
                      this.profile.password,
                      this.profile.username,
                      this.profile.firstName,
                      this.profile.lastName,
                    );
                  });
                }
                const profileHeaders = {
                  fullName: this.fullName,
                  accountType: this.accountType,
                  profilePic: this.profilePic,
                  email: this.userEmail
                };
                this.setUserProfile(profileHeaders);

                if (!this.timerStarted) {
                  this.addIntervalTimertoCheckUserAccess(5);
                  this.timerStarted = true;
                }

                resolve();
              });
          }
        })
        .catch((e) => resolve());
    });
  }

  public setUserProfile(state: any) {
    if (
      this.usersNameMap &&
      this.usersNameMap.length &&
      this.usersNameMap.find((x) => x.email == this.userEmail)
    ) {
      const i = this.usersNameMap.indexOf(
        this.usersNameMap.find((x) => x.email == this.userEmail),
      );
      this.usersNameMap.splice(i, 1); // Reset UserFullNameMap stored for the current user
    }
    this.userProfile.next(state);
  }

  public getUserProfile(): Observable<any> {
    return this.userProfile.asObservable();
  }

  public getUsersProfile(id: string): Observable<UserProfile> {
    const url: string = this._userProfileUrl + "/" + id;
    return this._http
      .get<UserProfile>(url, RivirHttp.getHttpOptions())
      .pipe(catchError(RivirHttp.handleError));
  }

  public resetUsersPasswordDates(
    username: string,
    updatedBy: string,
  ): Observable<any> {
    const initialDate = username ? new Date().toISOString() : "";
    username = username || "";
    const url = `${this._userProfileUrl}/resetPasswordDates`;
    const data = { initialDate, username, updatedBy };
    return this._http
      .post(url, data, RivirHttp.getHttpOptions())
      .pipe(catchError(RivirHttp.handleError));
  }

  /**
   * Gets all active User Profile
   */
  public getActiveUsers(): Observable<UserProfile[]> {
    const url: string =
      this._userProfileUrl + '?filter={ "where" : {"active" : true} }';
    return this._http
      .get<UserProfile[]>(url, RivirHttp.getHttpOptions())
      .pipe(catchError(RivirHttp.handleError));
  }

  /**
   * Gets all non system users
   */
  public getNonSystemUsers(): Observable<UserProfile[]> {
    const url: string =
      this._userProfileUrl + '/nonsystem?filter={ "where" : {"active" : true} }';
    return this._http
      .get<UserProfile[]>(url, RivirHttp.getHttpOptions())
      .pipe(catchError(RivirHttp.handleError));
  }

  public getProfilecompletePercent(profile): number {
    const fields = [
      "email",
      "username",
      "firstName",
      "lastName",
      "profilePicUrl",
      "accountType",
      "officeLocation",
      "organization",
      "cellPhoneNumber",
      "homePhoneNumber",
    ];
    let percent = 0;
    for (let i = 0; i < fields.length; i++) {
      if (
        profile &&
        profile[fields[i]] &&
        profile[fields[i]].indexOf("/user.png") == -1
      ) {
        percent = percent + 10;
      }
    }
    return percent;
  }

  public getUserProfileByEmail(filterValue: string): Observable<any> {
    const filter = `?filter={"where" : {"email" : "${filterValue}"}}`;
    const url: string = this._userProfileUrl + filter;
    return this._http
      .get<any>(url, RivirHttp.getHttpOptions())
      .pipe(catchError(RivirHttp.handleError));
  }

  public getGroups(groupName: string): Observable<any> {
    let url = `${this._userProfileUrl}/groups`;
    if (groupName) {
      url += `?groupName=${groupName}`;
    }
    return this._http
      .get<any>(url, RivirHttp.getHttpOptions())
      .pipe(catchError(RivirHttp.handleError));
  }

  public saveUsers(users: UserProfile[], updatedBy): Observable<any> {
    const url = `${this._userProfileUrl}/saveUsers`;
    return this._http
      .post(url, { users, updatedBy }, RivirHttp.getHttpOptions())
      .pipe(catchError(RivirHttp.handleError));
  }

  public getSuccessfulLogin(username: string): Observable<any> {
    const userLoginUrl = `${this.env.rivirApiBaseUrl}/securityAudit/userLogin?username=${username}`;

    return this._http
      .get(userLoginUrl, RivirHttp.getHttpOptions())
      .pipe(catchError(RivirHttp.handleError));
  }

  public addIntervalTimertoCheckUserAccess(minutes) {
    const milliseconds = minutes * 60 * 1000;
    const _timer = timer(1000, milliseconds);
    this.subscription = _timer.subscribe((ct) => {
      this.getUserProfileByEmail(this.userEmail).subscribe((res) => {
        if (res[0] && res[0].active == false) {
          this.kc.logout( "addIntervalTimertoCheckUserAccess" );
          this.subscription.unsubscribe();
        }
      });
    });
  }

  public registerUser(
    ssoId: string,
    token: string,
    email: string,
    firstname: string,
    lastname: string,
  ): void {
    const data = new UserProfile();
    data.active = false;
    data.email = email;
    data.name = firstname + " " + lastname;
    data.firstName = firstname;
    data.lastName = lastname;
    data.username = email;
    data.profilePicUrl = "./images/user.png";
    data.manager = "";
    data.ssoId = ssoId;
    data.token = token;
    data.accountType = "Reporter";
    data.CreatedBy = email;
    data.UpdatedBy = email;
    data.CreatedDateTime = new Date();
    data.UpdatedDateTime = new Date();

    // Default the date to 90 days from this date
    // TODO set the date to 90 days from now
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 90);
    data.passwordExpirationDate = currentDate;

    const parameters: RivirObject = new RivirObject(null);
    parameters.setData(data);

    this.rivirApi
      .postRequestWithPath("userprofiles", parameters)
      .pipe(catchError(RivirHttp.handleError))
      .subscribe((rivirResponse) => {
        if (rivirResponse.getStatusCode() == 200) {
          
          // save the email and id to the filesystem
          const userProfile = rivirResponse.getData();

          if (userProfile != null) {
            this.cookieService.set("email", userProfile.email);
            this.cookieService.set("user-id", userProfile.id);
          }
        } else {
          if (isDevMode()) {
            console.error(
              "An error occurred while creating new account. Status Code: " +
              rivirResponse.getStatusCode() +
              ", Error: " +
              rivirResponse.getMessage(),
            );
          }
        }
      });
  }

  public getUserFullNameFromEmail(userEmail) {
    return new Promise<NameMapObject>((resolve, reject) => {
      if (!userEmail) {
        resolve(null);
      } else if (userEmail == "System") {
        resolve({ email: userEmail, fullName: userEmail });
      } else {
        this.getUserProfileByEmail(userEmail).subscribe((res) => {
          const user = res[0] || res || null;
          const userFullName =
            user.name ||
            (user.firstName ? user.firstName + " " + user.lastName : null);
          const obj = {
            email: userEmail,
            fullName: userFullName ? userFullName : userEmail,
          };
          resolve(obj);
        });
      }
    });
  }

  public addUserEmailToUsersListing(userEmail) {
    if (this.usersListing.indexOf(userEmail) == -1) {
      this.usersListing.push(userEmail);
    }
  }

  public getusersNameMap() {
    return new Promise<any[]>((resolve, reject) => {
      const promises = [];
      this.usersListing.forEach((userEmail) => {
        const found = this.usersNameMap.find((x) => x.email == userEmail);
        if (!found) {
          promises.push(this.getUserFullNameFromEmail(userEmail));
        }
      });
      Promise.all(promises).then((res) => {
        for (let i = 0; i < res.length; i++) {
          this.usersNameMap.push(res[i]);
        }
        resolve(this.usersNameMap);
      });
    });
  }

  public getManagerStaffUsersCount() {
    const url = `${this._userProfileUrl}/count?where={"and" :[
      {"customManagerEmailAddress": "${this.userEmail}"},
      {"or" :[{"status": "Active"},{"status": "Inactive"},{"status": "Expired"}]}]}`;
    return this._http
      .get<any>(url, RivirHttp.getHttpOptions())
      .pipe(catchError(RivirHttp.handleError));
  }

  public getManagerStaffUsers() {
    const url = `${this._userProfileUrl}?filter={"where": {"and" :[
    {"customManagerEmailAddress": "${this.userEmail}"},
    {"or" :[{"status": "Active"},{"status": "Inactive"},{"status": "Expired"}]}]}}`;
    return this._http
      .get<any>(url, RivirHttp.getHttpOptions())
      .pipe(catchError(RivirHttp.handleError));
  }

}

export class NameMapObject {
  public email: string;
  public fullName: string;
}
