import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-json-diff-dialog',
  templateUrl: './json-diff-dialog.component.html',
  styleUrls: ['./json-diff-dialog.component.css']
})
export class JsonDiffDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  exportData( arrayToBeExported : any[] ) {
    // Get the columns
    let supportingDataCSV = "data:text/csv;charset=utf-8,";

    // Get all possible headers from data objects
    const dataColumns = Array.from(new Set(arrayToBeExported.flatMap(Object.keys)));

    supportingDataCSV += this.adjustCommasAndQuotes(dataColumns) + "\r\n";

    // Get the rows, adding apostrophes to values with leading zeros and not having decimals
    const dataRows = arrayToBeExported.map((sdata) => {
      Object.keys(sdata).forEach((item) => {
        let value = sdata[item];
        // Number found so continue
        if (!isNaN(value)) {
          if (value && value.toString()[0] === "0") {

            // No apostrophe if a single 0
            if (value.toString().length > 1) {
              const row = value.toString();
              let decimalFound = false;
              for (let i = 1; i < row.length; i++) {
                if (row[i] === ".") decimalFound = true;
              }
              // Decimal not found so add single apostrophe to beginning of value
              if (!decimalFound) sdata[item] = "'" + value;
            }
          }
        }
      })
      return sdata
    });

    const Adjusted_dataRows = [];
    for (let r = 0; r < dataRows.length; r++) {
      // Make sure the data order matches the column order
      let data = []
      for (let s = 0; s < dataColumns.length; s++) {
        data.push(dataRows[r][dataColumns[s]] || "")
      }
      Adjusted_dataRows.push(this.adjustCommasAndQuotes(data));
    }
  
    // Join to create rows
    supportingDataCSV += Adjusted_dataRows.join("\r\n");

    // Open the CSV in a new window
    const encodedUri = encodeURI(supportingDataCSV);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute(
      "download",
      `${this.data.taId}_${this.data.modelItemKey}.csv`,
    );
    document.body.appendChild(link); // Required for FF
    link.click();
  }

  public adjustCommasAndQuotes(data) {
    // Adjust any rowValue that have commas to cover it with quotes.
    const Adjusted_data = [];
    for (let i = 0; i < data.length; i++) {
      let rowValue = (data[i] !== undefined && data[i] !== null) ? data[i].toString() : '';
      if (rowValue && rowValue.indexOf('"') != -1) {
        rowValue = rowValue.replace(/"/gi, '""');
        rowValue = '"' + rowValue + '"';
      } else if (rowValue && rowValue.indexOf(',') != -1) {
        rowValue = '"' + rowValue + '"';
      }
      if (rowValue && rowValue.indexOf('#') != -1) {
        rowValue = rowValue.replace(/#/gi, '');
      }
      Adjusted_data.push(rowValue);
    }
    return Adjusted_data;
  }
}

export interface DialogData {
  modelItemKey : string
  modalItemOriginal : any[]
  modalItemUpdated : any[],
  modalOriginalIsArray : boolean,
  modalUpdatedIsArray : boolean,
  modalOriginalColumns : any[],
  modalUpdatedColumns : any[],
  showOneTable : boolean,
  taId : string,
}
