<div *ngIf="observableMetaData" class="modal-header {{wrapModal ? 'wrap-modal':''}}">
    <h2 class="hidden"></h2>
    <h3 class="modal-title pull-left">Edit Meta Data for Observable Data {{observableMetaData.name}}</h3>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body {{wrapModal ? 'wrap-modal':''}}">

    <div *ngIf="observableMetaData">
        <div *ngIf="message" class="alert alert-{{message.class}}" role="alert">
            <h4><b>{{message.status}}: </b> {{message.header}}</h4>
            <h5>{{message.desc}}</h5>
        </div>
        <div>
            <fieldset class="col-sm-10 m-l-md m-t-md" style="font-size: 15px;">
                <legend class="hidden">Edit Meta Data Form</legend>
                <div class="row form-group">
                    <label class="col-sm-4 control-label" for="variableName">Variable Name: </label>
                    <div class="col-sm-8">
                        <input class="form-control" id="variableName" type="text" [(ngModel)]="observableMetaData.name"
                            [attr.disabled]="true">
                    </div>
                </div>

                <div class="row form-group">
                    <label class="col-sm-4 control-label" for="threatActorType">{{label_threatActor}} Type </label>
                    <div class="col-sm-8">
                        <input class="form-control" id="threatActorType" type="text"
                            [(ngModel)]="observableMetaData.type" [attr.disabled]="true">
                    </div>
                </div>

                <div class="row form-group">
                    <label class="col-sm-4 control-label" for="description">Description:</label>
                    <div class="col-sm-8">
                        <input class="form-control" id="description" type="text"
                            [(ngModel)]="observableMetaData.description">
                    </div>
                </div>

                <div class="row form-group">
                    <label class="col-sm-4 control-label" for="helpText">Help Text:</label>
                    <div class="col-sm-8">
                        <input class="form-control" id="helpText" type="text" [(ngModel)]="observableMetaData.helpText">
                    </div>
                </div>

                <div class="row form-group">
                    <label class="col-sm-4 control-label" for="section">Section:</label>
                    <div class="col-sm-8">
                        <input class="form-control" id="section" type="text" [(ngModel)]="observableMetaData.section">
                    </div>
                </div>

                <div class="row form-group">
                    <label class="col-sm-4 control-label" for="order">Order:</label>
                    <div class="col-sm-8">
                        <input class="form-control" id="order" type="text" [(ngModel)]="observableMetaData.order">
                    </div>
                </div>

                <div class="row form-group">
                    <label class="col-sm-4 control-label" for="type">Field Type:</label>
                    <div class="col-sm-8">
                        <select class="form-control" [(ngModel)]="observableMetaData.metaDataType">
                            <option *ngFor="let mdType of metaDataTypes" [ngValue]="mdType.name">
                                {{ mdType.name }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="row form-group">
                    <div class="col-sm-4 control-label">Is Hidden:</div>
                    <div class="col-sm-8">
                        <label for="isHiddenYes" class="i-checks m-l-xs">
                            <input type="radio" [(ngModel)]="observableMetaData.hidden" id="isHiddenYes"
                                name="radioValue" [value]="true" /><i></i>Yes
                        </label>
                        <label for="isHiddenNo" class="i-checks m-l-md">
                            <input type="radio" [(ngModel)]="observableMetaData.hidden" id="isHiddenNo"
                                name="radioValue" [value]="false" /><i></i>No
                        </label>
                    </div>
                </div>

                <div class="row form-group">
                    <div class="col-sm-4 control-label">Is Searchable:</div>
                    <div class="col-sm-8">
                        <label for="isSearchableYes" class="i-checks m-l-xs">
                            <input type="radio" [(ngModel)]="observableMetaData.isSearchable" id="isSearchableYes"
                                name="radioValue2" [value]="true" /><i></i>Yes
                        </label>
                        <label for="isSearchableNo" class="i-checks m-l-md">
                            <input type="radio" [(ngModel)]="observableMetaData.isSearchable" id="isSearchableNo"
                                name="radioValue2" [value]="false" /><i></i>No
                        </label>
                    </div>
                </div>
            </fieldset>

            <fieldset class="col-sm-10 m-l-md m-t-md">
                <legend class="hidden">Edit Meta Data Submit</legend>
                <div class="row m-t-xl m-b-lg">
                    <div class="col-sm-3">
                        <button type="button" class="btn btn-secondary btn-algorithm"
                            (click)="modalRef.hide()">Cancel</button>
                    </div>
                    <div class="col-sm-3" style="float:right;">
                        <button type="button" class="btn btn-success btn-algorithm"
                            (click)="saveObservableMetada()">Save</button>
                    </div>
                </div>
            </fieldset>
        </div>
    </div>
</div>