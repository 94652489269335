<!-- insufficient values modal template -->

<div  class="modal fade" bsModal #insufficientValuesModal="bs-modal" [config]="{backdrop: 'static'}"
      tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Insufficient Values</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="insufficientValuesModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        A Nerdy Algorithm must have a Name, Source Code, and at least one Visualization to be valid.
        <br><br>
        Click <b>&times;</b> to continue.
      </div>
    </div>
  </div>
</div>

<div class="">
  <div  [style.display]="m_showAlert">
    <alert [type]="m_alertType">
        <strong>{{m_saveState}}</strong> {{m_statusMessage}}
    </alert>
  </div>
  <div style="position: relative; margin-left: 0px; margin-top: 2em; width: 100%; border-bottom: 1px solid #afafaf;">
    <input  type="text" 
                                [(ngModel)]="m_name"
                                placeholder="Algorithm Name"
                                (keyup)="onHandleTextKeyUp($event)"
                                style="border: 0; background: transparent; width: 100%;"/>
    <span class="fa fa-info-circle iconspan"></span>
  </div>
  <div style="position: relative; margin-left: 0px; margin-top: 2em; width: 100%; border-bottom: 1px solid #afafaf;">
    <input  type="text" 
                                [(ngModel)]="m_coaPath"
                                placeholder="Path to Course of Action"
                                (keyup)="onHandleTextKeyUp($event)"
                                style="border: 0; background: transparent; width: 100%;"/>
    <span class="fa fa-info-circle iconspan"></span>
  </div>
  <div style="position: relative; margin-left: 0px; margin-top: 2em; width: 100%; border-bottom: 1px solid #afafaf;">
    <input  type="text" 
                                [(ngModel)]="m_vizPath"
                                placeholder="Visualization Paths"
                                (keyup)="onHandleTextKeyUp($event)"
                                style="border: 0; background: transparent; width: 100%;"/>
    <span class="fa fa-info-circle iconspan"></span>
  </div>
  <div style="position: relative; margin-left: 0px; margin-top: 2em; width: 100%; border-bottom: 1px solid #afafaf;">
    <input  type="text" 
                                [(ngModel)]="m_inputDataPaths"
                                placeholder="Input Data Path"
                                (keyup)="onHandleTextKeyUp($event)"
                                style="border: 0; background: transparent; width: 100%;"/>
    <span class="fa fa-info-circle iconspan"></span>
  </div>
  <div style="position: relative; margin-left: 0px; margin-top: 2em; width: 100%; border-bottom: 1px solid #afafaf;">
    <input  type="text" 
                                [(ngModel)]="m_outputDataPaths"
                                placeholder="Output Data Path"
                                (keyup)="onHandleTextKeyUp($event)"
                                style="border: 0; background: transparent; width: 100%;"/>
    <span class="fa fa-info-circle iconspan"></span>
  </div>
  <div style="position: relative; margin-left: 0px; margin-top: 2em; width: 100%; border-bottom: 0px solid #afafaf;">  
    <div class="btn-group" dropdown>
      <button dropdownToggle type="button" class="btn btn-profile dropdown-toggle" style="border-color: #fff;">
        <span style="color: #ffffff;">{{m_classType}}</span>
      </button>
      <ul  class="dropdown-menu" role="menu">
        <li role="menuitem"><a class="dropdown-item" href="javascript:" (click)="onPersistentClassTypeSelected()">Persistent</a></li>
        <li role="menuitem"><a class="dropdown-item" href="javascript:" (click)="onTransientClassTypeSelected()">Transient</a></li>
      </ul>
    </div>
  </div>
  <div style="position: relative; margin-left: 0px; margin-top: 2em; width: 100%; border-bottom: 0px solid #afafaf;">
      <textarea cols="100" rows="5" [(ngModel)]="m_script"></textarea>
      <span class="fa fa-info-circle iconspan"></span>
  </div>
  <div style="margin-top: 44px;">
    <hr />
    <button type="button" class="btn btn-orange-button" style="width: 150px;"
            (click)="onSubmitButtonClicked($event)">
      {{m_submitButtonLabelText}}
    </button>
  </div>
</div>