import { Component, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import * as Survey from "survey-angular";
import * as SurveyEditor from "surveyjs-editor";

import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from "ngx-toastr";

import { StixConfigService } from "../shared/services/stixConfig.service";
import { SurveyService } from "../shared/services/survey.service";
import { TasksService } from "../tasks/tasks.service";
import { Subject } from "rxjs";
import { RivirHttp } from "../shared/services/rivir-http.service";

declare var $: any;

@Component({
  selector: "surveyjs-component",
  templateUrl: "./survey.component.html",
  styleUrls: ["./survey.component.css"],
})
export class SurveyComponent {
  public editor: SurveyEditor.SurveyEditor;
  public model: any;
  public surveyIsLoading: boolean;
  public instanceId: string;
  public currentTask: any;
  public currentUser: string;
  public oldTask: any;
  public pages: any;
  public failedToLoadSurvey: boolean;
  @Input() statusSubject: Subject<string> = new Subject<string>();

  constructor(
    private surveyService: SurveyService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router,
    private taskservice: TasksService,
    public stixConfig: StixConfigService,
    private cookieService: CookieService

  ) { }

  public ngOnInit() {
    try {
      this.currentUser = this.cookieService.get("email");
      this.route.params.subscribe(async (params) => {
        this.instanceId = params.instanceId;
        await this.getTaskSurvey(this.instanceId);
      });

      // Make sure this global flag is not set to true by default
      window["__rivirSkipTaskVariableUpdateOnSave"] = false;

      // Sets the status internal when the Pending button is selected
      // in the parent (task-details)
      this.statusSubject.subscribe(async (status) => {
        this.model.setValue('Status', status);
      });
    } catch (e) {
      this.failedToLoadSurvey = true;
    }
  }

  public async getTaskSurvey(instanceId: string): Promise<boolean> {
    
    this.surveyIsLoading = true;
    let { task, surveyJson } = await this.surveyService.getTask(instanceId);

    // Format the task and SurveyJson before sending to SurveyJs
    task = this.surveyService.setDefaultValuesForTask(task, surveyJson);
    surveyJson = this.surveyService.updateChoicesFromPreviousQuestions( task, surveyJson);

    // Save the current task as a session to be used but custom javascript
    // in surveyjs pages
    sessionStorage.setItem( "rivir_current_task", JSON.stringify(task) );
    sessionStorage.setItem( "rivir_access_token", RivirHttp.rivirApiToken)

    const isOldTask = this.oldTask && task.taskName === this.oldTask.taskName;

    if (!task) {
      this.failedToLoadSurvey = true;
    } else if (task && task.WorkFlowCompleted) {
      const index = this.router.url.indexOf("/task/");
      if (index !== -1) {
        let prevPath = this.router.url.substring(0, index);
        prevPath = prevPath ? decodeURI(decodeURI(prevPath)) : prevPath;
        this.router.navigate([prevPath]);
      }
    } else if (!isOldTask) {

      // Update the Details in the task details component
      const taskDetailsTask = {
        name: task.taskName,
        assignee: task.assignee,
        assigneeFullName: task.assigneeFullName,
        id: task.taskId,
      };
      this.taskservice.updateTask(taskDetailsTask);

      this.model = new Survey.ReactSurveyModel(surveyJson);
      this.model.onAfterRenderPage.add((survey, options) => {
        this.surveyIsLoading = false;
      });

      this.model.data = task;
      this.model.showCompletedPage = false;
      this.model.showNavigationButtons = false;
      this.model.showPageTitles = false;
      this.model.checkErrorsMode = "onComplete";
      this.model.clearInvisibleValues = "onHidden";
      this.currentTask = task;
      this.pages = this.model.pages;
      Survey.SurveyNG.render("surveyContainer", { model: this.model });
    }

    return task ? task.WorkFlowCompleted : false;
  }

  public async complete() {
    const isPageValid = !this.model.hasErrors(true, true);

    if (isPageValid) {
      this.surveyIsLoading = true;
      let modelData = this.surveyService.deDuplicateValues(this.model.data);

      // Always use the current logged user as the assignee 
      modelData.assignee = this.currentUser;
      modelData.assigneeFullName = `${this.cookieService.get("firstName")} ${this.cookieService.get("lastName")}`;

      const skipTaskVariableUpdateOnSave = window["__rivirSkipTaskVariableUpdateOnSave"];
      if ( skipTaskVariableUpdateOnSave ){
        modelData = null
        window["__rivirSkipTaskVariableUpdateOnSave"] = null;
      }

      await this.surveyService.completeTask(modelData,
        this.currentTask.taskId,
        this.instanceId,
        this.currentUser);
      this.oldTask = this.currentTask;

      let pollForNextTask = true;
      let pollRetries = 0;
      while (pollForNextTask) {
        await this.surveyService.timeout(1000);
        const isWorkflowComplete = await this.getTaskSurvey(this.instanceId);

        // if the workflow is not complete and the task names are the same
        // poll the api again
        pollForNextTask = !isWorkflowComplete && this.oldTask.taskName === this.currentTask.taskName;

        pollRetries++;
        if (pollRetries >= 10) {
          pollForNextTask = false;
          const index = this.router.url.indexOf("/task/");
          let prevPath = this.router.url.substring(0, index);
          prevPath = prevPath ? decodeURI(decodeURI(prevPath)) : prevPath;
          this.router.navigate([prevPath]);
        }

      }

      this.updateDiagram();
    }
  }

  public nextPage() {
    this.model.nextPage();
  }

  public prevPage() {
    this.model.prevPage();
  }

  public getPage($event, page) {
    $event.stopPropagation();
    $event.preventDefault();
    this.model.currentPageNo = page;
  }

  public async save() {
    try {
      await this.surveyService.saveTask(this.model.data, this.currentTask.taskId, this.instanceId, this.currentUser);
      this.toastr.info("Saved Successfully");
    } catch (err) {
      this.toastr.error("Unable to save task");
    }
  }

  public updateDiagram() {

    const task = this.currentTask;
    if (task && task.results && task.results.length > 0) {
      // get current active step
      const activeElement = $(`[data-element-id='${task.results[0].taskDefinitionKey}'] > .djs-visual > rect`);
      const prevRect = $(".activerect");
      const currRect = activeElement.parent().parent();
      // compare data-element-id of current step to previous step, update if changed
      if (prevRect.attr("data-element-id") !== currRect.attr("data-element-id")) {
        $(".activestep").attr("style", "stroke: black; stroke-width: 2px; fill: white; fill-opacity: 0.95;");
        activeElement.attr(
          "style", "fill:rgba(194,213,237,0.4) !important; stroke:#155cb5 !important; stroke-width:2px !important")
          .addClass("activestep");
        prevRect.removeClass("activerect");
        currRect.addClass("activerect");
      }
    }
  }
}

