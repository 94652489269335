import { Component, OnInit } from "@angular/core";

import tableau from "tableau-api-js";

import { UserProfileService } from "../core/userProfile.service";
import { TableauReport, TableauService } from "../core/tableau.service";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { Router } from '@angular/router';
import { StixConfigService } from "../shared/services/stixConfig.service";

@Component({
    selector: "app-mainview",
    templateUrl: "./mainview.component.html",
    styleUrls: ["./mainview.component.css"],
})
export class MainViewComponent implements OnInit {

    public dashboardSelected: TableauReport;
    public isLoading = true;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public viz: any;

    constructor(
        private userProfileService: UserProfileService,
        private tableauService: TableauService,
        public dialog: MatDialog,
        private router: Router,
        public stixConfig: StixConfigService
    ) {}

    public async ngOnInit() {

        this.dashboardSelected = await this.tableauService.getUserDashboard(this.userProfileService.userEmail);
        if ( this.dashboardSelected ){
          console.log(this.dashboardSelected);
            const containerDiv = document.getElementById("vizContainer");
            const options = { hideTabs: false, hideToolbar: false };
            this.viz = new tableau.Viz( containerDiv, this.dashboardSelected.url, options );
        }
        
        this.isLoading = false;
    }

    public removeDashboard() {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: {
              title: `Remove Dashboard: ${this.dashboardSelected.workbook} `,
              message: `Are you sure you would like to remove the workbook?`
            }
          });
    
          dialogRef.afterClosed().subscribe( async (confirmed) => {
    
            if (confirmed) {
                await this.tableauService.removeDashboard(this.userProfileService.userEmail);
                window.location.reload();
            }
          });
    }

    public changeDashboard() {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: {
              title: `Change Dashboard: ${this.dashboardSelected.workbook} `,
              message: `Are you sure you would like to change the workbook?`
            }
          });
    
          dialogRef.afterClosed().subscribe( async (confirmed) => {
    
            if (confirmed) {
                const navigationDetails: string[] = ['/tableau'];
                this.router.navigate(navigationDetails);
            }
          });
        
    }

    goToSearch(){
        const navigationDetails: string[] = ['/tableau'];
        this.router.navigate(navigationDetails);
    }

}