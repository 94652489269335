import { ExecutionResults } from "./execution-results";

export class IAlgorithm {
    public name: string;
    public description?: string;
    public version: number;
    public script: string;
    public decodedScript: string;
    public isEditMode: boolean;
    public isDraft: boolean;
    public requirements: string;
    public inputDataPath: string;
    public outputDataPath: string;
    public coa: string;
    public visualizations: string;
    public defaultView: string;
    public CreatedBy: string;
    public UpdatedBy: string;
    public CreatedDateTime: Date;
    public UpdatedDateTime: Date;
    public id: string;
    public message: string;
    public executionResults: ExecutionResults[];
    public coursesOfAction: string[];
    public selectedCoursesOfAction: object[];
    public autoStartCoursesOfAction: CourseOfActionAutoStart[];
    public classifications: string[];
    public types: string[];
    public coasDesc: string;
    public typesDesc: string;
    public classDesc: string;
    public canSave: boolean;
    public canExecute: boolean;
}

export class CourseOfActionAutoStart {
    // Model Properties
    public courseOfActions: string[] = [];
    public user = "";
    public daysOut = 0;
    public showRemovePrompt?: boolean;

    // View Model Properties
    public showCancelPrompt = false;
    public coaIsInvalid = false;
    public userIsInvalid = false;
    public dayOutIsInvalid = false;

    // Needs to be an Array to work with ng2-select even though it's singular
    public selectedUser: any[][];
    public selectedCourseOfAction: any[][];
    public coasDesc: string;

    public constructor(init?: Partial<CourseOfActionAutoStart>) {
        Object.assign(this, init);
    }
}
