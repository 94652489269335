import {
  HttpClient,
} from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { catchError } from 'rxjs/operators';


import { EnvService } from "../../app/env.service";
import { coa } from "../shared/models/coa";
import { RivirHttp } from "../shared/services/rivir-http.service";
import { StixConfigService } from "../shared/services/stixConfig.service";
import { PermissionService } from "../core/permission.service";
import { UserProfileService } from "../core/userProfile.service";
import { SearchService } from "../search/search.service";

@Injectable()
export class CoAService {
  public id;
  private _coursesOfActionsUrl: string;
  public tabs = [];
  public activeTab;
  public prevTab;

  public dateFormatFields: string[] = [];
  public critiria = {
    page: 1,
    count: 10,
    sort: null,
    filter: null,
  };
  public selected_COAs: string[] = [];

  constructor(
    private _http: HttpClient,
    private permissions: PermissionService,
    private userProfileService: UserProfileService,
    public stixConfig: StixConfigService,
    private searchService: SearchService,
    private env: EnvService
  ) { 
    this._coursesOfActionsUrl = `${this.env.rivirApiBaseUrl}/courseofactions`;
  }

  public getTabs() {
    return new Promise<any[]>((resolve, reject) => {
      if (this.tabs.length <= 0) {
        this.tabs.push({
          prop: "allCOAs",
          name: "All " + this.stixConfig.coa_plural,
        });
        this.permissions.getPermission("My Staff Page").then((permission) => {

          this.userProfileService.getManagerStaffUsersCount().subscribe((res) => {
            if (res && res.count > 0 && permission) {
              this.tabs.push({ prop: "myStaff", name: "My Staff" });
            }
            this.activeTab = this.tabs[0] || {};
            resolve(this.tabs);
          });
        });
      } else {
        resolve(this.tabs);
      }
    });
  }

  public getCoursesOfAction(): Observable<coa[]> {
    return this._http
      .get<coa[]>(this._coursesOfActionsUrl, RivirHttp.getHttpOptions())
      .pipe(catchError(RivirHttp.handleError));
  }

  public coaFilter(criteria: any, manager: string): Observable<any> {
    const url = this._coursesOfActionsUrl + "/filter";

    const wrapper = { criteria, manager };
    return this._http
      .post<any>(url, wrapper, RivirHttp.getHttpOptions())
      .pipe(catchError(RivirHttp.handleError));
  }

  public getUserStaffAggregateCOAs(staffList): Observable<any[]> {
    return this._http
      .get<coa[]>(
        this._coursesOfActionsUrl +
        "/getUserStaffAggregateCOAs/" +
        JSON.stringify(staffList),
        RivirHttp.getHttpOptions(),
      )
      .pipe(catchError(RivirHttp.handleError));
  }

  public getCoursesOfAction_activeOnly(): Observable<coa[]> {
    const url = `${this._coursesOfActionsUrl}?filter={"where" : {"workflowComplete" : "false"}}`;
    return this._http
      .get<coa[]>(url, RivirHttp.getHttpOptions())
      .pipe(catchError(RivirHttp.handleError));
  }

  public getCoursesOfActionById(id): Observable<coa> {
    return this._http
      .get<coa>(
        this._coursesOfActionsUrl + "/details/" + id,
        RivirHttp.getHttpOptions(),
      )
      .pipe(catchError(RivirHttp.handleError));
  }

  public getCoaNotes(id): Observable<coa> {
    return this._http
      .get<coa>(
        this._coursesOfActionsUrl + "/" + id + `?filter={"fields" : "notes"}`,
        RivirHttp.getHttpOptions(),
      )
      .pipe(catchError(RivirHttp.handleError));
  }

  public getCoursesOfActionByThreatActor(id): Observable<coa[]> {
    const url = `${this._coursesOfActionsUrl}?filter={"where" : {"threatActorId" : "${id}"}}`;
    return this._http
      .get<coa[]>(url, RivirHttp.getHttpOptions())
      .pipe(catchError(RivirHttp.handleError));
  }

  public getCoursesOfActionByThreatActor_Sighting(
    id,
    sightingName,
  ): Observable<coa[]> {
    const url = `${this._coursesOfActionsUrl}?filter={"where" : {"threatActorId" : "${id}", "sightingName" : "${sightingName}"}}`;
    return this._http
      .get<coa[]>(url, RivirHttp.getHttpOptions())
      .pipe(catchError(RivirHttp.handleError));
  }

  /**
   * Call REST method in the RIVIR-API to update the due date in the necessary tables and history
   * @param {string} instanceId Course of Action Identifier
   * @param {Date} coaDueDate New due date for course of action
   * @param {string} user User updating the Due Date
   */
  public updateDuedate(
    instanceId: string,
    coaDueDate: string,
    user: string,
  ): Observable<any> {
    const dueDatePostData = {
      instanceId,
      coaDueDate,
      user,
    };

    return this._http
      .post(
        this._coursesOfActionsUrl + `/duedate`,
        dueDatePostData,
        RivirHttp.getHttpOptions(),
      )
      .pipe(catchError(RivirHttp.handleError));
  }

  public updateCourseOfAction(instanceId: string, value): Observable<any> {
    return this._http
      .post(
        this._coursesOfActionsUrl +
        `/update?where={"instanceId":"${instanceId}"}`,
        value,
        RivirHttp.getHttpOptions(),
      )
      .pipe(catchError(RivirHttp.handleError));
  }

  public updateCoaAttribute(instanceId: string, notes): Observable<any> {
    let payload = { instanceId, notes };
    return this._http
      .post(
        this._coursesOfActionsUrl + "/survey/notes",
        { payload },
        RivirHttp.getHttpOptions(),
      )
      .pipe(catchError(RivirHttp.handleError));
  }

  public modifyFilter(prop, string) {
    if (!this.critiria.filter) {
      this.critiria.filter = {};
    }
    if (typeof string === "boolean") {
      this.critiria.filter[prop] = string;
    } else if (string) {
      this.critiria.filter[prop] = string.trim();
      if (
        this.dateFormatFields.indexOf(prop) != -1 &&
        new Date(string).toString() != "Invalid Date"
      ) {
        const dateString =
          prop === "coaDueDate"
            ? this.formatISODateWithoutLocalTime(string)
            : string;
        this.critiria.filter[prop] = this.searchService.filterInDateFormat(
          dateString,
        );
      }
    }
    if (this.critiria.filter[prop] && !string) {
      delete this.critiria.filter[prop];
    }

    if (Object.keys(this.critiria.filter).length < 1) {
      this.critiria.filter = null;
    }
  }

  public formatISODateWithoutLocalTime(string) {
    let dateISO = new Date(string).toISOString();
    const i = dateISO.indexOf("T");
    dateISO = dateISO.substring(0, i);
    dateISO = dateISO + "T00:00:00.000Z";
    return dateISO;
  }

  public resetCritiria() {
    this.critiria.page = 1;
    this.critiria.count = 10;
    this.critiria.sort = null;
    this.critiria.filter = null;
  }
}
