import { OnInit, Component } from "@angular/core";
import { ActivatedRoute } from '@angular/router';
import { StixConfigService } from "../../shared/services/stixConfig.service";
import { TableauService } from "../../core/tableau.service";
import tableau from "tableau-api-js";
import { IndicatorService } from "../indicators.service";

@Component({
  selector: "app-sighting-report",
  styleUrls: ["./sighting-report.component.css"],
  templateUrl: "./sighting-report.component.html",
})
export class SightingReportComponent implements OnInit  {

  public message = "No visualization available.";

  viz: any;
  public workBookError = false;

  constructor(
    public stixConfig: StixConfigService,
    private tableauService: TableauService,
    private indicatorService: IndicatorService,
    private route: ActivatedRoute) {

  }

  public ngOnInit() {
    this.route.params.subscribe(async (params) => 
    {
      if (params.visualizations  && params.id) {
        await this.loadWorkBook( params.visualizations, params.id);
      }
      else{
        console.error( 'Unable to retreieve Vizualization Name and Indicator Id from the url');
        this.workBookError = true;
      }
    });
  }

  public ngOnDestroy() {
    localStorage.removeItem("sightingreportname");
  }

  async loadWorkBook( visualizationName, indicatorId ) {
    try {

      // Get all workbooks and find the workbook we want by name
      const workbooks = await this.tableauService.getAllWorkBooks( visualizationName );
      const indicators = await this.indicatorService.getIndicatorDetail( indicatorId ).toPromise();
      const indicator = indicators && indicators.length ? indicators.pop() : {};

      if (workbooks && workbooks.length ) {
        const selectedWorkBook = workbooks.pop();

        if ( selectedWorkBook ) {

          let view = null;

          // Views can be a single object or array so force to array so we can filter
          const allViews = [].concat(selectedWorkBook.workbookViews.view[0].view);

          // Get the view by the default View
          if ( indicator.defaultView ){
            view = allViews.find( v => v.name === indicator.defaultView );
          }
          
          // If we can't find the default View for the indicator use the first view available
          if ( !view ){
              view = allViews[0];
          }
          
          // Get the view url by removing the '/sheets' from the content url 
          const viewUrl = view.contentUrl.replace('/sheets','');

            const workBookUrl = await this.tableauService.getWorkbookUrl( viewUrl );
            const containerDiv = document.getElementById("vizContainer");
            const options = { hideTabs: false, hideToolbar: false };
            this.viz = new tableau.Viz( containerDiv, workBookUrl, options );
          
        }
      }
      else{
        console.error( `Workbook ${visualizationName} was not found in Tableau` );
        this.workBookError = true;
      }
    }
    catch (ex) {
      console.error(ex);
      this.workBookError = true;
    }
  }
}
