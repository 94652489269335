
<div class="container-fluid">
    <div class="row">
        <rivir-header></rivir-header>
        <mat-toolbar style="background-color: #135A78; color:#FFF" fxShow="true" fxHide.gt-sm="true">
            <div>
              <button mat-icon-button (click)="sidenav.toggle()">
                <mat-icon>menu</mat-icon>
              </button>
            </div>
            <div style="width: 100%; text-align:center;">
                <img src="../assets/img/RIViR_logo_h_new.png" alt="RIViR Logo" class="header-image" />
            </div>
        </mat-toolbar>
        <mc-breadcrumbs></mc-breadcrumbs>

          <mat-sidenav-container>
        
            <mat-sidenav color="primary" #sidenav fxLayout="column" mode="over"  opened="false" fxHide.gt-sm="true">
              <div fxLayout="column">
                
                <button *ngIf="hasSearch" mat-button aria-label="Search" [routerLink]="['/search' ]" style="text-align:left;">
                    <mat-icon>search</mat-icon>
                    Search
                </button>
                <button *ngIf="hasDataEntry" mat-button aria-label="Data Entry" [routerLink]="['/data-entry' ]"  style="text-align:left;">
                    <mat-icon>control_point</mat-icon>
                    Data Entry
                </button>
                <button mat-button aria-label="Indicators" [routerLink]="['/indicators' ]"  style="text-align:left;">
                    <mat-icon>list</mat-icon>
                    Indicators
                </button>
                <button mat-button aria-label="Threat Actors" [routerLink]="['/threatActor' ]" style="text-align:left;">
                    <mat-icon fontSet="material-icons-outlined">person_search</mat-icon>
                    Threat Actors
                </button>
                <button *ngIf="hasCaseManagement" mat-button aria-label="Cases" [routerLink]="['/case/listing' ]"  style="text-align:left;">
                    <mat-icon fontSet="material-icons-outlined">folder_copy</mat-icon>
                    Cases
                </button>
                <button mat-button aria-label="Courses of Action" [routerLink]="['/coa' ]"  style="text-align:left;">
                    <mat-icon>format_list_numbered</mat-icon>
                    Courses of Action
                </button>
                <button *ngIf="hasReport" mat-button aria-label="Report" [routerLink]="['/report' ]"  style="text-align:left;">
                    <mat-icon>bar_chart</mat-icon>
                    Report
                </button>
              </div>
            </mat-sidenav>
            <mat-sidenav-content fxFlexFill class="app-margin-top">
                <router-outlet></router-outlet>
            </mat-sidenav-content>
          </mat-sidenav-container>
    </div>
</div>