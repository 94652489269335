export class EnvService {

  // Add any new properties from the environment.ts here also
  public production = null;
  public appKey = null;
  public envLogo = null;
  public searchBar = null;
  public reportBar = null;
  public bulkAssignment = null;
  public visualizationBaseUrl = null;
  public visualizationApiBaseUrl = null;
  public visualizationAuthApiBaseUrl = null;
  public rivirApiBaseUrl = null;
  public workflowBaseUrl = null;
  public keycloak = null;
  public uploadContainer = null;
  public courseOfActionStatus = null;
  public workflowClosedStatus = null;
  public redirectDashboard = null;
  public showReportExport = null;
  public reportSuffixes = null;
  public sessionIdleTimeout = null;
  public hasCaseManagement = null;
  public hasDataEntry = null;
  public caseStatusCustomOrder = null;
  public coaAttachmentAllowedFileTypes = null;
  public coaAttachmentAllowedMaxSize = null;
  public associatedDocumentsDescriptionRequired = null;
  public tableau = null;
  public tableauServer = null;
  public useMayan = null;
  

  constructor() {
  }

}