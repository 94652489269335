import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';

@Component({
  selector: "app-map-view",
  templateUrl: "./map-view.component.html",
  styleUrls: ["./map-view.component.css"],
})
export class MapViewComponent implements OnInit {
  // TODO the Google Maps API key should come from configuration
  googleApiKey: string = 'AIzaSyD6iBcgnWH_hhu7Cr2I2Npsa5LODIoVUDI';
  googleMapsUrl: string = `https://maps.googleapis.com/maps/api/js?key=${this.googleApiKey}`;
  googleMapsGeoCodeUrl: string = `https://maps.googleapis.com/maps/api/geocode/json?address=`;
  googleSearchUrl: string = 'https://www.google.com/maps/search/?api=1&query=';
  googleRedirectUrl: string = "";
  apiLoaded: Observable<boolean>;

  @Input() public name: string;
  @Input() public address: string;

  //@ts-ignore
  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;
  center: google.maps.LatLngLiteral = { lat: 24, lng: 12 };
  markerOptions: google.maps.MarkerOptions = { draggable: false };
  zoom = 15;

  constructor(private httpClient: HttpClient) {
    this.apiLoaded = this.httpClient.jsonp(this.googleMapsUrl, 'callback')
      .pipe(
        map(() => true),
        catchError(() => of(false)),
      );
  }

  public async ngOnInit() {

    // Get the coordinates from the address
    const formattedAddress = this.address.replace(/\s+/g, "+");
    const geoCodeResponse = await this.getGeoCordinates(formattedAddress).toPromise();
    this.center = geoCodeResponse.results[0].geometry.location;

    // Set the link to open up Google Maps in a new tab
    this.googleRedirectUrl = this.googleSearchUrl + formattedAddress;

  }

  public getGeoCordinates(address: string): Observable<any> {
    const coordinatesUrl =
      `${this.googleMapsGeoCodeUrl}${address.replace(/\s+/g, "+")}&key=${this.googleApiKey}`;
    return this.httpClient.get<any>(coordinatesUrl);
  }

  public openInfoWindow(marker: MapMarker) {
    this.infoWindow.open(marker);
  }

}