<div class="container-fluid" class="wizard" *ngIf="model">
    <indicator-wiz-navigation [stepNumber]="5"  [parentLocalSaveFunction]="saveLocalDataToModel"></indicator-wiz-navigation>
    <div class="row">
        <div class="col-sm-12 text-center">
            <label class="header-label" for="description">
                Review entries.  Change as needed.  Click on the 'Save' or 'Execute' button to proceed.
            </label>
        </div>
        <div class="col-md-4 review-col">
            <div class="row review-header-row">
                <div class="col-md-8 review-header" >
                    SCRIPT
                </div>
                <div class="col-md-4 review-edit-btn">
                    <button mat-button aria-label="Edit Review" (click)="edit(0)" >
                        <mat-icon>edit</mat-icon>
                        <span>Edit</span>
                    </button>
                </div>
            </div>
            <div class="row review-row" *ngIf="algorithm.name">
                <div class="col-md-4  review-label">Name: </div>
                <div class="col-md-8">{{algorithm.name}}</div>
            </div>
            <div class="row review-row" *ngIf="algorithm.name">
                <div class="col-md-4  review-label">Version: </div>
                <div class="col-md-8">{{ indicatorHistory && indicatorHistory.length ?  indicatorHistory[ 0 ].version  : 1 }} </div>
            </div>
            <div class="row review-row" *ngIf="!algorithm.name">
                <div class="col-md-12 review-invalid-field">
                    Name is blank
                </div>
            </div>
            <div class="row review-row" *ngIf="!algorithm.script || ( algorithm.script && !algorithm.script.trim())">
                <div class="col-md-12 review-invalid-field">
                    Script is blank
                </div>
            </div>
            <div class="row review-header-row">
                <div class="col-md-8 review-header" >
                    DETAILS
                </div>
                <div class="col-md-4 review-edit-btn">
                    <button mat-button aria-label="Edit Review" (click)="edit(1)" >
                        <mat-icon>edit</mat-icon>
                        <span>Edit</span>
                    </button>
                </div>
            </div>
            <div class="row review-row" *ngIf="algorithm.description">
                <div class="col-md-4  review-label">Description: </div>
                <div class="col-md-8">{{algorithm.description}}</div>
            </div>
            <div class="row review-row" *ngIf="!algorithm.description || ( algorithm.description && !algorithm.description.trim())">
                <div class="col-md-12 review-invalid-field">
                    Description is blank
                </div>
            </div>
            <div class="row review-row" *ngIf="algorithm.classDesc">
                <div class="col-md-4  review-label">Classification: </div>
                <div class="col-md-8">{{algorithm.classDesc}}</div>
            </div>
            <div class="row review-row" *ngIf="!algorithm.classDesc">
                <div class="col-md-12 review-invalid-field">
                    No Classifications have been selected
                </div>
            </div>
            <div class="row review-row">
                <div class="col-md-4  review-label">Type: </div>
                <div class="col-md-8">{{algorithm.typesDesc}}</div>
            </div>
            <div class="row review-header-row">
                <div class="col-md-8 review-header" >
                    {{stixConfig.visualization_plural.toUpperCase()}}
                </div>
                <div class="col-md-4 review-edit-btn">
                    <button mat-button aria-label="Edit Review" (click)="edit(2)" >
                        <mat-icon>edit</mat-icon>
                        <span>Edit</span>
                    </button>
                </div>
            </div>
            <div *ngIf="algorithm.visualizations" class="row review-row">
                <div class="col-md-4  review-label">Workbook: </div>
                <div class="col-md-8">{{algorithm.visualizations}} <span *ngIf="algorithm.defaultView"> - {{algorithm.defaultView}}</span></div>
            </div>
            <div *ngIf="!algorithm.visualizations" class="row review-row">
                <div class="col-md-12 review-label">No {{stixConfig.visualization_plural}} selected</div>
            </div>
        </div>
        <div class="col-md-4 review-col">
            <div class="row review-header-row">
                <div class="col-md-8 review-header" >
                    {{ label_coa.toUpperCase() }}
                </div>
                <div class="col-md-4 review-edit-btn">
                    <button mat-button aria-label="Edit Review" (click)="edit(3)" >
                        <mat-icon>edit</mat-icon>
                        <span>Edit</span>
                    </button>
                </div>
            </div>
            <div *ngIf="algorithm.coasDesc" class="row review-row">
                <div class="col-md-4 review-label">{{label_coa}}: </div>
                <div class="col-md-8">{{algorithm.coasDesc}}</div>
            </div>
            <div *ngIf="!algorithm.coasDesc" class="row review-row">
                <div class="col-md-12 review-label">No {{label_coa}} selected</div>
            </div>
            <div class="row review-header-row" style="padding-top: 20px;">
                <div class="col-md-8 review-header" >
                    AUTOSTART {{ label_coas.toUpperCase() }}
                </div>
                <div class="col-md-4 review-edit-btn">
                    <button mat-button aria-label="Edit Review" (click)="edit(4)" >
                        <mat-icon>edit</mat-icon>
                        <span>Edit</span>
                    </button>
                </div>
            </div>
            <div class="col-md-12 review-label" *ngIf="!algorithm.autoStartCoursesOfAction || algorithm.autoStartCoursesOfAction.length === 0">
                No Autostart {{label_coa}} selected
            </div>
            <ng-container *ngIf="algorithm.autoStartCoursesOfAction && algorithm.autoStartCoursesOfAction.length !== 0">
                <div *ngFor="let autostart of algorithm.autoStartCoursesOfAction; let last = last" [ngClass]="last ? 'row' : 'row review-autostart-row'">
                    <div class="row review-row">
                        <div class="col-md-4 review-label">{{label_coa}}: </div>
                        <div class="col-md-8">{{autostart.coasDesc}}</div>
                    </div>
                    <div class="row review-row">
                        <div class="col-md-4 review-label">Assigned To:</div>
                        <div class="col-md-8" *ngIf="autostart.user">{{autostart.user}}</div> 
                        <div class="col-md-8 review-invalid-field" *ngIf="!autostart.user">No User Selected</div> 
                    </div>
                    <div class="row review-row">
                        <div class="col-md-4 review-label">Due In (Days):</div>
                        <div class="col-md-8" *ngIf="autostart.daysOut">{{autostart.daysOut}}</div> 
                        <div class="col-md-8 review-invalid-field" *ngIf="!autostart.daysOut">Invalid Due In (Days)</div> 
                    </div>
                </div>
            </ng-container>
        </div>
        <div  class="col-md-4 review-col">
            <div class="row" style="padding-bottom: 10px;">
                <div class="col-md-8 review-header" >
                    VERSIONS
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 version-title">Last Saved</div>
                <div class="col-md-5 version-title">By</div>
                <div class="col-md-1 version-title"><span >Version</span></div>
                <div class="col-md-1"></div>
            </div>
            <div class="row" *ngFor="let indicatorVersion of indicatorHistory">
                <div class="col-md-4 version-row"> {{ indicatorVersion.UpdatedDateTime | date: 'short' }}</div>
                <div class="col-md-5 version-row"> {{ indicatorVersion.UpdatedBy }}</div>
                <div class="col-md-1 version-row"> {{ indicatorVersion.version }}</div>
                <div class="col-md-1"> <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="More Options for Version">
                    <mat-icon style="font-size: 20px;">more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="download( indicatorVersion )">
                      <mat-icon style="font-size: 25px;">file_download</mat-icon>
                      <span>Download Python Script</span>
                    </button>
                  </mat-menu> </div>
            </div>
        </div>
    </div>
</div>