import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'indicator-wiz-new-item-dialog',
  templateUrl: './indicator-wiz-new-item-dialog.component.html',
  styleUrls: ['./indicator-wiz-new-item-dialog.component.css']
})
export class IndicatorWizNewItemDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: IndicatorWizardDialogData) { }
}

export interface IndicatorWizardDialogData{
  title: string;
  placeholder: string;
  item: string;
}