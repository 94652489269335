<table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort matSortDisableClear matSortActive="coaDueDate" matSortDirection="desc" style="width:100%">
    <!-- Course of Action ID Column -->
    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            ID
        </th>
        <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="!element.workflowComplete">
                <a routerLink="/coa/task/{{element.instanceId}}" class="cases-coas-link">
                    {{ element.instanceUIId }}</a>
            </ng-container>
            <ng-container *ngIf="element.workflowComplete">
                {{ element.instanceUIId }}
            </ng-container>
        </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="courseOfActionName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Name
        </th>
        <td mat-cell *matCellDef="let element">
            {{ element.courseOfActionName }}
        </td>
    </ng-container>

    <!-- Due Date Column -->
    <ng-container matColumnDef="coaDueDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Due Date
        </th>
        <td mat-cell *matCellDef="let element">
            {{ element.coaDueDate }}
        </td>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Status
        </th>
        <td mat-cell *matCellDef="let element">
            {{ element.status }}
        </td>
    </ng-container>

    <!-- Task Column -->
    <ng-container matColumnDef="task">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Task
        </th>
        <td mat-cell *matCellDef="let element">
            {{ element.task }}
        </td>
    </ng-container>

    <!-- UpdatedBy Column -->
    <ng-container matColumnDef="UpdatedBy">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Assignee
        </th>
        <td mat-cell *matCellDef="let element">
            {{ element.UpdatedBy }}
        </td>
    </ng-container>

    <!-- Indicator Name Column -->
    <ng-container matColumnDef="indicatorName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{stixConfig.indicator_singular}} Name
        </th>
        <td mat-cell *matCellDef="let element">
            <a routerLink="/indicators/{{element.indicatorName}}" class="cases-coas-link">
                {{ element.indicatorName }}</a>
        </td>
    </ng-container>

    <!-- SightingCreatedDateTime Column -->
    <ng-container matColumnDef="sightingCreatedDateTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{stixConfig.sighting_singular}}
        </th>
        <td mat-cell *matCellDef="let element">
            <a routerLink="/indicators/{{element.indicatorName}}/sighting/{{element.sightingName}}"
                class="cases-coas-link">
                {{ element.sightingCreatedDateTime }}</a>

        </td>
    </ng-container>


    <ng-container matColumnDef="taId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Un-link
        </th>
        <td mat-cell *matCellDef="let element">
            <a mat-button (click)="openUnlinkDialog(element, $event)" [disabled]="courseOfActions.length === 1">
                <mat-icon matSuffix>link_off</mat-icon>
            </a>

        </td>
    </ng-container>


    <!-- RIViR Top Header -->
    <ng-container matColumnDef="rivir-top-header">
        <th mat-header-cell *matHeaderCellDef [attr.colspan]="9" class="rivir-mat-top-header">
            <div class="row">
                <div class="col-md-10" style="color:#fcfcfc; font-size:17px;">
                    List of {{stixConfig.coa_plural}}
                </div>
                <div class="col-md-2">
                </div>
            </div>
        </th>
    </ng-container>

    <!-- RIViR Search Header -->
    <ng-container matColumnDef="rivir-search-header">
        <th mat-header-cell *matHeaderCellDef [attr.colspan]="10">
            <div class="row">
                <div class="col-md-7"></div>
                <div class="col-md-1 rivir-mat-search-label">
                    Filter:
                </div>
                <div class="col-md-2">
                    <input #input type="search" class="form-control" title="Filter">
                </div>
                <div class="col-md-1">
                    <button class="btn btn-primary" (click)="redirectToCaseEdit()">Link
                        {{stixConfig.coa_plural}}</button>
                </div>
            </div>
        </th>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="['rivir-top-header']">
    </tr>
    <tr mat-header-row *matHeaderRowDef="['rivir-search-header']">
    </tr>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index"
        [ngClass]="i % 2 ? 'rivir-mat-table-row-even' : 'rivir-mat-table-row-odd'"></tr>
</table>

<mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 50]">
</mat-paginator>