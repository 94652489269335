import { Component, Inject } from '@angular/core';
import { FormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CookieService } from 'ngx-cookie-service';
import { EnvService } from '../env.service';
import { MayanDocumentService } from '../shared/services/mayan.document.service';
import { TasksService } from '../tasks/tasks.service';

@Component({
  selector: 'coa-file-upload-dialog',
  templateUrl: './coa-file-upload-dialog.component.html',
  styleUrls: ['./coa-file-upload-dialog.component.css']
})
export class CoaFileUploadDialogComponent {

  public taskId: string;
  public fileToUpload: File = null;
  public uploadControl = new FormControl();
  public uploadFileName = "";
  public uploadDescription = "";
  public instanceId = "";
  public errorMessage;
  public isUploading = false;
  public mayanFolder;
  public error;
  public hideUploadButton = true;

  constructor(public dialogRef: MatDialogRef<CoaFileUploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CoaFileUploadDialogData,
    private mayanDocumentService: MayanDocumentService,
    private env: EnvService,
    private cookieService: CookieService,
    private taskService: TasksService) {
      this.instanceId = window.location.href.split('task/').pop();

      // Temporarily hide the Upload button until the folder is available
      if ( this.env.useMayan ){
        let uploadIntervalId = null; 
        uploadIntervalId = setInterval(() => {
          if (mayanDocumentService.folder != null){
            this.hideUploadButton = false;
            clearInterval(uploadIntervalId);
          }
        }, 1000);
      }
      else {
        this.hideUploadButton = false;
      }
     }

  public fileSelected(fileInput) {

    const file = fileInput.target.files[0];
    const fileSize: number = file.size;
    const fileExtension: string = file.name.split(".").pop().toLowerCase();
    this.errorMessage = null;

    if (fileInput.target.files.length > 0) {
      if (!this.mayanDocumentService.allowedFileTypes.includes(fileExtension)) {
        this.errorMessage = `File extension "${fileExtension}" is not permitted.`;
      } else if (fileSize <= this.mayanDocumentService.docMaxSize) {

        if (fileInput.target.files && fileInput.target.files[0]) {
          // update file and metadata
          this.fileToUpload = fileInput.target.files[0];
          this.uploadFileName = fileInput.target.files[0].name;
        }
      } else {
        const maxSizeInMB = Math.floor(this.env.coaAttachmentAllowedMaxSize / 1024 / 1024);
        this.errorMessage = `Uploaded file cannot be larger than ${maxSizeInMB} MB`;
      }

      if ( this.data.doc && (this.data.doc.name !== this.fileToUpload.name) ){
        this.errorMessage = 'File name does not match.  Please upload a file with the same name';
      }
      
    } else {
      this.errorMessage = "Uploaded file corrupted or length is zero.";
    }

  }

  public async fileUpload() {


    let requiredMessage = null;
    if (!this.fileToUpload) {
      requiredMessage = "Please upload a file";
    }

    const hasRequiredDescription = this.env.associatedDocumentsDescriptionRequired ? this.uploadDescription : true;
    if (!hasRequiredDescription) {
      requiredMessage = requiredMessage ? `${requiredMessage} and enter a description.` : `Please enter a description`;
    }

    if (requiredMessage) {
      this.errorMessage = requiredMessage;
    } else {
      // save document
      this.isUploading = true;
      
      const response = await this.mayanDocumentService.saveDocument(this.data.doc ? this.data.doc.name : this.fileToUpload.name, 
                                                   this.mayanFolder ? this.mayanFolder.id : this.mayanDocumentService?.folder?.id, 
                                                   this.fileToUpload, 
                                                   this.uploadDescription, 
                                                   this.cookieService.get("email"), 
                                                   `${this.cookieService.get("firstName")} ${this.cookieService.get("lastName")}`,
                                                   this.data.task.name, 
                                                   this.data.doc ? this.data.doc.id : '',
                                                   this.instanceId);

      if ( !response.error ) {

        // Send message to task-documents to refresh because file has been uploaded
        this.taskService.updateAttachments(this.fileToUpload.name);
  
        // clear file
        this.fileToUpload = null;
        this.dialogRef.close();
      }
      else {
        this.error = response.error;
      }

    }
  }

  public closeDialog() {
    
    this.dialogRef.close();
  }
}



export interface CoaFileUploadDialogData {
  task: any,
  doc: any
}