import { Component, OnInit } from "@angular/core";
import { ExecutionResults } from "./execution-results";
import { IAlgorithm } from "./nerdy-algorithm-list";
import { AlgorithmService } from "./nerdy-algorithm-list.service";

@Component({
    selector: "app-nerdy-algorithm-list",
    templateUrl: "./nerdy-algorithm-list.component.html",
    styleUrls: ["./nerdy-algorithm-list.component.css"],
})
export class NerdyAlgorithmListComponent implements OnInit {
    public pageTitle = "Algorithm List";
    public errorMessage: string;
    public algorithms: IAlgorithm[] = [];
    public originalAlgorithmList: IAlgorithm[] = [];
    public selectedAlgorithm: IAlgorithm;
    public ascending: boolean;
    public searchFilter = "";

    constructor(private _algorithmService: AlgorithmService) {

    }

    public resultsSort(a: ExecutionResults, b: ExecutionResults) {
        return b.id - a.id;
    }

    public onSelect(algorithm: IAlgorithm): void {
        this._algorithmService.getExecutionResults(algorithm)
            .subscribe((execResults) => {
                execResults.sort(this.resultsSort);

                for (const er of execResults) {
                    er.output = er.output.replace(/\n/g, "<br />");
                }

                algorithm.decodedScript = decodeURIComponent(algorithm.script);
                algorithm.executionResults = execResults;

                this.selectedAlgorithm = algorithm;
            }, (error) => this.errorMessage = error as any);
    }

    public createNewAlgorithm(): void {
        // this.router.navigate(["/newalgorithm"]);
        const newAlgorithm = new IAlgorithm();
        newAlgorithm.isEditMode = true;
        newAlgorithm.version = 0;
        newAlgorithm.CreatedBy = "testUser";
        newAlgorithm.CreatedDateTime = new Date();
        newAlgorithm.isDraft = true;
        this.algorithms.push(newAlgorithm);
        this.selectedAlgorithm = this.algorithms[this.algorithms.length - 1];
    }
    public filterAlgorithms(searchTerm): void {
        if (searchTerm) {
            this.algorithms = this.originalAlgorithmList.filter((algo) => algo.name.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) > -1);
        } else {
            this.algorithms = this.originalAlgorithmList;
        }

    }
    public sortAlgorithms(): void {
        if (this.ascending) {
            this.algorithms = this.algorithms.sort((a, b) => a.name.localeCompare(b.name));
        } else {
            this.algorithms = this.algorithms.sort((a, b) => b.name.localeCompare(a.name));
        }

        this.ascending = !this.ascending;
    }

    public ngOnInit(): void {
        this._algorithmService.getAlgorithms()
            .subscribe((algorithms) => {

                // TODO Refactor!! possibly server side
                for (const algo of algorithms) {
                    const existingAlgo = this.algorithms.find((x) => x.name == algo.name);

                    if (!existingAlgo) {
                        this.algorithms.push(algo);
                    } else {
                        if (existingAlgo.version < algo.version) {
                            this.algorithms = this.algorithms.filter((obj) => obj !== existingAlgo);
                            this.algorithms.push(algo);
                        }
                    }

                }

                this.algorithms.sort((a, b) => a.name.localeCompare(b.name));
                this.ascending = false;
                this.originalAlgorithmList = this.algorithms;
            },
                (error) => this.errorMessage = error as any);
    }

}
