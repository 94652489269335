export const TABLE_COLUMNS_TASKS =
[
    { prop: "instance_Id", name: "coa_singular ID" },
    { prop: "courseOfActionName", name: "coa_singular Name" },
    { prop: "threatActorName", name: "threatActor_singular Name" },
    { prop: "name", name: "Current Task" },
    { prop: "assignee", name: "Assigned To" },
    { prop: "UpdatedDateTime", name: "Last Updated" },
    { prop: "viewHistory", name: "coa_singular History" },
  ];
