import { Injectable } from "@angular/core";
import { RolePermission } from "../shared/models/rolePermission";
import { SightingService } from "../shared/services/sighting.service";
import { UserProfileService } from "./userProfile.service";

@Injectable()
export class PermissionService {

  public userType: any;
  public userPermissions: RolePermission[] = [];

  constructor(private userService: UserProfileService, private sightingService: SightingService) { }

  public getPermission(permission): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {

      if (this.userPermissions.length > 0) {
        resolve(this.getAPermission(permission));
      } else {

        if (!this.userService.accountType) {
          this.userService.getUserProfile().subscribe((user) => {
            this.getUserPermissions(user.accountType, permission).then((perms) => {
              this.userPermissions = perms;
              resolve(this.getAPermission(permission));
            });
          });
        } else {
          this.getUserPermissions(this.userService.accountType, permission).then((perms) => {
            this.userPermissions = perms;
            resolve(this.getAPermission(permission));
          });
        }
      }
    });
  }

  public getUserPermissions(role, permissionName) {
    return new Promise<RolePermission[]>((resolve, reject) => {
      this.sightingService.getPermissions(role, true)
        .subscribe((userPerms) => { resolve(userPerms); });
    });
  }

  public getAPermission(permissionName) {
    const permission = this.userPermissions.find((p) => p.page === permissionName) || null;
    return permission ? permission.access == "Yes" ? true : false : false;
  }

}
