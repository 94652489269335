import { Component, Input, OnInit } from "@angular/core";
import { UserProfileService } from '../../core/userProfile.service';
import { ToastrService } from "ngx-toastr";
import { CoAService } from "../../coa/coa.service";
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { coa } from "../../shared/models/coa";
import { WorkflowService } from "../../shared/services/workflow.service";
import { formatDate } from '@angular/common';

@Component({
  selector: "app-task-note",
  templateUrl: "./task-notes.component.html",
  styleUrls: ["./task-notes.component.css"],
})
export class TaskNoteComponent implements OnInit {
  public coaInstanceId: string;
  public coa: coa;
  public uploadDescription = "";
  public taskName: string;
  public notesArray= [];
  public isCollapsed = false;
  public toggleNote = {};
  public activeUsers: any;
  public allNotes = [];
  public showMorebtn: boolean;
  @Input() isHidden: boolean;
  @Input() instanceId: string;
  public notesTitle: string = "Notes";

  constructor(
    private userService: UserProfileService,
    public toastr: ToastrService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private coaService: CoAService,
    private workflowService: WorkflowService

  ) {
    this.toggleNote = {};
  }

  public ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.coaInstanceId = params.instanceId;
      this.userService.getActiveUsers().subscribe((users) => {
        this.activeUsers = users;
        this.loadNotes(false);
      });
    });
  }

  public saveNote() {
    if (this.uploadDescription === "") {
      this.toastr.error("Please enter a note.");
    }
    else {
      this.coaService.getCoaNotes(this.coaInstanceId)
        .subscribe((res: any) => {
          this.workflowService.getTask(this.coaInstanceId).subscribe((task) => {
            const notesArray = res.notes && res.notes.length ? [].concat( res.notes) : [];

            notesArray.push( {
              note: this.removeTags(this.uploadDescription),
              CreatedBy: this.userService.userEmail,
              DateTimeCreated: new Date(),
              Task: task.results[0].name
            });

            this.coaService.updateCoaAttribute(this.coaInstanceId, notesArray).subscribe((res: any) => {
              this.toastr.success("Note added successfully.");
              this.uploadDescription = "";
              this.loadNotes(true);
            });
          })
        });
    }
  }

  public cancelNote() {
    this.uploadDescription = "";
  }

  public loadNotes(noteAdded?: boolean) {
    // Use parent instanceId if undefined
    if (this.coaInstanceId === undefined){
      this.coaInstanceId = this.instanceId; 
    }
    this.notesArray.length = 0;
    this.coaService.getCoaNotes(this.coaInstanceId)
      .subscribe((res: any) => {
        if (res && res.notes) {
          res.notes.forEach(el => {
            let user = this.activeUsers.find(u => u.email === el.CreatedBy);
            el.DateTimeCreated = formatDate(el.DateTimeCreated, 'MM-dd-yyyy hh:mm a', 'en-US');
            el.CreatedBy = user ? user.name : "";
            el.note = el.note.replace(/(\r\n|\n|\r)/g, "<br />");
            this.notesArray.push(el);
          });
        }
        this.notesArray = this.notesArray.sort((a, b) => {
          return new Date(b.DateTimeCreated).getTime() - new Date(a.DateTimeCreated).getTime();
        });
        this.allNotes = this.notesArray;
        if (this.allNotes.length === 0){
          this.notesTitle = "No Notes";
        }
        if (this.notesArray.length > 9) {
          this.notesArray = this.notesArray.slice(0, 10);
          this.showMorebtn = true;
        }
        else {
          this.showMorebtn = false;
        }
      })
  }

  public loadAllNotes() {
    this.notesArray = this.allNotes;
    this.showMorebtn = false;
  }

  /**
   * Remove HTML tags from a string 
   * referenced article https://www.tutorialspoint.com/how-to-remove-html-tags-from-a-string-in-javascript
   * @param str Unsafe string with HTML
   */
  public removeTags(str: string) {
    if ((str === null) || (str === ''))
      return false;
    else
      str = str.toString();
    return str.replace(/(<([^>]+)>)/ig, '');
  }

}
