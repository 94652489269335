
import { TaskDetailsComponent } from "../tasks/task-details/task-details.component";
import { MapViewComponent } from "./threat-actor-details/map-view/map-view.component";
import { ThreatActorDetailsComponent } from "./threat-actor-details/threat-actor-details.component";
import { ThreatActorListComponent } from "./threat-actor-list/threat-actor-list.component";
import { ThreatActorSightingListComponent } from "./threat-actor-sighting-list/threat-actor-sighting-list.component";
import { ThreatActorSupportingDataComponent } from "./threat-actor-supporting-data/threat-actor-supporting-data.component";
import { CoaDetailsComponent } from '../coa/coa-details/coa-details.component';
import { TaskDocHistoryComponent } from "../tasks/task-doc-history/task-doc-history.component";

const ThreatActorRoutes = [
  {
    path: "",
    component: ThreatActorListComponent,
  },
  {
    path: ":ta_id",
    data: {
      text: "threatActor_singular Details",
      breadcrumbs: true,
    },
    children: [
      {
        path: "",
        component: ThreatActorDetailsComponent,
      },
      {
        path: "task/:instanceId",
        data: { text: "Task", breadcrumbs: true },
        children: [
          { path: "",  component: TaskDetailsComponent},
          { path: "file-history/:documentId", component: TaskDocHistoryComponent, data: { text: "File History", breadcrumbs: true}}
        ]
      },
      {
        path: "coa/:id",
        component: CoaDetailsComponent,
        data: { text: "coa_singular", breadcrumbs: true },
      }
    ],
  },
];
export const ThreatActorRoutingComponents = [

  ThreatActorListComponent,
  ThreatActorDetailsComponent,
  ThreatActorSightingListComponent,
  ThreatActorSupportingDataComponent,
  MapViewComponent,
];

export { ThreatActorRoutes };
