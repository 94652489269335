<div class="wiz-diagram-container">
    <div id="{{canvasId}}" class="wiz-diagram-canvas" (mousemove)="moveDiagram($event)" (mouseup)="stopDiagramMove($event)" (mousedown)="startDiagramMove($event)"  (mouseleave)="stopDiagramMove($event)" ></div>
    <div class="navigation">
        <button class="btn btn-default zoomin" title="zoom in" (click)="zoomIn()">
            <span class="glyphicon glyphicon-plus"></span>
        </button>
        <button class="btn btn-default zoomout" title="zoom out" (click)="zoomOut()">
            <span class="glyphicon glyphicon-minus"></span>
        </button>
        <button class="btn btn-default zoomreset" title="reset zoom" (click)="zoomReset()">
            <span class="glyphicon glyphicon-screenshot"></span>
        </button>
    </div>
</div>