import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FilterPipe } from "../../shared/pipes/filter.pipe";
import { StixConfigService } from "../../shared/services/stixConfig.service";
import { WorkflowService } from "../../shared/services/workflow.service";
import { CoAService } from "../../coa/coa.service";
import { PermissionService } from "../../core/permission.service";
import { UserProfileService } from "../../core/userProfile.service";
import { TABLE_COLUMNS_TASKS } from "./tasks-list-columns";
import { forkJoin } from 'rxjs';

@Component({
  selector: "app-my-tasks-list",
  templateUrl: "./tasks-list.component.html",
  styleUrls: ["./tasks-list.component.css"],
  providers: [FilterPipe],
})
export class MyTasksListComponent implements OnInit {
  public pageTitle: "My Tasks";
  public coursesOfActions: any[] = [];
  public rows: any[] = [];
  public original_rows: any[] = [];
  public columns = [];
  public tableTitle: string = "List of My Tasks";
  public p: number = 1;
  public limitRowsOptions = [10, 25, 50, 100];
  public limitRows: number = this.limitRowsOptions[0];
  public tableInfo: string = "";
  public sortKey = "UpdatedDateTime";
  public asc = false;
  public loaded = false;
  public searchFilter: string = "";
  public term: string = "";
  public perm_accessCamunda = false;
  public tasksColumns = TABLE_COLUMNS_TASKS;

  constructor(private userService: UserProfileService, private coaService: CoAService, private permissions: PermissionService,
    private route: ActivatedRoute, private router: Router, private workflowService: WorkflowService, private filterPipe: FilterPipe,
    public stixConfig: StixConfigService) { }

  public ngOnInit() {

    this.permissions.getPermission("Access Camunda").then((p) => { this.perm_accessCamunda = p; });

    this.fetchCoursesOfActions();
  }

  public fetchCoursesOfActions() {
    this.loaded = false;
    this.columns = this.stixConfig.stixLabeling(this.tasksColumns);
    if (this.userService.userEmail) {
      const source = forkJoin([
        this.workflowService.getUserTasks(this.userService.userEmail),
        this.coaService.getCoursesOfAction_activeOnly()]
      );
      source.subscribe((subscriptions) => {
        this.formatTasks(subscriptions);
      }, (err) => this.loaded = true);
    } else {
      this.userService.getUserProfile().subscribe((user) => {
        const source = forkJoin([
          this.workflowService.getUserTasks(user.email),
          this.coaService.getCoursesOfAction_activeOnly()]
        );
        source.subscribe((subscriptions) => {
          this.formatTasks(subscriptions);
        }, (err) => this.loaded = true);
      });
    }
  }

  public formatTasks(subscriptions) {
    const formattedTasks = [];
    const tasks = subscriptions[0];
    const coursesOfAction = subscriptions[1];
    tasks && tasks.forEach((t) => {
      t.instanceId = t.processInstanceId;
      const coa = coursesOfAction.find((x) => x.instanceId === t.instanceId);
      if (coa) {
        t.instance_Id = t.instanceId.substring(0, 8).toUpperCase();
        t.UpdatedDateTime = coa.UpdatedDateTime;
        t.threatActorName = coa.threatActorName;
        t.threatActorId = coa.threatActorId;
        t.courseOfActionName = coa.courseOfActionName;
        if (t.assignee) { this.userService.addUserEmailToUsersListing(t.assignee); }
        formattedTasks.push(t);
      }
    });
    this.rows = formattedTasks;

    // get Assignee Full name instead of email
    this.userService.getusersNameMap().then((users) => {
      this.rows.forEach((row) => {
        const user = users.find((u) => u && u.email == row.assignee) || {};
        row.assignee = user.fullName || "--";
      });
    });
    this.original_rows = this.rows;
    this.updateTabelInfo();
    this.loaded = true;
  }

  public itemClick(columnName, item) {
    if (columnName === "viewHistory") {
      this.router.navigate([item.instanceId], { relativeTo: this.route });
    }

    if (columnName === "threatActorName") {
      const queryParams = { queryParams: { sighting: item.sightingName } };
      this.router.navigate(["threatActor", item.threatActorId], queryParams);
    }

    if (columnName == "instance_Id" && this.perm_accessCamunda) {
      this.router.navigate(["task", item.instanceId], { relativeTo: this.route });
    }
  }

  public updateTabelInfo() {
    const startRange = this.rows.length ? this.limitRows * (this.p - 1) + 1 : 0;
    let endRange = this.limitRows * this.p;
    endRange = endRange > this.rows.length ? this.rows.length : endRange;
    this.tableInfo = "Showing " + startRange + " to " + endRange + " of " + this.rows.length + " entries";
  }

  public columnClick(columnName) {
    if (this.sortKey === columnName) {
      this.asc = !this.asc;
    }
    this.sortKey = columnName;
    this.p = 1;
    this.updateTabelInfo();
  }

  public getHoverTitle(prop, item) {
    if (prop == "instance_Id" && this.perm_accessCamunda) {
      return "Click to open " + this.stixConfig.coa_singular + " Current Task";
    }
    if (prop == "viewHistory") {
      return "Click to view " + this.stixConfig.coa_singular + " History";
    }
    if (prop == "threatActorName") {
      return "Click to open " + this.stixConfig.threatActor_singular;
    }
  }

  public filterRows() {
    this.rows = this.filterPipe.transform(this.original_rows, this.term, this.tasksColumns);
    this.updateTabelInfo();
  }

}
