import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { EnvService } from '../env.service';
import { RivirHttp } from '../shared/services/rivir-http.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit {

  @Input() public rivirApiAccessToken;
  @Input() public apiUrl;

  constructor(
    private env: EnvService, 
    private toastr: ToastrService,
  ) {
    this.apiUrl = this.env.rivirApiBaseUrl;
    this.rivirApiAccessToken = RivirHttp.rivirApiToken;
  }

  public async uploadDoc () {
    // get form data
    const fileInput = document.querySelector('input[type="file"]') as HTMLInputElement;
    this.rivirApiAccessToken = RivirHttp.rivirApiToken;

    try {
      // create MayanEDMS document via RIViR api and get MayanEDMS file list url and token to use for upload POST
      const response = await fetch(`${this.apiUrl}/documents/initializeNewDocument?access_token=${this.rivirApiAccessToken}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ fileName: fileInput.files[0].name, documentTypeId: "1" })
      });

      const newDocument = await response.json();
      if (newDocument.error && newDocument.error.message) throw newDocument.error.message;

      // prepare file POST
      const headers = new Headers();
      headers.append('Authorization', `Token ${newDocument.token}`);
      var data = new FormData()
      data.append('action', "1");
      data.append('file_new', fileInput.files[0]);

      // prepare data and call MayanEDMS to post submitted file directly
      fetch(newDocument.postUrl, {
        method: 'POST',
        body: data,
        headers
      })
      .then(response => response.text())
      .then(data => {
        if (!data) {
          this.toastr.success("File successfully uploaded");
        } else {
          throw "Unexpected response.";
        }
      })
    } catch (err) {
      this.toastr.error("Error uploading file.");
      console.log("There was a problem with the fetch operation.", err);
    }
  }

  ngOnInit(): void {
  }

}
