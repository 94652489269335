import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { EnvServiceFactory } from "./app/env.service.provider";

import {KeycloakService} from "./app/core/keycloak/keycloak.service";

const env = EnvServiceFactory();

if (env.production) {
  enableProdMode();
}

// start keycloak interface
KeycloakService.init()
.then(() => {
  platformBrowserDynamic().bootstrapModule(AppModule);
})
.catch((err) => console.error(err));
