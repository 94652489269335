import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-tasks",
  templateUrl: "./tasks.component.html",
  styleUrls: ["./tasks.component.css"],
})
export class TasksComponent implements OnInit {

  public tabs = [];
  public activeTab = { prop: "", name: "" };

  constructor() { }

  public ngOnInit() {
  }

  public selectTab(tab) {
  }
}
