import { Component, Input,  OnChanges, SimpleChanges } from '@angular/core';

import { WorkflowService } from '../../shared/services/workflow.service';

import * as BpmnJS from '../../../assets/bpmn-js/bpmn-viewer.development.js';

declare const $: any;

@Component({
  selector: 'indicator-wiz-diagram',
  templateUrl: './indicator-wiz-diagram.component.html',
  styleUrls: ['./indicator-wiz-diagram.component.css']
})
export class IndicatorWizDiagramComponent implements OnChanges {

  private viewer: BpmnJS;
  private zoomLevel = 1;
  private zoomStep = 0.2;
  private context: any = {};
  private allowMove = false;
  public canvasId: string;
  public canvasSelector: string;

  @Input() deploymentId;

  constructor(
    private workflowService: WorkflowService,
  ) { 
     this.canvasId = 'canvas' + Math.floor(1000 + Math.random() * 9000);
     this.canvasSelector = '#' + this.canvasId;
  }

  ngOnChanges(changes: SimpleChanges) {

    if ( !changes.deploymentId.currentValue  ){
      this.clearCanvas();
    }
    else if ( changes.deploymentId.currentValue && (changes.deploymentId.currentValue !== changes.deploymentId.previousValue ) ) {
      this.clearCanvas();
      this.loadBPMNViewer( changes.deploymentId.currentValue );
    }
  }

  clearCanvas(){
    const canvasElem = document.querySelector( this.canvasSelector );
    if ( canvasElem ){
      canvasElem.innerHTML= "";
    }
  }

  public async loadBPMNViewer( deploymentId  ) {
    const diagramXml = await    this.workflowService.getCamundaDiagramXML( null, deploymentId ).toPromise();
    this.viewer = new BpmnJS({ container: this.canvasSelector });
    await this.viewer.importXML( diagramXml.results);
    $(".bjs-powered-by").attr("style", "display:none;");
  }

  public moveDiagram(e) {
    if(this.allowMove) {
      const position = { x: e.clientX, y: e.clientY };
      const lastPos = this.context.last || this.context.start;
      const delta = {
        x: position.x - lastPos.x,
        y: position.y - lastPos.y
      };

      this.viewer.get('canvas').scroll({
        dx: delta.x,
        dy: delta.y
      });

      this.context.last = position;
    }
    e.preventDefault();
  }

  public startDiagramMove(e) {
    this.allowMove = true;
    $(  this.canvasSelector ).css("cursor", "grabbing");
    this.context = {
      start: { x: e.clientX, y: e.clientY }
    };
    e.preventDefault();
  } 

  public stopDiagramMove(e) {
    this.allowMove = false;
    $( this.canvasSelector ).css("cursor", "grab");
    e.preventDefault();
  } 

  public zoomOut() {
    this.zoomLevel = Math.max( this.zoomLevel - this.zoomStep, this.zoomStep );
    this.viewer.get('canvas').zoom( this.zoomLevel, 'auto' );
  }

  public zoomIn() {
    this.zoomLevel = Math.min( this.zoomLevel + this.zoomStep, 4 );
    this.viewer.get('canvas').zoom( this.zoomLevel, 'auto' );
  }

  public zoomReset() {
    this.zoomLevel = this.viewer.get('canvas').zoom( 'fit-viewport' );
  }

}
