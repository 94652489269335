import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { catchError } from 'rxjs/operators';

import { EnvService } from  "../../env.service";
import { RivirHttp } from "./rivir-http.service";

@Injectable()
export class AlertService {
  private apiUrl: string;

  constructor(private http: HttpClient, private env: EnvService) {
    this.apiUrl = this.env.rivirApiBaseUrl + "/alerts";
  }

  public getAllAlerts(): Observable<any> {
    const url: string = this.apiUrl;
    return this.http.get(url, RivirHttp.getHttpOptions())
      .pipe(catchError(RivirHttp.handleError));
  }

  
  public extractNotificationSettings(data) {
    return new Promise<any>((resolve, reject) => {
      const notifications:Array<object> = [];

      Object.values(data.result).forEach((records: Array<object>) => {
        for (const notification of records) notifications.push(notification);
      });

      resolve (notifications);
    });

  }

  public saveNotifications(UpdatedBy, notifications) {
    return new Promise<any>((resolve, reject) => {
      const url = this.apiUrl;
      const payloadObject = { UpdatedBy, name: "", frequency: 0, active: false };
      let data;

      notifications.forEach(notification => {
        data = Object.assign({}, payloadObject);
        data.name = notification.name;
        data.frequency = notification.frequency || 0; 
        data.active = notification.active || false;
        this.updateNotificationSettings(url, data).subscribe();  
      })
  
      resolve("saved.");
    });
  }

  public sendStatusChangeNotification(userId, data) {
    const url: string = this.apiUrl + "/userStatusUpdate/" + userId;
    return this.http.post(url , data, RivirHttp.getHttpOptions())
      .subscribe((res) => console.log(res), (err) => RivirHttp.handleError(err));
  }

  public updateNotificationSettings(url, data): Observable<any>  {
    return this.http.post(url, data, RivirHttp.getHttpOptions())
      .pipe(catchError(RivirHttp.handleError));
  }
}
