import { NgModule, Pipe } from "@angular/core";

@Pipe({
  name: "orderBy",
})
export class OrderByPipe {

  public transform(array, orderBy, asc = true) {

    if (!orderBy || orderBy.trim() == "") {
      return array;
    }

    // ascending
    if (asc) {
      return Array.from(array).sort((item1: any, item2: any) => {
        return this.orderByComparator(item1[orderBy], item2[orderBy]);
      });
    } else {
      // not asc
      return Array.from(array).sort((item1: any, item2: any) => {
        return this.orderByComparator(item2[orderBy], item1[orderBy]);
      });
    }

  }

  public orderByComparator(a: any, b: any): number {

    if (a === undefined || a === null || a === "") {
      return 1;
    }
    if (b === undefined || b === null || b === "") {
      return -1;
    }
    a = this.parseIfBoolean(a);
    b = this.parseIfBoolean(b);

    a = this.parseIfDate(a);
    b = this.parseIfDate(b);

    if ((isNaN(parseFloat(a)) || !isFinite(a)) || (isNaN(parseFloat(b)) || !isFinite(b))) {
      // Isn't a number so lowercase the string to properly compare
      if (a.toLowerCase() < b.toLowerCase()) { return -1; }
      if (a.toLowerCase() > b.toLowerCase()) { return 1; }
    } else {
      // Parse strings as numbers to compare properly
      if (parseFloat(a) < parseFloat(b)) { return -1; }
      if (parseFloat(a) > parseFloat(b)) { return 1; }
    }

    return 0; // equal each other
  }

  public parseIfBoolean(x) {
    if (x === false) { return 0; }
    if (x === true) { return 1; }
    return x;
  }
  public parseIfDate(x) {
    if (typeof x.getTime === "function") {
      x = x.getTime();
    }
    return x;
  }
}

@NgModule({
  imports: [],
  declarations: [OrderByPipe],
  exports: [OrderByPipe]
})
export class OrderByPipeModule { }
