import { Component, OnInit } from '@angular/core';
import {EditorState} from "@codemirror/state";
import {EditorView, keymap ,highlightSpecialChars, drawSelection, highlightActiveLine, dropCursor} from "@codemirror/view";
import {defaultKeymap} from "@codemirror/commands";
import {lineNumbers, highlightActiveLineGutter} from "@codemirror/gutter";
import {defaultHighlightStyle} from "@codemirror/highlight";
import {indentOnInput} from "@codemirror/language";
import {history, historyKeymap} from "@codemirror/history";
import {foldGutter, foldKeymap} from "@codemirror/fold";
import {searchKeymap, highlightSelectionMatches} from "@codemirror/search";
import {autocompletion, completionKeymap} from "@codemirror/autocomplete";
import {commentKeymap} from "@codemirror/comment";
import {rectangularSelection} from "@codemirror/rectangular-selection"
import {python} from "@codemirror/lang-python";

import { RivirHttp } from "../../shared/services/rivir-http.service";
import { IndicatorWizardModel, IndicatorWizardService  } from '../indicator-wizard.service';
import { IndicatorService } from '../../indicators/indicators.service';
import { StixConfigService } from '../../shared/services/stixConfig.service';
import { AppSettingService } from "../../shared/services/appsetting.service";


@Component({
  selector: 'indicator-edit',
  templateUrl: './indicator-edit.component.html',
  styleUrls: ['./indicator-edit.component.css']
})
export class IndicatorEditComponent implements OnInit {

  view: EditorView;
  startState: EditorState;
  indicator: string;
  model: IndicatorWizardModel;
  existingIndicatorNames: string[];
  nameAlreadyExists: boolean;
  indicatorAttemptedName: string;
  public saveBoundCallback: () => void;
  public uploadCallback: ( args: any ) => void;
  public label_indicator: string;
  public accessToken = RivirHttp.rivirApiToken;

  nav_position: boolean = <boolean> false;
  hide_window: string = "visible";
  pythonRequirements: any = null;
  pyArray: any = null;
  pyArrayFormatted: Array<any> = [];
  initialViewCheck = false;
  pythonVersion: any = null;


  constructor( 
    private indicatorWizardService: IndicatorWizardService,
    private indicatorService: IndicatorService,
    public stixConfig: StixConfigService,
    private appSettingService: AppSettingService
  ) {
    this.label_indicator = this.stixConfig.indicator_singular;
  }

  onTogglePosition(position: string) {
    this.nav_position = !this.nav_position;
  }

  hideWindow() {
    this.hide_window = "hidden";
  }

  async ngOnInit() {
    
    this.saveBoundCallback = this.saveLocalDataToModel.bind(this);
    this.uploadCallback = this.fileSelected.bind(this);
    this.pythonVersion = await this.appSettingService.getAppSetting('pythonVersion').toPromise();
    if (this.pythonVersion?.results?.pythonVersion){
        this.pythonVersion = this.pythonVersion.results.pythonVersion;
    }
    this.pythonRequirements = await this.appSettingService.getAppSetting('pythonRequirements').toPromise();
    if (this.pythonRequirements?.results?.pythonRequirements){
      const rawData = this.pythonRequirements.results.pythonRequirements.replace(/(\r\n|\n|\r)/gm, " ");
      const data = rawData.split(" ");
      this.pyArray = data.map(el => el.replace("==", " "));
      this.pyArray.forEach(el => {

        // Don't add rivirpy 
          if (!el.includes('rivirpy') && el.trim() !== '@'){
           const obj = el.split(" ");

           this.pyArrayFormatted.push({
              Name: obj[0],
              Version: obj[1]
            });
          }
      });
    }


    this.indicatorWizardService.currentData.subscribe( indicatorWizardModel => {

      // Keep a local copy of the model
      this.model = indicatorWizardModel ? indicatorWizardModel : new IndicatorWizardModel();

      this.model.indicator.coasDesc = (this.model.indicator && this.model.indicator.id) ? this.model.indicator.coasDesc : null;
      this.model.indicator.coursesOfAction = (this.model.indicator && this.model.indicator.id) ? this.model.indicator.coursesOfAction : [];
      // Pull the pythonScript to a single level to prevent null ref
      const pythonScript = this.model  && this.model.indicator && this.model.indicator.script ?
                                    this.model.indicator.script.trim() : "";

      this.showOnlineEditor( pythonScript );
      
    });

    this.indicatorWizardService.navigation.subscribe( event => {
      if ( event )  {
        this.saveLocalDataToModel();

        this.showOnlineEditor( this.model.indicator.script );
      }
    });

    this.existingIndicatorNames = await this.indicatorService.getIndicatorNames();
  }

  public saveLocalDataToModel(){
    this.model.indicator.script = this.view ? this.view.state.doc.toString() : null;
  }

  public fileSelected(fileInput) {

    const file = fileInput.target.files[0];
    const fileExtension: string = file.name.split(".").pop().toLowerCase();

    const reader = new FileReader();
    const self = this;
    reader.onload = ( event ) => {
      const fileContents = event.target.result;
      self.model.indicator.name =  this.model.indicator.name ? this.model.indicator.name : file.name.replace( `.${fileExtension}`, "" );
      self.showOnlineEditor( fileContents.toString() , true);

    }
    reader.onerror = error => console.log(error);
    reader.readAsText(file);
  }

  


  showOnlineEditor( pythonScript, forceRefresh = false) {

    if ( forceRefresh ){
      document.querySelector("#editor").innerHTML = ''; 
    }

    this.model.indicator.script = pythonScript;
    // Check to see if the editor is populated by checking to see if the state is set and if the editor
    // div has been populated
    const isEditorShowing = this.startState && !!document.querySelector( "#editor")?.innerHTML;

    if ( forceRefresh || (this.model.indicator.script  && !isEditorShowing) ) {
      this.startState = EditorState.create({doc: this.model.indicator.script, extensions: [
        lineNumbers(),
        highlightActiveLineGutter(),
        highlightSpecialChars(),
        drawSelection(),
        highlightActiveLine(),
        dropCursor(),
        indentOnInput(),
        history(),
        foldGutter(),
        highlightSelectionMatches(),
        autocompletion(),
        rectangularSelection(),
        defaultHighlightStyle.fallback,
        keymap.of([
        ...defaultKeymap,
        ...historyKeymap,
        ...foldKeymap,
        ...searchKeymap,
        ...completionKeymap,
        ...commentKeymap,
        ]),
        python(),
      ]});

      this.view = new EditorView({
      state: this.startState,
      parent: document.querySelector("#editor")
      });
    }
  }
}
