<div class="col-lg-7 col-md-12 col-sm-12 run-list">
  <div class="panel panel-default">
    <div class="panel-heading">
      {{ tableTitle }}
      <a (click)="refreshPage()" class="refresh-list">
        <i class="fa fa-refresh"></i> Refresh List</a>
    </div>
    <div *ngIf="!loaded" class="loader">
      Loading
      <span class="loader__dot">.</span>
      <span class="loader__dot">.</span>
      <span class="loader__dot">.</span>
    </div>
    <div *ngIf="loaded" class="table-responsive">
      <div class="dataTables_wrapper form-inline dt-bootstrap no-footer">
        <div class="row">
          <div class="col-sm-12">
            <table class="table table-striped b-t b-b dataTable no-footer" role="grid">
              <thead>
                <tr role="row">
                  <th *ngFor="let column of columns" (click)="columnClick(column.prop)" class="{{ 'th-' + column.prop }} sorting{{
                      sortKey == column.prop
                        ? asc == true
                          ? '_asc'
                          : '_desc'
                        : ''
                    }}">
                    {{ column.name }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="
                    let item of rows
                      | orderBy: sortKey:asc
                      | paginate: { itemsPerPage: limitRows, currentPage: p }
                  " class="status-{{ item['status'] }}" role="row">
                  <td *ngFor="let column of columns" class="{{ 'td-' + column.prop }}">
                    <span *ngIf="column.prop == 'CreatedDateTime'">
                      {{ item[column.prop] | date: "short" }}</span>
                    <span *ngIf="
                        column.prop == 'threatActorCount' &&
                        item['threatActorCount']
                      " (click)="itemClick(column.prop, item)">
                      {{ item[column.prop] }}</span>
                    <span *ngIf="column.prop == 'status'" (click)="itemClick(column.prop, item)" [style.color]="
                        item['status'] == 'Error' || item['status'] == 'Aborted'
                          ? 'red'
                          : ''
                      ">
                      {{ item[column.prop] }}</span>
                      <span *ngIf="column.prop == 'viewMoreDetails'" (click)="itemClick(column.prop, item)"
                      title="{{ item.moreDetailsTitle }}">
                      {{ item.moreDetailsText }}
                    </span>

                    <span *ngIf="column.prop == 'bulkStartResults'">
                      <div *ngFor="let bulkRun of item[column.prop]" (click)="bulkRunClick(bulkRun)"
                        class="bulkStartResults">
                        {{ bulkRun.bulkRunDate | date: "short" }}
                      </div>
                    </span>

                    <span *ngIf="column.prop == 'reportStatus'">
                      <div (click)="downloadReport(column.prop, item)" class="{{item.zipDownloadClass}}">
                        {{ item.zipDownloadStatus }}
                      </div>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4">
            <div class="dataTables_info">
              {{ tableInfo }}
            </div>
          </div>
          <div class="col-sm-8">
            <pagination-controls (pageChange)="p = $event; updateTabelInfo()" class=" pull-right"></pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>