<h1 mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content>
    <h4>{{ data.workbookName }}</h4>
    <div class="form-group">
        <div class="row">
            <div class="col-md-12">Choose default view:</div>
            <div class="col-md-12">
              <select id="selectedWorkbookView" class="form-control" [(ngModel)]="data.item">
                <option *ngFor="let workbookView of data.workbookViewList" [ngValue]="workbookView.name">{{ workbookView.name }}</option>
              </select>
            </div>
        </div>
    </div>
</div>
<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="false">Cancel</button>
    <button mat-button [mat-dialog-close]="data.item" cdkFocusInitial>Ok</button>
</mat-dialog-actions>
