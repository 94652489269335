import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { EnvService } from "../../env.service";
import * as moment from "moment";
import { StixConfigService } from "../../shared/services/stixConfig.service";
import { PermissionService } from "../../core/permission.service";
import { UserProfileService } from "../../core/userProfile.service";
import { Indicator } from "../indicators-list/indicator";
import { IndicatorService } from "../indicators.service";
import { ScheduleViewComponent } from "../scheduleview/scheduleview.component";
import { TableauService } from "../../core/tableau.service";
import { RivirHttp } from "../../shared/services/rivir-http.service";
import {
  LABELS_IND_DETAIL,
} from "./indicator-detail-columns";

@Component({
  selector: "app-indicator-detail",
  templateUrl: "./indicator-detail.component.html",
  styleUrls: ["./indicator-detail.component.css"]
})
export class IndicatorDetailComponent implements OnInit {
  public indicator: Indicator;
  public labels = [];
  public permission_executeAlgo = false;
  public permission_editAlgo = false;
  public permission_activateAlgo = false;
  public permission_scheduleExecute = false;
  public indicator_scheduled = false;
  public schedule_verify_remove = false;
  public modalRef: BsModalRef;
  public indicatorId: string;
  public label_indicator: string;
  public schedule_label: string;
  public scheduled_timedjob_id: string[] = [];
  public maxScheduleEndDate: string;
  private timedJobsQueueApiUrl: string;
  public accessToken = RivirHttp.rivirApiToken;
  private algorithmApiUrl: string;
  public canExecute: boolean;
  public selectedWorkBook: any;

  constructor(
    private indicatorService: IndicatorService,
    private route: ActivatedRoute,
    public stixConfig: StixConfigService,
    private permissions: PermissionService,
    private userService: UserProfileService,
    private modalService: BsModalService,
    private tableauService: TableauService,
    private _http: HttpClient,
    private env: EnvService,
    private router: Router
  ) {
    this.timedJobsQueueApiUrl = `${this.env.rivirApiBaseUrl}/timedJobsQueue`;
    this.algorithmApiUrl = `${this.env.rivirApiBaseUrl}/algorithms`;
   }

  public ngOnInit() {
    this.label_indicator = this.stixConfig.indicator_singular;
    this.permissions.getPermission("Execute Indicator").then(p => {
      this.permission_executeAlgo = p;
    });
    this.permissions.getPermission("Activate/Deactivate Indicator").then(p => {
      this.permission_activateAlgo = p;
    });
    this.permissions.getPermission("Edit Indicator").then(p => {
      this.permission_editAlgo = p;
    });
    this.permissions.getPermission("Schedule to Execute").then(p => {
      this.permission_scheduleExecute = p;
    });
    this.labels = this.stixConfig.stixLabeling(LABELS_IND_DETAIL);

    this.route.params.subscribe(params => {
      this.indicatorId = params.id;
      this.fetchIndicatorsDetails();
    });
    this.modalService.onHide.subscribe(() => {
      this.isIndicatorScheduled();
      this.fetchIndicatorsDetails();
    });
    this.isIndicatorScheduled();
    this.maxScheduleEndDate = "2040-12-31";
  }

  public fetchIndicatorsDetails() {
    this.indicatorService
      .getIndicatorDetail(this.indicatorId)
      .subscribe(indicator => {
        this.indicator = indicator[0];
        const autostartsInvalid = this.indicator.autoStartCoursesOfAction && this.indicator.autoStartCoursesOfAction.length ? 
            this.indicator.autoStartCoursesOfAction.find( x => !x.user ) || this.indicator.autoStartCoursesOfAction.find( x => x.daysOut <= 0 ) :
            false;

        this.canExecute = this.indicator.name && this.indicator.script && this.indicator.description &&  this.indicator.classifications && this.indicator.classifications.length && !autostartsInvalid;

        this.loadWorkBook(this.indicator.visualizations); 

        if (this.indicator.status === "in-progress") {
          if (this.indicatorService.autoRefresh) {
            this.indicatorService.autoRefresh.unsubscribe();
          }
          this.indicatorService
            .autoRefreshCalls(this.indicator, 1000, 60000)
            .then(r => {
              this.onRefresh();
            });
        }
      });
  }

  async loadWorkBook(visualizations) {
    try {

      // Get all workbooks and find the workbook we want by name
      let workbooks = 
        await this.tableauService.getAllWorkBooks(visualizations);

      if (workbooks[0]) {
        this.selectedWorkBook = workbooks[0];
        // Get the title for the specific workbook and save it in a cookie for display in the sighting report crumbtrail
        let workbook = 
          await this.tableauService.getWorkBookById(this.selectedWorkBook.workBookId);

        if (workbook) {
          let workBookTitle = this.selectedWorkBook.workbookName;
          localStorage.setItem('sightingreportname', workBookTitle);
        }
      }
    }
    catch (ex) {
      console.error(ex);
    }
  }

  public switchActivation() {
    this.indicator.active = !this.indicator.active;
    this.indicator.id = null;
    this.indicator.updateRecord = true;
    this.indicator.UpdatedDateTime = new Date();
    (this.indicator.UpdatedBy = this.userService.fullName),
      this.indicatorService.saveIndicator(this.indicator).subscribe(
        data => {
          this.indicator.execute = !this.indicator.execute;
        },
        error => {
          this.indicator.active = !this.indicator.active;
        }
      );
  }

  public executeIndicator() {
    if (this.indicator.status !== "in-progress") {
      this.indicatorService
        .executeAlgorithm(this.indicator, this.userService.fullName)
        .subscribe(res => {
          console.log("execute Algorithm: " + this.indicator.name);
          this.indicator.status = "in-progress";
          this.onRefresh();
          this.indicatorService
            .autoRefreshCalls(this.indicator, 1000, 60000)
            .then(r => this.onRefresh());
        });
    } else {
      this.indicatorService.abortExecution(this.indicator).subscribe(res => {
        this.indicator.status = "";
      });
    }
  }

  public onRefresh() {
    this.fetchIndicatorsDetails();
  }

  public createdSelectedArray(valueArray) {
    const selectedArray = [];
    valueArray.forEach(element => {
      if (element) {
        selectedArray.push({ id: element, text: element });
      }
    });

    return selectedArray;
  }

  public editAlgorithm() {
    this.router.navigate(["indicator-wizard", this.indicator.id], { relativeTo: this.route });
  }

  public scheduleIndicator() {
    this.modalRef = this.modalService.show(ScheduleViewComponent);
    this.modalRef.content.modelName =
      "Schedule " + this.label_indicator + " To Execute";
    this.modalRef.content.indicator = this.indicator;
    this.modalRef.content.editMode = true;
    this.modalRef.content.updateRecord = true;
    this.modalRef.content.maxScheduleEndDate = this.maxScheduleEndDate;
  }

  public scheduleVerifyMessageShow(show) {
    this.schedule_verify_remove = show;
  }

  public stopScheduleIndicator() {
    let id = "";
    while (id = this.scheduled_timedjob_id.pop()) {

      const payload = {
        name: id,
        jobId: id.replace(/\s+/g, "-"),
        indicator: id,
        type: "indicator",
        createdBy: `"${this.userService.fullName}" <${this.userService.userEmail}>` || "system",
        updated: moment().toISOString()
      };

      this._http.post(`${this.timedJobsQueueApiUrl}/removeRequest?access_token=${this.accessToken}`, { job: payload })
        .subscribe((data) => {
          this.schedule_label = "";
          this.indicator_scheduled = false;
          this.scheduleVerifyMessageShow(false);
        });
    };
  }

  public isIndicatorScheduled() {
    const url = `${this.algorithmApiUrl}?filter={"where":{"name":"${encodeURIComponent(this.indicatorId)}"}}`;

    this._http.get(url, RivirHttp.getHttpOptions()).subscribe((data: any[]) => {
      data.forEach(record => {
        if("uIScheduledMessage" in record && record.uIScheduledMessage !== "") {
          this.scheduled_timedjob_id.push(record.name);
          this.schedule_label = record.uIScheduledMessage;
          this.indicator_scheduled = true;
        }
      })
    });
  }

  public isArray(obj: any) {
    return Array.isArray(obj);
  }
}
