export class iMessage {
    public class: string;

    constructor(public status: string, public header: string, public desc: string) {
        if (status === "Success") {
            this.class = "success";
        } else if (status === "Error") {
            this.class = "danger";
        }
    }

}
