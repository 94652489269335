<mat-toolbar style="background-color: #135A78; color:#FFF" fxShow="true" fxHide.lt-md="true">
    <button mat-button aria-label="RIViR Home Page" [routerLink]="['/' ]">
        <div *ngIf="headerImage">
            <img *ngIf="headerImage" src="{{headerImage}}" alt="RIVIR Logo" class="header-image" />
        </div>
    </button>
    <span class="title-center">
        <button *ngIf="hasSearch" mat-button aria-label="Search" [routerLink]="['/search' ]" [ngClass]="currentRoute === 'search'  ? 'selectednavButton' : 'navButton'">
            <mat-icon>search</mat-icon>
            Search
        </button>
        <button *ngIf="hasDataEntry" mat-button class="navButton" aria-label="Data Entry" [routerLink]="['/data-entry-list' ]" [ngClass]="currentRoute === 'data-entry-list'  ? 'selectednavButton' : 'navButton'">
            <mat-icon>control_point</mat-icon>
            {{stixConfig.dataEntry_plural}}
        </button>
        <button mat-button aria-label="Indicators" class="selectedRoute" [routerLink]="['/indicators' ]" [ngClass]="currentRoute === 'indicators'  ? 'selectednavButton' : 'navButton'">
            <mat-icon>list</mat-icon>
            {{stixConfig.indicator_plural}}
        </button>
        <button mat-button aria-label="Threat Actors" [routerLink]="['/threatActor' ]" [ngClass]="currentRoute === 'threatActor'  ? 'selectednavButton' : 'navButton'">
            <mat-icon fontSet="material-icons-outlined">person_search</mat-icon>
            {{stixConfig.threatActor_plural}}
        </button>
        <button *ngIf="hasCaseManagement" mat-button aria-label="Cases" [routerLink]="['/case/listing' ]" [ngClass]="currentRoute === 'case/listing'  ? 'selectednavButton' : 'navButton'">
            <mat-icon fontSet="material-icons-outlined">folder_copy</mat-icon>
            Cases
        </button>
        <button mat-button aria-label="Courses of Action" [routerLink]="['/coa' ]" [ngClass]="currentRoute === 'coa'  ? 'selectednavButton' : 'navButton'">
            <mat-icon>account_tree</mat-icon>
            {{stixConfig.coa_plural}}
        </button>
        <button *ngIf="hasReport" mat-button aria-label="Reports" [routerLink]="['/report' ]" [ngClass]="currentRoute === 'report'  ? 'selectednavButton' : 'navButton'">
            <mat-icon>bar_chart</mat-icon>
            {{stixConfig.visualization_plural}}
        </button>
    </span>
    <span class="example-spacer"></span>
    <button mat-button [matMenuTriggerFor]="notificationsMenu" aria-label="Notifications" (click)="clearNotifications()">
        <mat-icon>notifications_none</mat-icon>
        <span *ngIf="newMessageCount && newMessageCount > 0" class="circle"> {{newMessageCount}} </span>
    </button>
    <mat-menu #notificationsMenu="matMenu" xPosition="before">
        <div class="menu-new-alerts"> You have {{newMessageCount}} new alerts </div>
        <button *ngFor="let msg of notifications" mat-menu-item style="height: auto; white-space: pre-wrap !important;"
            aria-label="msg.type">
            <div style="border-bottom: 1px  #D3D3D3 solid;">
                <div style="font-weight:bold;">
                    <span *ngIf="msg.isNew">
                        <mat-icon>mail</mat-icon>
                    </span>
                    <span *ngIf="!msg.isNew">
                        <mat-icon>mail_outline</mat-icon>
                    </span>
                    {{msg.type}}
                </div>
                <div>{{msg.body}}</div>
                <div class="menu-time-passed">{{msg.timePassed}}</div>
            </div>
        </button>
        <button mat-menu-item [routerLink]="['/notificationList' ]" style="font-weight:bold;"
            aria-label="All Notifications">
            <mat-icon>pending_actions</mat-icon>See all notifications
        </button>
    </mat-menu>

    <button mat-button [matMenuTriggerFor]="settingsMenu" aria-label="Setting Menu Dropdown">
        <div class="row" *ngIf="currentUserProfile">
            <div class="column left">
                <img src={{profileProfile}} style="border-radius: 50%; height: 50px;" />
            </div>
            <div class="column middle">
                <div class="header-setting-text">{{currentUserProfile.name}}</div>
                <div class="header-setting-text header-setting-text-bottom">{{currentUserProfile.accountType}}</div>
            </div>
            <div class="column right">
                <mat-icon>arrow_drop_down</mat-icon>
            </div>
        </div>
    </button>
    <mat-menu #settingsMenu="matMenu" xPosition="before">
        <button mat-menu-item [routerLink]="['/accountsettings' ]" aria-label="Profile">
            <mat-icon>badge</mat-icon>
            <span>Profile</span>
        </button>
        <button *ngIf="showSettings" mat-menu-item [routerLink]="['/settings' ]" aria-label="Settings">
            <mat-icon>settings</mat-icon>
            <span>Settings</span>
        </button>
        <button mat-menu-item [routerLink]="['/contactus' ]" aria-label="Contact Us">
            <mat-icon>help_outline</mat-icon>
            <span>Help</span>
        </button>
        <button mat-menu-item (click)="onLogout()" aria-label="Log out">
            <mat-icon>logout</mat-icon>
            <span>Logout</span>
        </button>
    </mat-menu>
</mat-toolbar>
