<div style="height: 700px;" *ngIf="model">
    <indicator-wiz-navigation [stepNumber]="1"  [parentLocalSaveFunction]="saveBoundCallback"></indicator-wiz-navigation>
    <div class="row form-group justify-content-center" >
        <div class="container" style="display:flex">
            <div class="col-sm-11 text-center">
                <label class="header-label" for="description">Provide details for this {{label_indicator}} script.  All fields are required except 'Type.'</label>
            </div>
        </div>
    </div>

    <div class="row form-group justify-content-center" >
        <div class="container" style="display:flex">
            <div class="col-sm-3">
                <label class="control-label" for="description">{{label_indicator}} Description:<span style="color:red">*</span></label>
            </div>
            <div class="col-sm-8">
                <textarea class="form-control" id="description" name="description" type="text"
                    [(ngModel)]="model.indicator.description" #description="ngModel" autocomplete="off" required
                    placeholder="Enter short description for {{label_indicator}}"></textarea>
            </div>
        </div>
    </div>

    <div class="row form-group justify-content-center" >
        <div class="container" style="display:flex">
            <div class="col-sm-3">
                <label class="control-label" for="classification_input">Classification:<span style="color:red">*</span></label>
            </div>
            <div class="col-sm-8">
                <ng-select [items]="allClassifications" placeholder="Select Classifications"
                    [multiple]="true" [closeOnSelect]="false" [(ngModel)]="model.indicator.classifications"
                    id="classification" name="classification">
                </ng-select>
                <a class="link-add" (click)="openNewItemDialog($event, 'classification')">
                    <i class="fa fa-plus"></i> Add Classification</a>
            </div>
        </div>
    </div>


    <div class="row form-group justify-content-center" >
        <div class="container" style="display:flex">
            <div class="col-sm-3">
                <label class="control-label" for="type_input">Type:</label>
            </div>
            <div class="col-sm-8">
                <ng-select [items]="allTypes" placeholder="Select Types" [multiple]="true"
                    [closeOnSelect]="false" [(ngModel)]="model.indicator.types" id="types" name="types">
                </ng-select>
                <a class="link-add" (click)="openNewItemDialog($event, 'type')" >
                    <i class="fa fa-plus"></i> Add Type</a>
            </div>
        </div>
    </div>