<table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8" matSort matSortActive="caseId"
    matSortDirection="desc" matSortDisableClear>
    <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
        <th mat-header-cell *matHeaderCellDef mat-sort-header attr.aria-label="{{getCaseColumnName(column)}}">{{getCaseColumnName(column)}}</th>
        <td mat-cell *matCellDef="let element">
            <span *ngIf="getCaseColumnType(column) === 'text'">{{element[column]}}</span>
            <span *ngIf="getCaseColumnType(column) === 'link'">
                <a routerLink="{{getGridLink(column, element)}}" class="cases-grid-link">{{element[column]}}</a>
            </span>
            <div *ngIf="getCaseColumnType(column) === 'expand' && element != expandedCase">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-down" fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                        d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                </svg>
            </div>
            <div *ngIf="getCaseColumnType(column) === 'expand'&& element == expandedCase">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-up" fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                        d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z" />
                </svg>
            </div>

        </td>
    </ng-container>

    <!-- RIViR Top Column -->
    <ng-container matColumnDef="rivir-top-header">
        <th mat-header-cell *matHeaderCellDef [attr.colspan]="10" class="rivir-mat-top-header">
            <div class="row">
                <div class="col-md-10" style="color:#fcfcfc; font-size:17px;">
                    List of Cases
                </div>
                <div class="col-md-2">
                    <a class="refresh-list" (click)="refreshList()">
                        <i class="fa fa-refresh"></i> Refresh List
                    </a>
                </div>
            </div>
        </th>
    </ng-container>

    <!-- RIViR Search Column -->
    <ng-container matColumnDef="rivir-search-header">
        <th mat-header-cell *matHeaderCellDef [attr.colspan]="10">
            <div class="row">
                <div class="col-md-9"></div>
                <div class="col-md-1 rivir-mat-search-label">
                    Filter:
                </div>
                <div class="col-md-2">
                    <input #input type="search" class="form-control" title="Filter">
                </div>
            </div>
        </th>
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let rivirCase" [attr.colspan]="columnsToDisplay.length">
            <div class="example-element-detail" [@detailExpand]="rivirCase == expandedCase ? 'expanded' : 'collapsed'">
                <div *ngIf="rivirCase.courseOfActions" class="container-fluid case-coa-table">

                    <div class="row case-coa-header-row">
                        <div class="col-md-12">
                            {{stixConfig.coa_plural}}
                        </div>
                    </div>
                    <div class="row case-coa-header-row">
                        <div class="col-md-2">
                            <button type="button" class="btn btn-link btn-case-coa"
                                (click)="sortCoas(rivirCase, 'indicatorName')">
                                {{stixConfig.indicator_singular}} Name
                            </button>
                            <mat-icon class="case-coa-sort-icon" *ngIf="displaySortIcon('indicatorName')">
                                {{displaySortIcon('indicatorName')}}</mat-icon>
                        </div>
                        <div class="col-md-1">
                            <button type="button" class="btn btn-link btn-case-coa"
                                (click)="sortCoas(rivirCase, 'classification')">
                                Classification
                            </button>
                            <mat-icon class="case-coa-sort-icon" *ngIf="displaySortIcon('classification')">
                                {{displaySortIcon('classification')}}</mat-icon>
                        </div>
                        <div class="col-md-1">
                            <button type="button" class="btn btn-link btn-case-coa"
                                (click)="sortCoas(rivirCase, 'type')">
                                Type
                            </button>
                            <mat-icon class="case-coa-sort-icon" *ngIf="displaySortIcon('type')">
                                {{displaySortIcon('type')}}</mat-icon>
                        </div>
                        <div class="col-md-1">
                            <button type="button" class="btn btn-link btn-case-coa"
                                (click)="sortCoas(rivirCase, 'sigthing')">
                                {{stixConfig.sighting_singular}}
                            </button>
                            <mat-icon class="case-coa-sort-icon" *ngIf="displaySortIcon('sigthing')">
                                {{displaySortIcon('sigthing')}}</mat-icon>
                        </div>
                        <div class="col-md-1">
                            <button type="button" class="btn btn-link btn-case-coa"
                                (click)="sortCoas(rivirCase, 'instanceUIId')">
                                ID
                            </button>
                            <mat-icon class="case-coa-sort-icon" *ngIf="displaySortIcon('instanceUIId')">
                                {{displaySortIcon('instanceUIId')}}</mat-icon>
                        </div>
                        <div class="col-md-2">
                            <button type="button" class="btn btn-link btn-case-coa"
                                (click)="sortCoas(rivirCase, 'courseOfActionName')">
                                Name
                            </button>
                            <mat-icon class="case-coa-sort-icon" *ngIf="displaySortIcon('courseOfActionName')">
                                {{displaySortIcon('courseOfActionName')}}</mat-icon>
                        </div>
                        <div class="col-md-1">
                            <button type="button" class="btn btn-link btn-case-coa"
                                (click)="sortCoas(rivirCase, 'dueDate')">
                                Due Date
                            </button>
                            <mat-icon class="case-coa-sort-icon" *ngIf="displaySortIcon('dueDate')">
                                {{displaySortIcon('dueDate')}}</mat-icon>
                        </div>
                        <div class="col-md-1">
                            <button type="button" class="btn btn-link btn-case-coa"
                                (click)="sortCoas(rivirCase, 'status')">
                                Status
                            </button>
                            <mat-icon class="case-coa-sort-icon" *ngIf="displaySortIcon('status')">
                                {{displaySortIcon('status')}}</mat-icon>
                        </div>
                        <div class="col-md-2">
                            <button type="button" class="btn btn-link btn-case-coa"
                                (click)="sortCoas(rivirCase, 'assignee')">
                                Assignee
                            </button>
                            <mat-icon class="case-coa-sort-icon" *ngIf="displaySortIcon('assignee')">
                                {{displaySortIcon('assignee')}}</mat-icon>
                        </div>
                    </div>
                    <div *ngFor="let coa of rivirCase.courseOfActions" class="row case-coa-data-row">
                        <div class="col-md-2">
                            <a routerLink="/indicators/{{coa.indicatorName}}"
                                class="cases-grid-link">{{coa.indicatorName}}</a>
                        </div>
                        <div class="col-md-1">{{coa.classification}}</div>
                        <div class="col-md-1">{{coa.type}}</div>
                        <div class="col-md-1">
                            <div class="case-tooltip">{{coa.sigthingShort}}
                                <span class="case-tooltiptext">{{coa.sigthing}}</span>
                            </div>
                        </div>
                        <div class="col-md-1">
                            <a routerLink="/coa/task/{{coa.instanceId}}"
                                class="cases-grid-link">{{coa.instanceUIId}}</a>
                        </div>
                        <div class="col-md-2">{{coa.courseOfActionName}}</div>
                        <div class="col-md-1">{{coa.dueDate}}</div>
                        <div class="col-md-1">{{coa.status}}</div>
                        <div class="col-md-2">{{coa.assignee}}</div>
                    </div>
                </div>
                <div *ngIf="!rivirCase.courseOfActions" class="case-no-coas">
                    This case does not contain any courses of action
                </div>
            </div>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="['rivir-top-header']">
    </tr>
    <tr mat-header-row *matHeaderRowDef="['rivir-search-header']">
    </tr>
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let rivirCase; columns: columnsToDisplay;" class="example-element-row"
        [class.example-expanded-row]="expandedCase === rivirCase" (click)="caseRowSelected($event, rivirCase)"
        [ngClass]="rivirCase.index % 2 ? 'rivir-mat-table-row-even' : 'rivir-mat-table-row-odd'">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
</table>
<mat-progress-bar mode="indeterminate" *ngIf="(dataSource && dataSource.loading$) | async"></mat-progress-bar>
<mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" [length]="paginationLength">
</mat-paginator>