<div *ngIf="bulkAssignVisible" class="bulkAssign-btn pull-right">
  <button
    class="btn btn-primary"
    [disabled]="disableBulkAssign"
    (click)="bulkReAssignCOA()"
  >
    <i class="fa fa-align-justify"></i>
    Bulk Re-Assign {{ coaLabel }}
  </button>
</div>
<div class="panel panel-default">
  <div class="panel-heading">
    {{ tableTitle }}
    <span style="color:#69d1ff">{{ getAggregateFilterByText() }}</span>
    <a (click)="resetCheckboxes_refreshList()" class="refresh-list">
      <i class="fa fa-refresh"></i> Refresh List</a
    >
  </div>
  <div *ngIf="!loaded" class="loader">
    Loading
    <span class="loader__dot">.</span>
    <span class="loader__dot">.</span>
    <span class="loader__dot">.</span>
  </div>
  <div *ngIf="loaded" class="table-responsive">
    <div class="dataTables_wrapper form-inline dt-bootstrap no-footer">
      <div class="row">
        <div class="col-sm-9">
          <div class="dataTables_length">
            <label
              >Show
              <select
                [(ngModel)]="limitRows"
                class="form-control input-sm"
                (change)="updateTable()"
              >
                <option *ngFor="let num of limitRowsOptions" [value]="num">{{
                  num
                }}</option>
              </select>
              entries
            </label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <table
            class="table table-striped b-t b-b dataTable no-footer"
            role="grid"
          >
            <thead>
              <tr role="row">
                <th
                  class="select-checkbox {{
                    columns.length < 6 ? 'center' : ''
                  }}"
                >
                  <div *ngIf="bulkAssignVisible" class="checkbox">
                    <label
                      class="i-checks"
                      for="select_all"
                      title="{{
                        selectAll
                          ? 'Deselect All'
                          : 'Select All for Bulk Re-assignment'
                      }}"
                      style="display: none;"
                    >Select All for Bulk Re-assignment
                  </label>
                      <input
                        name="select_all"
                        id="select_all"
                        type="checkbox"
                        [(ngModel)]="selectAll"
                        (ngModelChange)="selectAllChange()"
                      />
     
                  </div>
                </th>
                <th
                  *ngFor="let column of columns"
                  attr.aria-label="{{column.name}}"
                  (click)="columnClick(column.prop)"
                  class="{{ 'th-' + column.prop }} sorting{{
                    sortKey == column.prop
                      ? asc == true
                        ? '_asc'
                        : '_desc'
                      : ''
                  }}"
                >
                  {{ column.name }}
                </th>
              </tr>
              <tr role="row">
                <th aria-hidden="true"><span style="display: none; visibility: hidden;">no content</span></th>
                <th *ngFor="let column of columns">
                  <span style="display: none;">Select</span> 
                  <input
                    title="{{column.name}}"
                    *ngIf="column.prop != 'viewHistory'"
                    class="form-control filterByColumn"
                    name="searcher{{ column.prop }}"
                    [disabled]="
                      searcher && !searcher[column.prop] && lockFilters
                    "
                    (focus)="filterClick(column.prop)"
                    id="searcher{{ column.prop }}"
                    [(ngModel)]="searcher[column.prop]"
                    (ngModelChange)="
                      filterChange(column.prop, searcher[column.prop])
                    "
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let item of rows
                    | paginate
                      : {
                          itemsPerPage: limitRows,
                          currentPage: p,
                          totalItems: totalRecords
                        }
                "
                role="row"
                class="status-{{ isWorkflowClosed(item) ? 'Closed' : 'Open' }}"
              >
                <td
                  class="select-checkbox {{
                    columns.length < 6 ? 'center' : ''
                  }}"
                >
                  <div *ngIf="bulkAssignVisible" class="checkbox">
                    <label
                      class="i-checks"
                      for="select_{{ item['instanceId'] }}"
                      title="{{
                        selectAll ? 'Deselect' : 'Select for Bulk Re-assignment'
                      }}"
                    ><span style="display: none; visibility: hidden;">Select</span>
                      <input
                        name="select_{{ item['instanceId'] }}"
                        id="select_{{ item['instanceId'] }}"
                        [(ngModel)]="item.selected"
                        type="checkbox"
                        (ngModelChange)="selectChange(item)"
                        [disabled]="isWorkflowClosed(item)"
                      /> 
                      <i
                        class="{{
                          isWorkflowClosed(item) ? 'disable-checkbox' : ''
                        }}"
                      ></i>
                    </label>
                  </div>
                </td>
                <td
                  *ngFor="let column of columns"
                  class="{{ 'td-' + column.prop }} {{
                    'perm-' +
                      (column.prop == 'instanceUIId'
                        ? perm_accessCamunda
                        : perm_update_dueDate)
                  }}"
                >
                  <span
                    *ngIf="item[column.prop] || column.prop == 'viewHistory'"
                    (click)="itemClick(column.prop, item)"
                    title="{{ getHoverTitle(column.prop, item) }}"
                  >
                    {{
                      column.prop == "viewHistory"
                        ? "View"
                        : column.prop == "UpdatedDateTime"
                        ? (item[column.prop] | date: "short")
                        : column.prop == "coaDueDate"
                        ? (item[column.prop] | date: "M/dd/yyyy":"UTC")
                        : item[column.prop]
                    }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="dataTables_info">
            {{ tableInfo }}
          </div>
        </div>
        <div class="col-sm-6">
          <pagination-controls
            (pageChange)="p = $event; updateTable()"
            class=" pull-right"
          ></pagination-controls>
        </div>
      </div>
    </div>
  </div>
</div>
