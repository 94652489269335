import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class TasksService {

  // Observable string sources
  private taskSource = new BehaviorSubject<string>(null);

  private attachmentsSource = new BehaviorSubject<string>(null);

  // Observable string streams
  taskUpdated$ = this.taskSource.asObservable();

  attachmentsUpdated$ = this.attachmentsSource.asObservable();

  // Service message commands
  updateTask(task: any) {
    this.taskSource.next(task);
  }

  updateAttachments(documentName) {
    this.attachmentsSource.next(documentName);
  }
}
