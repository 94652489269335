import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ElementRef } from '@angular/core';
import { UserProfileService } from '../core/userProfile.service';
import { EnvService } from '../env.service';
import { RivirHttp } from '../shared/services/rivir-http.service';
import { MatDialog } from "@angular/material/dialog";
import { Router } from '@angular/router';
import * as moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';
import { TableauReport, TableauService } from "../core/tableau.service";


@Component({
  selector: "app-report",
  templateUrl: "./report.component.html",
  styleUrls: ["./report.component.css"],
})
export class ReportComponent implements OnInit {

  viz: any;
  private _tableauUrl: string;
  public searchItem: string;
  public workbookName: string;
  public allWorkBooks: any;
  public modifiedAllWorkBooks = [];
  public isSelected: string;
  public selectedWorkBookId: string;
  public selectedWorkBook: any;
  selected=0;
  public loaded = false;
  public isWorkBookSelected = false;
  public dashboard: TableauReport;
  public workBookTitle: string;
  public contentUrl: string;
  public tableauServer: string;


  constructor(
    private _http: HttpClient,
    private env: EnvService,
    private userProfileService: UserProfileService,
    public dialog: MatDialog,
    private tableauService: TableauService,
    private router: Router,
    public elm: ElementRef,
    private sanitizer: DomSanitizer
  ) {
    this._tableauUrl = this.env.rivirApiBaseUrl + "/tableau";
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };
  }

  public async ngOnInit() {
    this.initTableau();
  }

  async initTableau() {
    this.loadWorkBooks(`${this._tableauUrl}/getWorkBook?filter=`);
    this.dashboard = await this.tableauService.getUserDashboard(this.userProfileService.userEmail);
    this.tableauServer = await this.tableauService.getServerURI();
  }   

  arrayBufferToBase64(buffer) {
    let binary = '';
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  } 

  loadWorkBooks(url){
    this.allWorkBooks = [];
    this.loaded = false;
    this._http.get(url, RivirHttp.getHttpOptions()).subscribe((workbooks) => {
      this.allWorkBooks = workbooks;
      this.allWorkBooks.forEach((obj)=>{
        obj.lastUpdatedDate = moment(obj.lastUpdatedDate).format("MM-DD-YYYY");
        if (obj.previewImage.data.length == 0){
          obj.previewImage = "https://images.squarespace-cdn.com/content/v1/587670ef03596ec731de6e3d/1486276069386-ENNKG7EAXQBUZHPMEY37/Tableau+Logo.png";
        }
        else{
          let base64String = this.arrayBufferToBase64(obj.previewImage.data);
          obj.previewImage = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + base64String);
        }
      });
      this.allWorkBooks.sort(function(a, b) {
        return +new Date(b.lastUpdatedDate) - +new Date(a.lastUpdatedDate);
      });
      this.loaded = true;
    });
  }


  getWorkbooks() {
    this.loadWorkBooks(`${this._tableauUrl}/getWorkBook?filter=` + this.searchItem);
  }


  async select(workBookId){
    this.allWorkBooks.filter(obj => {
      if (obj.workBookId === workBookId){
        this.selectedWorkBook = obj;
      }
    });
    console.log(this.selectedWorkBook);
    this.workBookTitle = this.selectedWorkBook.workbookName;
    localStorage.setItem('reportname', this.workBookTitle);
    if (this.selectedWorkBook.workbookViews.view && this.selectedWorkBook.workbookViews.view[0].view.contentUrl){
      this.contentUrl = this.selectedWorkBook.workbookViews.view[0].view.contentUrl.replace('/sheets','');
    }
    else if(this.selectedWorkBook.workbookViews.view[0].view[0]){
      this.contentUrl = this.selectedWorkBook.workbookViews.view[0].view[0].contentUrl.replace('/sheets',''); 
    }
    const navigationDetails: string[] = ['/report/reportpreview', `${this.selectedWorkBook.workBookId}`];
    this.router.navigate(navigationDetails);
  }




}
