import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FilterPipe } from "../shared/pipes/filter.pipe";
import { SightingService } from "../shared/services/sighting.service";
import { StixConfigService } from "../shared/services/stixConfig.service";
import { Sighting } from "../indicators/indicators-list/sighting";

@Component({
  selector: "app-sightings-view",
  templateUrl: "./sightings-view.component.html",
  styleUrls: ["./sightings-view.component.css"],
  providers: [FilterPipe],
})
export class SightingsViewComponent implements OnInit {
  public pageTitle: "Sightings";
  public sightings: Sighting[] = [];
  public SightingsColumns =
    [
      { prop: "type", name: "sighting_singular Type" },
      { prop: "indicatorId", name: "indicator_singular Id" },
      { prop: "indicatorName", name: "indicator_singular Name" },
      { prop: "classificationsDesc", name: "Classifications" },
      { prop: "runDate", name: "Run Date" },
      { prop: "threatActorCount", name: "threatActor_plural" },
    ];

  public rows: Sighting[] = [];
  public original_rows: Sighting[] = [];
  public columns = [];
  public tableTitle = "List of All Sightings";
  public p = 1;
  public limitRowsOptions = [10, 25, 50, 100];
  public limitRows: number = this.limitRowsOptions[0];
  public tableInfo = "";
  public sortKey = "runDate";
  public asc = false;
  public loaded = false;
  public term = "";

  constructor(private sightingService: SightingService, private router: Router, private filterPipe: FilterPipe, public stixConfig: StixConfigService) { }

  /**
   * Setup for the Sightings page
   */
  public ngOnInit() {
    this.fetchSightings();

  }

  public fetchSightings() {

    this.sightingService.getFormattedSightings().subscribe((s) => {
      this.columns = this.stixConfig.stixLabeling(this.SightingsColumns);
      this.sightings = s;
      this.rows = s;
      this.original_rows = this.rows;
      this.updateTabelInfo();
      this.loaded = true;
    });
  }

  public updateTabelInfo() {
    const startRange = this.rows.length ? this.limitRows * (this.p - 1) + 1 : 0;
    let endRange = this.limitRows * this.p;
    endRange = endRange > this.rows.length ? this.rows.length : endRange;
    this.tableInfo = "Showing " + startRange + " to " + endRange + " of " + this.rows.length + " entries";
  }

  public itemClick(columnName, item) {
    if (columnName === "indicatorId" || columnName === "indicatorName") {
      this.router.navigate(["indicators", item.indicatorId]);
    } else if (columnName == "threatActorCount") {
      this.router.navigate(["/indicators/runDate", item.name]);
    }
  }

  public getHoverTitle(prop, item) {
  }
  public columnClick(columnName) {
    if (this.sortKey === columnName) {
      this.asc = !this.asc;
    }
    this.sortKey = columnName;
    this.p = 1;
    this.updateTabelInfo();
  }

  public filterRows() {
    this.rows = this.filterPipe.transform(this.original_rows, this.term, this.columns);
    this.updateTabelInfo();
  }

}
