<div class="container-fluid">
  <div class="row" *ngIf="bulkStatusType !== 'Bulk Assignment'">
    <div class="col-md-4"></div>
    <div class="col-md-2 bulk-status-run-header">
      Bulk Run Date\Time:
    </div>
    <div class="col-md-6">
      <select class="form-control" (change)="onBulkRunChanged($event)" [(ngModel)]="selectedBulkRun">
        <optgroup *ngFor="let instanceGroup of instanceGroups" label="{{ instanceGroup.sighting }}">
          <option *ngFor="let instance of instanceGroup.instances" value="{{ instance.instanceId }}">
            {{ instance.bulkRunDate }}</option>
        </optgroup>
      </select>
    </div>
  </div>

  <mat-card class="bulk-status-card">
    <mat-card-header>
      <mat-card-title><span *ngIf="bulkStatusType === 'Bulk Assignment'">Bulk Re-assignment</span>
        <span *ngIf="bulkStatusType !== 'Bulk Assignment'">{{
          selectedSighting
        }}</span></mat-card-title>
      <mat-card-subtitle>{{ selectedBulkRun }}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div class="col-md-6 bulk-status-total-header">
          <span *ngIf="bulkStatusType === 'Bulk Assignment'">Bulk Re-assignment Totals
          </span>
          <span *ngIf="bulkStatusType !== 'Bulk Assignment'">
            {{ bulkStatusType }} Totals
          </span>
        </div>
      </div>
      <div class="row" *ngFor="let statusTotal of statusTotals">
        <div class="col-md-3">{{ statusTotal.user }}</div>
        <div class="col-md-2 ">
          <span class="status-{{ statusTotal.status }}">{{
            statusTotal.status
          }}</span>
        </div>
        <div class="col-md-1">{{ statusTotal.total }}</div>
      </div>
    </mat-card-content>
  </mat-card>

  <div class="row">
    <div class="col-md-12">
      <div id="bulk-grid" class="bulk-grid">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
          <!-- Threat Actor Column -->
          <ng-container matColumnDef="taId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ stix.threatActorLabel }} ID
            </th>
            <td mat-cell *matCellDef="let element">
              <a href="/threatActor/{{ element.threatActorId }}" target="_blank"
                style="color:#1c6fc6;">{{ element.taId }}</a>
            </td>
          </ng-container>

          <!-- User Column -->
          <ng-container matColumnDef="user">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              User
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.user }}
            </td>
          </ng-container>

          <!-- Course Of Action Column -->
          <ng-container matColumnDef="courseOfAction">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ stix.coursesOfActionsLabel }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.courseOfActionName }}
            </td>
          </ng-container>

          <!-- Status Column -->
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Status
            </th>
            <td mat-cell *matCellDef="let element">
              <span class="status-{{ element.status }}">{{
                element.status
              }}</span>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 100, 1000]">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>