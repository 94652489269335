<a (click)="onIconClick()" data-toggle="dropdown" class="alert-icon dropdown-toggle" (clickElsewhere)="clickOut()">
  <i class="fa fa-bell-o fa-fw"></i>
  <span class="visible-xs-inline">Notifications</span>
  <span *ngIf="newMessagesCount" class="badge badge-sm up bg-danger pull-right-xs">{{newMessagesCount}}</span>
</a>
<div class="dropdown-menu w-xxl ">
  <div class="panel bg-white">
    <div class="panel-heading b-light bg-light">
      <strong>You have
        <span>{{newMessagesCount}}</span> new alerts</strong>
    </div>

    <div class="list-group">
      <a *ngFor="let message of userMessages" class="list-group-item status-{{message.status}}">
        <!-- <span class="pull-left m-r thumb-sm">
                <img src="img/a3.jpg" alt="..." class="img-circle">
              </span> -->
        <span class="clear block m-b-none">
          <b>{{message.type}}: </b>{{message.body}}  
          <br>
          <small class="text-muted">{{message.timePassed}}</small>
        </span>
      </a>
    </div>

    <div class="panel-footer text-sm button" routerLink="notificationList">
      <!-- <a class="pull-right">
        <i class="fa fa-cog"></i>
      </a> -->
      <a data-toggle="fadeInRight">See all the notifications</a>
    </div>
  </div>
</div>