import { HttpClient } from "@angular/common/http";
import { Component, Input, OnInit } from "@angular/core";
import * as moment from "moment";
import { BsModalRef } from "ngx-bootstrap/modal";
import { EnvService } from "../../env.service";
import { RivirHttp } from "../../shared/services/rivir-http.service";
import { UserProfileService } from "../../core/userProfile.service";

@Component({
  selector: "app-scheduleview",
  templateUrl: "./scheduleview.component.html",
  styleUrls: ["./scheduleview.component.css"],
})
export class ScheduleViewComponent implements OnInit {
  @Input() public payload: any;
  @Input() public modelName: string;

  public frequency: string;
  public untilDate: string;
  public startDate: string;
  public startTime: string;
  
  public frequencyValues: any[] = [];

  public startDate_valid = false;
  public untilDate_valid = true;
  public todaysDate = moment().format("YYYY-MM-DD");

  private executeApiUrl = "/algorithms/execute";
  private timedJobsQueueApiUrl: string;
  public accessToken = RivirHttp.rivirApiToken;

  constructor(public modalRef: BsModalRef, 
              private _http: HttpClient, 
              private userService: UserProfileService,
              private env: EnvService) {
                this.timedJobsQueueApiUrl = this.env.rivirApiBaseUrl + "/timedJobsQueue";
              }

  public ngOnInit() {
    this.frequencyValues = ["Hourly", "Daily", "Weekly", "Monthly", "Quarterly", "Annually"];
  }

  public saveSchedule() {
    if (this.startDate_valid && this.untilDate_valid && this.startTime) {
      const executeUrl = `${this.executeApiUrl}?name=${encodeURIComponent(this.modalRef.content.indicator.name)}&currentUser=${encodeURIComponent(this.userService.fullName)}`;
      const label = this.modalRef.content.indicator.name;
      const start = `${this.startDate} ${this.startTime}`;
      const momentStartDate = moment(start, "YYYY-MM-DD hh:mm a");
      let cron_frequency = [];
      let description = "";
      const endTime = this.untilDate ? moment(`${this.untilDate} ${this.startTime}`, "YYYY-MM-DD hh:mm a").toISOString() : moment(this.modalRef.content.maxScheduleEndDate, "YYYY-MM-DD").toISOString();

      switch(this.frequency.toLocaleLowerCase()){
        case "hourly":
          cron_frequency.push(`0 ${momentStartDate.minute()} * * * *`);
          description = this.getScheduleDescriptionText("hourly", momentStartDate.toISOString(), endTime)
          break;
        case "daily":
          cron_frequency.push(`0 ${momentStartDate.minute()} ${momentStartDate.hour()} * * *`);
          description = this.getScheduleDescriptionText("daily", momentStartDate.toISOString(), endTime)
          break;
        case "weekly":
          cron_frequency.push(`0 ${momentStartDate.minute()} ${momentStartDate.hour()} * * ${momentStartDate.day()}`);
          description = this.getScheduleDescriptionText("weekly", momentStartDate.toISOString(), endTime)
          break;
        case"monthly":
          // const monthday = momentStartDate.date()
          // adjust based on proper last day of month when month is great than 28 days
          if (momentStartDate.date() > 28) {
            // add three entries when month has less days than the scheduled day of month number
            cron_frequency.push(`0 ${momentStartDate.minute()} ${momentStartDate.hour()} ${momentStartDate.date()} 1,3,5,7,8,10,12 *`);
            cron_frequency.push(`0 ${momentStartDate.minute()} ${momentStartDate.hour()} ${momentStartDate.date() > 30 ? 30 : momentStartDate.date()} 4,6,9,11 *`);
            cron_frequency.push(`0 ${momentStartDate.minute()} ${momentStartDate.hour()} 28,29 2 *`);
          } else {
            cron_frequency.push(`0 ${momentStartDate.minute()} ${momentStartDate.hour()} ${momentStartDate.date()} * *`);
          }
          description = this.getScheduleDescriptionText("monthly", momentStartDate.toISOString(), endTime)
          break;
        case "quarterly":
          // calculate quarter months
          const monthQ1 = momentStartDate.month(); // day of month from 0-11
          const monthQ2 = (momentStartDate.month() + 3) % 12;
          const monthQ3 = (momentStartDate.month() + 6) % 12;
          const monthQ4 = (momentStartDate.month() + 9) % 12;

          // calculate day
          const dayOfMonthMatchQ1 = momentStartDate.date(); // day of month from 1-31
          cron_frequency.push(`0 ${momentStartDate.minute()} ${momentStartDate.hour()} ${dayOfMonthMatchQ1} ${monthQ1} *`);

          if (moment(this.untilDate).isSameOrAfter(moment(this.startDate).add(3, "M"))) {
            const dayOfMonthMatchQ2 = this.getProperDayOfMonth(monthQ2, dayOfMonthMatchQ1);
            if (dayOfMonthMatchQ2.leapDay) {
              cron_frequency.push(`0 ${momentStartDate.minute()} ${momentStartDate.hour()} 28,29 ${monthQ2} *`);
            } else {
              cron_frequency.push(`0 ${momentStartDate.minute()} ${momentStartDate.hour()} ${dayOfMonthMatchQ2.dayOfMonth} ${monthQ2} *`);
            }
          }
          if (moment(this.untilDate).isSameOrAfter(moment(this.startDate).add(6, "M"))) {
            const dayOfMonthMatchQ3 = this.getProperDayOfMonth(monthQ3, dayOfMonthMatchQ1);
            if (dayOfMonthMatchQ3.leapDay) {
              cron_frequency.push(`0 ${momentStartDate.minute()} ${momentStartDate.hour()} 28,29 ${monthQ3} *`);
            } else {
              cron_frequency.push(`0 ${momentStartDate.minute()} ${momentStartDate.hour()} ${dayOfMonthMatchQ3.dayOfMonth} ${monthQ3} *`);
            }
          }
          if (moment(this.untilDate).isSameOrAfter(moment(this.startDate).add(9, "M"))) {
            const dayOfMonthMatchQ4 = this.getProperDayOfMonth(monthQ4, dayOfMonthMatchQ1);
            if (dayOfMonthMatchQ4.leapDay) {
              cron_frequency.push(`0 ${momentStartDate.minute()} ${momentStartDate.hour()} 28,29 ${monthQ4} *`);
            } else {
              cron_frequency.push(`0 ${momentStartDate.minute()} ${momentStartDate.hour()} ${dayOfMonthMatchQ4.dayOfMonth} ${monthQ4} *`);
            }
          }

          description = this.getScheduleDescriptionText("quarterly", momentStartDate.toISOString(), endTime)
          break;
        case "annually":
          cron_frequency.push(`0 ${momentStartDate.minute()} ${momentStartDate.hour()} ${momentStartDate.date()} ${momentStartDate.month()} *`);
          description = this.getScheduleDescriptionText("annually", momentStartDate.toISOString(), endTime)
          break;
        default:
          break;
      }

      cron_frequency.forEach(cronSchedule => {
        const payload = {
          jobId: label.replace(/\s+/g, "-"),
          active: true,
          endpoint: executeUrl,
          method: "POST",
          name: label,
          indicator: this.modalRef.content.indicator.name,
          frequency: this.frequency.toLocaleLowerCase(),
          cronSchedule: cronSchedule,
          startTime: momentStartDate.toISOString(),
          endTime: endTime,
          description: description,
          type: "indicator",
          createdBy: `"${this.userService.fullName}" <${this.userService.userEmail}>` || "system",
          updated: moment().toISOString()
        };
  
        if (this.frequency) {
          this._http.post(`${this.timedJobsQueueApiUrl}/addRequest?access_token=${this.accessToken}`, { job: payload })
            .subscribe((data) => {
              this.modalRef.hide();
            });
        }  
      })
    }
  }

  /* Make sure month day is valid, otherwise calculate day */
  private getProperDayOfMonth = function (month, day) {
    // const monthsWith31Days = [0, 2, 4, 6, 7, 9, 11];
    const monthsWith30Days = [3, 5, 8, 10];
    let leapDay = false;

    if (monthsWith30Days.includes(month)) { // Apr, Jun, Sep, Nov
      if (day > 30) day = 30;
    } else { // Feb
      if (day > 28) {
        leapDay = true;
        day = 28;
      }
    }
    return {
      dayOfMonth: day,
      leapDay
    }
  }

  private getScheduleDescriptionText = function(type, startTime, endTime) {
    const untilMessage = moment(endTime).format("YYYY-MM-DD") === this.maxScheduleEndDate
        ? ""
        : ` It will Run until ${moment(endTime).format("MM/DD/YYYY")} at ${moment(endTime).format("h:mmA")}.`;

    return (`Scheduled to Run ${type.charAt(0).toUpperCase() +
      type.slice(1)} Starting at ${moment(startTime).format(
        "h:mmA on MM/DD/YYYY"
      )}.${untilMessage}`);
  };

  public datesChanged() {
    const checkStartDate = moment(this.startDate, "YYYY-MM-DD");
    const checkUntilDate = moment(this.untilDate, "YYYY-MM-DD");
    this.untilDate_valid = !!(checkUntilDate.diff(checkStartDate, "days") >= 0 || !this.untilDate);
    this.startDate_valid = !!(checkStartDate.diff(moment(), "days") >= 0);
  }
}
