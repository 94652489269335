<h2 class="row col-sm-12 m-b-md" *ngIf="indicator">
  <div class="id-label" style="position: absolute;top: 2px;"><i class="indicator-icon bg-dark"></i></div>
  <div style="padding-left: 55px;">
    <span class="id-label " attr.aria-label="{{indicator.name}}">{{indicator.name}}</span>
  </div>
</h2>
<div class="row run-date" *ngIf="indicator && runDate">
  <div class="i-label col-sm-2">{{sightingLabel}}</div>
  <div class="col-sm-4 date-label" style="color: #135978;">
    {{runDate | date: 'M/d/yyyy h:mm a'}}
  </div>
  <div class="col-sm-6"></div>
</div>
<div class="row col-sm-12 m-t-sm ta-list" *ngIf="indicator">
  <app-threat-actor-sighting-list [recommendedCOAs]="indicator.coursesOfAction" [sightingParams]="sightingId_params">
  </app-threat-actor-sighting-list>
</div>