import { Component, OnInit } from '@angular/core';
import { AppSettingService, ServiceStatus } from '../../shared/services/appsetting.service';

@Component({
  selector: 'app-service-check',
  templateUrl: './service-check.component.html',
  styleUrls: ['./service-check.component.css']
})
export class ServiceCheckComponent implements OnInit {

  public serviceStatus : ServiceStatus[];

  constructor(
    private appSettingsService: AppSettingService
  ) { }

  async ngOnInit() {
    this.serviceStatus = await this.appSettingsService.getServiceStatus();
  }

}
