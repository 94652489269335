
import { AccountRequestViewComponent } from "../admin-settings/accountrequestview/accountrequestview.component";
import { CoaDetailsComponent } from "../coa/coa-details/coa-details.component";
import { TaskDetailsComponent } from "./task-details/task-details.component";
import { TaskDiagramViewComponent } from "./task-diagram-view/task-diagram-view.component";
import { AllTasksListComponent } from "./tasks-list/all-tasks-list.component";
import { MyTasksListComponent } from "./tasks-list/my-tasks-list.component";
import { TasksComponent } from "./tasks.component";

const TasksRoutes = [
    {
      path: "", component: TasksComponent,
    },
    {
      path: "task/:instanceId", component: TaskDetailsComponent,
      data: {text: "Task Details", breadcrumbs: true},
     },
    {
      path: ":id",
      data: {
        text: "coa_singular Details",
        breadcrumbs: true,
      },
      children: [
        {
          path: "",
          component: CoaDetailsComponent,
        },
         {
           path: "userProfile/:id",
           component: AccountRequestViewComponent,
           data: {
             text: "View User Profile",
             breadcrumbs: true,
           },
          },
      ],
    },
  ];

export const TasksComponents = [
  TasksComponent,
  MyTasksListComponent,
  AllTasksListComponent,
  TaskDetailsComponent,
  TaskDiagramViewComponent
];

export { TasksRoutes };
