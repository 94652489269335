import { Component } from "@angular/core";
import { Router } from "@angular/router";

import { KeycloakService } from "./core/keycloak/keycloak.service";
import { StartupService } from "./core/startup.service";
import { RivirAPI } from "./rivirapi/rivirapi.sdk";

import { DEFAULT_INTERRUPTSOURCES, Idle } from "@ng-idle/core";
import { Keepalive } from "@ng-idle/keepalive";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { EnvService } from "./env.service";
import { IdleTimeoutComponent } from "./core/idle-timeout/idle-timeout.component";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
  providers: [RivirAPI],
})
export class AppComponent {

  public model: any = {};
  public loading: boolean = false;
  public error: string = "";
  public modalRef: BsModalRef;
  public sessionIdleConfig: number;
  public hasSearch: boolean;
  public hasCaseManagement: boolean;
  public hasDataEntry: boolean;
  public hasReport: boolean;

  constructor(private router: Router, private startup: StartupService, private kc: KeycloakService,
    private modalService: BsModalService,
    private idle: Idle, private keepalive: Keepalive, private env: EnvService) {

    this.sessionIdleConfig = this.env.sessionIdleTimeout || 30;
    if (this.sessionIdleConfig >= 2) {
      this.setSessionIdle();
    }
  }

  public ngOnInit() {
    if (!this.startup.initialized) {
      console.error("Application configuration failed");
      this.router.navigate(["error"], { replaceUrl: true });
    }
    this.hasSearch = this.env.searchBar;
    this.hasCaseManagement = this.env.hasCaseManagement;
    this.hasDataEntry = this.env.hasDataEntry;
    this.hasReport = this.env.reportBar;
  }

  public setSessionIdle() {
    // Session Idle timeout Configuration
    const refreshToken = Math.round(this.sessionIdleConfig / 3);
    const userTimeout = Math.round(this.sessionIdleConfig / 3);
    const userIdleTime = this.sessionIdleConfig - userTimeout;
    this.idle.setIdle(userIdleTime * 60);
    this.idle.setTimeout(userTimeout * 60);
    this.keepalive.interval(refreshToken * 60);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document

    this.idle.onTimeoutWarning.subscribe((countdown) => {
      if (countdown != null && countdown >= (userTimeout * 60)) {
        this.modalRef = this.modalService.show(IdleTimeoutComponent, null);
        this.modalRef.content.modelName = "Session Timeout";
        this.modalRef.content.closeBtnName = "Close";
      }
    });

    this.idle.onTimeout.subscribe(() => {
      this.kc.logout( "IDLE On Timeout" );
    });

    this.keepalive.onPing.subscribe(() => {
      this.kc.getToken()
        .catch((err) => {
          console.error( err );
          this.kc.logout("Keep Alive On Ping");
        });
    });

    this.idle.watch();
  }
}
