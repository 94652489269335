<div [hidden]="isLoading">
    <div [hidden]="dashboardSelected" class="container-fluid">
        <div class="row">
            <div class="col-md-4"></div>
            <div class="col-md-4 new-workbook">
                <div class="nw_icon">
                    <mat-icon fontSet="material-icons-outlined" >insights</mat-icon>
                    <mat-icon fontSet="material-icons-outlined" >account_tree</mat-icon>
                </div>
                <div class="nw_icon">
                    <mat-icon fontSet="material-icons-outlined" >bar_chart</mat-icon>
                    <mat-icon fontSet="material-icons-outlined" >pie_chart</mat-icon>
                </div>
                <div class="nw_label">
                    This Dashboard is empty, choose a {{stixConfig.visualization_singular}} that meets your needs and preference
                </div>
                <div>
                    <button class="btn btn-success" aria-label="Choose Workbook" (click)="goToSearch()">Choose {{stixConfig.visualization_singular}}</button>
                </div>
            </div>
            <div class="col-md-4"></div>
        </div>
    </div>
    <div [hidden]="!dashboardSelected">
        <div class="container-fluid"> 
            <div class="row dashboard-header-row">
                <div class="col-md-6">
                    <span class="dashboard-header">{{ dashboardSelected  ? dashboardSelected.workbook  : ''}}</span>
                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="changeDashboard()">
                        <mat-icon>edit</mat-icon>
                        <span>Change Workbook</span>
                        </button>
                        <button mat-menu-item (click)="removeDashboard()">
                        <mat-icon>remove_circle_outline</mat-icon>
                        <span>Remove Workbook</span>
                        </button>
                    </mat-menu>
                    
                </div>
            </div>
        </div>
        <div id="vizContainer" style="display: flex; justify-content: center"></div>
    </div>
</div>