import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { isDevMode } from "@angular/core";

import { CookieService } from 'ngx-cookie-service';

import { Observable } from "rxjs";
import { catchError, map } from 'rxjs/operators';

import { EnvService } from  "../../env.service"; 
import { UserProfile } from "../../shared/models/userProfile";
import { UserTask } from "../../shared/models/usertask";
import { WorkflowError } from "../../shared/models/workflowError";
import { WorkflowUserProfile } from "../../shared/models/workflowUserProfile";
import { RivirHttp } from "./rivir-http.service";

export class ProcessDefinition {
  public id: string;
  public key: string;
  public category: string;
  public description: string;
  public name: string;
  public version: number;
  public resource: string;
  public deploymentId: string;
  public diagram: string;
  public suspended: boolean;
  public tenantId: string;
  public versionTag: string;
  public historyTimeToLive: number;
  public active: boolean;
}

@Injectable()
export class WorkflowService {
  public static currentUser: WorkflowUserProfile = null;

  // Camunda SSO URL
  public workflowLoginUrl: string;

  public lastError: WorkflowError = null;
  // Whether the Camunda REST API has Basic Auth turned on
  private basicAuthorization = true;

  // Rivir workflow api URL
  private workflowApiUrl: string;

  constructor(private _http: HttpClient, private cookieService: CookieService, private env: EnvService) {

    this.workflowLoginUrl =
      this.env.workflowBaseUrl + "/camunda/api/admin/auth/user/default/login/tasklist";
    this.workflowApiUrl = this.env.rivirApiBaseUrl + "/workflow";


    // TODO Remove this static user and get data from the cookie instead
    WorkflowService.currentUser = {
      id: this.cookieService.get("email"),
      firstName: this.cookieService.get("firstName"),
      lastName: this.cookieService.get("lastName"),
      email: this.cookieService.get("email"),
      password: RivirHttp.camundaToken,
      group: this.cookieService.get("role"),
    };
  }

  public loginUser(
    userId: string,
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    group: string,
  ) {
    if (!userId || !password) {
      throw new Error("User id and password must be provided");
    }

    if (password) {
      // Trim the keycloak password of equal signs and limited characters (storing the full token length had issues)
      password = password.replace("=", "").substring(0, 8);
    }

    if (!group) {
      group = "System Administrator";
    }

    this.getUser(userId).subscribe((user) => {
      if (user) {
        // User was not found as an exception was thrown
        if (user.results && user.results.status == "NOTFOUND") {
          // Ensure user is created in the workflow engine, even if it was missed during initial user registration
          this.createUser(
            userId,
            firstName,
            lastName,
            email,
            password,
            group,
          ).subscribe((res) => {
            if (res == "SUCCESS") {
            }
          });
        }

        // User was found or an error occured, but allow login to contine
        WorkflowService.currentUser = new WorkflowUserProfile();
        WorkflowService.currentUser.id = userId;
        WorkflowService.currentUser.firstName = firstName;
        WorkflowService.currentUser.lastName = lastName;
        WorkflowService.currentUser.email = email;
        WorkflowService.currentUser.password = password;
        WorkflowService.currentUser.group = group;

        this.workflowServerLogin();
      }
    });
  }

  public logoutUser() {
    WorkflowService.currentUser = null;
  }

  public workflowServerLogin() {
    if (
      WorkflowService.currentUser &&
      WorkflowService.currentUser.id &&
      WorkflowService.currentUser.password
    ) {
      // Ensure keycloak and workflow password are syncrhonized
      this.updateUserPassword(
        WorkflowService.currentUser.id,
        WorkflowService.currentUser.password,
      ).subscribe(
        (status) => {
          // Programmatically log into the workflow server
          const url = this.workflowLoginUrl;

          const params =
            "username=" +
            WorkflowService.currentUser.id +
            "&password=" +
            WorkflowService.currentUser.password;

          const xhr = new XMLHttpRequest();
          xhr.open("POST", url, true);
          xhr.setRequestHeader(
            "Content-type",
            "application/x-www-form-urlencoded",
          );
          xhr.withCredentials = true;

          xhr.onreadystatechange = function () {
            if (xhr.readyState == 4) {
              if (xhr.status == 200) {
              } else {
                if (isDevMode()) {
                  console.error(
                    "workflowServerLogin ERROR: ",
                    WorkflowService.currentUser.id,
                  );
                }
              }
            }
          };

          xhr.send(params);
        },
        (err) => {
          console.error(err);
        },
      );
    }
  }

  public updateUserPassword(userId: string, password: string) {
    const url: string = this.workflowApiUrl + "/updateUserPassword";

    const body = {
      id: userId,
      password,
    };

    return this._http
      .post(url, body)
      .pipe(catchError(RivirHttp.handleError));
  }

  public getUsers() {
    const url: string =
      this.workflowApiUrl + "/getUsers" + "?token=" + this.getCredentials();

    return this._http
      .get(url)
      .pipe(catchError(RivirHttp.handleError));
  }

  public getUser(userId: string, token: string = ""): Observable<any> {
    if (!userId) {
      throw new Error("User id must be provided");
    }

    // Allow looking up if a user exists without an existing login for initial user creation purposes
    if (!token) {
      token = this.getCredentials();
    }

    const url: string =
      this.workflowApiUrl + "/getUser" + "?token=" + token + "&id=" + userId;

    return this._http
      .get(url)
      .pipe(catchError(RivirHttp.handleError));
  }

  public createUser(
    userId: string,
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    group: string,
  ): Observable<any> {
    if (!userId) {
      throw new Error("User id must be provided");
    }

    if (!password) {
      throw new Error("Password must be provided");
    }

    const url = this.workflowApiUrl + "/createUser";

    const body = {
      token: this.getCredentials(),
      id: userId,
      firstName,
      lastName,
      email,
      password,
      group,
    };

    return this._http
      .post(url, body)
      .pipe(catchError(RivirHttp.handleError));
  }

  public updateUser(profile: UserProfile, oldGroup: string) {
    if (!profile) {
      throw new Error("User profile must be provided");
    }

    const url = this.workflowApiUrl + "/updateUser";

    const body = {
      token: this.getCredentials(),
      id: profile.username,
      firstName: profile.firstName,
      lastName: profile.lastName,
      email: profile.email,
      group: profile.accountType,
      oldGroup,
    };

    return this._http
      .post(url, body)
      .pipe(catchError(RivirHttp.handleError));
  }

  public deleteUser(userId: string): Observable<any> {
    if (!userId) {
      throw new Error("User id must be provided");
    }

    const url = this.workflowApiUrl + "/deleteUser";

    const body = {
      token: this.getCredentials(),
      id: userId,
    };

    return this._http
      .post(url, body)
      .pipe(map((resp: any) => {
        return JSON.parse(resp.status);
      }))
      .pipe(catchError(RivirHttp.handleError));
  }

  public getGroups() {
    const url: string =
      this.workflowApiUrl + "/getGroups" + "?token=" + this.getCredentials();

    return this._http
      .get(url)
      .pipe(catchError(RivirHttp.handleError));
  }

  public getGroupUsers(id) {
    const url: string =
      this.workflowApiUrl +
      "/getGroupUsers" +
      "?token=" +
      this.getCredentials() +
      "&id=" +
      id;

    return this._http.get(url).pipe(catchError(RivirHttp.handleError));
  }

  public createGroup(id: string, name: string, type: string): Observable<any> {
    if (!id) {
      throw new Error("id and password must be provided");
    }

    const url = this.workflowApiUrl + "/createGroup";

    const body = {
      token: this.getCredentials(),
      id,
      name,
      type,
    };

    return this._http
      .post(url, body)
      .pipe(catchError(RivirHttp.handleError));
  }

  public deleteGroup(id: string): Observable<any> {
    if (!id) {
      throw new Error("id must be provided");
    }

    const url = this.workflowApiUrl + "/deleteGroup";

    const body = {
      token: this.getCredentials(),
      id,
    };

    return this._http
      .post(url, body)
      .pipe(catchError(RivirHttp.handleError));
  }

  public getFilters() {
    const url: string =
      this.workflowApiUrl + "/getFilters" + "?token=" + this.getCredentials();

    return this._http
      .get(url)
      .pipe(catchError(RivirHttp.handleError));
  }

  public createFilter(
    resourceType: string,
    name: string,
    owner: string,
    query: any,
    properties: any,
  ): Observable<any> {
    if (!resourceType || !name || !owner || !query || !properties) {
      throw new Error(
        "resourceType, name, owner, query, and properties are required",
      );
    }

    const url = this.workflowApiUrl + "/createFilter";

    const body = {
      token: this.getCredentials(),
      resourceType,
      name,
      owner,
      query,
      properties,
    };

    return this._http
      .post(url, body)
      .pipe(catchError(RivirHttp.handleError));
  }

  public deployProcess(
    businessKey: string,
    source: string,
    file: File,
  ): Observable<any> {
    if (!businessKey) {
      throw new Error("Process business key not provided");
    }

    const url = this.workflowApiUrl + "/deployProcess";

    const body = {
      token: this.getCredentials(),
      businessKey,
      source,
      fileName: file.name,
    };

    return this._http
      .post(url, body)
      .pipe(catchError(RivirHttp.handleError));
  }

  public startProcess(businessKey: string): Observable<any> {
    if (!businessKey) {
      throw new Error("Process business key not provided");
    }

    const url: string = this.workflowApiUrl + "/startProcess";

    const body = {
      token: this.getCredentials(),
      businessKey,
    };

    return this._http
      .post(url, body)
      .pipe(map((resp: any) => {
        return JSON.parse(resp.status);
      }))
      .pipe(catchError(RivirHttp.handleError));
  }

  public startProcessWithPayload(
    businessKey: string,
    payload,
    userId,
  ): Observable<any> {
    if (!businessKey) {
      throw new Error("Process business key not provided");
    }

    const url: string = this.workflowApiUrl + "/startProcessWithPayload";

    payload.userId = userId;

    // Users can possibly make a payload key with invalid
    // remove them to ensure that the course of action start correctly 
    payload = this.cleanPayloadKeys(payload);

    const body = {
      token: this.getCredentials(),
      businessKey,
      payload,
      autoStart: false,
    };

    return this._http
      .post(url, body)
      .pipe(catchError(RivirHttp.handleError));
  }

  public startProcessWithTenantId( key: string,
                                   tenantId : string, 
                                   payload: object){

    const url = `${this.workflowApiUrl}/start/tenant/${tenantId}/key/${key}`;
    return this._http
               .post(url, payload)
               .toPromise();
  }

  public async getProcessDefinitions(): Promise<ProcessDefinition[]> {
    const url: string =
      this.workflowApiUrl +
      "/processdefinitions";

    const processDefinitions = await this._http.get(url).pipe(catchError(RivirHttp.handleError)).toPromise();

    return processDefinitions ? processDefinitions as ProcessDefinition[] : [];
  }

  public cleanPayloadKeys(dirtyPayload): any {

    let cleanPayload = {};
    for (const [dirtyKey, value] of Object.entries(dirtyPayload)) {

      // Do no add old Reference Data like coaJSON and WorkflowCompleted
      const invalidReferenceDataVars = ["coaJSON", "WorkFlowCompleted"];
      if (!invalidReferenceDataVars.includes(dirtyKey)) {
        // Remove the default characters 
        let cleanKey = dirtyKey.replace(/[!@#$^&%*()+=[\]\/{}|:<>?,.\\-]/g, '');
        cleanPayload[cleanKey] = value;
      }
    }

    return cleanPayload;
  }

  public getUserTasks(userId): Observable<UserTask[]> {
    const url: string = this.workflowApiUrl + "/getUserTasks?userId=" + userId;

    return this._http
      .get(url)
      .pipe(map((resp: any) => {
        return resp.results || resp;
      }))
      .pipe(catchError(RivirHttp.handleError));
  }

  public getAllTasks(): Observable<UserTask[]> {
    const url: string = this.workflowApiUrl + "/getAllTasks";

    return this._http
      .get(url)
      .pipe(map((resp: any) => {
        return resp.results || resp;
      }))
      .pipe(catchError(RivirHttp.handleError));
  }

  public claimUserTask(taskId: string, userId: string): Observable<any> {
    const url: string = this.workflowApiUrl + "/claimUserTask/";

    if (!taskId && !userId) {
      throw new Error("taskId and userId must be provided");
    }

    const body = {
      token: this.getCredentials(),
      taskId,
      userId,
    };

    return this._http
      .post(url, body)
      .pipe(catchError(RivirHttp.handleError));
  }

  public completeUserTask(taskId: string, userId: string): Observable<any> {
    const url: string = this.workflowApiUrl + "/completeUserTask/";

    if (!taskId) {
      throw new Error("taskId must be provided");
    }

    const body = {
      token: this.getCredentials(),
      taskId,
      userId,
    };

    return this._http
      .post(url, body)
      .pipe(map((resp: any) => {
        return JSON.parse(resp.status);
      }))
      .pipe(catchError(RivirHttp.handleError));
  }

  public getTask(instanceId): Observable<any> {
    const url: string =
      this.workflowApiUrl + "/getTask?instanceId=" + instanceId;

    return this._http
      .get(url)
      .pipe(catchError(RivirHttp.handleError));
  }

  private getCredentials() {
    let credentials = "";

    if (this.basicAuthorization) {
      if (WorkflowService.currentUser != null) {
        credentials = btoa(
          WorkflowService.currentUser.id +
          ":" +
          WorkflowService.currentUser.password,
        );
      }
    }

    return credentials;
  }

  /**
   * Gets the next task in a Camunda workflow
   * @param processInstanceId Camunda Process Instance Id
   * @param currentTaskId Current Task Id
   */
  public getNextTask(processInstanceId: string, currentTaskId: string): any {
    // Create the url for the retrieving the next task
    let getNextTaskUrl = `${this.workflowApiUrl}/task?processInstanceId=${processInstanceId}`;
    getNextTaskUrl = currentTaskId
      ? getNextTaskUrl + `&currentTask=${currentTaskId}`
      : getNextTaskUrl;

    return this._http.get(getNextTaskUrl).pipe(catchError(RivirHttp.handleError));
  }

  public getCamundaDiagramXML(processInstanceId? : string, deploymentId? : string): Observable<any> {
    const url = processInstanceId  ? 
                  `${this.workflowApiUrl}/getCamundaDiagramXML?processDefinitionId=${processInstanceId}` :
                  `${this.workflowApiUrl}/getCamundaDiagramXML?deploymentId=${deploymentId}`;

    return this._http
      .get(url)
      .pipe(catchError(RivirHttp.handleError));
  }

  public getIntakeForms() : Promise<ProcessDefinition[]>{
    const intakeUrl = `${this.env.rivirApiBaseUrl}/workflow/intakes`;
    return this._http
    .get<ProcessDefinition[]>(intakeUrl, RivirHttp.getHttpOptions())
    .toPromise()
  }
}
