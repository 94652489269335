<div [style.width]="matCardWidth" *ngIf="caseCounts">
    <mat-card>
        <mat-card-content>
            <div class="row">
                <div *ngFor="let caseCount of caseCounts" [class]="statusColClass" [style.color]="caseCount.color">
                    <div class="case-counts-count">{{caseCount.count}}</div>
                    <div class="case-counts-status">{{caseCount.status}}</div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>