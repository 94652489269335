import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as moment from "moment";

import { Observable } from "rxjs";
import { catchError } from 'rxjs/operators';

import { EnvService } from "../env.service";
import { RivirHttp } from "../shared/services/rivir-http.service";

@Injectable()
export class SearchService {
  public criteria : any = {
    pageNumber: 1,
    pageLimit: 50,
    sortBy: "updateddatetime",
    asc: false,
    filterBy: "",
    advanceFilter: null
  };
  public dateFormatFields: string[] = [];

  private _observableDataUrl: string;
  private _EDVMedicalReview: string;

  constructor(private _http: HttpClient, private env: EnvService) {
    this._observableDataUrl = this.env.rivirApiBaseUrl + "/observableDataMetadata";
    this._EDVMedicalReview = this.env.rivirApiBaseUrl + "/EDVMedicalReviews";
  }

  public getSearchableItems(): Observable<any> {

    this.criteria.pageLimit = (typeof this.criteria.pageLimit === 'string' || this.criteria.pageLimit instanceof String) ?
                              parseInt(this.criteria.pageLimit) : this.criteria.pageLimit;
                              
    const params = "?criteria=" + encodeURIComponent(JSON.stringify(this.criteria));

    return this._http.get<any>(this._EDVMedicalReview + "/search" + params, RivirHttp.getHttpOptions())
      .pipe(catchError(RivirHttp.handleError));
  }

  public getSearchableDataFields(type): Observable<any> {
    const query = `?filter={"where" : {"and" : [{"isSearchable": "true"}, {"type": "${type}"}]}}`;
    return this._http.get<any>(this._observableDataUrl + query, RivirHttp.getHttpOptions())
      .pipe(catchError(RivirHttp.handleError));
  }

  public getCsv(statusFilter): Observable<any> {
    const urlParams = (statusFilter) ? "?statusFilter=" + statusFilter : "";
    return this._http.get<any>(this._EDVMedicalReview + "/csv" + urlParams, RivirHttp.getHttpOptions())
      .pipe(catchError(RivirHttp.handleError));
  }

  public modifyAdvanceFilter(prop, string) {
    if (!this.criteria.advanceFilter) {
      this.criteria.advanceFilter = {};
    }
    if (string) {
      this.criteria.advanceFilter[prop] = string.trim();
    }
    if (this.criteria.advanceFilter[prop] && !string) {
      delete this.criteria.advanceFilter[prop];
    }

    if (Object.keys(this.criteria.advanceFilter).length < 1) {
      this.criteria.advanceFilter = null;
    }
  }

  public filterInDateFormat(string) {
    const startDate = moment(string);
    if (startDate) {
      const endDate = moment(startDate, "DD-MM-YYYY")
      .add(23, 'hours')
      .add(59, 'minutes')
      .add(59, 'seconds')
      .toDate();
      if (string.length <= 4) {
        moment(endDate).add(1, 'year');
      }
      return [moment(startDate, moment.ISO_8601).toDate(), endDate.toISOString()];
    } else {
      return [];
    }
  }
}
