import { AfterViewInit, Component, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CookieService } from 'ngx-cookie-service';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { FilterPipe } from "../../shared/pipes/filter.pipe";
import { StixConfigService } from "../../shared/services/stixConfig.service";
import { CoaExecuteComponent } from "../../coa/coa-execute/coa-execute.component";
import { PermissionService } from "../../core/permission.service";
import { ThreatActorService } from "../threat-actor.service";
import { TABLE_COLUMNS_TA_LIST } from "./threat-actor-list-columns";

@Component({
  selector: "app-threat-actor-list",
  templateUrl: "./threat-actor-list.component.html",
  styleUrls: ["./threat-actor-list.component.css"],
  providers: [FilterPipe],
})
export class ThreatActorListComponent implements AfterViewInit {

  public rows = [];
  public original_rows = [];
  public columns = [];
  public tableTitle: string;
  public p: number;
  public limitRowsOptions = [10, 25, 50, 100];
  public limitRows: number;
  public tableInfo: string = "";
  public sortKey: string;
  public asc: boolean;
  public loaded = false;
  public term: string;
  public filterSubject: Subject<void> = new Subject<void>();
  public modalRef: BsModalRef;
  public permission_ignoreTA = false;
  public totalRecords: number;
  public totalAllRecords: number;
  @Input() public recommendedCOAs;
  @Input() public sightingParams;

  constructor(private threatActorService: ThreatActorService, private route: ActivatedRoute, private permissions: PermissionService,
    private modalService: BsModalService, private router: Router,
    public stixConfig: StixConfigService, private cookieService: CookieService) {
    this.tableTitle = "List of All " + this.stixConfig.threatActor_plural;
    this.p = this.threatActorService.critiria.pageNumber;
    this.limitRows = this.threatActorService.critiria.pageLimit;
    this.sortKey = this.threatActorService.critiria.sortBy;
    this.asc = this.threatActorService.critiria.asc;
    this.term = this.threatActorService.critiria.filterBy;
  }

  public ngAfterViewInit() {
    this.permissions.getPermission("Ignore Threat Actor").then((p) => { this.permission_ignoreTA = p; });
    this.threatActorService.getAllThreatActorsCount(null)
      .subscribe((res) => {
        this.totalRecords = res.count;
        this.loaded = false;
        this.updateTable();
        this.totalAllRecords = this.totalRecords;
      });
  }

  public ngOnInit() {
    this.filterSubject
      .pipe(debounceTime(800))
      .subscribe((x) => {
        this.updateTable();
      });
  }

  public updateTable() {
    this.threatActorService.critiria.pageNumber = this.p;
    this.threatActorService.critiria.pageLimit = this.limitRows;
    this.threatActorService.critiria.sortBy = this.sortKey;
    this.threatActorService.critiria.asc = this.asc;
    this.threatActorService.critiria.filterBy = this.term;
    this.fetchThreatActorList();
  }

  public fetchThreatActorList() {
    this.threatActorService.getFormattedThreatActors()
      .subscribe((threatActors) => {
        this.threatActorService.getTableColumns(TABLE_COLUMNS_TA_LIST).then((cols) => {
          this.columns = cols;
          if (!this.permission_ignoreTA) {
            this.rows = threatActors.filter((ta) => ta.ignore == false);
          } else {
            this.rows = threatActors;
          }
          if (this.term) {
            this.threatActorService.getAllThreatActorsCount(this.term)
              .subscribe((res) => {
                this.totalRecords = res.count;
                this.original_rows = this.rows;
                this.updateTabelInfo();
                this.loaded = true;
              });
          } else {
            this.totalRecords = this.totalAllRecords;
            this.original_rows = this.rows;
            this.updateTabelInfo();
            this.loaded = true;
          }
        });
      });
  }

  public itemClick(columnName, item) {

    if (columnName === "taId") {
      if (this.sightingParams) {
        this.router.navigate([item.id], { relativeTo: this.route, queryParams: { sighting: this.sightingParams } });
      } else {
        this.router.navigate([item.id], { relativeTo: this.route });
      }
    }
    if (columnName === "ignore") {
      this.threatActorService
        .ignoreThreatActor(item.id, !item.ignore)
        .subscribe((ignoreResponse) => {

          // if successfully change the ignore flag
          if (ignoreResponse.status === 200) {
            item.ignore = !item.ignore;
            item.selected = false;
          }

        });

    }
    if (columnName === "coaInProgress") {
      console.log("Threat Actor's Inprogress COA: " + item.coaInProgress);
    }
  }

  public subItemClick(columnName, item, subItem) {
    if (columnName === "coa") {
      console.log("Threat Actor's Recommended COA: " + item.coa);
      const payload = {
        courseOfActionName: { value: subItem, type: "String" },
        threatActorId: { value: item.id, type: "String" },
        threatActorName: { value: item.name, type: "String" },
        threatActorAddress: { value: item.address, type: "String" },
        StartedBy: { value: this.cookieService.get("email"), type: "String" },
        sightingName: { value: this.sightingParams || item.sightings[item.sightings.length - 1], type: "String" },
      };
      item.observableData.forEach((ob) => {
        if (typeof ob.value == "object") {
          payload[ob.name] = { value: JSON.stringify(ob.value), type: "json" };
        }
        else {
          payload[ob.name] = { value: ob.value, type: "String" };
        }
      });

      this.modalRef = this.modalService.show(CoaExecuteComponent, null);
      this.modalRef.content.modelName = "Execute " + this.stixConfig.coa_singular;
      this.modalRef.content.recommendedCoa = item.coa;
      this.modalRef.content.payload = payload;
      this.modalRef.content.closeBtnName = "Close";
    }
  }

  public getHoverTitle(prop, item) {
    if (prop == "ignore") {
      return !item[prop] ? "Click to mark Ignore" : "Click to unmark Ignore";
    }
  }

  public updateTabelInfo() {
    const startRange = this.rows.length ? this.limitRows * (this.p - 1) + 1 : 0;
    let endRange = this.limitRows * this.p;
    const extriesCount = this.rows.length > this.totalRecords ? this.rows.length : this.totalRecords;
    endRange = endRange > extriesCount ? extriesCount : endRange;
    this.tableInfo = "Showing " + startRange + " to " + endRange + " of " + extriesCount + " entries";
  }

  public filterColumns() {
    this.filterSubject.next();
  }

  public columnClick(columnName) {
    if (this.sortKey === columnName) {
      this.asc = !this.asc;
    }
    this.sortKey = columnName;
    this.p = 1;
    this.updateTable();
  }

  public isArray(obj: any) {
    return Array.isArray(obj);
  }

}
