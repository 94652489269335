
<div *ngIf="searchFailed">
  <div class="alert alert-danger" role="alert">
    Failed to load Search.  Please contact your System Administrator
  </div>
</div>
<div class="panel panel-default" *ngIf="!searchFailed">
  <div class="panel-heading">
    {{tableTitle}}
    <a (click)="fetchThreatActorList()" class="refresh-list">
      <i class="fa fa-refresh"></i> Refresh List</a>
  </div>
  <div *ngIf="!loaded" class="loader">Loading
    <span class="loader__dot">.</span>
    <span class="loader__dot">.</span>
    <span class="loader__dot">.</span>
  </div>
  <div *ngIf="loaded" class="table-responsive">
    <div class="dataTables_wrapper form-inline dt-bootstrap no-footer">
      <div class="row">
        <div class="col-sm-9">
          <div class="dataTables_length">
            <label>Show
              <select [(ngModel)]="limitRows" class="form-control input-sm" (change)="updateTable()">
                <option *ngFor="let num of limitRowsOptions" [value]="num">{{num}}</option>
              </select>
              entries
            </label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <table class="table table-striped b-t b-b dataTable no-footer" role="grid">
            <thead>
              <tr role="row">
                <th *ngFor="let column of columns" (click)="columnClick(column.prop)"
                  class="{{'th-'+column.prop}} sorting{{sortKey == column.prop ? asc == true ? '_asc' : '_desc' : ''}}">
                  {{column.name}}
                </th>
              </tr>
              <tr role="row">
                <td *ngFor="let column of columns">
                  <input class="form-control filterByColumn" title="{{column.name}} Search" name="searcher{{column.prop}}" id="searcher{{column.prop}}"
                    [disabled]="searcher && !searcher[column.prop] && lockFilters" (focus)="filterClick(column.prop)"
                    [(ngModel)]="searcher[column.prop]"
                    (ngModelChange)="filterChange(column.prop, searcher[column.prop])" />
                  </td>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let item of rows | paginate: { itemsPerPage: limitRows, currentPage: p, totalItems: totalRecords }"
                class="status-{{!item['workflowcomplete'] ? 'Open':''}}" role="row">
                <td *ngFor="let column of columns" class="{{'td-'+column.prop}} {{'perm-'+ perm_accessCamunda}}">
                  <span
                    *ngIf="(item[column.prop] || item[column.prop] === 0 || column.prop == 'ignore') && !isArray(item[column.prop])"
                    (click)="itemClick(column.prop, item)" title="{{getHoverTitle(column.prop, item)}}">
                    {{item[column.prop]}}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="dataTables_info">
            {{tableInfo}} </div>
        </div>
        <div class="col-sm-6">
          <pagination-controls (pageChange)="p = $event;updateTable()" class=" pull-right"></pagination-controls>
        </div>
      </div>
    </div>
  </div>
</div>