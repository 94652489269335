import { Component, OnInit, AfterViewInit } from "@angular/core";
import { UserTask } from "../../shared/models/usertask";
import { WorkflowService } from "../../shared/services/workflow.service";
import * as BpmnJS from '../../../assets/bpmn-js/bpmn-viewer.development.js';
import { TasksService } from '../tasks.service';
import { ActivatedRoute } from '@angular/router';

declare var $: any;
declare var window: any;

@Component({
  selector: "app-task-diagram-view",
  templateUrl: "./task-diagram-view.component.html",
  styleUrls: ["./task-diagram-view.component.css"],
})
export class TaskDiagramViewComponent implements OnInit, AfterViewInit {
  public pageTitle: string = "Task Diagram View";
  public instanceId: String;
  public taskId: String;
  public task: UserTask;
  private viewer: BpmnJS;
  private zoomLevel: number = 1;
  private zoomStep: number = 0.2;

  constructor(
    private workflowService: WorkflowService,
    private taskService: TasksService,
    private route: ActivatedRoute,
  ) { }

  public ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.instanceId = params.instanceId;

    });
  }


  ngAfterViewInit() {
    // Run bpmn rendering when DOM is ready
    this.loadBPMNViewer().then((r) => this.setActiveStep());
    this.taskService.taskUpdated$.subscribe((task) => this.setActiveStep());
  }

  public setActiveStep() {
    this.workflowService.getTask(this.instanceId).subscribe((taskdef) => {
      if (taskdef && taskdef.results && taskdef.results.length > 0) {
        let activeStep = taskdef.results[0].taskDefinitionKey;

        setTimeout(() => this.timeout(activeStep), 1000);
      }
    });
  }


  public timeout(activeStep) {

    // Clear out the current active tasks 
    $(".djs-visual > rect").attr("style", "stroke: black; stroke-width: 2px; fill: white; fill-opacity: 0.95;")

    // Set the current active task
    var activeElement = $(`[data-element-id='${activeStep}'] > .djs-visual > rect`);
    $(`[data-element-id='${activeStep}']`).addClass("activerect");
    activeElement.attr("style", "fill:rgba(194,213,237,0.4) !important; stroke:#155cb5 !important; stroke-width:2px !important").addClass("activestep");
    $(".bjs-powered-by").attr("style", "display:none;");
  }

  public loadBPMNViewer() {
    return new Promise((resolve, reject) => {
      try {
        this.workflowService.getTask(this.instanceId).subscribe((task) => {
          if (task && task.results && task.results.length > 0) {
            this.workflowService.getCamundaDiagramXML(task.results[0].processDefinitionId).subscribe((diagramXML) => {
              const xml = JSON.parse(diagramXML.results.body).bpmn20Xml;
              this.renderDiagram(xml);
              // this.viewer.get('canvas').zoom('fit-viewport', "auto");
            });
          }
          resolve(null);
        });
      }
      catch (e) {
        reject(e);
      }
    });
  }

  private context: any = {};
  private allowMove: boolean = false;

  public moveDiagram(e) {
    if(this.allowMove) {
      const position = { x: e.clientX, y: e.clientY };
      const lastPos = this.context.last || this.context.start;
      const delta = {
        x: position.x - lastPos.x,
        y: position.y - lastPos.y
      };;

      this.viewer.get('canvas').scroll({
        dx: delta.x,
        dy: delta.y
      });

      this.context.last = position;
    }
    e.preventDefault();
  }

  public startDiagramMove(e) {
    this.allowMove = true;
    $('#canvas').css("cursor", "grabbing");
    this.context = {
      start: { x: e.clientX, y: e.clientY }
    };
    e.preventDefault();
  } 

  public stopDiagramMove(e) {
    this.allowMove = false;
    $('#canvas').css("cursor", "grab");
    e.preventDefault();
  } 

  public zoomOut() {
    this.zoomLevel = Math.max( this.zoomLevel - this.zoomStep, this.zoomStep );
    this.viewer.get('canvas').zoom( this.zoomLevel, 'auto' );
  }

  public zoomIn() {
    this.zoomLevel = Math.min( this.zoomLevel + this.zoomStep, 4 );
    this.viewer.get('canvas').zoom( this.zoomLevel, 'auto' );
  }

  public zoomReset() {
    this.zoomLevel = this.viewer.get('canvas').zoom( 'fit-viewport' );
  }

  // Refactored BPMNViewer to use async
  public async renderDiagram(xml) {

    try {
      this.viewer = new BpmnJS({
        container: '#canvas',
      });
      await this.viewer.importXML(xml);

    } catch (err) {
      console.log('error rendering', err);
    }
  }

}
