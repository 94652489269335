import { AfterViewInit, Component, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { Subject } from "rxjs";
import { debounceTime } from 'rxjs/operators';
import { BulkAssignmentService } from "../../shared/services/bulk.assignment.service";
import { StixConfigService } from "../../shared/services/stixConfig.service";
import { PermissionService } from "../../core/permission.service";
import { SearchService } from "../../search/search.service";
import { TABLE_COLUMNS_INDICATOR_TA_LIST } from "../threat-actor-list/threat-actor-list-columns";
import { ThreatActorService } from "../threat-actor.service";
import { EnvService } from "../../env.service";;

@Component({
  selector: "app-threat-actor-sighting-list",
  templateUrl: "./threat-actor-sighting-list.component.html",
  styleUrls: ["../threat-actor-list/threat-actor-list.component.css"],
})
export class ThreatActorSightingListComponent implements AfterViewInit {
  public rows = [];
  public original_rows = [];
  public columns = [];
  public tableTitle: string;
  public p: number = 1;
  public limitRowsOptions = [10, 25, 50, 100];
  public limitRows: number = this.limitRowsOptions[0];
  public tableInfo: string = "";
  public sortKey = "name";
  public asc = true;
  public loaded = false;
  public permission_ignoreTA = false;
  public searcher: {} = {};
  public searcherSubject: Subject<any> = new Subject<any>();
  public searchableColumns: any[] = [];
  public lockFilters: boolean = false;
  public searchMetaData: any[] = [];
  public totalRecords: number;
  public coaLabel: string;
  public selectAll: boolean = false;
  public disableBulkAssign: boolean = false;
  public allFilteredIDs: string[] = [];
  public prevFilteredIDsCount = 0;
  public bulkAssignVisible: boolean = false;

  @Input() public recommendedCOAs;
  @Input() public sightingParams;

  constructor(
    private threatActorService: ThreatActorService,
    private route: ActivatedRoute,
    private permissions: PermissionService,
    private router: Router,
    public stixConfig: StixConfigService,
    private searchService: SearchService,
    private bulkAssignService: BulkAssignmentService,
    private env: EnvService
  ) {
    this.tableTitle =
      "List of " +
      this.stixConfig.sighting_singular +
      " " +
      this.stixConfig.threatActor_plural;
    this.coaLabel = this.stixConfig.coa_singular;
  }

  public ngAfterViewInit() {
    this.permissions.getPermission("Ignore Threat Actor").then((p) => {
      this.permission_ignoreTA = p;
    });

    this.route.params.subscribe((params) => {
      this.totalRecords = null;
      const sightingName = params.name;
      if (sightingName) {
        this.adjustSightingCritiria(sightingName);
        this.threatActorService.sighting_critiria.sightingName = sightingName;
        this.updateTable();
      }
    });
  }

  public ngOnInit() {
    this.searcherSubject.pipe(debounceTime(700)).subscribe((params) => {
      this.threatActorService.modifyFilter(params.prop, params.string);
      this.p = 1;
      this.updateTable();
    });

    this.permissions.getPermission("Bulk Assign Course of Action").then((permission) => {
      if (permission && this.env.bulkAssignment) { this.bulkAssignVisible = true; }
    });

  }

  public fetchThreatActorList() {
    //// Threat Actors from one Indicator sighting
    this.threatActorService
      .getFilteredThreatActorsBySighting(
        this.threatActorService.sighting_critiria,
      )
      .subscribe(
        (res) => {
          this.totalRecords = res.totalCount;
          const threatActors = res.results || [];

          this.allFilteredIDs = res.allFilteredIDs || [];

          //// Turn (Select all) checkbox to false If user is removing filters and getting more records than previous.
          if (
            (this.prevFilteredIDsCount &&
              this.prevFilteredIDsCount < this.allFilteredIDs.length) ||
            this.allFilteredIDs.length == 0
          ) {
            this.selectAll = false;
          }
          this.prevFilteredIDsCount = this.allFilteredIDs.length;

          this.getColumns(threatActors).then((c) => {
            threatActors.forEach((threatActor) => {
              threatActor.coursesOfActionCount =
                threatActor.coursesOfActionCount || 0;
              threatActor.ignore = threatActor.ignore || false;
              threatActor.selected = (this.threatActorService.selected_ThreatActors.indexOf(threatActor.taId) != -1 &&
                !threatActor.ignore) || false;

              Object.keys(threatActor).forEach((prop) => {
                // send back a list of props that have date ISO format values for server filter.
                const propertyData = this.columns.find((x) => x.prop === prop);
                if (propertyData) {
                  const isISOFormat =
                    threatActor[prop] &&
                    moment(
                      threatActor[prop],
                      moment.ISO_8601,
                      true,
                    ).isValid() &&
                    threatActor[prop].toString().indexOf(":") != -1;
                  if (threatActor[prop] && isISOFormat) {

                    threatActor[prop] = threatActor[prop].replace('Z', '');

                    if (
                      this.threatActorService.dateFormatFields.indexOf(prop) ==
                      -1
                    ) {
                      this.threatActorService.dateFormatFields.push(prop);
                    }
                  }
                }
              });
            });
            // pick only (the selected threatactors which also are included in the current filter records) for Bulk Assignment.
            this.disableBulkAssign = !this.threatActorService.selected_ThreatActors.some(id => this.allFilteredIDs.includes(id));

            this.rows = threatActors;
            this.tableTitle =
              "List of " +
              this.stixConfig.sighting_singular +
              " " +
              this.stixConfig.threatActor_plural +
              " (" +
              this.totalRecords +
              ")";
            this.original_rows = this.rows;
            this.updateTabelInfo();
            this.loaded = true;
          });
        },
        (err) => {
          this.rows = [];
          this.totalRecords = 0;
          this.updateTabelInfo();
          this.loaded = true;
          this.disableBulkAssign = true;
        },
      );
  }

  public getColumns(threatActors) {
    return new Promise((resolve, reject) => {
      if (this.columns.length > 0) {
        resolve(this.columns);
      } else {
        //// Get isSearchable columns type form first ThreatActor type.
        const firstThreatActor = threatActors[0] || {};
        const type = firstThreatActor.type || "";
        this.searchService.getSearchableDataFields(type).subscribe((fields) => {
          this.searchMetaData = fields;
          fields.forEach((field) => {
            this.searchableColumns.push({
              prop: field.name,
              name: field.description || field.name,
            });
            this.loaded = false;
          });
          this.threatActorService
            .getTableColumns(TABLE_COLUMNS_INDICATOR_TA_LIST)
            .then((cols) => {
              //// Concat original Columns with IsSearchable coulmns.
              this.columns = this.stixConfig
                .stixLabeling(cols)
                .concat(this.searchableColumns);
              //// Replace Ignore column location to the last.
              const ignoreIndex = this.columns.findIndex(
                (c) => c.prop == "ignore",
              );
              if (ignoreIndex !== -1 && this.searchableColumns.length > 0) {
                this.columns.splice(
                  this.columns.length - 1,
                  0,
                  this.columns.splice(ignoreIndex, 1)[0],
                );
              }
              resolve(this.columns);
            });
        });
      }
    });
  }

  public updateTable() {
    this.threatActorService.sighting_critiria.page = Number(this.p);
    this.threatActorService.sighting_critiria.count = Number(this.limitRows);
    this.threatActorService.sighting_critiria.sort = null;
    const sortObj = {};
    sortObj[this.sortKey] = this.asc ? "asc" : "desc";
    this.threatActorService.sighting_critiria.sort = sortObj;
    this.fetchThreatActorList();
  }

  public itemClick(columnName, item) {
    if (columnName === "taId") {
      if (this.sightingParams) {
        this.router.navigate([item.id], {
          relativeTo: this.route,
          queryParams: { sighting: this.sightingParams },
        });
      } else {
        this.router.navigate([item.id], { relativeTo: this.route });
      }
    }
    if (columnName === "ignore") {
      this.threatActorService
        .ignoreThreatActor(item.id, !item.ignore)
        .subscribe((ignoreResponse) => {

          // if successfully change the ignore flag
          if (ignoreResponse.status === 200) {
            item.ignore = !item.ignore;
            item.selected = false;
          }

        });
    }
  }

  public getHoverTitle(prop, item) {
    if (prop == "ignore") {
      return !item[prop] ? "Click to mark Ignore" : "Click to unmark Ignore";
    }
  }

  public bulkAssignCOA() {
    if (this.threatActorService.sighting_critiria.filter) {
      this.bulkAssignService.selectedThreatActors = this.threatActorService.selected_ThreatActors.filter((id) => this.allFilteredIDs.includes(id));
    } else {
      this.bulkAssignService.selectedThreatActors = this.threatActorService.selected_ThreatActors;
    }

    this.bulkAssignService.coaView = false;
    this.router.navigate(["bulkassignment"], { relativeTo: this.route });
  }

  public updateTabelInfo() {
    const startRange = this.totalRecords
      ? this.limitRows * (this.p - 1) + 1
      : 0;
    let endRange = this.limitRows * this.p;
    endRange = endRange > this.totalRecords ? this.totalRecords : endRange;
    this.tableInfo =
      "Showing " +
      startRange +
      " to " +
      endRange +
      " of " +
      this.totalRecords +
      " entries";
  }
  public columnClick(columnName) {
    if (this.sortKey === columnName) {
      this.asc = !this.asc;
    }
    this.sortKey = columnName;
    this.p = 1;
    this.updateTable();
  }

  public filterChange(prop, string) {
    if (this.searcher && Object.keys(this.searcher).length <= 4) {
      this.searcherSubject.next({ prop, string });
    }
    if (!string) {
      delete this.searcher[prop];
    }
  }

  public filterClick(prop) {
    // lock other filter fields if exceeded 4 filters at a time.
    if (!this.searcher[prop] && Object.keys(this.searcher).length >= 4) {
      this.lockFilters = true;
      alert("You have exceeded the maximum number of filters");
    } else {
      this.lockFilters = false;
    }
  }

  public selectAllChange() {

    this.rows.forEach((element) => {
      /// If its ignored threat actor; remove it from all Filtered IDs
      const id_index = this.allFilteredIDs.indexOf(element.taId);
      if (!element.ignore) {
        id_index == -1 && this.allFilteredIDs.push(element.taId);
        element.selected = this.selectAll || false;
      } else {
        id_index > -1 && this.allFilteredIDs.splice(id_index, 1);
      }
    });

    if (this.selectAll) {
      let selected_ThreatActors = this.threatActorService.selected_ThreatActors;
      selected_ThreatActors = selected_ThreatActors.concat(this.allFilteredIDs);
      this.threatActorService.selected_ThreatActors = [
        ...new Set(selected_ThreatActors),
      ];
    } else {
      this.allFilteredIDs.forEach((taId) => {
        const index = this.threatActorService.selected_ThreatActors.indexOf(
          taId,
        );
        if (index !== -1) {
          this.threatActorService.selected_ThreatActors.splice(index, 1);
        }
      });
    }

    this.disableBulkAssign = !this.threatActorService.selected_ThreatActors.some(id => this.allFilteredIDs.includes(id));

  }

  public selectChange(item) {
    if (item.selected == true) {
      this.threatActorService.selected_ThreatActors.push(item.taId);
    } else {
      const index = this.threatActorService.selected_ThreatActors.indexOf(
        item.taId,
      );
      if (index !== -1) {
        this.threatActorService.selected_ThreatActors.splice(index, 1);
      }
    }

    this.disableBulkAssign = !this.threatActorService.selected_ThreatActors.some(id => this.allFilteredIDs.includes(id));

  }

  public adjustSightingCritiria(sightingName) {
    //// re-apply the same filter critiria if its same sighting from prev page
    if (
      this.threatActorService.sighting_critiria.sightingName == sightingName
    ) {
      this.p = this.threatActorService.sighting_critiria.page;
      this.limitRows = this.threatActorService.sighting_critiria.count;
      const sortObj = this.threatActorService.sighting_critiria.sort;
      this.sortKey = Object.getOwnPropertyNames(sortObj)[0] || this.sortKey;
      this.asc = sortObj[this.sortKey] == "asc";
      if (this.threatActorService.sighting_critiria.filter) {
        const filter = this.threatActorService.sighting_critiria.filter;
        Object.keys(filter).forEach((prop) => {
          this.searcher[prop] = filter[prop];
        });
      }
    } else {
      //// reset the filter
      this.threatActorService.sighting_critiria.filter = null;
    }
  }

  public resetCheckboxes_refreshList() {
    this.threatActorService.selected_ThreatActors = [];
    this.disableBulkAssign = true;
    this.selectAll = false;
    this.fetchThreatActorList();
  }

  public isDateFormat(prop): string {
    if (prop && this.threatActorService.dateFormatFields.indexOf(prop) != -1) {
      if (prop == "UpdatedDateTime") {
        return "short";
      } else {
        return "MM/dd/yyyy";
      }
    } else {
      return "";
    }
  }
}
