export class ObservableMetaData {
    public id: String;
    public name: String;
    public type: String;
    public description: String;
    public helpText: String;
    public section: String;
    public order: String;
    public metaDataType: string;
    public hidden: Boolean;
    public CreatedBy: String;
    public UpdatedBy: String;
    public CreatedDateTime: Date;
    public UpdatedDateTime: Date;
    public isHiddenText?: String;
    public sectionText?: String;
    public isSearchable: any;
    public isSearchableText?: String;
}
