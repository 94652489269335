import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IAlgorithm } from '../indicators/nerdy-algorithm-list/nerdy-algorithm-list';
import { ProcessDefinition, WorkflowService } from '../shared/services/workflow.service';

export class IndicatorWizardModel{
  indicator: IAlgorithm;
  currentStep: number;
  validationMessage: string;
  constructor( indicator? : IAlgorithm ){

    if ( indicator ){
      this.indicator = indicator;
      this.currentStep = 5; 
    }
    else {
      this.indicator = new IAlgorithm();
      this.indicator.script = "";
      this.currentStep = 0;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class IndicatorWizardService {

  constructor(
    private workflowService: WorkflowService
  ){}

  private dataSource  = new BehaviorSubject<IndicatorWizardModel>(new IndicatorWizardModel());
  currentData = this.dataSource.asObservable();

  public navigationDataSource = new BehaviorSubject<StepperSelectionEvent>(null);
  navigation = this.navigationDataSource.asObservable();

  private allProcessDefintions: ProcessDefinition[];

  changeData(data: IndicatorWizardModel){
    data = this.validate( data );
    this.dataSource.next(data);
  }

  navigationChanged(event: StepperSelectionEvent ){
    this.navigationDataSource.next( event );
  }

  validate( model : IndicatorWizardModel ) : IndicatorWizardModel {
    model.indicator.canSave = !!model.indicator.name; 
    model.indicator.canExecute =  !!model.indicator.name && !!model.indicator.description && !!model.indicator.classDesc && !model.validationMessage;
    return model;
  }

  public async getAllProcessDefintions() {
    if ( !this.allProcessDefintions ){
      this.allProcessDefintions = await this.workflowService.getProcessDefinitions();
    }
    
    return this.allProcessDefintions;
  }

  refreshModel() {
    this.dataSource  = new BehaviorSubject<IndicatorWizardModel>(new IndicatorWizardModel());
    this.currentData = this.dataSource.asObservable();
  
    this.navigationDataSource = new BehaviorSubject<StepperSelectionEvent>(null);
    this.navigation = this.navigationDataSource.asObservable();

  }

}

export class RecommendCoaTabs {
  name: string;
  description: string;
  tabId: string;
  href: string;
  viewerId: string;
  hidden: boolean;
  active: boolean;
}