<div class="container" hidden={{isHidden}}>
  <div class="row top-row">
    <div class="col-md-9">
      <textarea class="form-control note-txt" placeholder="Write your note here..." [(ngModel)]="uploadDescription"
        rows="8"></textarea>
    </div>
  </div>
  <div class="row bottom-row">
    <div class="col-sm-3 col-md-2 col-lg-2">
      <button class="btn btn-primary note-btn" hidden={{isHidden}} (click)="saveNote()">Add Note</button>
      <button class="btn note-btn cancel-btn" (click)="cancelNote()">Cancel</button>
    </div>
  </div>
</div>
<div class="row" *ngIf="isHidden">
  <div class="col-md-12 coa-details-header">
    <h4>{{this.notesTitle}}</h4>
  </div>
</div>
<div class="row notes-list" [ngClass]="{'notesHistoryPadding' : isHidden}">
  <div *ngFor="let note of notesArray; let i=index" class="col-md-9 notes-hover">
    <a (click)="toggleNote[i] = !toggleNote[i]" class="noteHeading">
      <i class="material-icons" *ngIf="!toggleNote[i]">keyboard_arrow_down</i>
      <i class="material-icons" *ngIf="toggleNote[i]">keyboard_arrow_right</i>
        <span class="note-content">
          <b>{{note.CreatedBy}}</b> &nbsp; {{note.DateTimeCreated}} &nbsp; <b>{{note.Task}}</b>
        </span>
    </a>
    <div [hidden]="toggleNote[i]" class="note-collapsed" [innerHTML]="note.note | safeHtml"></div>
    <div class="case-start-divider">
        <mat-divider></mat-divider>
    </div>
  </div>
</div>
<div class="row notes-list" *ngIf="showMorebtn">
  <div class="col-sm-1">
    <button class="btn btn-light btn-show-more" (click)="loadAllNotes()">Show All</button>
  </div>
</div>
