import { Component, ElementRef, Injectable, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { MayanDocumentService } from '../../shared/services/mayan.document.service'; 

@Component({
  selector: 'app-task-doc-history',
  templateUrl: './task-doc-history.component.html',
  styleUrls: ['./task-doc-history.component.css']
})
@Injectable()
export class TaskDocHistoryComponent implements OnInit {

  public documentId : string;
  public displayedColumns: string[] = [
    "version",
    "description",
    "dateCreated",
    "size",
    "createdByName",
    "taskName",
  ];
  @ViewChild(MatPaginator) public paginator: MatPaginator;
  @ViewChild(MatSort) public sort: MatSort;
  @ViewChild("input") public input: ElementRef;
  public dataSource = new MatTableDataSource<any>();

  constructor(
    public dialog: MatDialog,
    private mayanDocumentService: MayanDocumentService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() : void {
    this.route.params.subscribe(async (params) => {
      this.documentId = params.documentId;
      this.loadTaskDocuments();
    });
    
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    fromEvent(this.input.nativeElement, "keyup")
      .pipe(
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.dataSource.filter = this.input.nativeElement.value.trim().toLocaleLowerCase();
        }),
      )
      .subscribe();
  }

  public async loadTaskDocuments() {
    this.dataSource.data = await this.mayanDocumentService.getVersions( this.documentId );
  }

  public async refreshList() {
    this.input.nativeElement.value = null;
    await this.loadTaskDocuments();
    this.dataSource.filter = null;
  }

}
