import { Component, OnInit } from "@angular/core";
import { ActivatedRoute} from '@angular/router';
import { SightingService } from "../../shared/services/sighting.service";

@Component({
  selector: "app-sighting-log",
  templateUrl: "./sighting-log.component.html",
  styleUrls: ["./sighting-log.component.css"],
})
export class SightingLogComponent implements OnInit {

  public log : string;
  public sightingName: string;
  public sightingStatus : string;

  constructor(private route: ActivatedRoute, private sightingService: SightingService) { }

  async ngOnInit() {

    this.route.params.subscribe(async (params) => 
    {
      this.sightingName = params.name;

      const sightings  = await this.sightingService.getSightingsByName( this.sightingName ).toPromise();

      if (sightings && sightings.length ){
        const sighting = sightings.pop();
        this.log = sighting.log;
        this.sightingStatus = sighting.status;
      }
    })
  }
}
