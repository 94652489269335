
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CookieService } from 'ngx-cookie-service';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { StixConfigService } from "../../shared/services/stixConfig.service";
import { CoaExecuteComponent } from "../../coa/coa-execute/coa-execute.component";
import { CoAService } from "../../coa/coa.service";
import { LoggingService, LogLevel, LogType } from "../../core/logging.service";
import { PermissionService } from "../../core/permission.service";
import { TasksService } from "../tasks.service";
import * as moment from 'moment';
import { SightingService } from '../../shared/services/sighting.service';
import { AppSettingService } from '../../shared/services/appsetting.service';
import { EnvService } from "../../env.service";
import { SurveyService } from '../../shared/services/survey.service';
import { CoaFileUploadDialogComponent } from "../../coa-file-upload-dialog/coa-file-upload-dialog.component";
import { Subject } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { WorkflowService } from "../../shared/services/workflow.service";
import { ToastrService } from "ngx-toastr";
declare var window: any;


@Component({
  selector: "app-task-details",
  templateUrl: "./task-details.component.html",
  styleUrls: ["./task-details.component.css"],
})
export class TaskDetailsComponent implements OnInit {
  public pageTitle: string = "Task Details";
  public instanceId: string;
  public courseOfAction;
  public modalRef: BsModalRef;
  public loaded = false;
  public permission_reassignCoA: boolean = false;
  public taskId: String;
  public task: any = {};
  public userEmail: string = this.cookieService.get("email");
  public retrying: boolean = false;
  public label_coa: string;
  public label_threatActor: string;
  public label_sighting: string;
  public label_indicator: string;
  public taskName: string = "";
  public indicatorDescription: string;
  public indicatorName: string;
  public runDate: string;
  public showPending: boolean;
  public showReassign: boolean;
  // public isPending: boolean = false;
  public pendingButtonText: string;
  public currentTask: any;
  public enableTabContent = false;
  public statusSubject: Subject<string> = new Subject<string>();
  public hideAssignToMeButton = false;

  constructor(
    private route: ActivatedRoute,
    private tasksService: TasksService,
    public stixConfig: StixConfigService,
    private modalService: BsModalService,
    private coaService: CoAService,
    private permissions: PermissionService,
    private log: LoggingService,
    private router: Router,
    private sightingService: SightingService,
    private cookieService: CookieService,
    private appSettingService: AppSettingService,
    private surveyService: SurveyService,
    private env: EnvService,
    public dialog: MatDialog,
    public workflowService: WorkflowService,
    public toastr: ToastrService
  ) {
    this.tasksService.taskUpdated$.subscribe(task => {
      this.task = task;
    });
  }



  public ngOnInit(): void {
    const self = this;

    this.label_coa = this.stixConfig.coa_singular;
    this.label_threatActor = this.stixConfig.threatActor_singular;
    this.label_sighting = this.stixConfig.sighting_singular;
    this.label_indicator = this.stixConfig.indicator_singular;

    this.route.params.subscribe((params) => {
      this.instanceId = params.instanceId;
      self.getCourseOfActionDetails();
    });

    this.modalService.onHide.subscribe(() => {
      if (self.modalRef && self.modalRef.content && self.modalRef.content.modelName) {
        self.getCourseOfActionDetails();
        self.modalRef.content.modelName = null;
      }
    });

  }

  public async getSightingRunDate(sightingName) {

    const sightings = await this.sightingService.getSightingsByName(sightingName).toPromise();
    const currentSighting = sightings && sightings.length > 0 ? sightings.pop() : null;
    const momentDate = currentSighting ? moment(currentSighting.CreatedDateTime) : null;
    const sightingRunDate = momentDate && momentDate.isValid() ? momentDate.format('LLL') : "";

    return sightingRunDate;
  }

  public getCourseOfActionDetails() {
    this.taskId = "";

    this.coaService.getCoursesOfActionById(this.instanceId)
      .subscribe(async (coa: any) => {
        this.indicatorName = coa.indicatorName;
        this.indicatorDescription = coa.indicatorDescription;

        // User the sighting name to generate the date time 
        this.runDate = await this.getSightingRunDate(coa.sightingName);
        // Hide/Show Assign to me button
        if (coa.assignee == this.userEmail){
          this.hideAssignToMeButton = true;
        }
        else{
          this.hideAssignToMeButton = false;
        }

        this.task = {
          name: coa.taskName,
          assignee: coa.assignee,
          assigneeFullName: coa.assigneeFullName,
          id: coa.taskId
        }
        this.courseOfAction = coa || {};
        this.loaded = true;

        await this.setupPendingAndReassign(coa);
      })
  }

  public reAssign() {

    this.modalRef = this.modalService.show(CoaExecuteComponent, null);
    this.modalRef.content.modelName = `Re-assign ${this.stixConfig.coa_singular}`;
    this.modalRef.content.currentTask = this.task;
    this.modalRef.content.closeBtnName = "Close";

    // Log coa reassign entry
    this.log.logClientEvent(
      "COA Reassigned",
      LogLevel.Info,
      LogType.App,
      this.userEmail,
      JSON.stringify(this.courseOfAction),
    );
  }

  public assignToMe() {

    this.workflowService
    .claimUserTask(this.task.id, this.userEmail)
    .subscribe((res) => {
      if (res.status.status == "SUCCESS") {
        this.toastr.success("Assignment confirmed");
        // Log COA Assign to me
        this.log.logClientEvent(
          "COA Assigned to current user",
          LogLevel.Info,
          LogType.App,
          this.userEmail,
          JSON.stringify(this.courseOfAction),
        );
        this.hideAssignToMeButton = true;
        this.enableTabContent = true;
        this.task.assigneeFullName = `${this.cookieService.get("firstName")} ${this.cookieService.get("lastName")}`
        this.task.assignee = this.userEmail;
      } else {
        this.toastr.success("COA Assign Failed");
      }
    });
  }

  public goToIndicatorPage(indicatorId) {
    this.router.navigate(["/indicators", indicatorId]);
  }

  public goToThreatactorPage() {
    const queryParams = { queryParams: { sighting: this.courseOfAction.sightingName } };
    this.router.navigate(["threatActor", this.courseOfAction.threatActorId], queryParams);
  }

  /**
   * Hides/ Shows the Pending and Re-assign buttons based on the state of the coa 
   * and if the coa is configured to have the pending button
   * @param coa Course of Action
   */
  public async setupPendingAndReassign(coa) {

    // Get the permision to determine whether the current user can re-assign a course of action
    this.permission_reassignCoA = await this.permissions.getPermission("Re-assign Course of Action");

    // First check the this.env variables to see if the Pending is should be available
    let appSettings : any = await this.appSettingService.getAppSetting(this.env.appKey).toPromise();
    appSettings = appSettings.results ? appSettings.results : appSettings;
    const isCoaConfiguredForPending = appSettings.pendingCoas && appSettings.pendingCoas.includes(coa.name);

    // check if the user has normal status (i.e non pending status)
    this.currentTask = coa.tasks && coa.tasks.length ? coa.tasks[coa.tasks.length - 1] : {};
    let isNormalStatus = true;
    if (this.currentTask.Status && this.currentTask.Status.toLowerCase() === "pending") {
      isNormalStatus = false;
    }
    
    // check if the user is assigned to the current course of action 
    const isAssigned = this.cookieService.get("email") === coa.assignee;

    if (isCoaConfiguredForPending) {

      if (isAssigned && isNormalStatus) {

        // Show both buttons and enable 
        this.showReassign = true && this.permission_reassignCoA;
        this.showPending = true;
        this.pendingButtonText = "Set to Pending";
        this.enableTabContent = true;
      }
      else if (isAssigned && !isNormalStatus) {

        // only show pending and disable
        this.showReassign = false;
        this.showPending = true;
        this.pendingButtonText = "Set to Ready";
        this.enableTabContent = false;
      }
      else if (!isAssigned) {

        // show reassign and disable
        this.showReassign = true && this.permission_reassignCoA;
        this.showPending = false;
        this.enableTabContent = false;
      }
    }
    else {
      // The Course of action is not configured for pending, hide the button 
      // only have logic for Ressign
      this.showReassign = this.permission_reassignCoA;
      this.showPending = false;
      this.enableTabContent = isAssigned;
    }
  }

  /**
   * Toggles the status between Pending and the previous status in the course of action
   * and saves that status back to the api
   */
  public async togglePending() {

    if (this.pendingButtonText === "Set to Pending") {


      const coa = await this.coaService.getCoursesOfActionById(this.instanceId).toPromise();
      this.currentTask = coa.tasks[coa.tasks.length - 1];

      this.currentTask.Status = "Pending";
      this.currentTask.isPending = true;
      await this.surveyService.saveTask(this.currentTask, this.currentTask.taskId, this.currentTask.processInstanceId, this.cookieService.get("email"));

      this.enableTabContent = false;
      this.pendingButtonText = "Set to Ready";
      this.showReassign = false;
    }
    else {

      // Get the current version of the coa to get the previous status 
      const coa = await this.coaService.getCoursesOfActionById(this.instanceId).toPromise();
      const nonPendingTasks = coa.tasks.filter(c => c.Status !== "Pending");
      this.currentTask.Status = nonPendingTasks && nonPendingTasks.length ? nonPendingTasks[nonPendingTasks.length - 1].Status : "";
      this.currentTask.isPending = false;
      await this.surveyService.saveTask(this.currentTask, this.currentTask.taskId, this.currentTask.processInstanceId, this.cookieService.get("email"));

      this.enableTabContent = true;
      this.pendingButtonText = "Set to Pending";
      this.showReassign = true && this.permission_reassignCoA;
    }

    // Refresh the Survey
    this.statusSubject.next(this.currentTask.Status);
  }

  public uploadFile() {
    this.dialog.open(CoaFileUploadDialogComponent, {
      data: {
        task : this.task
      },
    });
  }
}
