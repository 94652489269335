import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { Location } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';

import {
  CaseService, CaseEditPageType, CaseEditNextAction
} from "../case-service";
import { StixConfigService } from "../../shared/services/stixConfig.service";
import { MessageService } from '../../shared/services/message.service';
import { WorkflowService } from '../../shared/services/workflow.service';
import { SightingService } from '../../shared/services/sighting.service';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-case-start',
  templateUrl: './case-start.component.html',
  styleUrls: ['./case-start.component.css']
})
export class CaseStartComponent implements OnInit {

  public taId: string;
  public name: string;
  public threatActorId: string;
  public coas: Array<any> = [];
  public activeUsers: Array<any>;
  public newTypeControl = new FormControl();
  public priorities: string[];
  public caseTypes: string[];
  public selectedPriority: string;
  public caseId: string;
  public errorMessage: string;
  public showTypeEdit: boolean;
  public dueDate: string;
  public courseOfactionList: any[];
  public selectedCoursesOfAction: any[];
  public typesList: any[];
  public selectedTypes: any[];
  public usersList: any[];
  public selectedUsers: string;
  public rivirCase: any;
  public pageType: CaseEditPageType;
  public caseConfirmationMessage: string;
  public coaConfirmationMessage: string;
  public secondaryConfirmationMessage: string;

  public courseOfActionDueDate: string;
  public selectedCoaUser: any;
  public allProcessDefinitions: any[];
  public processDefinitions: any[];
  public selectedProcessDefintion: any;
  public sighting: any;
  public nextAction: CaseEditNextAction;
  public payload: any;
  public instanceId: string;

  public casesList: any[];
  public selectedCase: any[];
  public showCases: boolean;
  public casesAllList: any = [];
  public prevCaseType: string;
  public prevDueDate: string;
  public prevAssignTo: string;
  public prevAssignToDisplay: string;
  public prevPriority: string;
  public prevCaseSelected: boolean;
  public prevCaseValues: any;
  public hideExistingCases: boolean;
  public showNoAssignedCases: boolean;
  public threatActor : any;
  public sightingName : string;


  constructor(
    private route: ActivatedRoute,
    private caseService: CaseService,
    private router: Router,
    public stixConfig: StixConfigService,
    private location: Location,
    private messageService: MessageService,
    private workflowService: WorkflowService,
    private sightingService: SightingService,
    public toastr: ToastrService,
    private cookieService: CookieService
  ) {

  }

  /**
   * Loads by page using the route, if there is a taId then it's a new case,
   * if there is a case Id we are editing a case
   */
  async ngOnInit() {

    const { pageType, taId, caseId, sightingName } = await this.getPageType();
    this.pageType = pageType;

    // When starting a case load the base data for that threat actor 
    if (pageType === CaseEditPageType.CASE_START) {
      await this.loadPageByThreatActor(taId, null, sightingName);
    }
    // When editing a case load the case first then load the base data for the threat actor
    else if (pageType === CaseEditPageType.CASE_EDIT) {

      this.caseId = caseId;
      const rivirCase = await this.caseService.getCase(this.caseId).toPromise();
      await this.loadPageByThreatActor(rivirCase.taId, rivirCase, sightingName);

    }
    else {

      await this.setProcessDefinitions(sightingName);

      this.nextAction = CaseEditNextAction.START_CASE;


      await this.loadPageByThreatActor(taId, null, sightingName);

      await this.getExistingCases();
    }
  }

  /**
   * Sets the ng-select to show recommended process defintions for the current sighting, 
   * if there are none recommended thens display all proces defintions
   * @param sightingName Sighting used to create course of action
   */
  async setProcessDefinitions(sightingName) {

    // Get the sighting from the api 
    const sightings = await this.sightingService.getSightingsByName(sightingName).toPromise();
    this.sighting = sightings && sightings.length ? sightings.pop() : {};

    // Get the full listed of process defintions
    this.allProcessDefinitions = await this.workflowService.getProcessDefinitions();

    // Determine whether to use recommended process defintions or all 
    let recommendedCourseOfActions = [];
    if (this.sighting.recommendedCOAs && this.sighting.recommendedCOAs.length > 0) {
      recommendedCourseOfActions = this.allProcessDefinitions.filter(p => {
        return this.sighting.recommendedCOAs.includes(p.name);
      });
    }
    else {
      recommendedCourseOfActions = this.allProcessDefinitions;
    }
    // set the ng-select items
    this.processDefinitions = recommendedCourseOfActions.map(p => {
      return { id: p.key, text: p.name } as any;
    });
  }

  /**
   * Gets the Page Type and Parameters
   */
  getPageType(): Promise<any> {

    return new Promise((resolve, reject) => {
      this.route.params.subscribe(async (params) => {

        let pageType: CaseEditPageType;

        //
        if (window.location.href.includes("start")) {
          pageType = CaseEditPageType.CASE_START;
        }
        else if (window.location.href.includes("edit")) {
          pageType = CaseEditPageType.CASE_EDIT;
        }
        else {
          pageType = CaseEditPageType.COA_START;
        }

        resolve({ pageType, taId: params.taId, caseId: params.caseId, sightingName: params.sightingName });

      });
    });
  }

  /**
   * Loads the base data to populate the drop downs for the page, if there is a rivirCase
   * uses those values to set the selected value for the dropdowns
   * @param taId Threat Actor taId
   * @param rivirCase Optional RIViR Case
   */
  async loadPageByThreatActor(taId: string, rivirCase?: any, sightingName?: string) {

    this.taId = taId;
    const subscription = await this.caseService.getCaseStartInfo(this.taId).toPromise();

    if (subscription.threatActor && subscription.threatActor.length) {
      this.threatActor = subscription.threatActor.pop();
      this.name = this.threatActor ? this.threatActor.name : "";
      this.threatActorId = this.threatActor ? this.threatActor.id : null;
      this.sightingName = sightingName;
    }

    this.activeUsers = subscription.activeUsers;
    this.usersList = this.activeUsers.map((user) => {
      return { id: user.id, text: `${user.name} - (${user.email})` };
    });


    this.typesList = subscription.types.filter(t => t);


    this.priorities = ["High", "Medium", "Low"];

    if (rivirCase) {
      this.dueDate = rivirCase.dueDate;
      this.selectedTypes = rivirCase.types;
      this.selectedPriority = rivirCase.priority;

      //const selectedAssignees = this.activeUsers.filter(au => au.id === rivirCase.assignee);
      this.selectedUsers = rivirCase.assignee;

      // Set the coas by combing existing assigned courses of action and the available 
      // courses of action and set the value
      this.coas = [].concat(rivirCase.courseOfActions).concat(subscription.coas);
      this.courseOfactionList = this.coas.map((x) => {
        return { id: x.instanceId, text: `${x.instanceUIId} ${x.courseOfActionName} (${x.UpdatedBy || x.email})` };
      });

      if(rivirCase.courseOfActions && rivirCase.courseOfActions.length){
        this.selectedCoursesOfAction = rivirCase.courseOfActions.map((x) => {
          return { id: x.instanceId, text: `${x.instanceUIId} ${x.courseOfActionName} (${x.UpdatedBy || x.email})` };
        });
      }

      this.rivirCase = rivirCase;
    }
    else {
      // When there is a new case the course of action is only the existing available courses of action
      // for that threat actor that don't have a case
      this.coas = subscription.coas;
      this.courseOfactionList = this.coas.map((x) => {
        return { id: x.instanceId, text: `${x.instanceUIId} ${x.courseOfActionName} (${x.UpdatedBy || x.email})` };
      });
      this.selectedCoursesOfAction = [];

    }
  }

  /**
   * Created\Edits an Case
   */
  async saveForm() {


    if ((this.pageType === CaseEditPageType.CASE_START) || (this.pageType === CaseEditPageType.CASE_EDIT)) {
      const newCase = await this.saveCase();
      this.showConfirmationPage(newCase, null);
    }
    else {
      let newOrUpdatedCase = null;
      const courseOfActions = await this.startCourseOfAction();

      if (courseOfActions) {
        if (this.nextAction === CaseEditNextAction.START_CASE) {
          newOrUpdatedCase = await this.saveCase(courseOfActions);
        }
        else if (this.nextAction === CaseEditNextAction.ASSIGN_CASE) {
          newOrUpdatedCase = await this.saveCase(courseOfActions);
        }

        this.showConfirmationPage(newOrUpdatedCase, courseOfActions.pop());
      }

    }

  }

  showNewCaseSection() {
    if (this.nextAction === CaseEditNextAction.START_CASE || this.nextAction === CaseEditNextAction.NO_ACTION) {
      this.resetCasesDropdown();
    }
    if (this.hideExistingCases == false) {
      this.showNoAssignedCases = false;
    }
    return (this.pageType === CaseEditPageType.CASE_START) ||
      (this.pageType === CaseEditPageType.COA_START && this.nextAction === CaseEditNextAction.START_CASE)
  }

  showExistingCasesSection() {
    if (this.nextAction === CaseEditNextAction.ASSIGN_CASE && this.hideExistingCases == true) {
      this.showNoAssignedCases = true;
    }
    else {
      this.showNoAssignedCases = false;
    }
    return this.pageType === CaseEditPageType.COA_START && this.nextAction === CaseEditNextAction.ASSIGN_CASE;
  }

  goBack() {
    this.location.back();
  }

  openCaseDetailPage() {
    this.router.navigate(["/case", this.caseId]);
  }

  openCourseOfAction() {
    this.router.navigate(["/coa/task", this.instanceId]);
  }

  toggleTypeSection() {
    this.showTypeEdit = !this.showTypeEdit;

    if (this.newTypeControl.value && !this.typesList.includes(this.newTypeControl.value)) {
      this.typesList.push(this.newTypeControl.value);
      this.newTypeControl.setValue("");
    }
    else {
      this.newTypeControl.setValue("");
    }

  }

  cancelTypeEdit() {
    this.showTypeEdit = !this.showTypeEdit;
    this.newTypeControl.setValue("");
  }

  coaUserSelected(selectedUser: any) {
    this.selectedCoaUser = this.activeUsers.find(au => au.id == selectedUser.id);
  }

  processDefinitionSelected(selectedPD: any) {
    this.selectedProcessDefintion = this.allProcessDefinitions.find(apd => apd.key === selectedPD.id);
  }

  disableSaveButton(): boolean {
    let disableSave = true;

    if (this.pageType === CaseEditPageType.CASE_START || this.pageType === CaseEditPageType.CASE_EDIT) {
      disableSave = !this.selectedCoursesOfAction || !this.selectedCoursesOfAction.length || this.areCaseFieldsEntered();
    }
    else if (this.pageType === CaseEditPageType.COA_START) {

      const areCoaFieldsEntered = !this.selectedProcessDefintion || !this.courseOfActionDueDate || !this.selectedCoaUser;

      if (this.nextAction === CaseEditNextAction.NO_ACTION) {
        disableSave = areCoaFieldsEntered;
      }
      else if (this.nextAction === CaseEditNextAction.START_CASE) {
        disableSave = areCoaFieldsEntered || this.areCaseFieldsEntered();
      }
      else if (this.nextAction === CaseEditNextAction.ASSIGN_CASE) {
        disableSave = areCoaFieldsEntered || !this.prevCaseSelected || !this.selectedCoaUser;
      }
    }

    return disableSave;
  }

  areCaseFieldsEntered(): boolean {
    return !this.selectedTypes || !this.selectedTypes.length || !this.selectedUsers || !this.selectedUsers.length;
  }

  async startCourseOfAction(): Promise<any> {

    let courseOfActions = null;
    this.payload = await this.caseService.getPayload( this.threatActor , this.selectedProcessDefintion.name, this.sightingName );
    this.payload.courseOfActionName = { value: this.selectedProcessDefintion.name, type: "String" };
    this.payload.coaDueDate = { value: this.courseOfActionDueDate, type: "String" };
    const camundaResponse = await this.workflowService.startProcessWithPayload(this.selectedProcessDefintion.key, this.payload, this.selectedCoaUser.email).toPromise();

    if (camundaResponse.status === "SUCCESS") {
      courseOfActions = [camundaResponse.body.id];
    }
    else {
      console.error(`Starting Course of Action ${this.selectedProcessDefintion.key} with response : ${JSON.stringify(camundaResponse)}`);
      this.toastr.error(`Failed to start ${this.stixConfig.coa_singular}`);
    }

    return courseOfActions;
  }

  async saveCase(courseOfActions?: string[]): Promise<any> {

    if (!courseOfActions) {
      courseOfActions = this.selectedCoursesOfAction && this.selectedCoursesOfAction.length ?
        this.selectedCoursesOfAction.map(csc => csc.id) :
        [];
    };

    let id = "";
    let types = [];
    let status = "";
    let assignee = "";
    let CreatedBy = "";
    let CreatedDateTime = "";
    const UpdatedBy = this.cookieService.get("email");

    if (this.nextAction !== CaseEditNextAction.ASSIGN_CASE) {
      types = this.selectedTypes && this.selectedTypes.length ?
        this.selectedTypes:
        [];

      status = this.caseService.caseStatuses ? this.caseService.caseStatuses[0] : "Open";
      assignee = this.selectedUsers;
      CreatedBy = this.rivirCase && this.rivirCase.CreatedBy ? this.rivirCase.CreatedBy : this.cookieService.get("email");
      CreatedDateTime = this.rivirCase && this.rivirCase.CreatedDateTime ? this.rivirCase.CreatedDateTime : null;
      id = this.rivirCase ? this.rivirCase.id : null;
    }
    else {
      this.caseId = this.prevCaseValues.caseId;
      types = this.prevCaseValues.types;
      status = this.prevCaseValues.status;
      assignee = this.prevCaseValues.assignee;
      CreatedBy = this.prevCaseValues.CreatedBy;
      CreatedDateTime = this.prevCaseValues.CreatedDateTime;
      id = this.prevCaseValues.id;
      this.selectedPriority = this.prevCaseValues.priority;
      this.dueDate = this.prevCaseValues.dueDate;
      // modify COA array
      courseOfActions.forEach(el => {
        this.prevCaseValues.courseOfActions.push(el);
      });
      courseOfActions = this.prevCaseValues.courseOfActions;
    }

    let rivirCase = {
      id,
      caseId: this.caseId,
      status,
      priority: this.selectedPriority,
      courseOfActions,
      types,
      assignee: assignee,
      CreatedBy,
      UpdatedBy,
      CreatedDateTime,
      dueDate: this.dueDate,
      threatActorId: this.threatActorId
    }

    const newCase = await this.caseService.saveCase(rivirCase).toPromise();

    if (newCase.caseId) {
      this.caseId = newCase.caseId;
      // TODO Set this to a switch to to cover all three cases
      //this.confirmationMessage = this.pageType !== 'CASE_EDIT' ? "You have created new case with the Case Id:" : "You have updated Case: ";
      const assignedUser = this.activeUsers.find(au => au.id === assignee);
      const currentUser = this.activeUsers.find(au => au.email === this.cookieService.get("email"));

      this.messageService.postMessage(
        "Case assigned",
        `Case [${this.caseId}] has been assigned to you by ${currentUser.name}`,
        assignedUser.email,
        true
      )
    }
    else {
      this.errorMessage = newCase.message;
    }

    return newCase;
  }

  showConfirmationPage(newCase?, instanceId?): void {

    // default confirmation page for cases
    this.secondaryConfirmationMessage = "Please use the buttons below to back to your previous page or open the case details page";

    if (this.pageType === CaseEditPageType.CASE_START) {
      this.caseConfirmationMessage = "You have created new case with the Case Id:";
    }
    else if (this.pageType === CaseEditPageType.CASE_EDIT) {
      this.caseConfirmationMessage = "You have updated Case:";
    }
    else if (this.pageType === CaseEditPageType.COA_START) {

      this.instanceId = instanceId;
      const baseCoaConfirmMsg = `You have successfully started a new ${this.stixConfig.coa_singular}:`;
      this.secondaryConfirmationMessage = `Please use the buttons below to back to your previous page or open the ${this.stixConfig.coa_singular}`;

      if (this.nextAction === CaseEditNextAction.START_CASE) {
        this.coaConfirmationMessage = baseCoaConfirmMsg;
        this.caseConfirmationMessage = "with a new Case:";
      }
      else if (this.nextAction === CaseEditNextAction.NO_ACTION) {
        this.coaConfirmationMessage = baseCoaConfirmMsg;
      }
    }

    this.pageType = CaseEditPageType.CONFIRMATION;
  }


  caseSelected(selectedCase: any) {
    if (selectedCase) {
      this.prevCaseSelected = true;
      this.prevCaseValues = this.casesAllList.find(i => i.id === selectedCase.id);
      let user = this.activeUsers.find(i => i.id == this.prevCaseValues.assignee);
      this.selectedCoaUser = user;
      this.prevAssignTo = user.email;
      this.prevAssignToDisplay = `${user.name} - (${user.email})`;
      this.prevCaseType = this.prevCaseValues.strTypes;
      this.prevDueDate = this.prevCaseValues.strDueDate;
      this.prevPriority = this.prevCaseValues.priority;
    }
  }

  async getExistingCases() {
    this.casesAllList = await this.caseService.getRelatedCases(this.taId).toPromise();
    if (this.casesAllList.length == 0) {
      this.hideExistingCases = true;
    }
    else {
      this.casesList = this.casesAllList.map((c) => {
        return { id: c.id, text: c.caseId };
      });
    }
  }

  resetCasesDropdown() {
    this.prevCaseSelected = false;
    this.prevAssignTo = "";
    this.prevCaseType = "";
    this.prevDueDate = "";
    this.prevPriority = "";
    this.casesList = this.casesAllList.map((c) => {
      return { id: c.id, text: c.caseId };
    });
  }



}
