import { HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError } from "rxjs";

@Injectable()
export class RivirHttp {
  public static camundaToken: string;
  public static rivirApiToken: string;

  public static getHttpOptions( body? : any ) {
    const headers = new HttpHeaders().set("Authorization", this.rivirApiToken);
    const httpOptions = { headers, body };
    return httpOptions;
  }

  // TODO this method doesn't really do anything but print and rethrow the error
  // This should be refactors to get the url and payload log it back to the api 
  // for future debugging and then fail gracefully
  public static handleError(err: HttpErrorResponse) {

    if (  err?.message ){
      console.error( err.message)
    }
    else {
      console.error( err ) ;
    }

    return throwError(err?.message || err);
  }
}
