import { Injectable } from "@angular/core";

@Injectable()
export class StixConfigService {
  public coa_singular: string;
  public coa_plural: string;
  public indicator_singular: string;
  public indicator_plural: string;
  public sighting_singular: string;
  public sighting_plural: string;
  public threatActor_singular: string;
  public threatActor_plural: string;
  public visualization_singular: string;
  public visualization_plural: string;
  public stixConfig: object;
  public threatActor_referenceDataID: string;
  public dataEntry_singular: string;
  public dataEntry_plural: string;


  constructor() {
 }

  public setStixConfig(configObject) {
    this.coa_singular = configObject.coa || "COA";
    this.coa_plural = configObject.coa_plural || "COAs";
    this.indicator_singular = configObject.indicator || "Indicator";
    this.indicator_plural = configObject.indicator_plural || "Indicators";
    this.sighting_singular = configObject.sighting || "Sighting";
    this.sighting_plural = configObject.sighting_plural || "Sightings";
    this.threatActor_singular = configObject.threatActor || "Threat Actor";
    this.threatActor_plural = configObject.threatActor_plural || "Threat Actors";
    this.threatActor_referenceDataID = configObject.threatActor_referenceDataID || "ID";
    this.visualization_singular = configObject.visualization || "Visualization";
    this.visualization_plural = configObject.visualization_plural || "Visualizations";
    this.dataEntry_singular = configObject.dataEntry_singular || "Data Entry";
    this.dataEntry_plural = configObject.dataEntry_plural || "Data Entries";
    this.stixConfig = {
      coa_singular: this.coa_singular,
      coa_plural: this.coa_plural,
      indicator_singular: this.indicator_singular,
      indicator_plural: this.indicator_plural,
      sighting_singular: this.sighting_singular,
      sighting_plural: this.sighting_plural,
      threatActor_singular: this.threatActor_singular,
      threatActor_plural: this.threatActor_plural,
      threatActor_referenceDataID : this.threatActor_referenceDataID,
      visualization_singular: this.visualization_singular,
      visualization_plural: this.visualization_plural,
      dataEntry_singular: this.dataEntry_singular,
      dataEntry_plural: this.dataEntry_plural
    };
  }

  public stixLabeling(columns) {

    columns.forEach((c) => {
      if (c.name) {
      if (c.name.indexOf(" ") == -1) {
        if (this.stixConfig[c.name]) {
          c.name = this.stixConfig[c.name];
        }
      } else {
        const texts = c.name.split(" ");
        const strings = [];
        texts.forEach((t) => {
          if (this.stixConfig[t]) {
            strings.push(this.stixConfig[t]);
          } else {
            strings.push(t);
          }
        });
        c.name = strings.join(" ");
      }
    } else if (c.type) {
      if (c.type.indexOf(" ") == -1) {
        if (this.stixConfig[c.type]) {
          c.type = this.stixConfig[c.type];
        }
      } else {
        const texts = c.type.split(" ");
        const strings = [];
        texts.forEach((t) => {
          if (this.stixConfig[t]) {
            strings.push(this.stixConfig[t]);
          } else {
            strings.push(t);
          }
        });
        c.type = strings.join(" ");
      }
    }
    });
    return columns;
  }
}
