import { HttpClient } from "@angular/common/http";
import { Component, Input, OnInit } from "@angular/core";

import { BsModalRef } from "ngx-bootstrap/modal";

import { catchError } from 'rxjs/operators';

import { EnvService } from "../../../app/env.service";
import { ObservableMetaData } from "../../shared/models/observableMetadata";
import { RivirHttp } from "../../shared/services/rivir-http.service";
import { StixConfigService } from "../../shared/services/stixConfig.service";
import { CookieService } from 'ngx-cookie-service';

// TODO This component is not being used 
// should be refactored or deleted
@Component({
  selector: "app-ob-admin-edit",
  templateUrl: "./ob-admin-edit.component.html",
  styleUrls: ["./ob-admin-edit.component.css"],
})
export class ObAdminEditComponent implements OnInit {

  @Input() public observableMetaData: ObservableMetaData;
  public wrapModal: boolean = false;
  public label_threatActor: string;
  public metaDataTypes: any[];
  public message: any;


  constructor(
    private http: HttpClient,
    public modalRef: BsModalRef,
    public stixConfig: StixConfigService,
    private cookieService: CookieService,
    private env: EnvService) { }

  public ngOnInit() {
    this.label_threatActor = this.stixConfig.threatActor_singular;

    const getTypesUrl = `${this.env.rivirApiBaseUrl}/observableDataMetadata/types`;
    this.http.get(getTypesUrl, RivirHttp.getHttpOptions())
      .pipe(catchError(RivirHttp.handleError))
      .subscribe((response: any) => {
        this.metaDataTypes = response;
      });
  }

  public saveObservableMetada(): void {

    const self = this;

    const url = `${this.env.rivirApiBaseUrl}/observableDataMetadata/${this.observableMetaData.id}/replace`;

    const updatedMetaData: ObservableMetaData = {
      id: this.observableMetaData.id,
      name: this.observableMetaData.name,
      type: this.observableMetaData.type,
      isSearchable: this.observableMetaData.isSearchable,
      hidden: this.observableMetaData.hidden,
      description: this.observableMetaData.description,
      helpText: this.observableMetaData.helpText,
      section: this.observableMetaData.section,
      order: this.observableMetaData.order ? this.observableMetaData.order : null,
      metaDataType: this.observableMetaData.metaDataType,
      CreatedBy: this.observableMetaData.CreatedBy,
      CreatedDateTime: this.observableMetaData.CreatedDateTime,
      UpdatedBy: this.cookieService.get("email"),
      UpdatedDateTime: this.observableMetaData.UpdatedDateTime
    }

    this.http.post(url, updatedMetaData, RivirHttp.getHttpOptions())
      .pipe(catchError(RivirHttp.handleError))
      .subscribe(() => {

        const clearCacheUrl = `${this.env.rivirApiBaseUrl}/cache/clear`;
        this.http.post(clearCacheUrl, null, RivirHttp.getHttpOptions())
          .pipe(catchError(RivirHttp.handleError))
          .subscribe(() => {
            // Close the modal
            self.modalRef.hide();
          });
      });

  }
}
