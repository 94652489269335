import { Component, Input, OnInit } from "@angular/core";
import { CookieService } from 'ngx-cookie-service';
import { BsModalRef } from "ngx-bootstrap/modal";

import { Observable } from "rxjs";

import { coa } from "../../shared/models/coa";
import { MessageService } from "../../shared/services/message.service";
import { StixConfigService } from "../../shared/services/stixConfig.service";
import { WorkflowService } from "../../shared/services/workflow.service";
import { LoggingService, LogLevel, LogType } from "../../core/logging.service";
import { PermissionService } from "../../core/permission.service";
import { UserProfileService } from "../../core/userProfile.service";
import { CoAService } from "../coa.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-coa-execute",
  templateUrl: "./coa-execute.component.html",
  styleUrls: ["./coa-execute.component.css"],
})
export class CoaExecuteComponent implements OnInit {
  @Input() public recommendedCoa;
  @Input() public payload: any;
  @Input() public currentTask: any;
  @Input() public modelName: string;
  @Input() public courseOfAction: coa;

  public title: string;
  public closeBtnName: string;
  public errorMessage: string;
  public statusMessage: string;

  public processId: string;
  public processName: string;
  public coaSelected: any[] = [];
  public investigatorId: string;
  public dueDate: string;
  public todayDate: string;

  public processes: any[] = [];
  public investigators: any[] = [];

  public permission_execute_CoA = false;
  public permission_update_dueDate = false;
  public permission_reassignCoA = false;
  public investigatorItems: any[] = [];
  public processesItems: any[] = [];
  public recommendedCoAItems: any[] = [];
  public value: any = {};
  public label_coa: string;
  public investigator_valid = false;
  public workflow_valid = false;
  public execute_success = false;
  public newPayload: any;

  constructor(
    public bsModalRef: BsModalRef,
    private coaService: CoAService,
    private userProfileService: UserProfileService,
    private workflowService: WorkflowService,
    private permissions: PermissionService,
    private userProfile: UserProfileService,
    private messageService: MessageService,
    public stixConfig: StixConfigService,
    private log: LoggingService,
    private cookieService: CookieService,
    public toastr: ToastrService
  ) { }

  /**
   * Setup for the Course of Action Execute Modal
   */
  public async ngOnInit() {
    this.label_coa = this.stixConfig.coa_singular;

    // Get the permissions
    this.permission_execute_CoA = await this.permissions.getPermission("Start Course Of Action");
    this.permission_update_dueDate = await this.permissions.getPermission("Update Course of Action Due Date");
    this.permission_reassignCoA = await this.permissions.getPermission("Re-assign Course of Action");

    // Get the Course Of Actions
    await this.getCoAProcesses();

    const users = await this.userProfileService.getActiveUsers().toPromise();

    // Currently the list of investigators is all active user to be similar to the default
    // Camunda funcationality.   This may change in the future
    this.investigators = users.sort((a, b) => a.name.localeCompare(b.name));

    // Convert the Investigators into a list of objects {id: "", name: ""} to be used by ng2-select
    this.investigatorItems = this.investigators.map((x) => {
      return { id: x.email, text: `${x.name} (${x.email})` };
    });

    // If Course of Action is passed as Input create DueDate using mm/DD/yyyy format
    if (this.courseOfAction && this.courseOfAction.coaDueDate) {
      this.dueDate = new Date(this.courseOfAction.coaDueDate)
        .toISOString()
        .substring(0, 10);
    }

    // If there is only one Recommended Course of Action set that as the process name
    if (this.recommendedCoa && this.recommendedCoa.length > 0) {
      // Convert the recommendedCoAs into a list of objects {id: "", name: ""} to be used by ng2-select
      this.recommendedCoAItems = this.processesItems.filter( x  => this.recommendedCoa.includes( x.text) );
      this.processName = "";
      if (this.recommendedCoa && this.recommendedCoa.length == 1) {
        this.coaSelected = [this.recommendedCoAItems[0]];
        this.processName = this.recommendedCoa[0];
      }
    }
  }



  async executeCoa() {

    this.statusMessage = null;
    this.investigator_valid =
      this.investigatorId && this.investigatorId != "-1";
    this.workflow_valid =
      (this.processName && this.processName.length > 0) ||
      (this.processId && this.processId != "-1");
    if (this.workflow_valid && this.investigator_valid && this.dueDate) {
      // Set the course of action name in the payload to be the selected course of action from the drop down
      if (this.processName) {
        this.payload.courseOfActionName = { value: this.processName, type: "String" };
        const obj = this.processes.find((x) => x.name == this.processName) || {};
        this.processId = obj.key || "-1";
      } else {
        const courseOfActionName = this.processes.find(
          (x) => x.key == this.processId,
        ).name;
        this.payload.courseOfActionName = { value: courseOfActionName, type: "String" };
      }
      this.payload.coaDueDate = { value: this.dueDate, type: "String" };


      if (this.processId === "-1") {
        this.statusMessage = `Start ${this.label_coa} Process FAILURE: Cannot find ${this.processName}' Workflow file`;
      } else {
        this.workflowService.startProcessWithPayload( this.processId, this.payload, this.investigatorId )
          .subscribe(
            (res) => {
              if (res && res.status !== "ERROR") {
                this.execute_success = true;
                this.statusMessage = `Start ${this.label_coa } Process (${this.processName}): SUCCESS`;
                this.messageService.getMessagesForUserEmail( this.userProfileService.userEmail);
              } else {
                this.statusMessage = `Start ${this.label_coa} Process: FAILURE`;
                console.error(res);
              }
            },
            (err) => {
              this.statusMessage = this.label_coa + " Process Request: FAILURE";
              console.error(err);
            },
          );
      }
    }
  }

  public assignCoa() {
    this.statusMessage = null;
    if (this.currentTask.id) {
      this.investigator_valid =
        this.investigatorId && this.investigatorId != "-1";
      if (this.investigator_valid) {
        this.workflowService
          .claimUserTask(this.currentTask.id, this.investigatorId)
          .subscribe((res) => {
            if (res.status.status == "SUCCESS") {
              this.statusMessage = this.label_coa + " Re-assign: SUCCESS";
              this.messageService.getMessagesForUserEmail(
                this.userProfileService.userEmail,
              );
              this.execute_success = true;

              // Log COA reassign entry
              this.log.logClientEvent(
                "COA Reassigned",
                LogLevel.Info,
                LogType.App,
                this.cookieService.get("email"),
                JSON.stringify(this.courseOfAction),
              );
            } else {
              this.statusMessage =
                "Start " + this.label_coa + " Process: FAILURE";
              console.error(res);
            }
          });
      }
    } else {
      this.statusMessage = "Task ID is not available";
    }
  }

  public update() {
    if (this.courseOfAction) {
      if (this.dueDate) {
        this.statusMessage = null;

        // save new COA due date
        this.courseOfAction.coaDueDate = this.dueDate;
        this.coaService.updateDuedate(this.courseOfAction.instanceId, this.dueDate, this.userProfile.userEmail).subscribe(
          (res) => {
            this.execute_success = true;
            this.statusMessage = this.label_coa + " Due Date Update: SUCCESS";

            // Log COA reassign entry
            this.log.logClientEvent(
              "COA Date Updated",
              LogLevel.Info,
              LogType.App,
              this.cookieService.get("email"),
              JSON.stringify(this.courseOfAction),
            );
          },
          (error) =>
            (this.statusMessage = this.label_coa + " Due Date Update: FAILURE"),
        );
      }
    }
  }

  public async getCoAProcesses() {
    const courses = await this.workflowService.getProcessDefinitions();

    for (const c of courses) {
      const existingProcess = this.processes.find((x) => x.name == c.name);

      if (!existingProcess) {
        this.processes.push(c);
      } else {
        if (existingProcess.version < c.version) {
          this.processes = this.processes.filter(
            (obj) => obj !== existingProcess,
          );
          this.processes.push(c);
        }
      }
    }

    this.processes.sort((a, b) => {
      const bpmnA = a.name || a.key;
      const bpmnB = b.name || b.key;

      return bpmnA.localeCompare(bpmnB);
    });

    this.processesItems = this.processes.map((x) => {
      return {
        id: x.key,
        text: x.name || x.key
      };
    });
  }

  /**
   * Select Event Handler for Investigator ng2-select.  Sets the investigator id
   * @param value Selected Value
   */
  public investigatorSelected(value: any): void {
    this.investigatorId = value.id;
    this.investigator_valid = true;
  }

  /**
   * Removed Event Handler for the Investigator ng2-select, resets the investigator id
   * @param value
   */
  public investigatorRemoved(value: any): void {
    this.investigatorId = "-1";
  }

  /**
   * Select Event Handler for processes ng2-select.  Sets the investigator id
   * @param value Selected Value
   */
  public processesSelected(value: any): void {
    this.processId = value.id;
    this.workflow_valid = true;
  }

  /**
   * Removed Event Handler for the processes ng2-select, resets the investigator id
   * @param value
   */
  public processesRemoved(value: any): void {
    this.processId = "-1";
  }

  /**
   * Select Event Handler for recommendedCoA ng2-select.  Sets the investigator id
   * @param value Selected Value
   */
  public recommendedCoASelected(value: any): void {
    this.processName = this.processes.find(x => x.key === this.processId).name;
    this.workflow_valid = true;
  }

  /**
   * Removed Event Handler for the recommendedCoA ng2-select, resets the investigator id
   * @param value
   */
  public recommendedCoARemoved(value: any): void {
    this.processName = "";
  }

  public handleException(error: Response) {
    console.error(error);
    return Observable.throw(error.json());
  }
}
