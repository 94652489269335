import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import _groupBy from "lodash-es/groupBy";

import { forkJoin, BehaviorSubject, of, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { EnvService } from "../../env.service";
import { UserProfileService } from "../../core/userProfile.service";
import { UserProfile } from "../models/userProfile";
import { RivirHttp } from "./rivir-http.service";
import { StixConfigService } from "./stixConfig.service";
import { WorkflowService } from "./workflow.service";
import { DataSource } from '@angular/cdk/table';
import { CollectionViewer } from '@angular/cdk/collections';
import { catchError, finalize } from "rxjs/operators";

export class BulkAssignmentThreatActor {
  public taId: string;
  public name: string;
}

export class BulkAssignmentCOA {
  public instanceId: string;
  public taId: string;
  public courseOfActionName: string;
}

export class BulkAssignmentModel {
  public threatActors: BulkAssignmentThreatActor[];
  public coadata: BulkAssignmentCOA[];
  public coursesOfActions: any[];
  public assignType?: string;
  public users: UserProfile[];
  public groups: string[];
  public totalThreatActorsCount: number;
  public threatActorTableHeader: string;
  public coaTableHeader: string;
  public courseOfActionLabel: string;
  public coaOptions: any[];
  public userOptions: any[];
}

export class BulkAssignmentRequest {
  public threatActors: any[];
  public coas: string[];
  public dueDate: string;
  public users: any[];
  public sighting: string;
}

export class BulkQueueItem {
  public type: string;
  public instanceId: string;
  public taId: string;
  public threatActorId: string;
  public status: string;
  public id: string;
  public courseOfActionId: string;
  public courseOfAction: string;
  public courseOfActionName?: string;
  public user: string;
}

export class BulkStatusModel {
  public queueItems: BulkQueueItem[];
  public bulkStatusType: string;
  public statusTotals: any[];
  public retry: boolean;
}

@Injectable()
export class BulkAssignmentService {
  public selectedThreatActors: string[] = [];
  public selectedCOAs: string[] = [];
  public coaView: boolean = false;
  public coursesOfAction: any[];

  constructor(
    private http: HttpClient,
    private userProfileService: UserProfileService,
    public stixConfig: StixConfigService,
    private workflowService: WorkflowService,
    private env: EnvService
  ) { }

  public async getInitialData(): Promise<BulkAssignmentModel> {
    const self = this;
    const promise = new Promise<BulkAssignmentModel>(async (resolve, reject) => {

      const coursesOfActions = await self.workflowService.getProcessDefinitions();
      const bulkSource = forkJoin({
        groups: self.userProfileService.getGroups(null),
        users: self.userProfileService.getActiveUsers(),
      });

      bulkSource.subscribe(
        (subscription) => {

          // Get the options that are being used for the options
          const coaOptions = coursesOfActions.map((c) => {
            return { id: c.key, text: c.name || c.key };
          });
          coaOptions.sort((a, b) => {
            const aText = a.text.toLowerCase();
            const bText = b.text.toLowerCase();
            if (aText == bText) return 0;
            return (aText > aText) ? 1 : -1;
          })
          const userOptions = subscription.users.map((u) => {
            return { id: u.email, text: `${u.name} - ${u.email}` };
          });

          // Get the labels
          const threatActorTableHeader = `${self.stixConfig.threatActor_plural
            } chosen:`;

          const coaTableHeader = `${self.stixConfig.coa_plural
            } chosen:`;

          const courseOfActionLabel = `${self.stixConfig.coa_plural
            } to assign to ${self.stixConfig.threatActor_singular}:*`;

          const model: BulkAssignmentModel = {
            threatActors: [],
            coursesOfActions: coursesOfActions,
            coadata: [],
            groups: subscription.groups.results,
            users: subscription.users,
            totalThreatActorsCount: 0,
            threatActorTableHeader,
            coaTableHeader,
            courseOfActionLabel,
            coaOptions,
            userOptions,
          };
          resolve(model);
        },
        (error) => {
          reject(error);
        },
      );
    });

    return promise;
  }

  public getDataForThreatActors(sightingName, sort, page, pageSize): Observable<BulkAssignmentThreatActor[]> {

    const sortArray = sort.split(" ");
    const sortObj = {};
    sortObj[sortArray[0]] = sortArray[1];

    page++;

    const taQuery = `${this.env.rivirApiBaseUrl}/sightings/getFilteredThreatActors`;
    return this.http
      .post<any>(
        taQuery,
        {
          sightingName,
          filter: { taId: this.selectedThreatActors },
          sort: sortObj,
          page,
          count: pageSize
        },
        RivirHttp.getHttpOptions(),
      )
      .pipe(map((response: any) => {
        return response.results;
      }
      ))
      .pipe(catchError(RivirHttp.handleError));
  }

  public getDataForCOAs(sort, page, pageSize): Observable<BulkAssignmentCOA[]> {
    const coaQuery = `${this.env.rivirApiBaseUrl}/courseofactions/search`;
    return this.http
      .post<BulkAssignmentCOA[]>(
        coaQuery,
        {
          ids: this.selectedCOAs
        },
        RivirHttp.getHttpOptions(),
      )
      .pipe(catchError(RivirHttp.handleError));
  }

  public bulkStartThreactActors(bulkStartRequest: any): Observable<any> {
    return this.http
      .post<BulkQueueItem[]>(
        `${this.env.rivirApiBaseUrl}/workflow/bulk/start`,
        { options: bulkStartRequest },
        RivirHttp.getHttpOptions(),
      )
      .pipe(catchError(RivirHttp.handleError));
  }

  public bulkAssignCoa(bulkStartRequest: any): Observable<any> {
    return this.http
      .post<BulkQueueItem[]>(
        `${this.env.rivirApiBaseUrl}/workflow/bulk/assignment`,
        { options: bulkStartRequest },
        RivirHttp.getHttpOptions(),
      )
      .pipe(catchError(RivirHttp.handleError));
  }

  public getQueueItems(instanceId: string): Observable<any> {
    return this.http
      .get(
        `${this.env.rivirApiBaseUrl
        }/CamundaRequestQueue/items/${instanceId}`,
        RivirHttp.getHttpOptions(),
      )
      .pipe(catchError(RivirHttp.handleError));
  }

  public getQueueInstanceIds(): Observable<string[]> {

    return this.http
      .get<string[]>(
        `${this.env.rivirApiBaseUrl}/CamundaRequestQueue/instanceIds`,
        RivirHttp.getHttpOptions(),
      )
      .pipe(catchError(RivirHttp.handleError));
  }

  public async loadQueueData(instanceId: string): Promise<any> {
    const self = this;
    const promise = new Promise<any>(function (resolve, reject) {
      const bulkSource = forkJoin({
        queueItems: self.getQueueItems(instanceId),
      });

      bulkSource.subscribe(
        (subscription) => {
          // Deconstruct the subscriptions
          const queueItems = subscription.queueItems;

          // Get the totals for each of the queue items by status and user
          const statusTotals = self.getTotals(queueItems);

          // There is only one type per bulk status instance, grab the first one
          // from the list
          const bulkStatusType =
            queueItems && queueItems.length > 0 ? queueItems[0].type : "";

          // Check to see this instance should be retried if any of the queueItems are
          // in RETRYING status
          const retryQueueItem = queueItems.find(
            (q) => q.status === "RETRYING" || q.status === "PENDING",
          );
          const retry = retryQueueItem ? true : false;

          const model: BulkStatusModel = {
            queueItems,
            statusTotals,
            bulkStatusType,
            retry,
          };

          resolve(model);
        },
        (error) => {
          reject(error);
        },
      );
    });

    return promise;
  }

  public getTotals(bulkQueueItems: BulkQueueItem[]): any[] {
    const totals = [];
    const possibleStatues = ["SUCCESS", "ERROR", "PENDING", "RETRYING"];

    possibleStatues.forEach((pStatus) => {
      const statusBulkQueueItems = bulkQueueItems.filter(
        (b) => b.status == pStatus,
      );

      if (statusBulkQueueItems && statusBulkQueueItems.length) {
        const statusTotal = _groupBy(statusBulkQueueItems, "user");

        const users = Object.keys(statusTotal);
        if (users) {
          users.forEach((user) => {
            totals.push({
              status: pStatus,
              user: user ? user : "",
              total: statusTotal[user].length,
            });
          });
        }
      }
    });

    return totals;
  }
}

export class BulkAssignmentCOADataSource implements DataSource<BulkAssignmentCOA> {

  private coaSubject = new BehaviorSubject<BulkAssignmentCOA[]>([]);

  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();

  constructor(private bulkAssignmentService: BulkAssignmentService) {

  }

  loadingCourseOfActions(
    sort: string = 'taId asc',
    pageIndex: number = 0,
    pageSize: number = 10
  ) {

    this.loadingSubject.next(true);

    this.bulkAssignmentService
      .getDataForCOAs(sort, pageIndex, pageSize)
      .pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe(coas => this.coaSubject.next(coas));
  }

  connect(collectionViewer: CollectionViewer): Observable<BulkAssignmentCOA[]> {
    return this.coaSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.coaSubject.complete();
    this.loadingSubject.complete();
  }
}

export class BulkAssignmentTADataSource implements DataSource<BulkAssignmentThreatActor> {

  private taSubject = new BehaviorSubject<BulkAssignmentThreatActor[]>([]);

  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();

  constructor(private bulkAssignmentService: BulkAssignmentService) {

  }

  loadingThreatActors(
    sightingName: string,
    sort: string = 'name ASC',
    pageIndex: number = 0,
    pageSize: number = 3
  ) {

    this.loadingSubject.next(true);

    this.bulkAssignmentService
      .getDataForThreatActors(sightingName, sort, pageIndex, pageSize)
      .pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe(coas => this.taSubject.next(coas));
  }

  connect(collectionViewer: CollectionViewer): Observable<BulkAssignmentThreatActor[]> {
    return this.taSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.taSubject.complete();
    this.loadingSubject.complete();
  }
}