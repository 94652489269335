<div class="modal-header">
    <h3 class="modal-title pull-left">{{modelName}}</h3>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <form name="form" (ngSubmit)="fm.form.valid" class="darker-form" #fm="ngForm" novalidate>
        <fieldset class="col-sm-10 m-l-xs" style="font-size: 15px;">
            <div class="form-group m-t-md">
                <div class="col-sm-3">
                    <label>Frequency:</label>
                </div>
                <div class="input-group  col-sm-4">
                    <div *ngFor="let freq of frequencyValues">
                        <label for="frequency_{{freq}}">
                            <input id="frequency_{{freq}}" type="radio" name="frequency" required
                                [(ngModel)]="frequency" [value]="freq"> {{freq}}
                        </label>
                    </div>
                    <div *ngIf="fm.submitted && !frequency" class="alert alert-danger">
                        Frequency is required
                    </div>
                </div>
            </div>
            <div class="form-group m-t-md">
                <div class="col-sm-3">
                    <label for="setStartDate">Starting at Date:</label>
                </div>
                <div class="input-group  col-sm-5">
                    <input class="form-control date-input" type="date" [(ngModel)]="startDate" #startDateIn="ngModel"
                        name="startDate" min="{{todaysDate}}" id="setStartDate"
                        [ngClass]="{ 'is-invalid': fm.submitted && (startDateIn.invalid || !startDate_valid)}" required
                        (change)="datesChanged()">
                    <span class="input-group-btn datepicker-btn">
                        <button type="button" class="btn btn-default">
                            <i class="glyphicon glyphicon-calendar"></i><span class="hidden">Calender</span>
                        </button>
                    </span>
                    <div *ngIf="fm.submitted && (startDateIn.invalid || !startDate_valid)" class="alert alert-danger"
                        style="padding-top: 38px;padding-bottom: 7px;">
                        <div *ngIf="startDateIn.errors && startDateIn.errors.required">Start Date is required</div>
                        <div *ngIf="!startDate_valid">Start Date must be today or later</div>
                    </div>
                </div>
            </div>
            <div class="form-group m-t-md">

                <div class="col-sm-3">
                    <label for="setStartDate">Starting at Time:</label>
                </div>

                <div class="input-group col-offset-sm-3 col-sm-6 time-pick">

                    <input type="time" id="appt" name="appt" [(ngModel)]="startTime" style="height: 30px; width: 219px; margin-left:16px; border-color: #cfdadd; border-style:solid; border-width:1px;">

                    <div *ngIf="fm.submitted && !(this.startTime)"
                        class="alert alert-danger" style="margin-left: 15px;margin-right: 15px;">
                        <div style="padding-top: 30px;">Start Time is required</div>
                    </div>
                </div>
            </div>

            <div class="form-group m-t-md">
                <div class="col-sm-3">
                    <label for="setUntilDate">Until (End Date):</label>
                </div>
                <div class="input-group  col-sm-5">
                    <input class="form-control date-input" type="date" [(ngModel)]="untilDate" #untilDateIn="ngModel"
                        name="untilDate" min="{{todaysDate}}" id="setUntilDate"
                        [ngClass]="{ 'is-invalid': fm.submitted && (untilDateIn.invalid || !untilDate_valid) }"
                        (change)="datesChanged()">
                    <span class="input-group-btn datepicker-btn">
                        <button type="button" class="btn btn-default">
                            <i class="glyphicon glyphicon-calendar"></i><span class="hidden">Calender</span>
                        </button>
                    </span>
                    <div *ngIf="fm.submitted && !untilDate_valid" class="alert alert-danger"
                        style="padding-top: 38px;padding-bottom: 7px;">
                        <div *ngIf="!untilDate_valid">End Date cannot be earlier than Start Date</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="line line-dashed b-b line-lg pull-in m-t-md m-b-sm"></div>
            </div>
        </fieldset>
        <fieldset class="col-sm-10 m-l-xs" style="font-size: 15px;">
            <div class="row col-sm-12 m-t-xl m-b-lg">
                <div class="col-sm-3">
                    <button type="submit" class="btn btn-primary btn-algorithm" (click)="saveSchedule()">Save</button>
                </div>
                <div class="col-sm-3">
                    <button type="button" class="btn btn-secondary btn-algorithm"
                        (click)="modalRef.hide()">Cancel</button>
                </div>
            </div>
        </fieldset>
    </form>
</div>