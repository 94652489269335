<button
  *ngIf="permission_createAlgo"
  type="button"
  class="btn btn-md btn-primary btn-addon"
  (click)="createAlgorithm()"
>
  <i class="fa fa-plus"></i>
  Add New {{ label_indicator }}
</button>
<br /><br />
<div class="panel panel-default">
  <div class="panel-heading">
    {{ tableTitle }}
    <a (click)="fetchIndicatorsList()" class="refresh-list"
      ><i class="fa fa-refresh"></i> Refresh List</a
    >
  </div>
  <div *ngIf="!loaded" class="loader">
    Loading <span class="loader__dot">.</span><span class="loader__dot">.</span
    ><span class="loader__dot">.</span>
  </div>
  <div *ngIf="loaded" class="table-responsive">
    <div class="dataTables_wrapper form-inline dt-bootstrap no-footer">
      <div class="row" *ngIf="!sideTable">
        <div class="col-sm-9">
          <div class="dataTables_length">
            <label
              >Show
              <select
                [(ngModel)]="limitRows"
                class="form-control input-sm"
                (change)="updateTabelInfo()"
              >
                <option *ngFor="let num of limitRowsOptions" [value]="num">{{
                  num
                }}</option>
              </select>
              entries
            </label>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="dataTables_filter pull-right m-r-sm">
            <label
              >Filter:
              <input
                [(ngModel)]="term"
                class="form-control input-sm"
                type="search"
                (ngModelChange)="filterRows()"
              />
            </label>
          </div>
        </div>
      </div>
      <div class="row" [style.margin-bottom]="sideTable ? '0' : '16px'">
        <div class="col-sm-12">
          <table
            class="table table-striped b-t b-b dataTable no-footer"
            role="grid"
          >
            <thead>
              <tr role="row">
                <th
                  *ngFor="let column of columns"
                  (click)="columnClick(column.prop)"
                  class="{{ 'th-' + column.prop }} 
                    sorting{{
                    sortKey == column.prop
                      ? asc == true
                        ? '_asc'
                        : '_desc'
                      : ''
                  }}"
                >
                  {{ column.name }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let item of rows
                    | orderBy: sortKey:asc
                    | paginate
                      : {
                          id: 'indicator',
                          itemsPerPage: limitRows,
                          currentPage: p
                        }
                "
                class="active-{{ item['active'] }} status-{{ item['status'] }}"
                role="row"
              >
                <td
                  *ngFor="let column of columns"
                  class="{{ 'td-' + column.prop }}"
                >
                  <span
                    *ngIf="
                      (item[column.prop] || column.prop == 'active') &&
                      !isArray(item[column.prop])
                    "
                    (click)="itemClick(column.prop, item)"
                    title="{{ getHoverTitle(column.prop, item) }}"
                  >
                    {{
                      column.prop == "lastRunDate"
                        ? (item[column.prop] | date: "short")
                        : item[column.prop]
                    }}
                  </span>
                  <span *ngIf="isArray(item[column.prop])" class="subItem">
                    <span
                      *ngFor="let subItem of item[column.prop]"
                      (click)="subItemClick(column.prop, item, subItem)"
                    >
                      {{ subItem }}
                      <br />
                    </span>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="dataTables_info">
            {{ tableInfo }}
          </div>
        </div>
        <div class="col-sm-6">
          <pagination-controls
            id="indicator"
            (pageChange)="p = $event; updateTabelInfo()"
            class=" pull-right"
          ></pagination-controls>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="loaded" class="mobile-view">
    <mat-accordion>
      <mat-expansion-panel
        *ngFor="
          let indicator of rows
          | orderBy: sortKey:asc
          | paginate
            : {
                id: 'indicator',
                itemsPerPage: limitRows,
                currentPage: p
            }
        ">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ indicator.name }}
          </mat-panel-title>
        </mat-expansion-panel-header>
  
        <div class="container-fluid">
          <div class="row">
            <div class="col-12" (click)="itemClick('name', indicator)">
                <span class="mobile-description">{{ indicator.description }}</span>                
            </div>
          </div>
          <div class="row">
            <div class="col-xs-8">
              <div *ngIf="indicator.classification" class="row">
                <div class="col-12">
                  <span class="mobile-label">Classification: </span>
                  <span class="mobile-value">{{ indicator.classification }}</span>
                </div>
              </div>
              <div *ngIf="indicator.type" class="row">
                <div class="col-12">
                  <span class="mobile-label">Type: </span>
                  <span class="mobile-value">{{ indicator.type }}</span>
                </div>
              </div>
              <div *ngIf="indicator.lastRunDate" class="row">
                <div class="col-12">
                  <span class="mobile-label">Last Run Date: </span>
                  <span class="mobile-value mobile-value-block">{{ indicator.lastRunDate | date: "short" }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <span class="mobile-label">Sightings: </span>
                  <span class="mobile-value">{{ indicator.numOfSightings }}</span>
                </div>
              </div>
              <div *ngIf="indicator.coa" class="row">
                <div class="col-12">
                  <span class="mobile-label">Recommended CoA: </span>
                  <span class="mobile-value mobile-value-block">{{ indicator.coa }}</span>
                </div>
              </div>
            </div>
            <div
              class="col-xs-4 mobile-execute"
              (click)="itemClick('execute', indicator)"
            >
              <!-- <i class="fa fa-play-circle"></i> -->
            <span class="execute-status status-{{ indicator['status'] }}"></span>
              
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <div class="row mobile-pagination">
      <div class="col-12">
          {{ tableInfo }}
      </div>
      <div class="col-12">
        <pagination-controls
          id="indicator"
          (pageChange)="p = $event; updateTabelInfo()"
        ></pagination-controls>
      </div>
    </div>
  
  </div>  
</div>
