<div *ngIf="showSupportingData" class="panel panel-default">
  <div class="panel-heading">
    {{tableTitle}}
    <a (click)="exportSupportingData()" aria-label="Export CSV" class="refresh-list"><i class="fa fa-file-o"></i>Export CSV</a>
  </div>
  <div class="table-responsive">
    <div class="dataTables_wrapper form-inline dt-bootstrap no-footer">
      <div class="row" *ngIf="!sideTable">
        <div class="col-sm-9">
          <div class="dataTables_length">
            <label>Show
              <select [(ngModel)]="limitRows" class="form-control input-sm" (change)="updateTabelInfo()">
                <option *ngFor="let num of limitRowsOptions" [value]="num">{{num}}</option>
              </select>
              entries
            </label>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="dataTables_filter pull-right m-r-sm">
              <label>Filter:
                  <input [(ngModel)]="term" class="form-control input-sm" type="search">
                </label>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="columns.length > 0" [style.margin-bottom]="sideTable? '0':'16px'">
        <div class="col-sm-12">
          <table class="table table-striped b-t b-b dataTable no-footer" role="grid">
            <thead>
              <tr role="row">
                <th *ngFor="let column of columns" (click)="columnClick(column.prop)" class="{{'th-'+column.prop}} sorting{{sortKey == column.prop ? asc == true ? '_asc' : '_desc' : ''}}">
                  {{column.name}}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of rows | orderBy: sortKey: asc | filter : term : columns| paginate: { id: 'supporting', itemsPerPage: limitRows, currentPage: p }"
                role="row">
                <td *ngFor="let column of columns" class="{{'td-'+column.prop}}">
                  <span *ngIf="item[column.prop]" (click)="itemClick(column.prop, item)">{{item[column.prop]}}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row" *ngIf="columns.length > 0">
        <div class="col-sm-6">
            <div class="dataTables_info">
                {{tableInfo}} </div>
        </div>
        <div class="col-sm-6">
          <pagination-controls id="supporting" (pageChange)="p = $event;updateTabelInfo()" class=" pull-right"></pagination-controls>
        </div>
      </div>
      <div class="row" *ngIf="columns.length == 0">
        <div class="col-sm-6">
         <em style="opacity:0.85">No Records Available</em>
      </div>
    </div>
  </div>
</div>
