import { Component, OnInit } from "@angular/core";

import { StixConfigService } from "../../shared/services/stixConfig.service";
import { IndicatorService } from "../../indicators/indicators.service";
import { MatDialog } from "@angular/material/dialog";
import { IndicatorWizNewItemDialogComponent } from "../indicator-wiz-new-item-dialog/indicator-wiz-new-item-dialog.component";
import { IndicatorWizardModel, IndicatorWizardService } from "../indicator-wizard.service";

@Component({
  selector: 'indicator-wiz-details',
  templateUrl: './indicator-wiz-details.component.html',
  styleUrls: ['./indicator-wiz-details.component.css']
})
export class IndicatorWizDetailsComponent implements OnInit {
  public label_indicator: string;
  public allClassifications: string[];
  public allTypes: string[];
  public model: IndicatorWizardModel;
  public saveBoundCallback: () => void;

  constructor(
    private indicatorWizardService: IndicatorWizardService, 
    private indicatorService: IndicatorService,
    public stixConfig: StixConfigService,
    public dialog: MatDialog,
  ) {
    this.label_indicator = this.stixConfig.indicator_singular;
  }

  async ngOnInit() {

    this.saveBoundCallback = this.saveLocalDataToModel.bind(this);
    this.allTypes = await this.indicatorService.getTtypesList().toPromise();
    this.allClassifications = await this.indicatorService.getTClassificationsList().toPromise();
    this.indicatorWizardService.currentData.subscribe( indicatorWizardModel => {

      // Keep a local copy of the model
      this.model = indicatorWizardModel ? indicatorWizardModel : new IndicatorWizardModel();
    });

    this.indicatorWizardService.navigation.subscribe( event => {
      if ( event )  {
        this.saveLocalDataToModel();
      }
    });

  }

  openNewItemDialog(event: MouseEvent, source: string) {

      const dialogRef = this.dialog.open(IndicatorWizNewItemDialogComponent, {
        panelClass: 'indicatorWizardPopup',
        data: {
          title: `Add new ${source}`,
          placeholder: `Enter new ${source}`
        }
      });

      dialogRef.afterClosed().subscribe(newItem => {

        if (newItem) {
          
          if ( source == 'classification' ){
            this.allClassifications = [ ...this.allClassifications, newItem ];
            this.model.indicator.classifications = this.model.indicator.classifications  ? 
                                              [ ...this.model.indicator.classifications, newItem ] :
                                              [ newItem ];
          }
          else {
            this.allTypes = [ ...this.allTypes, newItem ];
            this.model.indicator.types = this.model.indicator.types ? 
                                    [ ...this.model.indicator.types, newItem ] :
                                    [ newItem ];
          }
        }
      });
  }

  saveLocalDataToModel() {
    this.model.indicator.classDesc = this.model.indicator.classifications ? this.model.indicator.classifications.join( ", " ) : null;
    this.model.indicator.typesDesc = this.model.indicator.types ? this.model.indicator.types.join( ", " ) : null;
  }
}
