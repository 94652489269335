import { Component, OnInit } from "@angular/core";
import { CoAService } from './coa.service';

@Component({
  selector: "app-coa",
  templateUrl: "./coa.component.html",
  styleUrls: ["./coa.component.css"]
})
export class CoaComponent implements OnInit {

  public tabs = [];
  public activeTab = { prop: "", name: "" };

  constructor(private coaService: CoAService) { }

  public ngOnInit() {

    this.coaService.getTabs().then((tabs) => {
      this.tabs = tabs;
      this.activeTab = this.coaService.activeTab;
    })
  }

  public selectTab(tab) {
    if (tab !== this.activeTab) {
      this.activeTab = tab;
      this.coaService.activeTab = tab;
      this.coaService.resetCritiria();
    }
  }
}
