<div class="row">
  <div class="switch-container col-lg-7 col-md-8 col-sm-9">
    <div class="btn-group m-l-lg">
      <button title="Click to Switch Views" class="btn btn-{{ isAggregateView ? 'info' : 'default' }}"
        (click)="isAggregateView = true">
        Aggregate View
      </button>
      <button title="Click to Switch Views" class="btn btn-{{ isAggregateView ? 'default' : 'info' }}"
        (click)="isAggregateView = false">
        List View
      </button>
    </div>
  </div>
</div>

<app-aggregate-view *ngIf="isAggregateView" (isAggregateView)="changeToListView()"></app-aggregate-view>

<app-all-coa-list *ngIf="!isAggregateView" [myStaff]="true"></app-all-coa-list>