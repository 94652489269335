<div class="modal-header {{wrapModal ? 'wrap-modal':''}}">
    <h2 class="hidden">{{modelName}}</h2>
    <h3 class="modal-title pull-left">{{modelName}}</h3>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body {{wrapModal ? 'wrap-modal':''}}">

    <div *ngIf="algorithm">
        <div *ngIf="message" class="alert alert-{{message.class}}" role="alert">
            <h4><b>{{message.status}}: </b> {{message.header}}</h4>
            <h5>{{message.desc}}</h5>
        </div>
        <div>
            <form name="form" (ngSubmit)="f.form.valid && saveIndicator(algorithm)" #f="ngForm" novalidate>
                <fieldset *ngIf="indicatorForm" class="col-sm-10 m-l-md m-t-md" style="font-size: 15px;">
                    <legend class="hidden">{{modelName}} Form</legend>
                    <div class="row form-group">
                        <label class="col-sm-4 control-label" for="name_id">{{label_indicator}} Name:</label>
                        <div *ngIf="!editMode" class="col-sm-8">
                            <input class="form-control" id="name_id" name="name" type="text"
                                [(ngModel)]="algorithm.name" #name="ngModel" autocomplete="off" required
                                placeholder="Enter {{label_indicator}} Name..."
                                [ngClass]="{ 'is-invalid': f.submitted && name.invalid }">
                            <div *ngIf="f.submitted && name.invalid" class="alert alert-danger">
                                <div *ngIf="name.errors.required">{{label_indicator}} Name is required</div>
                            </div>
                        </div>
                        <div *ngIf="editMode" class="col-sm-8">{{algorithm.name}}</div>
                    </div>

                    <div class="row form-group">
                        <label class="col-sm-4 control-label" for="description">{{label_indicator}} Description:</label>
                        <div class="col-sm-8">
                            <input class="form-control" id="description" name="description" type="text"
                                [(ngModel)]="algorithm.description" #description="ngModel" autocomplete="off" required
                                placeholder="Enter short description for {{label_indicator}}.."
                                [ngClass]="{ 'is-invalid': f.submitted && description.invalid }">
                            <div *ngIf="f.submitted && description.invalid" class="alert alert-danger">
                                <div *ngIf="description.errors.required">{{label_indicator}} Description is required
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row form-group">
                        <label class="col-sm-4 control-label" for="classification_input">Classification:</label>
                        <div class="col-sm-5">
                            <ng-select [items]="allClassifications" placeholder="Select Classifications"
                                [multiple]="true" [closeOnSelect]="false" [(ngModel)]="algorithm.classifications"
                                id="classification" name="classification" (change)="classificationChange($event)">
                            </ng-select>
                            <div *ngIf="f.submitted && !classification_valid" class="alert alert-danger">
                                <div>At least one entry is required</div>
                            </div>
                        </div>
                        <div class="">
                            <a class="link-add" (click)="addClassificationModal.show()">
                                <i class="fa fa-plus"></i> Add Classification</a>
                        </div>
                    </div>


                    <div class="row form-group">
                        <label class="col-sm-4 control-label" for="type_input">Type:</label>
                        <div class="col-sm-5">
                            <ng-select [items]="allTypes" placeholder="Select Types" [multiple]="true"
                                [closeOnSelect]="false" [(ngModel)]="algorithm.types" id="types" name="types">
                            </ng-select>
                        </div>
                        <div class="">
                            <a class="link-add" (click)="addTypeModal.show()">
                                <i class="fa fa-plus"></i> Add Type</a>
                        </div>
                    </div>

                    <div class="row form-group hidden">
                        <label class="col-sm-4 control-label" for="input-data">Path to Input Data:</label>
                        <div class="col-sm-8">
                            <input class="form-control" id="input-data" name="input-data" type="text"
                                [(ngModel)]="algorithm.inputDataPath" placeholder="Enter path to input data..">
                        </div>
                    </div>
                    <div class="row form-group hidden">
                        <label class="col-sm-4 control-label" for="output-data">Path to Output Data:</label>
                        <div class="col-sm-8">
                            <input class="form-control" id="output-data" name="output-data" type="text"
                                [(ngModel)]="algorithm.outputDataPath" placeholder="Enter path to output data..">
                        </div>
                    </div>

                    <div class="line line-dashed b-b line-lg pull-in m-t-md m-b-lg"></div>

                    <div class="row form-group">
                        <label class="col-sm-4 control-label" for="recommendedCoA_input">Recommended
                            {{label_coa}}:</label>
                        <div class="col-sm-8">
                            <ng-select [items]="allProcessDefinitions" bindLabel="name" bindValue="name"
                                placeholder="Select one or multiple {{label_coas}}.." [multiple]="true"
                                [closeOnSelect]="false" [(ngModel)]="algorithm.coursesOfAction" id="coursesOfAction"
                                name="coursesOfAction">
                            </ng-select>
                        </div>
                    </div>


                    <div class="row form-group">
                        <label class="col-sm-4 control-label" for="visualizations">Visualizations:</label>
                        <div class="col-sm-8">
                            <ng-select [items]="allVisuals" bindLabel="visualizationName" bindValue="visualizationValue"
                                groupBy="visualiztionGroup" [(ngModel)]="algorithm.visualizations"
                                placeholder="Select Visualization.." name="visualizations" id="visualizations">
                            </ng-select>
                        </div>
                    </div>

                    <div class="line line-dashed b-b line-lg pull-in m-t-md m-b-lg"></div>

                    <div class="row">
                        <label class="col-sm-4 control-label" for="upload-script-file">
                            Upload Script:</label>
                        <button *ngIf="algorithm.script" class="btn-link"
                            (click)="downloadScript(this.algorithm)">{{algorithm.name}}.py</button>
                        <a *ngIf="algorithm.script" class="view-file" style="right: auto;" (click)="viewUploadedFile()">
                            View File <i class="fa fa-search-plus"></i></a>
                        <div *ngIf="!editMode" class="col-sm-8 form-group">
                            <input style="position: absolute; clip: rect(0px, 0px, 0px, 0px);" #fileInput tabindex="-1"
                                type="file" name="upload-script" id="upload-script" size="10"
                                (change)="onFileChange($event)" accept=".py">
                            <div class="bootstrap-filestyle input-group">
                                <span class="group-span-filestyle input-group-btn" tabindex="0">
                                    <label for="upload-script" class="btn btn-primary ">Choose file</label>
                                </span>
                                <input class="form-control file-name" disabled="" type="text" name="filename"
                                    [(ngModel)]="uploadedFile.filename" id="upload-script-file"
                                    [ngClass]="{ 'is-invalid': f.submitted && !uploadedFile.value }">
                            </div>
                            <div *ngIf="f.submitted && !uploadedFile.value" class="alert alert-danger">
                                <div>Script file is required</div>
                            </div>
                            <a *ngIf="uploadedFile.value" class="view-file" (click)="viewUploadedFile()">View Uploaded
                                File
                                <i class="fa fa-search-plus"></i>
                            </a>
                        </div>
                    </div>

                    <div class="row">
                        <div class="line line-dashed b-b line-lg pull-in m-t-md m-b-lg"></div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <button class="btn btn-default btn-addon" (click)="addNewAutoStartCoa($event)">Add Autostart
                                {{label_coa}}
                                <i class="fa fa-plus" style="opacity: 0.7;"></i>
                            </button>
                        </div>
                    </div>
                    <div *ngFor="let autoCoa of algorithm.autoStartCoursesOfAction;  let i=index" class="row m-t-md">

                        <div *ngIf="autoCoa.showRemovePrompt" class="container-fluid autostart-cancel-prompt">
                            <div class="row">
                                <div class="col-md-9">
                                    Are you sure you want to delete this Autostart {{label_coa}}?
                                </div>
                                <div class="autostart-cancel-prompt-buttons">
                                    <button class="btn btn-primary m-r-xs" (click)="removeAutoStartCoa(autoCoa)"> Yes
                                    </button>
                                    <button class="btn btn-secondary" (click)="cancelRemovePrompt(autoCoa)"> Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="container-fluid"
                            [ngClass]="{'autostart-disable': autoCoa.showRemovePrompt, '': !autoCoa.showRemovePrompt}">
                            <div class="row">
                                <div class="col-md-12" style="text-align:right">
                                    <a (click)="showRemovePrompt(autoCoa)">Delete</a>
                                </div>
                            </div>
                            <div class="row autostart-row">
                                <div class="col-md-4">
                                    {{label_coas}}:
                                </div>
                                <div class="col-md-8">
                                    <ng-select [items]="allProcessDefinitions" bindLabel="name" bindValue="key"
                                        placeholder="Select one or multiple {{label_coas}}.." [multiple]="true"
                                        [closeOnSelect]="false" [(ngModel)]="autoCoa.courseOfActions"
                                        [id]="'autoStartCoa_' + i" [name]="'autoStartCoa_' + i">
                                    </ng-select>
                                    <div *ngIf="autoCoa.coaIsInvalid" class="alert alert-danger">
                                        {{label_coas}} is required
                                    </div>
                                </div>
                            </div>
                            <div class="row autostart-row">
                                <div class="col-md-4">
                                    Assign To:
                                </div>
                                <div class="col-md-8">
                                    <ng-select [items]="allUsers" [(ngModel)]="autoCoa.user" bindLabel="nameAndEmail"
                                        bindValue="email" placeholder="Select Assignee" [id]="'autoStarUser_' + i"
                                        [name]="'autoStarUser_' + i">
                                    </ng-select>
                                    <div *ngIf="autoCoa.userIsInvalid" class="alert alert-danger">
                                        Assign is required
                                    </div>
                                </div>
                            </div>
                            <div class="row autostart-row">
                                <div class="col-md-4">
                                    Days out for Due Date:
                                </div>
                                <div class="col-md-8">
                                    <input class="form-control" type="number" min="0"
                                        [attr.disabled]="autoCoa.showRemovePrompt ? '' : null" [name]="'daysOut_' + i"
                                        [(ngModel)]="autoCoa.daysOut">
                                    <div *ngIf="autoCoa.dayOutIsInvalid" class="alert alert-danger">
                                        Days out is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="line line-dashed b-b line-lg pull-in m-t-md m-b-lg"></div> -->
                    </div>
                    <div class="row">
                        <div class="line line-dashed b-b line-lg pull-in m-t-lg m-b-lg"></div>
                    </div>
                </fieldset>

                <fieldset *ngIf="indicatorForm" class="col-sm-10 m-l-md m-t-md">
                    <legend class="hidden">{{modelName}} Submit</legend>
                    <div class="row m-t-xl m-b-lg">
                        <div class="col-sm-3">
                            <button type="submit" class="btn btn-primary btn-algorithm">Save</button>
                        </div>
                        <div class="col-sm-3">
                            <button type="button" class="btn btn-secondary btn-algorithm"
                                (click)="modalRef.hide()">Cancel</button>
                        </div>
                        <div class="col-sm-3" style="float:right;">
                            <input type="hidden" name="updateRecord" value={{updateRecord}}>
                            <button *ngIf="canExecute" type="submit" class="btn btn-success btn-algorithm"
                                (click)="executeAfterSave=true">Execute</button>
                        </div>
                    </div>
                </fieldset>
                <fieldset *ngIf="!indicatorForm" class="col-sm-10 m-l-md m-t-md">
                    <legend class="hidden">{{modelName}} Result</legend>
                    <div class="row m-t-xl m-b-lg">
                        <div class="col-sm-3">
                            <button type="button" class="btn btn-secondary" (click)="modalRef.hide()">Back to
                                {{label_indicators}} list</button>
                        </div>
                        <div class="col-sm-3" style="float:right;">
                            <button type="button" class="btn btn-secondary"
                                (click)="modalRef.hide();goToIndicatorDetail()">Go to {{label_indicator}}
                                Details</button>
                        </div>
                    </div>
                </fieldset>
            </form>
        </div>
    </div>
</div>

<div class="modal fade" bsModal #addClassificationModal="bs-modal" tabindex="-1" role="dialog" aria-hidden="true"
    (onShow)="wrapModal = true" (onHide)="wrapModal = false">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Add New Classification</h4>
                <button type="button" class="close pull-right" aria-label="Close"
                    (click)="addClassificationModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row form-group">
                    <div class="col-sm-12">
                        <label class="hidden" for="new-classification">new-classification</label>
                        <input class="form-control" id="new-classification" name="new-classification"
                            placeholder="Enter New Classification.." (keyup.enter)="addNewClassification()"
                            [(ngModel)]="newClass" type="text">
                    </div>
                </div>
                <button type="button" class="btn btn-primary" (click)="addNewClassification()">
                    Add
                </button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" bsModal #addTypeModal="bs-modal" tabindex="-1" role="dialog" aria-hidden="true"
    (onShow)="wrapModal = true" (onHide)="wrapModal = false">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Add New Type</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="addTypeModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row form-group">
                    <div class="col-sm-12">
                        <label class="hidden" for="new-type">new-type</label>
                        <input class="form-control" id="new-type" name="new-type" type="text" [(ngModel)]="newType"
                            (keyup.enter)="addNewType()" placeholder="Enter New Type..">
                    </div>
                </div>
                <button type="button" class="btn btn-primary" (click)="addNewType();">
                    Add
                </button>
            </div>
        </div>
    </div>
</div>