<div class="bg-light lter b-a wrapper-md">
    <div class="container-fluid">
            <div class="row">
                <div class="col-sm-3">
                    <button type="button" style="float: left" class="btn btn-secondary" (click)="createNewAlgorithm()">Create New Algorithm</button>
                    <img src="https://cdn1.iconfinder.com/data/icons/materia-arrows-symbols-vol-2/24/018_059_arrow_sort_exchange_sorting-512.png" 
                         height="25px" style="float:right" (click)="sortAlgorithms()"/>
                </div>
                    
            </div>
            <div class="row">
                <div class="col-sm-3" style="border-right: 1px solid #D3D3D3;">
                    <div class="container-fluid">
                        <div class="row" style="margin-top:20px">
                            <div class="form-group" style="width:100%">
                                <div class="input-group" style="width:100%">
                                    <span class="input-group-addon" style="text-align: center; width: 22px;">
                                            <i class="fa fa-search"></i>
                                        </span>
                                    <input type="text" class="form-control" style="height:30px;"
                                            placeholder="Search" id="inputGroup" [ngModel]="searchFilter" (ngModelChange)="filterAlgorithms($event)"/>
                                </div>
                            </div>
                        </div>
        
                        <div class="row" *ngFor='let algorithm of algorithms' >
                            <div *ngIf="!algorithm.isDraft">
                                <div (click)="onSelect(algorithm)">
                                    <div style="width:100%; font-size:2em; font-weight:bold; color: #5D606F; ">
                                        {{algorithm.name}}
                                    </div>
                                    <div style="width:100%; font-size:16px; font-style: italic; color: #5D606F;">
                                        Released {{algorithm.UpdatedDateTime | date: 'short'}}
                                    </div>
                                    <div style="margin:10px 10px 0 10px; border-bottom: 1px solid #D3D3D3;">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-9" >
                    <app-algoview [algorithm]="selectedAlgorithm"></app-algoview>
                </div>
            </div>
        </div>
</div>