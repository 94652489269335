<mat-card>
    <mat-card-content class="case-start-card">
        <ng-container *ngIf="pageType === 'CONFIRMATION'">
            <div class="row">
                <div class="col-md-1"></div>
                <div class="col-md-10 case-start-success">
                    <div>{{coaConfirmationMessage}}<span
                            class="case-start-case-id">{{selectedProcessDefintion?.name}}</span></div>
                    <div>{{caseConfirmationMessage}} <span class="case-start-case-id">{{caseId}}</span>. </div>
                    <div>{{secondaryConfirmationMessage}}</div>
                </div>
                <div class="col-md-1"></div>
            </div>
            <div class="row">
                <div class="col-md-8">
                    <button class="btn btn-md btn-light" (click)="goBack()">Previous Page</button>
                </div>
                <div [ngClass]="instanceId ? 'col-md-4 case-start-confirm-btn' : 'col-md-2 case-start-confirm-btn'">
                    <button *ngIf="instanceId" class="btn btn-md btn-primary" color="primary"
                        (click)="openCourseOfAction()">
                        View {{stixConfig.coa_singular}}
                    </button>
                </div>
                <div class="col-md-2 case-start-confirm-btn">
                    <button *ngIf="caseId" class="btn btn-md btn-primary" color="primary"
                        (click)="openCaseDetailPage()">
                        View Case
                    </button>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="pageType !== 'CONFIRMATION' && taId">

            <div class="row" *ngIf="caseId">
                <div class="col-md-2 case-start-label">Case ID:</div>
                <div class="col-md-2 case-start-description">{{caseId}}</div>
            </div>
            <div class="row">
                <div class="col-md-2 case-start-label">{{stixConfig.threatActor_singular}}:</div>
                <div class="col-md-2 case-start-description">{{name}}</div>
            </div>
            <div class="row">
                <div class="col-md-2 case-start-label">{{stixConfig.threatActor_singular}} ID:</div>
                <div class="col-md-2 case-start-description">{{taId}}</div>
            </div>
            <div class="row">
                <div class="col-md-12 case-start-divider">
                    <mat-divider></mat-divider>
                </div>
            </div>


            <div class="row" *ngIf="pageType !== 'COA_START'">
                <div class="col-md-2 case-start-label">{{stixConfig.coa_plural}}:<span
                        class="case-start-required-label">*</span></div>
                <div class="col-md-7 case-start-no-coas" *ngIf="coas.length == 0"> There are no unassigned
                    {{stixConfig.coa_plural}} for this Threat Actor</div>
                <div class="col-md-7" *ngIf="coas && coas.length > 0">
                    <ng-select [items]="courseOfactionList" bindLabel="text"
                        placeholder="Select {{stixConfig.coa_plural}}" [multiple]="true" [closeOnSelect]="false"
                        [(ngModel)]="selectedCoursesOfAction">
                    </ng-select>
                    <div>
                        <small *ngIf="courseOfactionList?.length === selectedCoursesOfAction?.length"
                            style="color:#337ab7"> *There are no more unassigned {{stixConfig.coa_plural}} for this
                            {{stixConfig.threatActor_singular}}</small>
                    </div>
                </div>

            </div>

            <ng-container *ngIf="pageType === 'COA_START'">
                <div class="row">
                    <div class="col-md-2 case-start-label">
                        {{stixConfig.coa_singular}}:
                        <span class="case-start-required-label">*</span>
                    </div>
                    <div class="col-md-7 case-start-input">
                        <ng-select [items]="processDefinitions" bindLabel="text" bindValue="id"
                            placeholder="-- Select a Recommended {{stixConfig.coa_singular}} --"
                            (change)="processDefinitionSelected($event)">
                        </ng-select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2 case-start-label">
                        Due Date:
                        <span class="case-start-required-label">*</span>
                    </div>
                    <div class="col-md-7 case-start-input">
                        <input class="form-control date-input" type="date"
                            [ngModel]="courseOfActionDueDate | date:'yyyy-MM-dd'"
                            (ngModelChange)="courseOfActionDueDate = $event">
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2 case-start-label">
                        Assignee
                        <span class="case-start-required-label">*</span>
                    </div>
                    <div class="col-md-7 case-start-input">
                        <ng-select [items]="usersList" bindLabel="text" bindValue="id" 
                            placeholder="-- Select Assignee --" (change)="coaUserSelected($event)">
                        </ng-select>
                    </div>
                </div>


                <div class="row">
                    <div class="col-md-12 case-start-divider">
                        <mat-divider></mat-divider>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-2 case-start-label">
                        Next Action:
                    </div>
                    <div class="col-md-7">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="chkNextAction" value="start_case"
                                id="start_case" [(ngModel)]="nextAction">
                            <label class="form-check-label" for="start_case">
                                <span class="case-start-next-action-label">Start a case</span>
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="chkNextAction" [(ngModel)]="nextAction"
                                id="assign_case" value="assign_case">
                            <label class="form-check-label" for="assign_case">
                                <span class="case-start-next-action-label">Assign an existing case</span>
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="chkNextAction" [(ngModel)]="nextAction"
                                id="no_action" value="no_action">
                            <label class="form-check-label" for="no_action">
                                <span class="case-start-next-action-label">Start {{stixConfig.coa_singular}} without
                                    case</span>
                            </label>
                        </div>
                    </div>
                </div>


            </ng-container>

            <div class="row">
                <div class="col-md-12 case-start-divider">
                    <mat-divider></mat-divider>
                </div>
            </div>
            <ng-container *ngIf="showNewCaseSection()">
                <div class="row">
                    <div class="col-md-2 case-start-label">Due Date:</div>
                    <div class="col-md-3 case-start-input">
                        <input class="form-control date-input" type="date" [ngModel]="dueDate | date:'yyyy-MM-dd'"
                            (ngModelChange)="dueDate = $event">
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2 case-start-label">Type:<span class="case-start-required-label">*</span></div>
                    <div class="col-md-3 case-start-input" *ngIf="!showTypeEdit">
                        <ng-select [items]="typesList" placeholder="Select Types"
                            [multiple]="true" [closeOnSelect]="false" [(ngModel)]="selectedTypes">
                        </ng-select>

                    </div>
                    <div class="col-md-3 case-start-input" *ngIf="showTypeEdit">
                        <input class="form-control" [formControl]="newTypeControl" placeholder="Enter new type">
                    </div>
                    <div class="col-md-2 case-start-add-type-cell">
                        <button class="btn btn-md btn-primary" (click)="toggleTypeSection()">
                            {{ showTypeEdit ? "Add" : "+ Add Type" }}
                        </button>
                        <button class="btn btn-md btn-light case-start-type-cancel" *ngIf="showTypeEdit"
                            (click)="cancelTypeEdit()">Cancel</button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2 case-start-label">Priority:</div>
                    <div class="col-md-3 case-start-input">
                        <select [(ngModel)]="selectedPriority" class="form-control">
                            <option *ngFor="let priority of priorities" [ngValue]="priority">
                                {{priority}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2 case-start-label">Assignee:<span class="case-start-required-label">*</span>
                    </div>
                    <div class="col-md-4 case-start-input" style="margin-top:14px;">
                        <ng-select [items]="usersList" bindLabel="text" bindValue="id" [(ngModel)]="selectedUsers"
                            placeholder="-- Select Assignee --">
                        </ng-select>
                    </div>
                </div>

            </ng-container>

            <ng-container *ngIf="showNoAssignedCases">
                <div class="row">
                    <div class="display-msg">
                        There are no assigned Cases for this Threat Actor
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="showExistingCasesSection() && !hideExistingCases">
                <div class="row">
                    <div class="col-md-1 case-start-label">Case ID:</div>
                    <div class="col-md-4 case-start-input" style="margin-top:14px;">
                        <ng-select [items]="casesList" bindLabel="text" bindValue="id" (change)="caseSelected($event)"
                            placeholder="-- Select Case --">
                        </ng-select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-2 case-start-label">Type:</div>
                    <div class="col-sm-3 case-start-description">{{prevCaseType}}</div>
                    <div class="col-sm-2 case-start-label">Due Date:</div>
                    <div class="col-sm-3 case-start-description">{{prevDueDate}}</div>
                </div>
                <div class="row">
                    <div class="col-sm-2 case-start-label">Assign To:</div>
                    <div class="col-sm-3 case-start-description">{{prevAssignToDisplay}}</div>
                    <div class="col-sm-2 case-start-label">Priority:</div>
                    <div class="col-sm-3 case-start-description">{{prevPriority}}</div>
                </div>

            </ng-container>
            <div class="row">
                <div class="col-md-10"></div>
                <div class="col-md-1">
                    <button class="btn btn-md btn-light" (click)="goBack()">Cancel</button>
                </div>
                <div class="col-md-1">
                    <button class="btn btn-md btn-primary" (click)="saveForm()"
                        [disabled]="disableSaveButton()">Save</button>
                </div>
            </div>
        </ng-container>
    </mat-card-content>
</mat-card>