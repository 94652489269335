import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";

import { StixConfigService } from "../../shared/services/stixConfig.service";
import { CaseHistory, CaseHistoryType, CaseService } from "../case-service";

import { ToastrService } from "ngx-toastr";

import { fromEvent } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { distinctUntilChanged } from "rxjs/operators";
import { tap } from "rxjs/operators";

@Component({
  selector: "case-coa-history",
  styleUrls: ["./case-coa-history.component.css"],
  templateUrl: "./case-coa-history.component.html",
})
export class CaseCoaHistoryComponent implements OnInit {

  @Input() public caseId: string;
  @ViewChild(MatPaginator) public paginator: MatPaginator;
  @ViewChild(MatSort) public sort: MatSort;
  @ViewChild("input") public input: ElementRef;

  public displayedColumns: string[] = [
    "instanceUIId",
    "courseOfActionName",
    "numberOfFields",
    "fieldsExpanded",
    "modifiedUserFullName",
    "modified",
    "instanceId",
  ];
  public dataSource = new MatTableDataSource<CaseHistory>();

  constructor(
    public stixConfig: StixConfigService,
    public caseService: CaseService,
    public toastr: ToastrService
  ) { }

  public async ngOnInit() {
    await this.loadCaseCoaHistory();
    this.dataSource.filterPredicate = this.createFilter();
  }

  public ngAfterViewInit(): void {

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    fromEvent(this.input.nativeElement, "keyup")
      .pipe(
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.dataSource.filter = this.input.nativeElement.value.trim().toLocaleLowerCase();
        }),
      )
      .subscribe();
  }

  public createFilter(): (data: CaseHistory, filter: string) => boolean {

    const filterFunction = (data: CaseHistory, filter: string): boolean => {

      return data.searchString.includes(filter.toLowerCase());
    };
    return filterFunction;

  }

  public async loadCaseCoaHistory() {
    const caseCoaHistory = await this.caseService.getHistory(this.caseId, CaseHistoryType.COURSE_OF_ACTION).toPromise();
    this.dataSource.data = caseCoaHistory;
  }

  public async refreshList() {
    this.input.nativeElement.value = null;
    await this.loadCaseCoaHistory();
    this.dataSource.filter = null;
  }

}
